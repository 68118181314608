import { EntityTypes } from '@prisma/client'
import classNames from 'classnames'
import moment from 'moment'
import { NavLink } from 'react-router-dom'

import EntityLabel from '@components/entity/EntityLabel'
import ProgressBar from '@components/progressBar/ProgressBar'
import UserLabel from '@components/user/UserLabel'

import styles from './RelationshipPreviewCard.module.scss'

const RelationshipPreviewCard = ({
    id,
    entityId,
    entityName,
    entityType,
    internalReference,
    latestReviewCreateDate,
    ownerUser,
    checklistPercentProgress,
}: {
    id?: string
    entityId?: string
    entityName?: string
    entityType?: EntityTypes
    internalReference?: string
    latestReviewCreateDate?: Date
    ownerUser?: any
    checklistPercentProgress?: number
}) => {
    if (!id)
        return (
            <div
                className={classNames({
                    [styles.relationshipPreviewCard]: true,
                    [styles.placeholder]: true,
                })}
            />
        )

    return (
        <NavLink
            to={`/relationships/${id}`}
            className={styles.relationshipPreviewCard}
        >
            <div className={styles.top}>
                <div>
                    <EntityLabel
                        id={entityId}
                        entityType={entityType}
                        name={entityName}
                        clickable
                    />
                    <p>{internalReference}</p>
                </div>
            </div>
            <div className={styles.bottom}>
                <div>
                    <p>{moment(latestReviewCreateDate).format('Do MMM YYYY')}</p>
                    <UserLabel user={ownerUser} />
                </div>
                {checklistPercentProgress ? (
                    <ProgressBar
                        progress={checklistPercentProgress * 100}
                        blue={true}
                    />
                ) : null}
            </div>
        </NavLink>
    )
}

export default RelationshipPreviewCard
