import Entity from '../layouts/Entity'
import { Grants } from '@prisma/client'
import { Navigate, Route } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import AddControllingParty from './views/AddControllingParty'
import ControllingParty from './views/ControllingParty'
import ControllingPartyVersion from './views/ControllingPartyVersion'
import ListControllingParties from './views/ListControllingParties'
import ListPositionsHeld from './views/ListPositionsHeld'

export const EntityRelationRoutes = ({
    isNestedInsideRelationship,
}: {
    isNestedInsideRelationship: boolean
}) => {
    const hasManageEntityGrant = useCheckGrants(Grants.ManageEntity)

    return (
        <>
            <Route
                path='controlling-parties'
                element={
                    !isNestedInsideRelationship ? (
                        <Entity>
                            <ListControllingParties />
                        </Entity>
                    ) : (
                        <ListControllingParties />
                    )
                }
            >
                <Route
                    path='create'
                    element={
                        hasManageEntityGrant ? <AddControllingParty /> : <Navigate to={`..`} />
                    }
                />
            </Route>
            <Route
                path='controlling-parties/:controllingPartyId'
                element={<ControllingParty />}
            />
            <Route
                path='controlling-parties/:controllingPartyId/versions/:controllingPartyVersionId'
                element={
                    <ControllingPartyVersion
                        isNestedInsideRelationship={isNestedInsideRelationship}
                    />
                }
            />
            <Route
                path='positions-held'
                element={
                    <Entity>
                        <ListPositionsHeld />
                    </Entity>
                }
            />
        </>
    )
}
