import { trpc } from '@utils/trpc'

export const useCreateRemediation = onSuccess => {
    const context = trpc.useContext()

    return trpc.relationshipReviews.createRemediation.useMutation({
        onSuccess,
        onSettled: async () => {
            await context.relationshipReviews.invalidate()
        },
    })
}

export const useGetRemediationById = (remediationId, onSuccess) =>
    trpc.relationshipReviews.getRemediationById.useQuery({ remediationId }, { onSuccess })

export const useMarkRemediationAsComplete = onSuccess => {
    const context = trpc.useContext()

    return trpc.relationshipReviews.markRemediationAsComplete.useMutation({
        onSuccess,
        onSettled: async () => {
            await context.relationshipReviews.invalidate()
        },
    })
}

export const useDeleteRemediation = onSuccess => {
    const context = trpc.useContext()

    return trpc.relationshipReviews.deleteRemediation.useMutation({
        onSuccess,
        onSettled: async () => {
            await context.relationshipReviews.invalidate()
        },
    })
}
