import { FormStatuses, Grants } from '@prisma/client'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import ExternalCustomerRiskAssessmentVersionForm from '@features/relationships/relationship/customerRiskAssessment/views/ExternalCustomerRiskAssessmentVersionForm'
import Initialise from '@features/relationships/relationship/customerRiskAssessment/views/Initialise'
import InternalCustomerRiskAssessmentVersionForm from '@features/relationships/relationship/customerRiskAssessment/views/InternalCustomerRiskAssessmentVersionForm'
import { useGetRelationshipReview } from '@features/relationships/relationship/hooks'
import { useCheckGrants } from '@features/user'

import Document from '@components/document/Document'
import Loading from '@components/loading/Loading'
import More from '@components/more/More'
import QueryError from '@components/queryError/QueryError'

import {
    useCreateCustomerRiskAssessmentVersionSignature,
    useExportVersionToPdf,
    useGetCustomerRiskAssessmentVersion,
    useRevertCustomerRiskAssessmentVersionToDraft,
    useUpdateCustomerRiskAssessmentVersion,
} from '../hooks'

import styles from './CustomerRiskAssessmentVersion.module.scss'

const CustomerRiskAssessmentVersion = () => {
    const isAllowed = useCheckGrants(Grants.ManageRelationship) ?? false
    const { relationshipId, relationshipReviewId, customerRiskAssessmentVersionId } = useParams()

    const form = useForm()
    const {
        reset,
        trigger,
        getValues,
        formState: { isDirty },
    } = form

    const isExternal = useWatch({ control: form.control, name: 'isExternal' })

    const relationshipReview = useGetRelationshipReview(
        relationshipId,
        relationshipReviewId,
        () => {}
    )

    const customerRiskAssessmentVersion = useGetCustomerRiskAssessmentVersion(
        customerRiskAssessmentVersionId,
        data => reset(data)
    )

    const exportVersion = useExportVersionToPdf(data => window.open(data, '_blank'))
    const updateVersion = useUpdateCustomerRiskAssessmentVersion()
    const signVersion = useCreateCustomerRiskAssessmentVersionSignature()
    const revertToDraft = useRevertCustomerRiskAssessmentVersionToDraft()

    const exportPdf = () =>
        exportVersion.mutate({
            customerRiskAssessmentVersionId: customerRiskAssessmentVersionId as string,
        })

    const update = async data => updateVersion.mutateAsync(data)

    const sign = async () => {
        try {
            const isValid = await trigger()
            if (!isValid) return null
            if (isDirty) await update(getValues())
            return signVersion.mutate({
                customerRiskAssessmentVersionId: customerRiskAssessmentVersionId as string,
            })
        } catch {
            return null
        }
    }

    const revert = async () =>
        revertToDraft.mutate({
            customerRiskAssessmentVersionId: customerRiskAssessmentVersionId as string,
        })

    const more = (
        <More>
            <button
                onClick={() =>
                    update({
                        customerRiskAssessmentVersionId: customerRiskAssessmentVersionId as string,
                        isExternal: !isExternal,
                    })
                }
                disabled={updateVersion.isLoading}
            >
                {updateVersion.isLoading ? (
                    <Loading />
                ) : isExternal ? (
                    'Switch to Internal CRA'
                ) : (
                    'Switch to External CRA'
                )}
            </button>
        </More>
    )

    if (relationshipReview.isLoading || customerRiskAssessmentVersion.isLoading) return <Loading />
    if (
        relationshipReview.isError ||
        customerRiskAssessmentVersion.isError ||
        !relationshipReview.data ||
        !customerRiskAssessmentVersion.data
    )
        return <QueryError />

    if (
        !customerRiskAssessmentVersion.data.isExternal &&
        !customerRiskAssessmentVersion.data.riskAssessmentAppliedTemplateId
    )
        return (
            <div className={styles.customerRiskAssessmentVersion}>
                <Initialise />
            </div>
        )

    return (
        <FormProvider {...form}>
            <Document
                more={more}
                isAllowed={isAllowed}
                pageTitle={`Customer Risk Assessment | ${relationshipReview.data.relationship.internalReference}`}
                showHeader={false}
                landscape={false}
                type={`${
                    customerRiskAssessmentVersion.data.isExternal ? 'External ' : ''
                }Customer Risk Assessment`}
                showBreadcrumbs={true}
                breadcrumbs={[
                    {
                        label: relationshipReview.data.relationship.internalReference,
                        link: `/relationships/${relationshipReview.data.relationship.id}`,
                    },
                    {
                        label: relationshipReview.data.versionName,
                        link: `/relationships/${relationshipReview.data.relationship.id}/reviews/${relationshipReview.data.id}`,
                    },
                ]}
                title={`${
                    customerRiskAssessmentVersion.data.isExternal ? 'External ' : ''
                }Customer Risk Assessment`}
                isDraft={customerRiskAssessmentVersion.data.status === FormStatuses.Draft}
                isPending={false}
                versionId={customerRiskAssessmentVersion.data.id}
                versionName={`Version ${customerRiskAssessmentVersion.data.version}`}
                Form={
                    customerRiskAssessmentVersion.data.isExternal
                        ? ExternalCustomerRiskAssessmentVersionForm
                        : InternalCustomerRiskAssessmentVersionForm
                }
                exportPdf={{
                    function: exportPdf,
                    isLoading: exportVersion.isLoading,
                }}
                update={{
                    function: update,
                    isLoading: updateVersion.isLoading,
                    isSuccess: updateVersion.isSuccess,
                }}
                sign={{
                    function: sign,
                    isLoading: signVersion.isLoading,
                }}
                revertToDraft={
                    relationshipReview.data.status === 'Profiling' ||
                    relationshipReview.data.status === 'Remediation'
                        ? {
                              function: revert,
                              isLoading: revertToDraft.isLoading,
                          }
                        : undefined
                }
                signature={{
                    user: customerRiskAssessmentVersion.data.signature?.user as {
                        firstName: string
                        lastName: string
                    },
                    date: customerRiskAssessmentVersion.data.signature?.createDate as Date,
                }}
                error={updateVersion.isError || signVersion.isError || revertToDraft.isError}
            />
        </FormProvider>
    )
}

export default CustomerRiskAssessmentVersion
