import AbsoluteChangeIndicator from '@features/dashboard/components/absoluteChangeIndicator/AbsoluteChangeIndicator'

import styles from './MetricCard.module.scss'

const MetricCard = ({ label, value, change }: { label: string; value: number; change: number }) => {
    return (
        <div className={styles.metricCard}>
            <p>{label}</p>
            <div>
                <p>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                <AbsoluteChangeIndicator
                    change={change}
                    large={false}
                    color={null}
                />
            </div>
        </div>
    )
}

export default MetricCard
