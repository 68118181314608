import { useFormContext } from 'react-hook-form'

import { useGetSelfUserMemberships } from '@features/organisation/teamMembers'
import TemplateTable from '@features/riskAssessmentPolicies/components/TemplateTable'

import InputWrapper from '@components/inputWrapper/InputWrapper'
import DateInput from '@components/inputs/dateInput/DateInput'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'
import UserSelect from '@components/inputs/userSelect/UserSelect'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import styles from '@styles/Form.module.scss'

const RiskAssessmentPolicyForm = ({ readOnly }) => {
    const {
        formState: { errors },
    } = useFormContext()

    const users = useGetSelfUserMemberships()

    if (users.isLoading) return <Loading />
    if (users.isError || !users.data) return <QueryError isInsideModal={true} />

    return (
        <>
            <TextInput
                hidden
                name='id'
            />

            <TextInput
                hidden
                name='riskAssessmentPolicyId'
            />

            <div className={styles.row}>
                {!readOnly ? (
                    <>
                        <InputWrapper
                            label='Policy Name'
                            input={
                                <TextInput
                                    name={'policyName'}
                                    required
                                    readOnly={readOnly}
                                />
                            }
                            errors={errors.policyName}
                            readOnly={readOnly}
                        />
                        <InputWrapper
                            label='Policy Version'
                            input={
                                <TextInput
                                    name={'versionName'}
                                    required
                                    readOnly={readOnly}
                                />
                            }
                            errors={errors.versionName}
                            readOnly={readOnly}
                        />
                    </>
                ) : null}
            </div>

            <div className={styles.row}>
                <InputWrapper
                    label='Template Name'
                    input={
                        <TextInput
                            name={'riskAssessmentTemplate.name'}
                            required
                            readOnly={readOnly}
                        />
                    }
                    errors={errors.riskAssessmentTemplate}
                    readOnly={readOnly}
                />
                <InputWrapper
                    label='Template Type'
                    input={
                        <Select
                            name={'riskAssessmentTemplate.riskAssessmentTemplateType'}
                            options={[
                                {
                                    label: 'Customer',
                                    value: 'Customer',
                                },
                                {
                                    label: 'Technology',
                                    value: 'Technology',
                                },
                                {
                                    label: 'Introducer',
                                    value: 'Introducer',
                                },
                            ]}
                            readOnly={readOnly}
                        />
                    }
                    errors={errors.riskAssessmentTemplate}
                    readOnly={readOnly}
                />
            </div>

            <TemplateTable
                name={'riskAssessmentTemplate.questions'}
                readOnly={readOnly}
            />

            <div className={styles.row}>
                <InputWrapper
                    label='Owner'
                    input={
                        <UserSelect
                            users={users.data.map(user => user.user)}
                            name={'ownerUserId'}
                            required={true}
                            readOnly={readOnly}
                        />
                    }
                    errors={errors.ownerUserId}
                    readOnly={readOnly}
                />
                <InputWrapper
                    label='Next Review Date'
                    input={
                        <DateInput
                            name={'nextReviewDate'}
                            placeholder={''}
                            readOnly={readOnly}
                            required
                        />
                    }
                    errors={errors.nextReviewDate}
                    readOnly={readOnly}
                />
            </div>
        </>
    )
}

export default RiskAssessmentPolicyForm
