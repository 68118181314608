import { trpc } from '@utils/trpc'

export const useGetRoles = () => trpc.userRoles.getMany.useQuery()

export const useCreateRole = onSuccess => {
    const context = trpc.useContext()

    return trpc.userRoles.create.useMutation({
        onSuccess,
        onSettled: () => context.userRoles.invalidate(),
    })
}

export const useGetRole = (id, onSuccess) => trpc.userRoles.getById.useQuery({ id }, { onSuccess })

export const useUpdateRole = onSuccess => {
    const context = trpc.useContext()

    return trpc.userRoles.update.useMutation({
        onSuccess,
        onSettled: () => context.userRoles.invalidate(),
    })
}
