import { Navigate, Route, Routes } from 'react-router-dom'

import CreateAccount from './views/CreateAccount'
import CreateAccountSuccess from './views/CreateAccountSuccess'

export const CreateAccountRoutes = () => {
    return (
        <Routes>
            <Route
                path=''
                element={<CreateAccount />}
            />
            <Route
                path='/success'
                element={<CreateAccountSuccess />}
            />
            <Route
                path='*'
                element={
                    <Navigate
                        to='.'
                        replace
                    />
                }
            />
        </Routes>
    )
}
