import { Grants } from '@prisma/client'
import classNames from 'classnames'
import { useState } from 'react'
import { Lock } from 'react-feather'
import { useParams } from 'react-router-dom'

import { useUpdateCustomerRiskAssessmentVersion } from '@features/relationships/relationship/customerRiskAssessment/hooks'
import InitialiseTemplate from '@features/relationships/relationship/customerRiskAssessment/views/InitialiseTemplate'
import { useCheckGrants } from '@features/user'

import Modal from '@layouts/modal/Modal'

import styles from './Initialise.module.scss'

const Initialise = () => {
    const isAllowed = useCheckGrants(Grants.ManageRelationship) ?? false
    const { customerRiskAssessmentVersionId } = useParams()
    const [internal, setInternal] = useState(false)

    const updateCustomerRiskAssessmentVersion = useUpdateCustomerRiskAssessmentVersion()
    const chooseExternal = () =>
        updateCustomerRiskAssessmentVersion.mutate({
            customerRiskAssessmentVersionId: customerRiskAssessmentVersionId as string,
            isExternal: true,
        })

    if (!isAllowed)
        return (
            <Modal
                overlay={false}
                heading='Initialise Customer Risk Assessment'
            >
                <div
                    className={classNames({
                        [styles.error]: true,
                        [styles.notAllowed]: true,
                    })}
                >
                    <Lock size={15} />
                    <p>
                        You do not have the necessary permissions to initialise a customer risk
                        assessment.
                    </p>
                </div>
            </Modal>
        )

    if (!internal)
        return (
            <Modal
                overlay={false}
                heading='Initialise Customer Risk Assessment'
            >
                <div className={styles.selector}>
                    <div onClick={() => setInternal(true)}>
                        <h3>Internal</h3>
                    </div>
                    <div onClick={() => chooseExternal()}>
                        <h3>External</h3>
                    </div>
                </div>
            </Modal>
        )

    return (
        <Modal
            overlay={false}
            heading='Initialise Customer Risk Assessment'
        >
            <InitialiseTemplate back={() => setInternal(false)} />
        </Modal>
    )
}

export default Initialise
