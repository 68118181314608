import { PieChart } from '@mui/x-charts'

import AbsoluteChangeIndicator from '@features/dashboard/components/absoluteChangeIndicator/AbsoluteChangeIndicator'

import styles from './RiskProfileCard.module.scss'

type RiskClassificationFigures = {
    change: number
    absolute: number
}

const RiskProfileCard = ({
    lowerRisk,
    standardRisk,
    higherRisk,
}: {
    lowerRisk: RiskClassificationFigures
    standardRisk: RiskClassificationFigures
    higherRisk: RiskClassificationFigures
}) => {
    return (
        <div className={styles.riskProfileCard}>
            <div className={styles.left}>
                <p>Risk Profile</p>
                <div className={styles.changes}>
                    <AbsoluteChangeIndicator
                        change={lowerRisk.change}
                        large={true}
                        color={'#28E554'}
                    />
                    <AbsoluteChangeIndicator
                        change={standardRisk.change}
                        large={true}
                        color={'#FFB95A'}
                    />
                    <AbsoluteChangeIndicator
                        change={higherRisk.change}
                        large={true}
                        color={'#FF5A5A'}
                    />
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.pieChartContainer}>
                    <PieChart
                        width={60}
                        height={60}
                        series={[
                            {
                                data: [
                                    { value: lowerRisk.absolute, color: '#28E554' },
                                    { value: standardRisk.absolute, color: '#FFB95A' },
                                    { value: higherRisk.absolute, color: '#FF5A5A' },
                                ],
                                innerRadius: 15,
                                outerRadius: 30,
                                cx: 25,
                                cy: 25,
                            },
                        ]}
                    />
                </div>
                <div className={styles.absoluteValues}>
                    <div>
                        <div>
                            <span className={styles.lowerRisk} />
                            <p>Lower Risk</p>
                        </div>
                        <p>{lowerRisk.absolute.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                    </div>
                    <div>
                        <div>
                            <span className={styles.standardRisk} />
                            <p>Standard Risk</p>
                        </div>
                        <p>
                            {standardRisk.absolute.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </p>
                    </div>
                    <div>
                        <div>
                            <span className={styles.higherRisk} />
                            <p>Higher Risk</p>
                        </div>
                        <p>
                            {higherRisk.absolute.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RiskProfileCard
