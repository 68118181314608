import { Navigate, Route, Routes } from 'react-router-dom'

import Login from './views/Login'

export const LoginRoutes = () => {
    return (
        <Routes>
            <Route
                path=''
                element={<Login />}
            />
            <Route
                path='*'
                element={
                    <Navigate
                        to='.'
                        replace
                    />
                }
            />
        </Routes>
    )
}
