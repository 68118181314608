import { Grants } from '@prisma/client'
import { NavLink, useParams } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import Button from '@components/button/Button'
import EntityTypeIndicator from '@components/entity/EntityTypeIndicator'
import Loading from '@components/loading/Loading'
import More from '@components/more/More'
import Tabs from '@components/tabs/Tabs'

import { useExportEntityToPdf, useGetEntity } from '../hooks'

import styles from './Entity.module.scss'

const Entity = ({ children }) => {
    const { entityId } = useParams()
    const entity = useGetEntity({ entityId }, null)
    const isAllowed = useCheckGrants(Grants.ManageEntity) ?? false

    const exportEntity = useExportEntityToPdf(data => window.open(data, '_blank'))

    let tabs: any[] = []

    tabs.push(
        ...[
            {
                label: 'Identity',
                link: `/entities/${entityId}/identity`,
                end: false,
            },
            {
                label: 'Documents',
                link: `/entities/${entityId}/documents`,
                alerts: entity.data?.identityCompletion?.unverifiedFields?.length,
            },
            {
                label: 'Controlling Parties',
                link: `/entities/${entityId}/controlling-parties`,
            },
            {
                label: 'Positions Held',
                link: `/entities/${entityId}/positions-held`,
            },
        ]
    )

    if (entity.isLoading) return <Loading />
    if (!entity.data) return null

    if (entity.data.isIntroducer)
        tabs.push({
            label: 'Introducer Risk Assessments',
            link: `/entities/${entityId}/introducer-risk-assessments`,
        })

    return (
        <>
            <div className={styles.heading}>
                <div>
                    <EntityTypeIndicator
                        entityType={entity.data.entityType}
                        size={26}
                    />
                    <h1>{entity.data.name}</h1>
                </div>
            </div>

            <Tabs
                tabs={tabs}
                more={
                    isAllowed ? (
                        <More>
                            <NavLink to={`/entities/${entityId}/delete`}>Delete Entity</NavLink>
                            <Button
                                label={'Export Entity'}
                                onClick={() =>
                                    exportEntity.mutate({
                                        entityId: entityId as string,
                                    })
                                }
                                loading={exportEntity.isLoading}
                                outline={true}
                            />
                        </More>
                    ) : undefined
                }
            />

            {children}
        </>
    )
}

export default Entity
