import { useNavigate, useParams } from 'react-router-dom'

import Loading from '@components/loading/Loading'

import { useGetCustomPolicyById } from '../hooks'

const CustomPolicy = () => {
    const { customPolicyId } = useParams()
    const navigate = useNavigate()

    useGetCustomPolicyById(customPolicyId, data => {
        navigate(`/custom-policies/${customPolicyId}/versions/${data.latestVersion.id}`, {
            replace: true,
        })
    })

    return <Loading />
}

export default CustomPolicy
