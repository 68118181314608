import { useGetSelf } from '@features/account'
import { useGetOrganisation } from '@features/organisation'

import UserLabel from '@components/user/UserLabel'

import styles from './DashboardHeader.module.scss'

const DashboardHeader = () => {
    const organisation = useGetOrganisation()
    const user = useGetSelf(() => {})

    if (!organisation.data || !user.data) return null

    return (
        <div className={styles.dashboardHeader}>
            <main>
                <h1>{organisation.data.name}</h1>
                <UserLabel user={user.data} />
            </main>
        </div>
    )
}

export default DashboardHeader
