import classNames from 'classnames'
import { Plus, X } from 'react-feather'
import { useFieldArray, useFormContext } from 'react-hook-form'

import AutoGrowTextArea from '@components/inputs/autoGrowTextArea/AutoGrowTextArea'

import Option from './Option'

import styles from './Question.module.scss'

const Question = ({ name, readOnly, add, remove }) => {
    const { control } = useFormContext()
    const options = useFieldArray({
        control,
        name: `${name}.options`,
    })

    return (
        <div
            className={classNames({
                [styles.question]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <div>
                <AutoGrowTextArea
                    name={`${name}.title`}
                    readOnly={readOnly}
                />

                <button
                    className={styles.add}
                    onClick={add}
                    type={'button'}
                >
                    <Plus
                        color={'#ffffff'}
                        size={14}
                    />
                    Question
                </button>
            </div>

            <div className={styles.options}>
                {options.fields.map((option, index) => (
                    <Option
                        key={option.id}
                        name={`${name}.options.${index}`}
                        readOnly={readOnly}
                        add={() => options.insert(index + 1, { title: '', riskLevelId: null })}
                        remove={options.fields.length === 1 ? null : () => options.remove(index)}
                    />
                ))}
            </div>

            <div>
                <AutoGrowTextArea
                    name={`${name}.justification`}
                    readOnly={readOnly}
                />
            </div>

            {remove ? (
                <button
                    className={styles.remove}
                    onClick={remove}
                    type={'button'}
                >
                    <X
                        color={'#ffffff'}
                        size={16}
                    />
                </button>
            ) : null}
        </div>
    )
}

export default Question
