import { Grants } from '@prisma/client'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import Relationships from './layouts/Relationships'
import { RelationshipRoutes } from './relationship'
import CreateRelationship from './views/CreateRelationship'
import ListRelationships from './views/ListRelationships'

export const RelationshipsRoutes = () => {
    const hasManageRelationshipGrant = useCheckGrants(Grants.ManageRelationship)

    return (
        <Routes>
            <Route
                path=''
                element={
                    <Relationships>
                        <ListRelationships />
                    </Relationships>
                }
            />
            <Route
                path='create'
                element={
                    hasManageRelationshipGrant ? (
                        <Relationships>
                            <CreateRelationship />
                        </Relationships>
                    ) : (
                        <Navigate to='/relationships' />
                    )
                }
            />
            <Route
                path={':relationshipId/*'}
                element={<RelationshipRoutes />}
            />
        </Routes>
    )
}
