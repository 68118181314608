import { Route, Routes } from 'react-router-dom'

import CreateCustomPolicy from './views/CreateCustomPolicy'
import CustomPolicy from './views/CustomPolicy'
import CustomPolicyVersion from './views/CustomPolicyVersion'

export const CustomPolicyRoutes = () => {
    return (
        <Routes>
            <Route
                path='create'
                element={<CreateCustomPolicy />}
            />
            <Route
                path=':customPolicyId'
                element={<CustomPolicy />}
            />
            <Route
                path=':customPolicyId/versions/:customPolicyVersionId'
                element={<CustomPolicyVersion />}
            />
        </Routes>
    )
}
