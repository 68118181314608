import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { httpBatchLink, TRPCClientError } from '@trpc/client'
import Cookies from 'js-cookie'
import { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import superjson from 'superjson'

import { Logout } from '@features/logout'

import { env } from '@utils/env'
import { trpc } from '@utils/trpc'

import Routes from './routes'

import '@styles/global.scss'

export function App() {
    const onError = error => {
        if (!(error instanceof TRPCClientError)) return null
        if (error.data?.httpStatus !== 401) return null

        // This is to catch 401s caused by 'User must sign in with two-factor authentication'
        if (window.location.pathname === '/two-factor') return null

        // This is to catch 401s caused by 'User must be associated with an organisation'
        if (error.data.path === 'users.getCurrentRole') return null
        if (error.data.path === 'organisations.getSelf') return null

        Logout()
    }

    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        retry: false,
                        refetchOnWindowFocus: false,
                    },
                },
                queryCache: new QueryCache({ onError }),
                mutationCache: new MutationCache({ onError }),
            })
    )
    const [trpcClient] = useState(() =>
        trpc.createClient({
            links: [
                httpBatchLink({
                    url: `${env.REACT_APP_API_BASE_URL as string}/trpc`,
                    headers() {
                        return {
                            Authorization: `Bearer ${Cookies.get('tokenString')}`,
                        }
                    },
                }),
            ],
            transformer: superjson,
        })
    )
    return (
        <BrowserRouter>
            <trpc.Provider
                client={trpcClient}
                queryClient={queryClient}
            >
                <QueryClientProvider client={queryClient}>
                    <Routes />
                </QueryClientProvider>
            </trpc.Provider>
        </BrowserRouter>
    )
}
