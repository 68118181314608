import { Grants, PolicyStatuses } from '@prisma/client'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { useGetSelf } from '@features/account'
import { useGetRelationshipReview } from '@features/relationships/relationship/hooks'
import RelationshipApprovalForm from '@features/relationships/relationship/relationshipApproval/views/RelationshipApprovalForm'
import { useCheckGrants } from '@features/user'

import Document from '@components/document/Document'
import Loading from '@components/loading/Loading'
import More from '@components/more/More'
import QueryError from '@components/queryError/QueryError'

import {
    useCreateApprovalVersionCounterSignature,
    useCreateApprovalVersionSignature,
    useExportVersionToPdf,
    useGetApprovalVersion,
    useRevertApprovalVersionToDraft,
    useUpdateApprovalVersion,
} from '../hooks'

const RelationshipApprovalVersion = () => {
    const isAllowed = useCheckGrants(Grants.ApproveRelationship) ?? false
    const { relationshipId, relationshipReviewId, relationshipApprovalVersionId } = useParams()

    const form = useForm()
    const {
        reset,
        trigger,
        getValues,
        formState: { isDirty },
    } = form

    const relationshipReview = useGetRelationshipReview(
        relationshipId,
        relationshipReviewId,
        () => {}
    )

    const relationshipApprovalVersion = useGetApprovalVersion(relationshipApprovalVersionId, data =>
        reset(data)
    )
    const isExternal = useWatch({ control: form.control, name: 'isExternal' })

    const exportVersion = useExportVersionToPdf(data => window.open(data, '_blank'))
    const updateVersion = useUpdateApprovalVersion()
    const signVersion = useCreateApprovalVersionSignature()
    const counterSignVersion = useCreateApprovalVersionCounterSignature()
    const revertToDraft = useRevertApprovalVersionToDraft()
    const self = useGetSelf(() => {})

    const hasAlreadySigned =
        relationshipApprovalVersion.data?.signatures.some(
            signature => signature.userId === self.data?.id
        ) ||
        relationshipApprovalVersion.data?.counterSignatures.some(
            signature => signature.userId === self.data?.id
        )

    const exportPdf = () =>
        exportVersion.mutate({
            relationshipApprovalVersionId: relationshipApprovalVersionId as string,
        })

    const update = async data => updateVersion.mutateAsync(data)

    const sign = async () => {
        try {
            const isValid = await trigger()
            if (!isValid) return null
            if (isDirty) await update(getValues())
            return signVersion.mutate({
                relationshipApprovalVersionId: relationshipApprovalVersionId as string,
            })
        } catch {
            return null
        }
    }

    const counterSign = async () =>
        counterSignVersion.mutate({
            relationshipApprovalVersionId: relationshipApprovalVersionId as string,
        })

    const revert = async () =>
        revertToDraft.mutate({
            relationshipApprovalVersionId: relationshipApprovalVersionId as string,
        })

    if (!self) return null

    const more = (
        <More>
            <button
                onClick={() =>
                    update({
                        relationshipApprovalVersionId: relationshipApprovalVersionId as string,
                        isExternal: !isExternal,
                    })
                }
                disabled={updateVersion.isLoading}
            >
                {updateVersion.isLoading ? (
                    <Loading />
                ) : isExternal ? (
                    'Switch to Internal Approval'
                ) : (
                    'Switch to External Approval'
                )}
            </button>
        </More>
    )

    if (relationshipReview.isLoading || relationshipApprovalVersion.isLoading) return <Loading />
    if (
        relationshipReview.isError ||
        relationshipApprovalVersion.isError ||
        !relationshipReview.data ||
        !relationshipApprovalVersion.data
    )
        return <QueryError />

    return (
        <FormProvider {...form}>
            <Document
                more={more}
                isAllowed={isAllowed}
                pageTitle={`Approval | ${relationshipReview.data.relationship.internalReference}`}
                showHeader={false}
                landscape={false}
                type={'Approval'}
                showBreadcrumbs={true}
                breadcrumbs={[
                    {
                        label: relationshipReview.data.relationship.internalReference,
                        link: `/relationships/${relationshipReview.data.relationship.id}`,
                    },
                    {
                        label: relationshipReview.data.versionName,
                        link: `/relationships/${relationshipReview.data.relationship.id}/reviews/${relationshipReview.data.id}`,
                    },
                ]}
                title={'Approval'}
                isDraft={relationshipApprovalVersion.data.status === PolicyStatuses.Draft}
                isPending={relationshipApprovalVersion.data.status === PolicyStatuses.Pending}
                versionId={relationshipApprovalVersion.data.id}
                versionName={`Version ${relationshipApprovalVersion.data.version}`}
                Form={RelationshipApprovalForm}
                exportPdf={{
                    function: exportPdf,
                    isLoading: exportVersion.isLoading,
                }}
                update={{
                    function: update,
                    isLoading: updateVersion.isLoading,
                    isSuccess: updateVersion.isSuccess,
                }}
                sign={{
                    function: sign,
                    isLoading: signVersion.isLoading,
                }}
                revertToDraft={
                    relationshipReview.data.status === 'Profiling'
                        ? {
                              function: revert,
                              isLoading: revertToDraft.isLoading,
                          }
                        : undefined
                }
                counterSign={
                    hasAlreadySigned
                        ? undefined
                        : {
                              function: counterSign,
                              isLoading: counterSignVersion.isLoading,
                          }
                }
                signature={{
                    user: relationshipApprovalVersion.data.signatures[0]?.user as {
                        firstName: string
                        lastName: string
                    },
                    date: relationshipApprovalVersion.data.signatures[0]?.createDate as Date,
                }}
                counterSignatures={relationshipApprovalVersion.data.counterSignatures.map(
                    counterSignature => {
                        return {
                            user: counterSignature.user as { firstName: string; lastName: string },
                            date: counterSignature.createDate as Date,
                        }
                    }
                )}
                error={
                    updateVersion.isError ||
                    signVersion.isError ||
                    counterSignVersion.isError ||
                    revertToDraft.isError
                }
                counterSignaturesRequired={
                    relationshipApprovalVersion.data.counterSignaturesRequired
                }
            />
        </FormProvider>
    )
}

export default RelationshipApprovalVersion
