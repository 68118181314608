import moment from 'moment'
import { useParams } from 'react-router-dom'

import { useGetRelationship } from '@features/relationships/relationship/hooks'

import RiskLevelTag from '@components/tag/RiskLevelTag'
import ManyUserAvatars from '@components/user/ManyUserAvatars'
import UserLabel from '@components/user/UserLabel'

import styles from './Metadata.module.scss'

const Metadata = () => {
    const { relationshipId } = useParams()

    const relationship = useGetRelationship(relationshipId, () => {})

    if (!relationship.data) return null

    return (
        <div className={styles.metadata}>
            <div>
                <p>Owner</p>
                <UserLabel user={relationship.data.ownerUser} />
            </div>
            <div>
                <p>Risk Level</p>
                <RiskLevelTag riskLevelId={relationship.data.riskLevel?.id} />
            </div>
            <div>
                <p>Subscribers</p>
                <ManyUserAvatars users={relationship.data.subscribedUsers} />
            </div>
            <div>
                <p>Scheduled Review Date</p>
                <p>
                    {relationship.data.nextScheduledReviewDate
                        ? moment(relationship.data.nextScheduledReviewDate as Date).format(
                              'DD/MM/YYYY'
                          )
                        : '-'}
                </p>
            </div>
        </div>
    )
}

export default Metadata
