import { useNavigate, useParams } from 'react-router-dom'

import Loading from '@components/loading/Loading'

import { useGetControllingPartyById } from '../hooks'

const ControllingParty = () => {
    const { controllingPartyId } = useParams()
    const navigate = useNavigate()

    useGetControllingPartyById(controllingPartyId, data => {
        navigate(`versions/${data.latestVersion.id}`, { replace: true })
    })

    return <Loading />
}

export default ControllingParty
