import { trpc } from '@utils/trpc'

export const useGetStatisticalReturns = () =>
    trpc.statisticalReturns.getAll.useQuery(undefined, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    })

export const useCreateStatisticalReturn = () => {
    const context = trpc.useContext()

    return trpc.statisticalReturns.create.useMutation({
        onSettled: () => context.statisticalReturns.getAll.invalidate(),
    })
}

export const useDownloadStatisticalReturn = (fileName, options) =>
    trpc.fileStorage.get.useQuery({ fileName }, options)
