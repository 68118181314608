import { Grants } from '@prisma/client'
import { Plus } from 'react-feather'
import { NavLink } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import Tabs from '@components/tabs/Tabs'

import { useGetEntities } from '../hooks'

const Entities = ({ children }) => {
    const entities = useGetEntities()
    const hasManageEntityGrant = useCheckGrants(Grants.ManageEntity)

    return (
        <>
            <div className='heading withTabs'>
                <div>
                    <h1>Entities</h1>
                </div>

                {hasManageEntityGrant ? (
                    <NavLink
                        to={'/entities/create'}
                        className='link dark'
                    >
                        <Plus size={16} />
                        Create Entity
                    </NavLink>
                ) : null}
            </div>
            <Tabs
                tabs={[
                    {
                        label: 'All Entities',
                        link: '/entities',
                        count: entities.data?.length,
                    },
                ]}
            />

            <main className={'top'}>{children}</main>
        </>
    )
}

export default Entities
