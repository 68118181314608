import { Jurisdictions, RegulatedActivities } from '@prisma/client'
import { Legislation } from '@proofdesk/common'
import Cookies from 'js-cookie'
import { FormProvider, useForm, useWatch } from 'react-hook-form'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import MultiSelect from '@components/inputs/multiSelect/MultiSelect'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'

import { env } from '@utils/env'
import pascalToReadable from '@utils/pascalToReadable'

import { useCreateOrganisation } from '../hooks'

import styles from '@styles/Form.module.scss'

const AddOrganisation = () => {
    const form = useForm()

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = form

    const jurisdiction = useWatch({
        control,
        name: 'jurisdiction',
    })

    const { mutate, isLoading, error } = useCreateOrganisation(data => {
        Cookies.set('tokenString', data.tokenString, {
            domain: env.REACT_APP_DOMAIN as string,
        })
        window.location.href = '/'
    })

    const options = Object.keys(RegulatedActivities).map(key => ({
        value: key,
        label: `${Legislation[key].class} - ${Legislation[key].description}`,
    }))

    const onSubmit = data => mutate(data)

    return (
        <Modal
            overlay={true}
            heading={'Set Up Organisation'}
            close={'/account/general-settings'}
        >
            <FormProvider {...form}>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <>
                        {error && (
                            <p className={styles.error}>
                                There was an error submitting the form, please try again.
                            </p>
                        )}

                        <InputWrapper
                            label={'Organisation Name'}
                            input={
                                <TextInput
                                    name={'name'}
                                    required
                                />
                            }
                            errors={errors.name}
                        />

                        <InputWrapper
                            label={'Jurisdiction'}
                            input={
                                <Select
                                    name={'jurisdiction'}
                                    required
                                    options={Object.keys(Jurisdictions).map(jurisdiction => ({
                                        label: pascalToReadable(jurisdiction),
                                        value: jurisdiction,
                                    }))}
                                />
                            }
                            errors={errors.name}
                        />

                        {jurisdiction ? (
                            <InputWrapper
                                label={'Regulated Activities'}
                                input={
                                    <MultiSelect
                                        name={'regulatedActivities'}
                                        options={options}
                                    />
                                }
                                errors={errors.regulatedActivities}
                            />
                        ) : null}

                        <Button
                            label={'Continue'}
                            fullWidth={true}
                            loading={isLoading}
                        />
                    </>
                </form>
            </FormProvider>
        </Modal>
    )
}

export default AddOrganisation
