import { Grants } from '@prisma/client'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useGetOrganisation } from '@features/organisation/hooks'
import { useCheckGrants } from '@features/user'

import Organisation from './layouts/Organisation'
import { RolesRoutes } from './roles'
import OrganisationSettings from './settings/OrganisationSettings'
import { TeamMembersRoutes } from './teamMembers'

export const OrganisationRoutes = () => {
    const hasTeamGrant = useCheckGrants(Grants.ManageTeam)
    const hasRolesGrant = useCheckGrants(Grants.ManageRoles)

    const organisation = useGetOrganisation(() => {}, {})
    if (!organisation.data) return null

    return (
        <Organisation>
            <Routes>
                <Route
                    path='settings'
                    element={<OrganisationSettings />}
                />
                {hasTeamGrant ? TeamMembersRoutes() : null}
                {hasRolesGrant ? RolesRoutes() : null}
                <Route
                    path='*'
                    element={
                        <Navigate
                            to='settings'
                            replace
                        />
                    }
                />
            </Routes>
        </Organisation>
    )
}
