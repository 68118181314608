import { Grants } from '@prisma/client'
import { Plus } from 'react-feather'
import { NavLink, useParams } from 'react-router-dom'

import Group from '@features/relationships/relationship/components/sidebar/checklist/group/Group'
import Header from '@features/relationships/relationship/components/sidebar/checklist/header/Header'
import Stage from '@features/relationships/relationship/components/sidebar/checklist/stage/Stage'
import SubmitForApproval from '@features/relationships/relationship/components/sidebar/submitForApproval/SubmitForApproval'
import {
    useGetRelationship,
    useGetRelationshipReview,
} from '@features/relationships/relationship/hooks'
import { useCheckGrants } from '@features/user'

import Loading from '@components/loading/Loading'

import styles from './Checklist.module.scss'

const checkCompletion = array => {
    return array.every(
        item =>
            item.identityCompletion.percentComplete === 1 &&
            checkCompletion(item.controllingParties)
    )
}

const Checklist = () => {
    const { relationshipId, relationshipReviewId } = useParams()

    const hasApproveRelationshipGrant = useCheckGrants(Grants.ApproveRelationship) ?? false

    const relationship = useGetRelationship(relationshipId, () => {})
    const review = useGetRelationshipReview(relationshipId, relationshipReviewId, () => {})

    if (relationship.isLoading || review.isLoading) return <Loading />
    if (!relationship.data || !review.data) return null

    const checklist = review.data.checklist

    return (
        <div className={styles.review}>
            <Header />
            <div className={styles.body}>
                <Group name={'Profiling'} />

                {checklist.relationshipDetails && (
                    <Stage
                        checked={checklist.relationshipDetails.isComplete}
                        name={'Relationship Details'}
                        entities={null}
                        unavailable={false}
                        customer={false}
                        link={`relationship-details-version/${checklist.relationshipDetails.id}`}
                        riskLevelId={null}
                    />
                )}

                <Stage
                    checked={
                        checklist.customerIdentity.isComplete
                            ? checklist.customerIdentity.isComplete
                            : false
                    }
                    name={'Customer'}
                    progress={0}
                    entities={[checklist.customerIdentity.entity]}
                    unavailable={false}
                    customer={true}
                    link={null}
                    riskLevelId={null}
                    edd={review.data.requiresEdd}
                />

                <Stage
                    checked={checkCompletion(checklist.controllingParties)}
                    name={'Controlling Parties'}
                    progress={50}
                    entities={checklist.controllingParties as []}
                    unavailable={false}
                    customer={false}
                    link={null}
                    riskLevelId={null}
                    edd={review.data.requiresEdd}
                />

                <Stage
                    checked={
                        checklist.customerRiskAssessment.isComplete
                            ? checklist.customerRiskAssessment.isComplete
                            : false
                    }
                    name={`${
                        checklist.customerRiskAssessment.isExternalCustomerRiskAssessment
                            ? 'External '
                            : ''
                    }Customer Risk Assessment`}
                    progress={0}
                    entities={null}
                    unavailable={false}
                    customer={false}
                    link={`customer-risk-assessment-version/${checklist.customerRiskAssessment.id}`}
                    riskLevelId={
                        checklist.customerRiskAssessment.isComplete &&
                        checklist.customerRiskAssessment.riskLevel
                            ? checklist.customerRiskAssessment.riskLevel
                            : null
                    }
                />

                {review.data.status === 'Profiling' ? (
                    <SubmitForApproval
                        disabled={
                            !(
                                checklist.relationshipDetails?.isComplete &&
                                checklist.customerRiskAssessment?.isComplete
                            )
                        }
                    />
                ) : null}

                {review.data.checklist.remediations.remediations?.length ? (
                    <Stage
                        checked={review.data.checklist.remediations.isComplete}
                        name={`Remediation`}
                        progress={0}
                        entities={null}
                        unavailable={false}
                        customer={false}
                        link={null}
                        riskLevelId={null}
                        remediations={review.data.checklist.remediations.remediations}
                    />
                ) : null}

                {review.data.status === 'Approval' && hasApproveRelationshipGrant ? (
                    <NavLink
                        to={`/relationships/${relationshipId}/reviews/${relationshipReviewId}/remediations/create`}
                        className={styles.requestRemediations}
                    >
                        <Plus size={16} />
                        Request Remediation
                    </NavLink>
                ) : null}

                <Group name={'Approval'} />

                <Stage
                    checked={checklist.approval?.isComplete || false}
                    name={`${checklist.approval?.isExternalApproval ? 'External ' : ''}Approval`}
                    progress={0}
                    entities={null}
                    unavailable={review.data.status === 'Profiling'}
                    customer={false}
                    link={`relationship-approval-version/${checklist.approval?.id}`}
                    riskLevelId={checklist.approval?.finalRiskLevelId || null}
                />
            </div>
        </div>
    )
}

export default Checklist
