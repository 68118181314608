import { useNavigate, useParams } from 'react-router-dom'

import { useGetRelationshipReview } from '@features/relationships/relationship/hooks'

import Loading from '@components/loading/Loading'

const RelationshipReviewRedirect = () => {
    const { relationshipId, relationshipReviewId } = useParams()
    const navigate = useNavigate()

    useGetRelationshipReview(relationshipId, relationshipReviewId, data =>
        navigate(
            `/relationships/${relationshipId}/reviews/${relationshipReviewId}/relationship-details-version/${data.relationshipDetailsVersion.id}`,
            {
                replace: true,
            }
        )
    )

    return <Loading />
}

export default RelationshipReviewRedirect
