import { Grants } from '@prisma/client'
import { Route, Routes } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import CreateRemediation from './views/CreateRemediation'
import ViewRemediation from './views/ViewRemediation'

export const RemediationRoutes = () => {
    const hasApproveRelationshipGrant = useCheckGrants(Grants.ApproveRelationship) ?? false

    return (
        <>
            {hasApproveRelationshipGrant ? (
                <Route
                    path={'remediations/create'}
                    element={<CreateRemediation />}
                />
            ) : null}
            <Route
                path={'remediations/:remediationId'}
                element={<ViewRemediation />}
            />
        </>
    )
}
