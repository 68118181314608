import { includesAny } from '../../utils'
import { RegulatedStatuses } from '@prisma/client'
import classNames from 'classnames'
import { useWatch } from 'react-hook-form'

import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'

import pascalToReadable from '@utils/pascalToReadable'

import IdentityComponentInput from '../components/IdentityComponentInput'

import styles from '../Identity.module.scss'

const Regulation = ({ entityType, control, readOnly }) => {
    const regulated = useWatch({
        control,
        name: 'regulated',
    })

    if (!includesAny(entityType, ['regulated', 'regulatedActivity', 'regulator'])) return null

    return (
        <div
            className={classNames({
                [styles.identityComponents]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <div className={styles.heading}>
                <h2>Regulation</h2>
                {/*<p>*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                {/*    incididunt.*/}
                {/*</p>*/}
            </div>

            <div className={styles.form}>
                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'regulated'}
                        input={
                            <Select
                                name={'regulated'}
                                options={Object.keys(RegulatedStatuses).map(key => ({
                                    value: key,
                                    label: pascalToReadable(key),
                                }))}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />
                </div>

                {regulated === RegulatedStatuses.Yes ? (
                    <div className={styles.row}>
                        <IdentityComponentInput
                            entityType={entityType}
                            name={'regulatedActivity'}
                            input={
                                <TextInput
                                    name={'regulatedActivity'}
                                    readOnly={readOnly}
                                />
                            }
                            readOnly={readOnly}
                        />

                        <IdentityComponentInput
                            entityType={entityType}
                            name={'regulator'}
                            input={
                                <TextInput
                                    name={'regulator'}
                                    readOnly={readOnly}
                                />
                            }
                            readOnly={readOnly}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default Regulation
