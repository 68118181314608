import * as Sentry from '@sentry/react'
import { App } from 'App'
import React from 'react'
import ReactDOM from 'react-dom/client'

import { env } from '@utils/env'

Sentry.init({
    dsn: 'https://b557653d74a546ae9374e8ae104a5ac5@o4504236175196160.ingest.sentry.io/4504236185419777',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    release: env.REACT_APP_VERSION,
    environment: env.REACT_APP_ENV,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
})

window['appVersion'] = env.REACT_APP_VERSION
window['appEnv'] = env.REACT_APP_ENV

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
