import { FileText, Image } from 'react-feather'

import styles from './FileNameWithIcon.module.scss'

const FileNameWithIcon = ({ fileName }: { fileName: string }) => {
    const images = ['jpg', 'jpeg', 'png', 'gif']

    const extension = fileName.split('.').pop() as string

    return (
        <div className={styles.fileNameWithIcon}>
            <div className={styles.icon}>
                {images.includes(extension) ? (
                    <Image
                        color={'#A2A2A2'}
                        size={18}
                    />
                ) : (
                    <FileText
                        color={'#A2A2A2'}
                        size={18}
                    />
                )}
            </div>
            <p>{fileName}</p>
        </div>
    )
}

export default FileNameWithIcon
