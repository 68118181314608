// @ts-nocheck
import Helmet from 'react-helmet'

import DashboardHeader from '@features/dashboard/components/dashboardHeader/DashboardHeader'
import DashboardSubheading from '@features/dashboard/components/dashboardSubheading/DashboardSubheading'
import GroupPlaceholder from '@features/dashboard/components/groupPlaceholder/GroupPlaceholder'
import MetricCard from '@features/dashboard/components/metricCard/MetricCard'
import RelationshipPreviewCard from '@features/dashboard/components/relationshipPreviewCard/RelationshipPreviewCard'
import RiskProfileCard from '@features/dashboard/components/riskProfileCard/RiskProfileCard'
import { useGetDashboard } from '@features/dashboard/hooks'
import { useGetOrganisation } from '@features/organisation'

import main from '@layouts/main/Main'

import Loading from '@components/loading/Loading'

import styles from './Dashboard.module.scss'

const Dashboard = () => {
    const organisation = useGetOrganisation()
    const dashboard = useGetDashboard()

    if (!organisation.data || !dashboard.data) return <Loading />

    return (
        <>
            <Helmet>
                <title>Dashboard | Proofdesk</title>
            </Helmet>

            <DashboardHeader />

            <div className={styles.metrics}>
                <span />
                <main>
                    <MetricCard
                        label={'Relationships'}
                        value={dashboard.data.stats.relationships.current}
                        change={dashboard.data.stats.relationships.change}
                    />
                    <MetricCard
                        label={'Entities'}
                        value={dashboard.data.stats.entities.current}
                        change={dashboard.data.stats.entities.change}
                    />
                    <MetricCard
                        label={'Warning Flags'}
                        value={dashboard.data.stats.screeningWarningEntities.current}
                        change={dashboard.data.stats.screeningWarningEntities.change}
                    />
                    <RiskProfileCard
                        lowerRisk={{
                            change: dashboard.data.stats.riskProfile.lower.change,
                            absolute: dashboard.data.stats.riskProfile.lower.current,
                        }}
                        standardRisk={{
                            change: dashboard.data.stats.riskProfile.standard.change,
                            absolute: dashboard.data.stats.riskProfile.standard.current,
                        }}
                        higherRisk={{
                            change: dashboard.data.stats.riskProfile.higher.change,
                            absolute: dashboard.data.stats.riskProfile.higher.current,
                        }}
                    />
                </main>
            </div>

            <main>
                {/*<DashboardSubheading*/}
                {/*    label={'Approvals'}*/}
                {/*    quantity={6}*/}
                {/*/>*/}

                {/*<div className={styles.group}>*/}
                {/*    <ApprovalPreviewCard*/}
                {/*        placeholder={false}*/}
                {/*        approvalType={ApprovalTypes.Policy}*/}
                {/*        name={'Business Risk Assessment'}*/}
                {/*        date={'01/18/2024'}*/}
                {/*    />*/}

                {/*    <ApprovalPreviewCard*/}
                {/*        placeholder={false}*/}
                {/*        approvalType={ApprovalTypes.Relationship}*/}
                {/*        name={'MO-POU-003'}*/}
                {/*        date={'01/18/2024'}*/}
                {/*    />*/}

                {/*    <ApprovalPreviewCard*/}
                {/*        placeholder={true}*/}
                {/*        approvalType={null}*/}
                {/*        name={null}*/}
                {/*        date={null}*/}
                {/*    />*/}

                {/*    <ApprovalPreviewCard*/}
                {/*        placeholder={true}*/}
                {/*        approvalType={null}*/}
                {/*        name={null}*/}
                {/*        date={null}*/}
                {/*    />*/}
                {/*</div>*/}

                <DashboardSubheading
                    label={'Onboarding'}
                    quantity={dashboard.data.relationshipsOnboarding.length}
                />

                {dashboard.data.relationshipsOnboarding.length ? (
                    <div className={styles.group}>
                        {dashboard.data.relationshipsOnboarding.map(relationship => {
                            return (
                                <RelationshipPreviewCard
                                    id={relationship.id}
                                    entityId={relationship.entity.id}
                                    entityName={relationship.entity.name}
                                    entityType={relationship.entity.entityType}
                                    internalReference={relationship.internalReference}
                                    latestReviewCreateDate={relationship.latestReview.createDate}
                                    ownerUser={relationship.ownerUser}
                                    checklistPercentProgress={relationship.checklistPercentProgress}
                                />
                            )
                        })}
                    </div>
                ) : (
                    <GroupPlaceholder
                        text={'You are not onboarding any new relationships.'}
                        large={true}
                    />
                )}

                <DashboardSubheading
                    label={'Current Reviews'}
                    quantity={dashboard.data.relationshipsWithReviewInProgress.length}
                />

                {dashboard.data.relationshipsWithReviewInProgress.length ? (
                    <div className={styles.group}>
                        {dashboard.data.relationshipsWithReviewInProgress.map(relationship => {
                            return (
                                <RelationshipPreviewCard
                                    id={relationship.id}
                                    entityId={relationship.entity.id}
                                    entityName={relationship.entity.name}
                                    entityType={relationship.entity.entityType}
                                    internalReference={relationship.internalReference}
                                    latestReviewCreateDate={relationship.latestReviewCreateDate}
                                    ownerUser={relationship.ownerUser}
                                    checklistPercentProgress={relationship.checklistPercentProgress}
                                />
                            )
                        })}
                    </div>
                ) : (
                    <GroupPlaceholder
                        text={'None of your relationships are being reviewed.'}
                        large={true}
                    />
                )}
            </main>
        </>
    )
}

export default Dashboard
