import Tabs from '@components/tabs/Tabs'

import { useGetSelfInvitations } from '../hooks'

const Account = ({ children }) => {
    const invitations = useGetSelfInvitations()
    if (invitations.isLoading) return null

    const tabs = [
        {
            label: 'General Settings',
            link: '/account/general-settings',
        },
        {
            label: 'Invitations',
            link: '/account/invitations',
            alerts: invitations.data?.length,
        },
    ]

    return (
        <>
            <Tabs tabs={tabs} />

            <main>{children}</main>
        </>
    )
}

export default Account
