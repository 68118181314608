import moment from 'moment'
import { ArrowRight } from 'react-feather'

import Tag, { TagThemes } from '@components/tag/Tag'

import styles from './ControllingPartyTerm.module.scss'

const ControllingPartyTerm = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    if (!startDate) return <p>-</p>

    return (
        <div className={styles.controllingPartyTerm}>
            <p>{moment(startDate).format('MMMM DD, YYYY')}</p>
            <ArrowRight size={14} />
            {!endDate ? (
                <Tag
                    label={'Present'}
                    theme={TagThemes.Grey}
                    outline={false}
                />
            ) : (
                <p>{moment(endDate).format('MMMM DD, YYYY')}</p>
            )}
        </div>
    )
}

export default ControllingPartyTerm
