import Cookies from 'js-cookie'
import { QRCodeSVG } from 'qrcode.react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FormProvider, useForm } from 'react-hook-form'

import { useGetSelf } from '@features/account'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import PinInput from '@components/inputs/pinInput/PinInput'
import TextInput from '@components/inputs/textInput/TextInput'

import { env } from '@utils/env'

import { useGenerateTwoFactorAuthCandidateSecret, useSetTwoFactorAuthSecret } from '../hooks'

import styles from '@styles/Form.module.scss'

const SetTwoFactorAuth = () => {
    const form = useForm()
    const {
        handleSubmit,
        formState: { errors, isSubmitSuccessful, isValid },
    } = form

    const user = useGetSelf(() => {})
    const secret = useGenerateTwoFactorAuthCandidateSecret()

    const { mutate, isLoading, isError } = useSetTwoFactorAuthSecret(data => {
        Cookies.set('tokenString', data, {
            domain: env.REACT_APP_DOMAIN as string,
        })
        window.location.reload()
    })

    useEffect(() => {
        if (isSubmitSuccessful) {
            form.reset()
        }
    }, [isSubmitSuccessful])

    useEffect(() => {
        if (isError) {
            form.setFocus('twoFactorAuthToken')
        }
    }, [isError, form])

    const onSubmit = data => mutate(data)

    if (!secret.data || !user.data) return <p>Loading...</p>

    const envName = env.REACT_APP_ENV === 'prod' ? '' : `%20(${env.REACT_APP_ENV})`
    const issuer = 'Proofdesk' + envName

    const value = `otpauth://totp/${issuer}:${user.data.email}?secret=${secret.data}&issuer=${issuer}`

    return (
        <>
            <Helmet>
                <title>Set up Two Factor Authentication | Proofdesk</title>
            </Helmet>
            <FormProvider {...form}>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div>
                        <h2>Set Up Two Factor Authentication</h2>

                        <p className={styles.grey}>
                            Scan the following QR code in your authenticator app, then enter your
                            six-digit Two Factor Auth Code below.
                        </p>
                    </div>

                    <QRCodeSVG
                        value={value}
                        className={styles.qr}
                    />

                    {isError && (
                        <p className={styles.error}>
                            There was an error submitting the form, please try again.
                        </p>
                    )}

                    <TextInput
                        hidden
                        name={'twoFactorAuthSecret'}
                        type='hidden'
                        defaultValue={secret.data}
                    />

                    <InputWrapper
                        label={'Two Factor Auth Code'}
                        input={
                            <PinInput
                                name={'twoFactorAuthToken'}
                                autoFocus={true}
                                disabled={isLoading}
                                required
                            />
                        }
                        errors={errors.twoFactorAuthSecret}
                    />
                    <Button
                        label={'Confirm Two Factor Auth'}
                        loading={isLoading}
                        disabled={!isValid}
                        fullWidth={true}
                    />
                </form>
            </FormProvider>
        </>
    )
}

export default SetTwoFactorAuth
