import { trpc } from '@utils/trpc'

export const useGetRiskLevelPolicyById = (riskLevelPolicyId, onSuccess) =>
    trpc.riskLevelPolicies.getById.useQuery(
        { riskLevelPolicyId },
        { enabled: !!riskLevelPolicyId, onSuccess }
    )

export const useGetRiskLevelPolicyVersionById = (
    riskLevelPolicyVersionId,
    riskLevelPolicyId,
    onSuccess
) =>
    trpc.riskLevelPolicies.getVersionById.useQuery(
        { riskLevelPolicyVersionId },
        { enabled: !!riskLevelPolicyVersionId, onSuccess }
    )

export const useCreateRiskLevelPolicyVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.riskLevelPolicies.createVersion.useMutation({
        onSuccess,
        onSettled: () => context.riskLevelPolicies.invalidate(),
    })
}

export const useUpdateRiskLevelPolicyVersion = () => {
    const context = trpc.useContext()

    return trpc.riskLevelPolicies.updateVersion.useMutation({
        onSuccess: () => context.riskLevelPolicies.invalidate(),
    })
}

export const useSetRiskLevelPolicyVersionPending = () => {
    const context = trpc.useContext()

    return trpc.riskLevelPolicies.setVersionPending.useMutation({
        onSettled: () => context.riskLevelPolicies.invalidate(),
    })
}

export const useRevertVersionToDraft = () => {
    const context = trpc.useContext()

    return trpc.riskLevelPolicies.revertVersionToDraft.useMutation({
        onSettled: () => context.riskLevelPolicies.invalidate(),
    })
}

export const useSignRiskLevelPolicyVersion = () => {
    const context = trpc.useContext()

    return trpc.riskLevelPolicies.createVersionSignature.useMutation({
        onSettled: () => context.riskLevelPolicies.invalidate(),
    })
}

export const useRemoveRiskLevelPolicyVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.riskLevelPolicies.deleteVersion.useMutation({
        onSuccess,
        onSettled: () => context.riskLevelPolicies.invalidate(),
    })
}
