import { Grants } from '@prisma/client'
import { useController, useFormContext } from 'react-hook-form'

import styles from './Grant.module.scss'

const Grant = ({ grant, description }: { grant: Grants; description: string }) => {
    const { control } = useFormContext()
    const { field } = useController({
        control,
        name: 'grants',
    })

    return (
        <label className={styles.grant}>
            <div className={styles.input}>
                <input
                    type={'checkbox'}
                    onChange={e => {
                        const checked = e.target.checked
                        const value = field.value
                        if (checked) {
                            field.onChange([...value, grant])
                        } else {
                            field.onChange(value.filter(v => v !== grant))
                        }
                    }}
                    checked={field.value?.includes(grant)}
                />
                <span />
            </div>
            <p>{description}</p>
        </label>
    )
}

export default Grant
