import { useEffect, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import styles from './AutoGrowTextArea.module.scss'

const AutoGrowTextArea = ({
    name,
    readOnly,
    medium = false,
    large = false,
    placeholder,
    required,
}: {
    name: string
    readOnly?: boolean
    medium?: boolean
    large?: boolean
    placeholder?: string
    required?: boolean
}) => {
    const { register, control } = useFormContext()
    const { ref, ...rest } = register(name, { required })
    const customRef = useRef<HTMLTextAreaElement | null>(null)

    const value = useWatch({
        control,
        name,
    })

    const minHeight = large ? 300 : medium ? 150 : 0

    useEffect(() => {
        if (customRef.current) {
            customRef.current.style.height = '0px'
            const scrollHeight = customRef.current.scrollHeight + 4
            customRef.current.style.height =
                scrollHeight > minHeight ? scrollHeight + 'px' : minHeight + 'px'
        }
    }, [ref, value])

    return (
        <textarea
            placeholder={placeholder}
            className={styles.autoGrowTextArea}
            {...rest}
            name={name}
            ref={x => {
                ref(x)
                customRef.current = x
            }}
            disabled={readOnly}
        />
    )
}

export default AutoGrowTextArea
