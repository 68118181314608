import { trpc } from '@utils/trpc'

export const useCreateOrganisation = onSuccess => {
    const context = trpc.useContext()

    return trpc.organisations.create.useMutation({
        onSuccess,
        onSettled: () => context.organisations.getById.invalidate(),
    })
}

export const useGetOrganisation = (onSuccess?, options?) =>
    trpc.organisations.getSelf.useQuery({ ...options, onSuccess })

export const useGetMemberships = isTwoFactorAuthVerified =>
    trpc.organisations.getSelfMemberships.useQuery(undefined, {
        enabled: !!isTwoFactorAuthVerified,
    })

export const useUpdateOrganisation = () => {
    const context = trpc.useContext()

    return trpc.organisations.updateSelf.useMutation({
        onSettled: () => context.invalidate(),
    })
}

export const useSwapTokenOrganisation = onSuccess => {
    const context = trpc.useContext()

    return trpc.authTokens.swapTokenOrganisation.useMutation({
        onSuccess,
        onSettled: () => context.invalidate(),
    })
}
