import classNames from 'classnames'
import { X } from 'react-feather'
import { Helmet } from 'react-helmet'
import { NavLink, useNavigate } from 'react-router-dom'

import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import styles from './Modal.module.scss'

const Modal = ({
    overlay,
    status,
    heading,
    icon,
    close,
    description,
    large,
    children,
    loading,
    error,
}: {
    overlay: boolean
    status?
    heading?
    icon?
    close?
    description?
    large?
    children?
    loading?
    error?
}) => {
    const navigate = useNavigate()

    return (
        <>
            {heading ? (
                <Helmet>
                    <title>{heading} | Proofdesk</title>
                </Helmet>
            ) : null}

            <div className={classNames(styles.modal, { [styles.large]: large })}>
                <div>
                    {(heading || close) && (
                        <div className={styles.header}>
                            <div>
                                <div>
                                    {icon && icon}
                                    {heading && <h2>{heading}</h2>}
                                    {status && status}
                                </div>
                                {close && (
                                    <NavLink to={close}>
                                        <X size={22} />
                                    </NavLink>
                                )}
                            </div>
                            {description && <p>{description}</p>}
                        </div>
                    )}
                    {loading ? <Loading /> : error ? <QueryError isInsideModal={true} /> : children}
                </div>
                {overlay ? <span onClick={() => navigate(close)} /> : null}
            </div>
        </>
    )
}

export default Modal
