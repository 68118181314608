const Legislation = {
    FSA08Class1: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Financial Services Act 2008',
        class: 'Class 1',
        description: 'Deposit Taking',
        forms: ['B3', 'C1'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    FSA08Class2: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Financial Services Act 2008',
        class: 'Class 2 (3), (6-7)',
        description: 'Financial Advisors Only',
        forms: ['C2'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    FSA08Class2Other: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Financial Services Act 2008',
        class: 'Class 2 - Other',
        description: 'Other Investment Business - excl. Financial Advisors',
        forms: ['C2'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    FSA08Class3: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Financial Services Act 2008',
        class: 'Class 3 (3-8)',
        description: 'Services to CIS - Classes 3 (3)-(8)',
        forms: ['C3a'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    FSA08Class3Other: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Financial Services Act 2008',
        class: 'Class 3 (1-2) and (9-12)',
        description: 'Services to CIS - Classes 3 (1), (2) and (9-12)',
        forms: ['C3a', 'C3b'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    FSA08Class4: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Financial Services Act 2008',
        class: 'Class 4',
        description: 'Corporate Services',
        forms: ['C4'],
        subActivities: ['Fully Managed', 'Mixed Board', 'Registered Office / Registered Agent'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    FSA08Class5: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Financial Services Act 2008',
        class: 'Class 5',
        description: 'Trust Services',
        forms: ['C4'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    FSA08Class6: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Financial Services Act 2008',
        class: 'Class 6',
        description: 'Crowdfunding Platforms',
        forms: ['C2'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    FSA08Class7: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Financial Services Act 2008',
        class: 'Class 7',
        description: 'Management or Administration Services',
        forms: ['C1'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    FSA08Class8: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Financial Services Act 2008',
        class: 'Class 8',
        description: 'Money Transmission Services',
        forms: ['B3', 'C1'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    FSA08Class9: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Financial Services Act 2008',
        class: 'Class 9',
        description: 'Money Transmission Services',
        forms: ['B3', 'C1'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    IA08LongTerm: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Insurance Act 2008',
        class: 'Classes 1, 2 & 10',
        description: 'Insurance - Long Term Business (including Reinsurance)',
        forms: ['CLT'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    IA08GeneralBusiness: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Insurance Act 2008',
        class: 'Classes 3-9 & 11',
        description: 'Insurance - General Business (including Reinsurance)',
        forms: ['CGI'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    IA08Restricted: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Insurance Act 2008',
        class: 'Class 12 Only / Class 13 Only',
        description: 'Insurance - Restricted (all insurance types) or ISPV Class 13',
        forms: ['CGI'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    IA08GeneralInsuranceIntermediary: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Insurance Act 2008',
        class: 'General Insurance Intermediary',
        description: 'General Insurance Intermediary',
        forms: ['C2'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    IA08InsuranceManager: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Insurance Act 2008',
        class: 'Insurance Manager',
        description: 'Insurance Manager',
        forms: ['CIM'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    IA08PermitHolderLongTerm: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Insurance Act 2008',
        class: 'Permit Holder (Long Term Business)',
        description: 'Permit Holder (Long Term Business)',
        forms: ['CLT'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    IA08PermitHolderNonLongTerm: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Insurance Act 2008',
        class: 'Permit Holder (Non-Long Term Business)',
        description: 'Permit Holder (Non-Long Term Business)',
        forms: ['CGI'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    RBSA00Administrator: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Retirement Benefits Schemes Act 2000',
        class: 'Administrator',
        description: 'Retirement Benefits Schemes Administrator',
        forms: ['CPEN'],
        dummy: {
            NaturalPerson: 0,
        },
    },
    DBA15ExternalAccountant: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Designated Businesses (Registration and Oversight) Act 2015',
        class: 'Designated Business',
        description: 'External Accountant',
        forms: ['C-DNFBP'],
        dummy: {
            NaturalPerson: 0.7,
        },
    },
    DBA15Crypto: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Designated Businesses (Registration and Oversight) Act 2015',
        class: 'Designated Business',
        description: 'Convertible Virtual Currency Activity',
        forms: ['C-DNFBP'],
        dummy: {
            NaturalPerson: 0.5,
        },
    },
    DBA15Payroll: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Designated Businesses (Registration and Oversight) Act 2015',
        class: 'Designated Business',
        description: 'Payroll Agent',
        forms: ['C-DNFBP'],
        dummy: {
            NaturalPerson: 0.5,
        },
    },
    DBA15Tax: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Designated Businesses (Registration and Oversight) Act 2015',
        class: 'Designated Business',
        description: 'Tax Adviser',
        forms: ['C-DNFBP'],
        dummy: {
            NaturalPerson: 0.5,
        },
    },
    DBA15Legal: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Designated Businesses (Registration and Oversight) Act 2015',
        class: 'Designated Business',
        description: 'Legal Professional',
        forms: ['C-DNFBP'],
        dummy: {
            NaturalPerson: 0.5,
        },
    },
    DBA15EstateAgent: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Designated Businesses (Registration and Oversight) Act 2015',
        class: 'Designated Business',
        description: 'Estate Agent',
        forms: ['C-DNFBP'],
        dummy: {
            NaturalPerson: 0.9,
        },
    },
    DBA15Lender: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Designated Businesses (Registration and Oversight) Act 2015',
        class: 'Designated Business',
        description: 'Lender / Financial Leasing / Financial Guarantees',
        forms: ['C-DNFBP'],
        dummy: {
            NaturalPerson: 0.5,
        },
    },
    DBA15HighValueGoods: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Designated Businesses (Registration and Oversight) Act 2015',
        class: 'Designated Business',
        description: 'High Valued Goods Dealer',
        forms: ['C-DNFBP'],
        dummy: {
            NaturalPerson: 0.5,
        },
    },
    DBA15SafeCustody: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Designated Businesses (Registration and Oversight) Act 2015',
        class: 'Designated Business',
        description: 'Safe Custody',
        forms: ['C-DNFBP'],
        dummy: {
            NaturalPerson: 0.5,
        },
    },
    DBA15NonProfit: {
        jurisdiction: 'IsleOfMan',
        legislation: 'Designated Businesses (Registration and Oversight) Act 2015',
        class: 'Designated Business',
        description: 'Specified Non-Profit Organisations',
        forms: ['C-DNFBP'],
        dummy: {
            NaturalPerson: 0.5,
        },
    },
}

export { Legislation }
