import { includesAny } from '../../utils'
import classNames from 'classnames'

import IdentityComponentInput from '../components/IdentityComponentInput'

import styles from '../Identity.module.scss'

const Funding = ({ entityType, register, readOnly }) => {
    if (!includesAny(entityType, ['sourceOfWealth'])) return null

    return (
        <div
            className={classNames({
                [styles.identityComponents]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <div className={styles.heading}>
                <h2>Funding</h2>
                {/*<p>*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                {/*    incididunt.*/}
                {/*</p>*/}
            </div>

            <div className={styles.form}>
                <IdentityComponentInput
                    entityType={entityType}
                    name={'sourceOfWealth'}
                    input={
                        <textarea
                            rows={8}
                            {...register('sourceOfWealth')}
                            disabled={readOnly}
                        />
                    }
                    readOnly={readOnly}
                />
            </div>
        </div>
    )
}

export default Funding
