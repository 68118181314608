import { Trash2 } from 'react-feather'
import { useNavigate, useParams } from 'react-router-dom'

import Button from '@components/button/Button'

import { useRemoveTeamMember } from '../hooks'

const RemoveTeamMember = () => {
    const { membershipId } = useParams()
    const navigate = useNavigate()
    const removeTeamMember = () => {
        if (window.confirm('Are you sure you wish to remove this team member?'))
            mutate({ membershipId: membershipId as string })
    }

    const { mutate, isLoading } = useRemoveTeamMember(() => navigate(`/organisation/team-members`))

    return (
        <Button
            loading={isLoading}
            icon={<Trash2 size={18} />}
            label={'Remove Team Member'}
            fullWidth={true}
            onClick={removeTeamMember}
            type={'button'}
            red={true}
        />
    )
}

export default RemoveTeamMember
