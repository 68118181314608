import classNames from 'classnames'
import { RefreshCw } from 'react-feather'

import Button from '@components/button/Button'

import styles from './QueryError.module.scss'

const QueryError = ({ isInsideModal }: { isInsideModal?: boolean }) => {
    return (
        <div
            className={classNames({
                [styles.queryError]: true,
                [styles.insideModal]: isInsideModal,
            })}
        >
            <p>An error occurred while loading the requested resource.</p>
            <Button
                icon={<RefreshCw size={14} />}
                label={'Retry'}
                outline={true}
                grey={true}
                onClick={() => window.location.reload()}
            />
        </div>
    )
}

export default QueryError
