import { TokenPayload } from '../../../api/src/services/AuthTokenService'
import { Grants } from '@prisma/client'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { Navigate } from 'react-router-dom'

import { AccountRoutes } from '@features/account'
import { useGetSelfInvitations } from '@features/account/hooks'
import { CustomPolicyRoutes } from '@features/customPolicies'
import { DashboardRoutes } from '@features/dashboard'
import { EntitiesRoutes } from '@features/entities'
import { IdentityPoliciesRoutes } from '@features/identityPolicies'
import { OrganisationRoutes, useGetOrganisation } from '@features/organisation'
import { useGetMemberships } from '@features/organisation/hooks'
import AddOrganisation from '@features/organisation/views/AddOrganisation'
import { PoliciesRoutes } from '@features/policies'
import { RelationshipsRoutes } from '@features/relationships'
import { ReportsRoutes } from '@features/reports'
import { RiskAssessmentPolicyRoutes } from '@features/riskAssessmentPolicies'
import { RiskLevelPoliciesRoutes } from '@features/riskLevelPolicies'
import { TwoFactorAuthRoutes } from '@features/twoFactorAuth'
import { useCheckGrants } from '@features/user'

import Entry from '@layouts/entry/Entry'
import Main from '@layouts/main/Main'

export const RestrictedRoutes = () => {
    const claims = jwtDecode(Cookies.get('tokenString')) as TokenPayload

    const organisations = useGetMemberships(claims.isTwoFactorAuthVerified)
    const invitations = useGetSelfInvitations()
    const organisation = useGetOrganisation(() => {}, {})

    const hasStatisticalReturnsGrant = useCheckGrants(Grants.GenerateReports)
    const hasDisclosuresGrant = useCheckGrants(Grants.ManageDisclosures)
    const hasEnquiriesGrant = useCheckGrants(Grants.ManageEnquiries)
    const hasConfigurationGrant = useCheckGrants(Grants.ManageConfiguration)

    // Two Factor Not Set Up or Not Verified
    let routes = [
        {
            path: '/',
            element: (
                <Navigate
                    to='/two-factor'
                    replace
                />
            ),
        },
        {
            path: '',
            element: <Entry />,
            children: [
                {
                    path: '/two-factor',
                    element: <TwoFactorAuthRoutes />,
                },
                {
                    path: '*',
                    element: (
                        <Navigate
                            to='.'
                            replace
                        />
                    ),
                },
            ],
        },
    ]

    // Fully Authenticated
    if (claims.isTwoFactorAuthVerified)
        routes = [
            {
                path: '/',
                element: (
                    <Navigate
                        to='/dashboard'
                        replace
                    />
                ),
            },
            {
                path: '/accept-invitation/:tokenString',
                element: (
                    <Navigate
                        to='/account/invitations'
                        replace
                    />
                ),
            },
            {
                path: '',
                element: <Main disabledNav={false} />,
                children: [
                    {
                        path: '/create-organisation',
                        element: <AddOrganisation />,
                    },
                    {
                        path: '/account/*',
                        element: <AccountRoutes />,
                    },
                    {
                        path: '/dashboard/*',
                        element: <DashboardRoutes />,
                    },
                    {
                        path: '/entities/*',
                        element: <EntitiesRoutes />,
                    },
                    {
                        path: '/relationships/*',
                        element: <RelationshipsRoutes />,
                    },
                    {
                        path: '/policies/*',
                        element: <PoliciesRoutes />,
                    },
                    {
                        path: '/custom-policies/*',
                        element: <CustomPolicyRoutes />,
                    },
                    {
                        path: '/risk-level-policies/*',
                        element: <RiskLevelPoliciesRoutes />,
                    },
                    {
                        path: '/identity-policies/*',
                        element: <IdentityPoliciesRoutes />,
                    },
                    {
                        path: '/risk-assessment-policies/*',
                        element: <RiskAssessmentPolicyRoutes />,
                    },
                    ...(hasStatisticalReturnsGrant || hasDisclosuresGrant || hasEnquiriesGrant
                        ? [
                              {
                                  path: '/reporting/*',
                                  element: <ReportsRoutes />,
                              },
                          ]
                        : []),
                    {
                        path: '/organisation/*',
                        element: <OrganisationRoutes />,
                    },
                    {
                        path: '*',
                        element: (
                            <Navigate
                                to='.'
                                replace
                            />
                        ),
                    },
                ],
            },
        ]

    if (claims.isTwoFactorAuthVerified && organisations.isLoading) return []

    if (organisations.data?.length === 0)
        routes = [
            {
                path: '/',
                element: (
                    <Navigate
                        to={
                            invitations.data?.length
                                ? '/account/invitations'
                                : '/create-organisation'
                        }
                        replace
                    />
                ),
            },
            {
                path: '',
                element: <Main disabledNav={true} />,
                children: [
                    {
                        path: '/create-organisation',
                        element: <AddOrganisation />,
                    },
                    {
                        path: '/account/*',
                        element: <AccountRoutes />,
                    },
                    {
                        path: '*',
                        element: (
                            <Navigate
                                to={
                                    invitations.data?.length
                                        ? '/account/invitations'
                                        : '/create-organisation'
                                }
                                replace
                            />
                        ),
                    },
                ],
            },
        ]

    if (organisations.data?.length && !organisation.data?.isDemo && !organisation.data?.billingPlan)
        routes = [
            {
                path: '/',
                element: (
                    <Navigate
                        to={'/organisation/settings'}
                        replace
                    />
                ),
            },
            {
                path: '',
                element: <Main disabledNav={true} />,
                children: [
                    {
                        path: '/create-organisation',
                        element: <AddOrganisation />,
                    },
                    {
                        path: '/account/*',
                        element: <AccountRoutes />,
                    },
                    {
                        path: '/organisation/*',
                        element: <OrganisationRoutes />,
                    },
                    {
                        path: '*',
                        element: (
                            <Navigate
                                to={'/organisation/settings'}
                                replace
                            />
                        ),
                    },
                ],
            },
        ]

    return routes
}
