import { useNavigate, useParams } from 'react-router-dom'

import { useGetRiskLevelPolicyById } from '../hooks'

const RiskLevelPolicy = () => {
    const { riskLevelPolicyId } = useParams()
    const navigate = useNavigate()

    useGetRiskLevelPolicyById(riskLevelPolicyId, data => {
        navigate(`/risk-level-policies/${riskLevelPolicyId}/versions/${data.latestVersion.id}`, {
            replace: true,
        })
    })

    return null
}

export default RiskLevelPolicy
