import { FormProvider, useController, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import FileUpload from '@components/inputs/fileUpload/FileUpload'
import TextInput from '@components/inputs/textInput/TextInput'

import { useCreateDocument } from '../hooks'

import styles from '@styles/Form.module.scss'

const AddDocument = () => {
    const { entityId } = useParams()
    const navigate = useNavigate()

    const form = useForm({
        shouldUnregister: true,
    })

    const {
        handleSubmit,
        control,
        formState: { errors },
        getValues,
    } = form

    const fileInput = useController({
        name: 'fileName',
        control,
    })

    const { mutate, isLoading, error } = useCreateDocument(() => navigate(`..`, { replace: true }))

    const onSubmit = data => mutate(data)

    return (
        <Modal
            overlay={true}
            heading={'New Document'}
            close={`..`}
            large={true}
        >
            <FormProvider {...form}>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {error && (
                        <p className={styles.error}>
                            There was an error submitting the form, please try again.
                        </p>
                    )}

                    <TextInput
                        hidden
                        name={'entityId'}
                        defaultValue={entityId}
                    />

                    <FileUpload
                        onUpload={fileInput.field.onChange}
                        acceptDocuments
                        acceptImages
                    />

                    <InputWrapper
                        label={'Document Title'}
                        input={
                            <TextInput
                                name={'title'}
                                required
                            />
                        }
                        errors={errors.title}
                    />

                    <Button
                        label={'Upload Document'}
                        fullWidth={true}
                        loading={isLoading}
                        disabled={!getValues('fileName')}
                    />
                </form>
            </FormProvider>
        </Modal>
    )
}

export default AddDocument
