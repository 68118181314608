import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import { FormProvider, useForm } from 'react-hook-form'
import { NavLink, useSearchParams } from 'react-router-dom'

import { useGetInvitation } from '@features/acceptInvitation'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'

import { env } from '@utils/env'

import { useGetToken } from '../hooks'

import styles from '@styles/Form.module.scss'
import EmailInput from '@components/inputs/emailInput/EmailInput'
import PasswordInput from '@components/inputs/passwordInput/PasswordInput'

const Login = () => {
    const [searchParams, _] = useSearchParams()

    const form = useForm()

    const {
        handleSubmit,
        formState: { errors },
    } = form

    const invitation = useGetInvitation(searchParams.get('invitation'), () => {})

    const { mutate, isLoading, isError } = useGetToken(
        data => {
            Cookies.set('tokenString', data, {
                domain: env.REACT_APP_DOMAIN as string,
            })
            if (invitation.data) {
                window.location.href = '/account/invitations'
            } else {
                window.location.href = '/'
            }
        },
        error => console.log(error)
    )

    if (invitation.isFetching) return null

    const onSubmit = data => mutate(data)

    return (
        <>
            <Helmet>
                <title>Proofdesk Login</title>
            </Helmet>

            <FormProvider {...form}>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <h2>
                        {invitation.data
                            ? `Login to join the ${invitation.data?.organisation} team.`
                            : 'Login'}
                    </h2>

                    {isError && (
                        <p className={styles.error}>
                            There was an error submitting the form, please try again.
                        </p>
                    )}

                    <InputWrapper
                        label={'Email Address'}
                        input={
                            <EmailInput
                                name={'email'}
                                required
                                disabled={isLoading}
                            />
                        }
                        errors={errors.email}
                    />

                    <InputWrapper
                        label={'Password'}
                        input={
                            <PasswordInput
                                name={'password'}
                                required
                                disabled={isLoading}
                            />
                        }
                        errors={errors.password}
                    />

                    <Button
                        label={'Login'}
                        loading={isLoading}
                        fullWidth={true}
                    />

                    <div className={styles.links}>
                        <NavLink to={'/forgot-password'}>Forgot Your Password?</NavLink>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}

export default Login
