import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import TextInput from '@components/inputs/textInput/TextInput'

import { useCreateCustomPolicy } from '../hooks'

const CreateCustomPolicy = () => {
    const navigate = useNavigate()

    const form = useForm({
        defaultValues: {
            initialVersion: {
                policyName: null,
                nextReviewDate: new Date(),
                versionName: 'New Version',
                content: '',
            },
        },
    })

    const {
        handleSubmit,
        formState: { errors },
    } = form

    const { mutate, isLoading } = useCreateCustomPolicy(data =>
        navigate(`/custom-policies/${data.id}`)
    )

    const onSubmit = data => mutate(data)

    return (
        <Modal
            overlay={true}
            heading={'New Custom Policy'}
            close={'/policies'}
        >
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputWrapper
                        label={'Policy Name'}
                        input={
                            <TextInput
                                name={'initialVersion.policyName'}
                                required
                            />
                        }
                        errors={errors.initialVersion?.policyName}
                    />

                    <Button
                        label={'Continue'}
                        fullWidth={true}
                        disabled={isLoading}
                    />
                </form>
            </FormProvider>
        </Modal>
    )
}

export default CreateCustomPolicy
