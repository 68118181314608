import { useNavigate, useParams } from 'react-router-dom'

import { useGetIdentityPolicyById } from '../hooks'

const IdentityPolicy = () => {
    const { identityPolicyId } = useParams()
    const navigate = useNavigate()

    useGetIdentityPolicyById(identityPolicyId, data => {
        navigate(`/identity-policies/${identityPolicyId}/versions/${data.latestVersion.id}`, {
            replace: true,
        })
    })

    return null
}

export default IdentityPolicy
