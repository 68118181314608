import { StatisticalReturnRiskLevels } from '@prisma/client'

import AutoGrowTextArea from '@components/inputs/autoGrowTextArea/AutoGrowTextArea'
import ColourPicker from '@components/inputs/colourPicker/ColourPicker'
import NumberInput from '@components/inputs/numberInput/NumberInput'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'

import pascalToReadable from '@utils/pascalToReadable'

import styles from './RiskLevelInput.module.scss'

const RiskLevelInput = ({ name, readOnly }) => {
    return (
        <div className={styles.riskLevelInput}>
            <TextInput
                name={`${name}.id`}
                hidden
            />

            <TextInput
                name={`${name}.version.id`}
                hidden
            />
            <div>
                <TextInput
                    name={`${name}.name`}
                    readOnly={readOnly}
                    inline={true}
                />
            </div>
            <div>
                <Select
                    name={`${name}.statisticalReturnRiskLevel`}
                    options={Object.keys(StatisticalReturnRiskLevels).map(key => ({
                        label: pascalToReadable(StatisticalReturnRiskLevels[key]),
                        value: StatisticalReturnRiskLevels[key],
                    }))}
                    readOnly={readOnly}
                    inline={true}
                />
            </div>
            <div>
                <ColourPicker
                    name={`${name}.version.colour`}
                    readOnly={readOnly}
                />
            </div>
            <div>
                <Select
                    name={`${name}.version.assessmentFrequency`}
                    options={[
                        {
                            label: '6 Months',
                            value: 6,
                        },
                        {
                            label: '12 Months',
                            value: 12,
                        },
                        {
                            label: '24 Months',
                            value: 24,
                        },
                        {
                            label: '36 Months',
                            value: 36,
                        },
                        {
                            label: '48 Months',
                            value: 48,
                        },
                    ]}
                    readOnly={readOnly}
                    inline={true}
                />
            </div>
            <div>
                <NumberInput
                    name={`${name}.version.counterSignaturesRequired`}
                    readOnly={readOnly}
                    inline={true}
                />
            </div>
            <div>
                <AutoGrowTextArea
                    name={`${name}.version.justification`}
                    readOnly={readOnly}
                />
            </div>
        </div>
    )
}

export default RiskLevelInput
