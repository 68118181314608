import { trpc } from '@utils/trpc'

export const useGetApprovalVersion = (relationshipApprovalVersionId, onSuccess) =>
    trpc.relationshipApproval.getVersionById.useQuery(
        { relationshipApprovalVersionId },
        { enabled: !!relationshipApprovalVersionId, onSuccess }
    )

export const useExportVersionToPdf = onSuccess =>
    trpc.relationshipApproval.exportVersionToPdf.useMutation({ onSuccess })

export const useUpdateApprovalVersion = () => {
    const context = trpc.useContext()

    return trpc.relationshipApproval.updateVersion.useMutation({
        onSuccess: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.relationshipApproval.invalidate()
        },
    })
}

export const useRevertApprovalVersionToDraft = () => {
    const context = trpc.useContext()

    return trpc.relationshipApproval.revertVersionToDraft.useMutation({
        onSettled: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.relationshipApproval.invalidate()
        },
    })
}

export const useCreateApprovalVersionSignature = () => {
    const context = trpc.useContext()

    return trpc.relationshipApproval.createVersionSignature.useMutation({
        onSettled: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.relationshipApproval.invalidate()
        },
    })
}

export const useCreateApprovalVersionCounterSignature = () => {
    const context = trpc.useContext()

    return trpc.relationshipApproval.createVersionCounterSignature.useMutation({
        onSettled: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.relationshipApproval.invalidate()
        },
    })
}
