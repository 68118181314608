import { FormStatuses, Grants } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { IdentityPolicyContexts } from '@features/relationships/relationship/relationshipEntity'
import { useCheckGrants } from '@features/user'

import Document from '@components/document/Document'
import EntityLabel from '@components/entity/EntityLabel'
import Loading from '@components/loading/Loading'
import MissingFields from '@components/missingFields/MissingFields'
import QueryError from '@components/queryError/QueryError'

import {
    useCreateIdentityVersion,
    useExportIdentityVersionToPdf,
    useGetIdentityByEntityId,
    useGetIdentityVersionById,
    useRemoveIdentityVersion,
    useSignIdentityVersion,
    useUpdateIdentityVersion,
} from '../hooks'

import IdentityForm from './IdentityForm'

const IdentityVersion = ({
    isNestedInsideRelationship,
    identityPolicyContext,
    edd,
}: {
    isNestedInsideRelationship: boolean
    identityPolicyContext?: IdentityPolicyContexts
    edd?: boolean
}) => {
    const isAllowed = useCheckGrants(Grants.ManageEntity) ?? false

    const navigate = useNavigate()
    const { entityId, identityVersionId } = useParams()
    const route = `../identity`

    const form = useForm()
    const {
        reset,
        trigger,
        getValues,
        formState: { isDirty },
    } = form

    const identity = useGetIdentityByEntityId(entityId, () => {})
    const identityVersion = useGetIdentityVersionById(identityVersionId, data => reset(data))

    const exportVersion = useExportIdentityVersionToPdf(data => window.open(data, '_blank'))
    const createVersion = useCreateIdentityVersion(data => navigate(route, { replace: true }))
    const updateVersion = useUpdateIdentityVersion()
    const signVersion = useSignIdentityVersion()
    const removeVersion = useRemoveIdentityVersion(() => navigate(route))

    const exportPdf = () => exportVersion.mutate({ id: identityVersionId as string })
    const create = () => createVersion.mutate({ id: identity.data?.id as string })

    const update = async data => updateVersion.mutateAsync(data)

    const sign = async () => {
        try {
            const isValid = await trigger()
            if (!isValid) return null
            if (isDirty) await update(getValues())
            return signVersion.mutate({ id: identityVersionId as string })
        } catch {
            return null
        }
    }

    const remove = () => removeVersion.mutate({ id: identityVersionId as string })

    if (identity.isLoading || identityVersion.isLoading) return <Loading />
    if (identity.isError || identityVersion.isError || !identity.data || !identityVersion.data)
        return <QueryError />

    return (
        <FormProvider {...form}>
            <Document
                isAllowed={isAllowed}
                alert={
                    identityPolicyContext && edd !== undefined ? (
                        <MissingFields
                            entityId={entityId as string}
                            edd={edd}
                            identityPolicyContext={identityPolicyContext}
                            type={'unidentified'}
                        />
                    ) : undefined
                }
                isNestedInsideRelationship={isNestedInsideRelationship}
                pageTitle={`Identity | ${identity.data.name}`}
                showHeader={true}
                landscape={false}
                type={'Identity'}
                showBreadcrumbs={true}
                breadcrumbs={[
                    {
                        label: (
                            <EntityLabel
                                id={identity.data.entity?.id}
                                name={identity.data.entity?.name}
                                entityType={identity.data.entity?.entityType}
                                clickable={true}
                            />
                        ),
                        link: `/entities/${entityId}`,
                    },
                ]}
                title={'Identity'}
                versions={identity.data.versions}
                isDraft={identityVersion.data.status === FormStatuses.Draft}
                isPending={false}
                versionId={identityVersion.data.id}
                versionName={`Version ${identityVersion.data.version}`}
                route={`${route}/versions`}
                Form={IdentityForm}
                exportPdf={{
                    function: exportPdf,
                    isLoading: exportVersion.isLoading,
                }}
                create={{
                    function: create,
                    isLoading: createVersion.isLoading,
                }}
                update={{
                    function: update,
                    isLoading: updateVersion.isLoading,
                    isSuccess: updateVersion.isSuccess,
                }}
                sign={{
                    function: sign,
                    isLoading: signVersion.isLoading,
                }}
                remove={
                    identity.data.versions.length > 1
                        ? {
                              function: remove,
                              isLoading: removeVersion.isLoading,
                          }
                        : undefined
                }
                signature={{
                    user: identityVersion.data.signature?.user as {
                        firstName: string
                        lastName: string
                    },
                    date: identityVersion.data.signature?.createDate as Date,
                }}
                history={identity.data.versions.map(version => ({
                    label: `Version ${version.version}`,
                    value: version.id,
                    isDraft: version.status === FormStatuses.Draft,
                    isPending: false,
                }))}
                error={
                    createVersion.isError ||
                    updateVersion.isError ||
                    signVersion.isError ||
                    removeVersion.isError
                }
            />
        </FormProvider>
    )
}

export default IdentityVersion
