import { trpc } from '@utils/trpc'

export const useRequestPasswordReset = onSuccess =>
    trpc.passwordResetTokens.create.useMutation({ onSuccess })

export const useGetPasswordResetToken = (tokenString, onSuccess, onError) =>
    trpc.passwordResetTokens.get.useQuery(
        { tokenString },
        {
            onSuccess,
            onError,
            retry: false,
        }
    )

export const useResetPassword = onSuccess =>
    trpc.users.updateSelfPasswordByTokenString.useMutation({ onSuccess })
