import { NavLink } from 'react-router-dom'

import styles from './Document.module.scss'

const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: { label: any; link: string }[] }) => {
    return (
        <div className={styles.breadcrumbs}>
            {breadcrumbs.map((crumb, i) => (
                <>
                    <NavLink to={crumb.link}>{crumb.label}</NavLink>
                    {i < breadcrumbs.length - 1 && <p> / </p>}
                </>
            ))}
        </div>
    )
}

export default Breadcrumbs
