import { useMemo } from 'react'
import { Plus } from 'react-feather'
import { Helmet } from 'react-helmet'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'

import Table from '@components/table/Table'
import VersionStatusTag from '@components/versionStatusTag/VersionStatusTag'

import { useGetDisclosures } from '../hooks'

const ListDisclosures = () => {
    const navigate = useNavigate()
    const link = props => navigate(`/reporting/disclosures/${props.original.id}`)

    const columns = useMemo(
        () => [
            {
                Header: 'Internal Reference',
                accessor: 'internalReference',
            },
            {
                Header: 'Latest Version',
                accessor: 'latestVersion.status',
                Cell: props => (
                    <VersionStatusTag status={props.row.original.latestVersion.status} />
                ),
            },
        ],
        []
    )

    const disclosures = useGetDisclosures()

    return (
        <>
            <Outlet />

            <Helmet>
                <title>Disclosures | Proofdesk</title>
            </Helmet>

            <div className='heading'>
                <div>
                    <h1>Disclosures</h1>
                </div>

                <NavLink
                    to={'/reporting/disclosures/create-disclosure'}
                    className='link dark'
                >
                    <Plus size={16} />
                    New Disclosure
                </NavLink>
            </div>

            {disclosures.isLoading || disclosures.isError || disclosures.data?.length ? (
                <Table
                    isLoading={disclosures.isLoading}
                    button={null}
                    columns={columns}
                    data={disclosures.data ?? []}
                    defaultPageSize={50}
                    link={link}
                    queryError={disclosures.isError}
                />
            ) : (
                <div className={'nothingToShow'}>
                    <p>You haven't created any disclosures yet.</p>
                </div>
            )}
        </>
    )
}

export default ListDisclosures
