import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useFormContext } from 'react-hook-form'

import { useGetSelfUserMemberships } from '@features/organisation/teamMembers'

import InputWrapper from '@components/inputWrapper/InputWrapper'
import DateInput from '@components/inputs/dateInput/DateInput'
import TextInput from '@components/inputs/textInput/TextInput'
import UserSelect from '@components/inputs/userSelect/UserSelect'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import RiskLevelTable from '../components/RiskLevelTable'

import styles from '@styles/Form.module.scss'

const RiskLevelPolicyForm = ({ readOnly = false }) => {
    const {
        formState: { errors },
    } = useFormContext()

    const users = useGetSelfUserMemberships()

    if (users.isLoading) return <Loading />
    if (users.isError || !users.data) return <QueryError isInsideModal={true} />

    return (
        <>
            <TextInput
                hidden
                name='id'
            />

            <TextInput
                hidden
                name='riskLevelPolicyId'
            />

            <div className={styles.row}>
                {!readOnly ? (
                    <InputWrapper
                        label='Policy Version'
                        input={
                            <TextInput
                                name={'versionName'}
                                required
                                readOnly={readOnly}
                            />
                        }
                        errors={errors.versionName}
                        readOnly={readOnly}
                    />
                ) : null}
                <span />
                <span />
            </div>

            <RiskLevelTable
                name={'riskLevels'}
                readOnly={readOnly}
            />

            <div className={styles.row}>
                <InputWrapper
                    label='Owner'
                    input={
                        <UserSelect
                            users={users.data.map(user => user.user)}
                            name={'ownerUserId'}
                            required={true}
                            readOnly={readOnly}
                        />
                    }
                    errors={errors.ownerUserId}
                    readOnly={readOnly}
                />
                <InputWrapper
                    label='Next Review Date'
                    input={
                        <DateInput
                            name={'nextReviewDate'}
                            placeholder={''}
                            readOnly={readOnly}
                            required={true}
                        />
                    }
                    errors={errors.nextReviewDate}
                    readOnly={readOnly}
                />
                <span />
            </div>
        </>
    )
}

export default RiskLevelPolicyForm
