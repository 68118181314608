const StatutoryProvisions = {
    IM: {
        LTD: ['Companies Act 1931', 'Companies Act 2006'],
        PLC: ['Companies Act 1931', 'Companies Act 2006'],
        LLC: ['Limited Liability Companies Act 1996'],
        PCC: ['Companies Act 1931', 'Companies Act 2006'],
        ICC: ['Companies Act 1931', 'Companies Act 2006'],
        IC: ['Companies Act 1931', 'Companies Act 2006'],
    },
}

export { StatutoryProvisions }
