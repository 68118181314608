import { trpc } from '@utils/trpc'

export const useGetDisclosures = () =>
    trpc.disclosures.getAll.useQuery(undefined, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    })

export const useCreateDisclosure = onSuccess => {
    const context = trpc.useContext()

    return trpc.disclosures.create.useMutation({
        onSuccess,
        onSettled: () => context.disclosures.getAll.invalidate(),
    })
}

export const useGetDisclosureById = (id, onSuccess) =>
    trpc.disclosures.getById.useQuery({ id }, { enabled: !!id, onSuccess })

export const useGetDisclosureVersionById = (id, onSuccess) =>
    trpc.disclosures.getVersionById.useQuery({ id }, { enabled: !!id, onSuccess })

export const useCreateDisclosureVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.disclosures.createVersion.useMutation({
        onSuccess,
        onSettled: () => context.disclosures.invalidate(),
    })
}

export const useUpdateDisclosureVersion = () => {
    const context = trpc.useContext()

    return trpc.disclosures.updateVersion.useMutation({
        onSuccess: () => context.disclosures.invalidate(),
    })
}

export const useSignDisclosureVersion = () => {
    const context = trpc.useContext()

    return trpc.disclosures.signVersion.useMutation({
        onSettled: () => context.disclosures.invalidate(),
    })
}

export const useRemoveDisclosureVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.disclosures.removeVersion.useMutation({
        onSuccess,
        onSettled: () => context.disclosures.invalidate(),
    })
}
