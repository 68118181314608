import { Route, Routes } from 'react-router-dom'

import NotificationSettings from '@features/account/views/NotificationSettings'

import Account from './layouts/Account'
import AccountSettings from './views/AccountSettings'
import ListInvitations from './views/ListInvitations'

export const AccountRoutes = () => {
    return (
        <Account>
            <Routes>
                <Route
                    path='general-settings'
                    element={<AccountSettings />}
                />
                <Route
                    path='invitations'
                    element={<ListInvitations />}
                />
                <Route
                    path='notification-settings'
                    element={<NotificationSettings />}
                />
            </Routes>
        </Account>
    )
}
