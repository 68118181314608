import { includesAny } from '../../utils'
import classNames from 'classnames'

import AddressInput from '@components/inputs/addressInput/AddressInput'
import TextInput from '@components/inputs/textInput/TextInput'

import IdentityComponentInput from '../components/IdentityComponentInput'

import styles from '../Identity.module.scss'

const Correspondence = ({ entityType, readOnly }) => {
    if (
        !includesAny(entityType, [
            'contactPerson',
            'emailAddress',
            'phoneNumber',
            'correspondenceAddress',
        ])
    )
        return null

    return (
        <div
            className={classNames({
                [styles.identityComponents]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <div className={styles.heading}>
                <h2>Correspondence</h2>
                {/*<p>*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                {/*    incididunt.*/}
                {/*</p>*/}
            </div>

            <div className={styles.form}>
                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'contactPerson'}
                        input={
                            <TextInput
                                name={'contactPerson'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'emailAddress'}
                        input={
                            <TextInput
                                name={'emailAddress'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'phoneNumber'}
                        input={
                            <TextInput
                                name={'phoneNumber'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />
                </div>

                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'correspondenceAddress'}
                        input={
                            <AddressInput
                                name={'correspondenceAddress'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />
                    <span />
                </div>
            </div>
        </div>
    )
}

export default Correspondence
