import { trpc } from '@utils/trpc'

export const useGetSelfUserMemberships = () =>
    trpc.organisations.getSelfOrganisationMemberships.useQuery()

export const useGetMembership = (id, onSuccess) =>
    trpc.organisations.getMembershipById.useQuery({ id }, { onSuccess })
export const useUpdateMembership = onSuccess => {
    const context = trpc.useContext()

    return trpc.organisations.updateUserMembership.useMutation({
        onSuccess,
        onSettled: () => context.organisations.invalidate(),
    })
}

export const useInviteUser = onSuccess => {
    const context = trpc.useContext()

    return trpc.inviteTokens.create.useMutation({
        onSuccess,
        onSettled: () => context.inviteTokens.invalidate(),
    })
}

export const useGetInvitedTeamMembers = () =>
    trpc.inviteTokens.getSelfOrganisationInvitedUsers.useQuery(undefined)

export const useRevokeInvite = () => {
    const context = trpc.useContext()

    return trpc.inviteTokens.revokeByUserEmail.useMutation({
        onSettled: () => context.inviteTokens.invalidate(),
    })
}

export const useRemoveTeamMember = onSuccess => {
    const context = trpc.useContext()

    return trpc.organisations.deleteSelfUserMembership.useMutation({
        onSuccess,
        onSettled: () => context.organisations.invalidate(),
    })
}
