import moment from 'moment/moment'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { useGetEntity } from '@features/entities/entity/hooks'

import ControllingPartyTerm from '@components/controllingPartyTerm/ControllingPartyTerm'
import EntityLabel from '@components/entity/EntityLabel'
import Table from '@components/table/Table'

import pascalToReadable from '@utils/pascalToReadable'

import { useGetControllingPartiesPos1 } from '../hooks'

const ListPositionsHeld = () => {
    const { entityId } = useParams()
    const controllingParties = useGetControllingPartiesPos1(entityId)
    const entity = useGetEntity({ entityId }, () => {})

    const navigate = useNavigate()
    const link = props =>
        navigate(
            `/entities/${props.original.entity2.id}/controlling-parties/${props.original.latestVersion.controllingPartyId}/versions/${props.original.latestVersion.id}`
        )

    const columns = useMemo(
        () => [
            {
                Header: 'Entity',
                accessor: 'entity2.name',
                Cell: props => (
                    <EntityLabel
                        id={props.row.original.entity2.id}
                        name={props.row.original.entity2.name}
                        entityType={props.row.original.entity2.entityType}
                        clickable
                    />
                ),
            },
            {
                Header: 'Position(s)',
                accessor: row => row.latestSignedVersion?.controllingPartyTypes || null,
                Cell: props => props.value?.map(x => pascalToReadable(x)).join(', '),
            },
            {
                Header: 'Shareholding',
                accessor: 'latestVersion.shareholdingPercentage',
                Cell: props => `${(props.value * 100).toFixed(2)}%`,
            },
            {
                Header: 'Term',
                accessor: 'latestVersion.startDate',
                Cell: props => (
                    <ControllingPartyTerm
                        startDate={props.row.original.latestVersion?.startDate}
                        endDate={props.row.original.latestVersion?.endDate}
                    />
                ),
            },
            {
                Header: 'Updated On',
                accessor: 'updateDate',
                Cell: props => moment(props.value).format('MMMM DD, YYYY'),
            },
        ],
        []
    )

    return (
        <main>
            <Outlet />

            <Helmet>
                <title>
                    Positions Held {entity.data ? `| ${entity.data.name} ` : ''}| Proofdesk
                </title>
            </Helmet>

            <div className='heading'>
                <div>
                    <h1>Positions Held</h1>
                </div>
            </div>

            {controllingParties.data?.length === 0 ? (
                <div className={'nothingToShow'}>
                    <p>This entity is not the controlling party of any other entities.</p>
                </div>
            ) : (
                <Table
                    button={null}
                    isLoading={controllingParties.isLoading}
                    columns={columns}
                    data={controllingParties.data ?? []}
                    defaultPageSize={50}
                    link={link}
                    queryError={controllingParties.isError || entity.isError}
                />
            )}
        </main>
    )
}

export default ListPositionsHeld
