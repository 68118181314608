import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useCreateRemediation } from '@features/relationships/relationship/remediations/hooks'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import AutoGrowTextArea from '@components/inputs/autoGrowTextArea/AutoGrowTextArea'
import TextInput from '@components/inputs/textInput/TextInput'

import styles from '@styles/Form.module.scss'

const CreateRemediation = () => {
    const navigate = useNavigate()
    const { relationshipId, relationshipReviewId } = useParams()

    const form = useForm()
    const {
        handleSubmit,
        formState: { errors },
    } = form

    const { mutate, isLoading } = useCreateRemediation(() =>
        navigate(`/relationships/${relationshipId}`)
    )

    const onSubmit = data => mutate(data)

    return (
        <Modal
            overlay={true}
            heading={'Request Remediation'}
            close={`..`}
            large={true}
        >
            <FormProvider {...form}>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <TextInput
                        hidden={true}
                        name={'relationshipReviewId'}
                        defaultValue={relationshipReviewId}
                    />

                    <InputWrapper
                        label={'Task'}
                        input={
                            <AutoGrowTextArea
                                name={'task'}
                                medium={true}
                                required
                            />
                        }
                        errors={errors.task}
                    />
                    <div className={styles.actions}>
                        <Button
                            label='Cancel'
                            onClick={() => navigate(`/relationships/${relationshipId}`)}
                            outline={true}
                            disabled={isLoading}
                            type={'button'}
                        />

                        <Button
                            label='Continue'
                            disabled={isLoading}
                            loading={isLoading}
                        />
                    </div>
                </form>
            </FormProvider>
        </Modal>
    )
}

export default CreateRemediation
