import { useEffect, useState } from 'react'

import ProgressBar from '@components/progressBar/ProgressBar'

const RandomProgressBar = () => {
    const [progress, setProgress] = useState(0)
    const minStart = 20
    const maxStart = 30
    const init = (maxStart - minStart + minStart) * Math.random() // Random number between 10 and 30 (inclusive)

    useEffect(() => {
        if (progress === 0) setProgress(init)
        const interval = setInterval(() => {
            setProgress(oldProgress => oldProgress + (100 - oldProgress) / 2.5)
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <ProgressBar
            progress={progress}
            blue={true}
        />
    )
}

export default RandomProgressBar
