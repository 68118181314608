import { useFieldArray, useFormContext } from 'react-hook-form'

import RequirementSelector from '@features/identityPolicies/components/requirementSelector/RequirementSelector'

import AutoGrowTextArea from '@components/inputs/autoGrowTextArea/AutoGrowTextArea'

import pascalToReadable from '@utils/pascalToReadable'

import styles from './RequirementsTable.module.scss'

const RequirementsTable = ({ name, readOnly }) => {
    const { control, getValues } = useFormContext()

    const components = useFieldArray({
        name: `${name}.components`,
        control,
    })

    return (
        <div className={styles.requirementsTable}>
            <h3>{pascalToReadable(getValues(`${name}.entityType`))}</h3>

            <div className={styles.table}>
                <div className={styles.header}>
                    <div>
                        <p>Component</p>
                        <p>Customer</p>
                        <p>Controlling Party</p>
                        <p>Beneficial Owner</p>
                        <p>Introducer</p>
                        <p>Justification</p>
                    </div>
                </div>

                {components.fields.map((component, index) => (
                    <div>
                        <div>
                            <p>{pascalToReadable(getValues(`${name}.components.${index}.name`))}</p>
                        </div>
                        <div>
                            <RequirementSelector
                                name={`${name}.components.${index}.customer`}
                                enhanced={false}
                                readOnly={readOnly}
                            />
                        </div>
                        <div>
                            <RequirementSelector
                                name={`${name}.components.${index}.customer`}
                                enhanced={true}
                                readOnly={readOnly}
                            />
                        </div>
                        <div>
                            <RequirementSelector
                                name={`${name}.components.${index}.controllingParty`}
                                enhanced={false}
                                readOnly={readOnly}
                            />
                        </div>
                        <div>
                            <RequirementSelector
                                name={`${name}.components.${index}.controllingParty`}
                                enhanced={true}
                                readOnly={readOnly}
                            />
                        </div>
                        <div>
                            <RequirementSelector
                                name={`${name}.components.${index}.beneficialOwner`}
                                enhanced={false}
                                readOnly={readOnly}
                            />
                        </div>
                        <div>
                            <RequirementSelector
                                name={`${name}.components.${index}.beneficialOwner`}
                                enhanced={true}
                                readOnly={readOnly}
                            />
                        </div>
                        <div>
                            <RequirementSelector
                                name={`${name}.components.${index}.introducer`}
                                enhanced={false}
                                readOnly={readOnly}
                            />
                        </div>
                        <div>
                            <RequirementSelector
                                name={`${name}.components.${index}.introducer`}
                                enhanced={true}
                                readOnly={readOnly}
                            />
                        </div>
                        <div>
                            <AutoGrowTextArea
                                name={`${name}.components.${index}.justification`}
                                readOnly={readOnly}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default RequirementsTable
