import styles from './Loading.module.scss'

const Loading = () => {
    return (
        <div className={styles.loading}>
            <img
                src='/images/loading-black.svg'
                alt={'Loading...'}
            />
        </div>
    )
}

export default Loading
