import { Route } from 'react-router-dom'

import CreateRole from './views/CreateRole'
import EditRole from './views/EditRole'
import ListRoles from './views/ListRoles'

export const RolesRoutes = () => {
    return (
        <Route
            path='roles'
            element={<ListRoles />}
        >
            <Route
                path='create'
                element={<CreateRole />}
            />
            <Route
                path=':roleId'
                element={<EditRole />}
            />
        </Route>
    )
}
