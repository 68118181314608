import { Route } from 'react-router-dom'

import RelationshipDetailsVersion from '@features/relationships/relationship/relationshipDetails/views/RelationshipDetailsVersion'

export const RelationshipDetailsRoutes = () => {
    return (
        <>
            <Route
                path='relationship-details-version/:relationshipDetailsVersionId'
                element={<RelationshipDetailsVersion />}
            />
        </>
    )
}
