import { FormStatuses, Grants } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import Document from '@components/document/Document'
import EntityLabel from '@components/entity/EntityLabel'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import {
    useCreateDocumentVersion,
    useExportDocumentVersionToPdf,
    useGetDocumentById,
    useGetDocumentVersionById,
    useRemoveDocument,
    useRemoveDocumentVersion,
    useSignDocumentVersion,
    useUpdateDocumentVersion,
} from '../hooks'

import DocumentForm from './DocumentForm'

const DocumentVersion = ({
    isNestedInsideRelationship,
}: {
    isNestedInsideRelationship: boolean
}) => {
    const isAllowed = useCheckGrants(Grants.ManageEntity) ?? false
    const navigate = useNavigate()
    const { entityId, documentId, documentVersionId } = useParams()

    const form = useForm()
    const {
        reset,
        trigger,
        getValues,
        formState: { isDirty },
    } = form
    const route = `../documents/${documentId}`

    const document = useGetDocumentById(documentId, () => {})
    const documentVersion = useGetDocumentVersionById(documentVersionId, data => reset(data))

    const exportVersion = useExportDocumentVersionToPdf(data => window.open(data, '_blank'))
    const createVersion = useCreateDocumentVersion(data => navigate(route, { replace: true }))
    const updateVersion = useUpdateDocumentVersion()
    const signVersion = useSignDocumentVersion()
    const removeVersion = useRemoveDocumentVersion(() => navigate(route))
    const deleteDocument = useRemoveDocument(() => navigate(`../documents`))

    const exportPdf = () => exportVersion.mutate({ id: documentVersionId as string })
    const create = () => createVersion.mutate({ id: documentId as string })

    const update = async data => updateVersion.mutateAsync(data)
    const sign = async () => {
        try {
            const isValid = await trigger()
            if (!isValid) return null
            if (isDirty) await update(getValues())
            return signVersion.mutate({ id: documentVersionId as string })
        } catch {
            return null
        }
    }

    const remove = () => removeVersion.mutate({ id: documentVersionId as string })
    const deleteAction = () => {
        if (
            window.confirm(
                `Are you sure you wish to permanently delete this document and all of its previous versions?`
            )
        )
            deleteDocument.mutate({ id: documentId as string })
    }

    if (document.isLoading || documentVersion.isLoading) return <Loading />
    if (document.isError || documentVersion.isError || !document.data || !documentVersion.data)
        return <QueryError />

    return (
        <FormProvider {...form}>
            <Document
                isAllowed={isAllowed}
                isNestedInsideRelationship={isNestedInsideRelationship}
                pageTitle={`${documentVersion.data.title} | ${documentVersion.data.document.entity?.name}`}
                showHeader={true}
                landscape={false}
                type={'Verification Document'}
                showBreadcrumbs={true}
                breadcrumbs={[
                    {
                        label: (
                            <EntityLabel
                                id={documentVersion.data.document.entity?.id}
                                name={documentVersion.data.document.entity?.name}
                                entityType={documentVersion.data.document.entity?.entityType}
                                clickable={true}
                            />
                        ),
                        link: `/entities/${entityId}`,
                    },
                    { label: 'Documents', link: `/entities/${entityId}/documents` },
                ]}
                title={document.data.title || ''}
                versions={document.data.versions}
                isDraft={documentVersion.data.status === FormStatuses.Draft}
                isPending={false}
                versionId={documentVersion.data.id}
                versionName={`Version ${documentVersion.data.version}`}
                route={`${route}/versions`}
                Form={DocumentForm}
                exportPdf={{
                    function: exportPdf,
                    isLoading: exportVersion.isLoading,
                }}
                create={{
                    function: create,
                    isLoading: createVersion.isLoading,
                }}
                update={{
                    function: update,
                    isLoading: updateVersion.isLoading,
                    isSuccess: updateVersion.isSuccess,
                }}
                sign={{
                    function: sign,
                    isLoading: signVersion.isLoading,
                }}
                remove={{
                    function: remove,
                    isLoading: removeVersion.isLoading,
                }}
                deleteAction={{
                    function: deleteAction,
                    isLoading: deleteDocument.isLoading,
                }}
                signature={{
                    user: documentVersion.data.signature?.user as {
                        firstName: string
                        lastName: string
                    },
                    date: documentVersion.data.signature?.createDate as Date,
                }}
                history={document.data.versions.map(version => ({
                    label: `Version ${version.version}`,
                    value: version.id,
                    isDraft: version.status === FormStatuses.Draft,
                    isPending: false,
                }))}
                error={
                    createVersion.isError ||
                    updateVersion.isError ||
                    signVersion.isError ||
                    removeVersion.isError
                }
            />
        </FormProvider>
    )
}

export default DocumentVersion
