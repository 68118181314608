import { DisclosureTypes } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'

import pascalToReadable from '@utils/pascalToReadable'

import { useCreateDisclosure } from '../hooks'

const CreateDisclosure = () => {
    const navigate = useNavigate()

    const form = useForm({
        shouldUnregister: true,
    })

    const {
        handleSubmit,
        formState: { errors },
    } = form

    const { mutate, isLoading } = useCreateDisclosure(data =>
        navigate(`/reporting/disclosures/${data.id}`)
    )

    const onSubmit = data => mutate(data)

    return (
        <Modal
            overlay={true}
            heading={'New Disclosure'}
            close={'/reporting/disclosures'}
        >
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputWrapper
                        label={'Internal Reference'}
                        input={
                            <TextInput
                                name={'internalReference'}
                                required
                            />
                        }
                        errors={errors.internalReference}
                    />

                    <InputWrapper
                        label={'Disclosure Type'}
                        input={
                            <Select
                                name={'disclosureType'}
                                required
                                options={Object.keys(DisclosureTypes).map(disclosureType => ({
                                    label: pascalToReadable(disclosureType),
                                    value: disclosureType,
                                }))}
                            />
                        }
                        errors={errors.disclosureType}
                    />

                    <Button
                        label={'Continue'}
                        fullWidth={true}
                        disabled={isLoading}
                    />
                </form>
            </FormProvider>
        </Modal>
    )
}

export default CreateDisclosure
