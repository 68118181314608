import { Grants } from '@prisma/client'
import fuzzy from 'fuzzy'
import moment from 'moment/moment'
import { useMemo, useState } from 'react'
import { Flag, Plus } from 'react-feather'
import { Helmet } from 'react-helmet'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import CircularProgress from '@components/circularProgress/CircularProgress'
import EntityLabel from '@components/entity/EntityLabel'
import Search from '@components/inputs/search/Search'
import ReviewTag from '@components/reviewTag/ReviewTag'
import Table from '@components/table/Table'
import RelationshipStatusTag from '@components/tag/RelationshipStatusTag'
import RiskLevelTag from '@components/tag/RiskLevelTag'
import UserLabel from '@components/user/UserLabel'

import { useGetRelationships } from '../hooks'

const ListRelationships = () => {
    const [searchParams] = useSearchParams()
    const hasManageRelationshipGrant = useCheckGrants(Grants.ManageRelationship)

    const [search, setSearch] = useState('')

    const navigate = useNavigate()
    const link = props => navigate(`/relationships/${props.original.id}`)

    const columns = useMemo(
        () => [
            {
                Header: 'Status',
                accessor: 'status',
                Cell: props => (
                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                        <RelationshipStatusTag status={props.row.original.status} />
                        {props.row.original.checklistPercentProgress ? (
                            <CircularProgress
                                value={props.row.original.checklistPercentProgress * 100}
                            />
                        ) : null}
                        {props.row.original.isFlaggedForReview ? (
                            <Flag
                                size={16}
                                color={'#295fe7'}
                            />
                        ) : null}
                    </div>
                ),
            },
            {
                Header: 'Reference',
                accessor: 'internalReference',
                Cell: props => props.row.original.internalReference,
            },
            {
                Header: 'Customer Entity',
                accessor: 'entity.name',
                Cell: props => (
                    <EntityLabel
                        id={props.row.original.entity.id}
                        name={props.row.original.entity.name}
                        entityType={props.row.original.entity.entityType}
                        clickable
                    />
                ),
            },
            {
                Header: 'Latest Review',
                accessor: 'latestReview',
                Cell: props =>
                    props.row.original.termination ? (
                        'Termination'
                    ) : (
                        <ReviewTag
                            status={props.row.original.latestReview.status}
                            name={props.row.original.latestReview.versionName}
                        />
                    ),
            },
            {
                Header: 'Owner',
                accessor: 'ownerUser',
                Cell: props => <UserLabel user={props.row.original.ownerUser} />,
            },
            {
                Header: 'Next Review Date',
                accessor: 'nextScheduledReviewDate',
                Cell: props =>
                    props.row.original.nextScheduledReviewDate
                        ? moment(props.row.original.nextScheduledReviewDate).format('MMMM DD, YYYY')
                        : undefined,
            },
            {
                Header: 'Risk Level',
                accessor: 'riskLevel',
                Cell: props => <RiskLevelTag riskLevelId={props.row.original.riskLevel?.id} />,
            },
        ],
        []
    )

    const relationships = useGetRelationships()

    let results = relationships.data?.sort((a, b) => {
        // Check if either relationship has a status that should move it to the end
        const endStatuses = ['Terminated', 'Declined', 'Closed']
        const isAEndStatus = endStatuses.includes(a.status)
        const isBEndStatus = endStatuses.includes(b.status)

        if (isAEndStatus && !isBEndStatus) {
            return 1
        } else if (!isAEndStatus && isBEndStatus) {
            return -1
        }

        return 0
    })
    if (searchParams.get('status') && searchParams.get('status') === 'onboarding')
        results = results?.filter(relationship => relationship.status === 'Onboarding')
    if (searchParams.get('status') && searchParams.get('status') === 'active')
        results = results?.filter(relationship => relationship.status === 'Active')
    if (searchParams.get('status') && searchParams.get('status') === 'terminated')
        results = results?.filter(
            relationship =>
                relationship.status === 'Terminated' ||
                relationship.status === 'Declined' ||
                relationship.status === 'Closed'
        )

    if (!results) results = []

    const fuzzyResults = fuzzy
        .filter(search, results, {
            extract: relationship =>
                relationship.internalReference +
                ' ' +
                relationship.entity.identity?.versions[
                    relationship.entity.identity?.versions.length - 1
                ]?.name,
        })
        .map(result => result.original)

    return (
        <>
            <Helmet>
                <title>Relationships | Proofdesk</title>
            </Helmet>

            <Search
                search={search}
                setSearch={setSearch}
            />

            {relationships.isLoading || relationships.isError || relationships.data?.length ? (
                <Table
                    isLoading={relationships.isLoading}
                    button={null}
                    columns={columns}
                    data={fuzzyResults ?? []}
                    defaultPageSize={50}
                    link={link}
                    queryError={relationships.isError}
                />
            ) : (
                <div className={'nothingToShow'}>
                    <p>You haven't established any relationships yet.</p>
                    {hasManageRelationshipGrant ? (
                        <NavLink
                            to={'/relationships/create'}
                            className='link dark'
                        >
                            <Plus size={16} />
                            Establish Relationship
                        </NavLink>
                    ) : null}
                </div>
            )}
        </>
    )
}

export default ListRelationships
