// Style for the container to center the SVG
const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const CircularProgress = ({ value, size = 18 }) => {
    const strokeWidth = 4
    const radius = (size - strokeWidth) / 2
    const circumference = 2 * Math.PI * radius
    const normalizedValue = Math.min(100, Math.max(0, value))
    const offset = circumference - (normalizedValue / 100) * circumference

    // SVG circle styles
    const circleStyle: React.CSSProperties = {
        fill: 'transparent',
        strokeLinecap: 'butt',
        transform: 'rotate(-90deg)',
        transformOrigin: '50% 50%',
        transition: 'stroke-dashoffset 0.35s',
        strokeDasharray: circumference,
        strokeDashoffset: offset,
    }

    return (
        <div style={containerStyle}>
            <svg
                width={size}
                height={size}
                viewBox={`0 0 ${size} ${size}`}
                xmlns='http://www.w3.org/2000/svg'
            >
                <circle
                    stroke='#e6e6e6'
                    fill='transparent'
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={strokeWidth}
                />
                <circle
                    style={circleStyle}
                    stroke='#285FE4' // Color of the progress
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={strokeWidth}
                />
            </svg>
        </div>
    )
}

export default CircularProgress
