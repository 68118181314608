import ReactAuthCodeInput, { AuthCodeRef } from 'react-auth-code-input'
import { useController, useFormContext } from 'react-hook-form'

import styles from './PinInput.module.scss'
import { useEffect } from 'react'

const PinInput = ({ name, disabled = false, autoFocus = false, length = 6, required = false }) => {
    const { control, watch } = useFormContext()
    const { field } = useController({
        name,
        control,
        rules: {
            minLength: length,
            maxLength: length,
            required: required,
        },
    })
    let inputRefInstance: AuthCodeRef | null = null

    useEffect(() => {
        if (autoFocus) {
            inputRefInstance?.focus()
        }
    }, [autoFocus])

    const watchField = watch(name)
    useEffect(() => {
        // Clear the input if the field is cleared to prevent weird behaviour from react-auth-code-input
        if (watchField == null) {
            inputRefInstance?.clear()
        }
    }, [watchField])

    return (
        <ReactAuthCodeInput
            ref={instance => {
                inputRefInstance = instance // Save the ref locally
                field.ref(instance) // Also pass it to react-hook-form
            }}
            containerClassName={styles.pinInput}
            autoFocus={false}
            allowedCharacters='numeric'
            length={length}
            disabled={disabled}
            onChange={value => field.onChange(value)}
        />
    )
}

export default PinInput
