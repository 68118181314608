import { ContentState, EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import styles from './WYSIWYG.module.scss'

const WYSIWYG = ({
    value,
    onChange,
    readOnly,
}: {
    value: string
    onChange: Function
    readOnly: boolean
}) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    useEffect(() => {
        const currentHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        const contentBlock = htmlToDraft(value)
        const contentState = contentBlock
            ? ContentState.createFromBlockArray(contentBlock.contentBlocks)
            : null

        if (currentHtml !== value) setEditorState(EditorState.createWithContent(contentState))
    }, [value])

    const onEditorStateChange = editorState => {
        setEditorState(editorState)
        return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }

    return (
        <Editor
            readOnly={readOnly}
            editorState={editorState}
            editorClassName={!readOnly ? styles.editor : styles.editorReadonly}
            wrapperClassName={!readOnly ? styles.wrapper : styles.wrapperReadonly}
            toolbarClassName={!readOnly ? styles.toolbar : styles.toolbarReadonly}
            onEditorStateChange={onEditorStateChange}
            toolbar={{
                options: ['inline', 'blockType', 'list', 'textAlign'],
                inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough'],
                },
                blockType: {
                    options: ['Normal', 'H1', 'H2', 'H3'],
                },
            }}
        />
    )
}

export default WYSIWYG
