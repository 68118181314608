import { Navigate } from 'react-router-dom'

import { AcceptInvitationRoutes } from '@features/acceptInvitation'
import { CreateAccountRoutes } from '@features/createAccount'
import { ForgotPasswordRoutes } from '@features/forgotPassword'
import { LoginRoutes } from '@features/login'
import { VerifyEmailRoutes } from '@features/verifyEmail'

import Entry from '@layouts/entry/Entry'

export const PublicRoutes = [
    {
        path: '/',
        exact: true,
        element: (
            <Navigate
                to='/login'
                replace
            />
        ),
    },
    {
        path: '/',
        element: <Entry />,
        children: [
            {
                path: '/login/*',
                element: <LoginRoutes />,
            },
            {
                path: '/forgot-password/*',
                element: <ForgotPasswordRoutes />,
            },
            {
                path: '/verify-email/*',
                element: <VerifyEmailRoutes />,
            },
            {
                path: '/create-account/*',
                element: <CreateAccountRoutes />,
            },
            {
                path: '/accept-invitation/:tokenString',
                element: <AcceptInvitationRoutes />,
            },
            {
                path: '*',
                element: <Navigate to='.' />,
            },
        ],
    },
]
