import { JSX } from 'react'
import { useFormContext } from 'react-hook-form'

import login from '@features/login/views/Login'

import Breadcrumbs from '@components/document/Breadcrumbs'

import { Action } from './Document'
import EditableActions from './EditableActions'

import styles from './Document.module.scss'
import formStyles from '@styles/Form.module.scss'

const Editable = ({
    more,
    isAllowed,
    title,
    Form,
    sign,
    update,
    setPending,
    remove,
    showBreadcrumbs,
    breadcrumbs,
    error,
}: {
    more?: JSX.Element
    isAllowed: boolean
    title: string
    parents?: JSX.Element
    Form: any
    update: Action
    sign: Action
    setPending?: Action
    remove?: Action
    showBreadcrumbs: boolean
    breadcrumbs: { label: any; link: string }[]
    error?: boolean
}) => {
    const { getValues } = useFormContext()
    const onSubmit = async data => {
        try {
            await update.function(data)
        } catch {
            return null
        }
    }

    return (
        <div className={styles.editable}>
            <div className={styles.top}>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <h1>{title}</h1>
                        {breadcrumbs && showBreadcrumbs ? (
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                        ) : null}
                    </div>

                    {isAllowed && more && more}
                </div>

                <form
                    id={'form'} // Required to link to the Save button
                    className={formStyles.form}
                    onSubmit={e => {
                        e.preventDefault()
                        onSubmit(getValues())
                    }}
                >
                    <Form readOnly={!isAllowed} />
                </form>
            </div>

            <EditableActions
                isAllowed={isAllowed}
                update={update}
                sign={sign}
                setPending={setPending}
                remove={remove}
                error={error}
            />
        </div>
    )
}

export default Editable
