import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Check, ChevronDown } from 'react-feather'
import { NavLink, useParams } from 'react-router-dom'

import { useGetRiskLevel } from '@features/riskLevels'

import EntityLabel from '@components/entity/EntityLabel'
import ProgressBar from '@components/progressBar/ProgressBar'
import RiskLevelTag from '@components/tag/RiskLevelTag'
import Tag, { TagThemes } from '@components/tag/Tag'

import styles from './Stage.module.scss'

const Stage = ({
    checked,
    name,
    progress,
    entities,
    unavailable,
    customer,
    link,
    riskLevelId,
    edd,
    remediations,
}: {
    checked: boolean
    name: string
    progress?: number
    entities: any[] | null
    unavailable: boolean
    customer: boolean
    link: string | null
    riskLevelId: string | null
    edd?: boolean
    remediations?: {
        id: string
        task: string
        completed: boolean
    }[]
}) => {
    const { relationshipId, relationshipReviewId } = useParams()
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(!checked)
    }, [])

    const riskLevel = useGetRiskLevel(riskLevelId, () => {})

    const Entity = ({ entity }) => {
        return (
            <div className={styles.entity}>
                <NavLink
                    to={`${edd ? 'edd/' : ''}${customer ? 'customer' : 'controlling-party'}/${
                        entity.id
                    }`}
                    className={({ isActive }) =>
                        classNames({
                            [styles.entityLabel]: true,
                            [styles.active]: isActive,
                        })
                    }
                >
                    <EntityLabel
                        id={entity.id}
                        name={entity.name}
                        entityType={entity.entityType}
                    />
                    {entity.identityCompletion?.percentComplete !== null && !checked ? (
                        <ProgressBar
                            progress={entity.identityCompletion?.percentComplete * 100}
                            blue={true}
                        />
                    ) : null}
                </NavLink>

                {entity.controllingParties ? (
                    <div className={styles.controllingParties}>
                        {entity.controllingParties.map(entity => (
                            <Entity entity={entity} />
                        ))}
                    </div>
                ) : null}
            </div>
        )
    }

    const content = () => {
        return (
            <>
                <div
                    onClick={() => setOpen(!open)}
                    className={styles.title}
                >
                    <div>
                        <div
                            className={classNames({
                                [styles.checkmark]: true,
                                [styles.checked]: checked,
                            })}
                        >
                            {checked ? (
                                <Check
                                    size={14}
                                    color={'#ffffff'}
                                />
                            ) : null}
                        </div>
                        <p>{name}</p>
                        {edd ? (
                            <Tag
                                label={'EDD'}
                                theme={TagThemes.Red}
                                outline={false}
                            />
                        ) : null}
                    </div>
                    <div>
                        {riskLevel.data ? (
                            <RiskLevelTag riskLevelId={riskLevelId} />
                        ) : !entities && progress ? (
                            <ProgressBar
                                progress={progress}
                                blue={true}
                            />
                        ) : entities || remediations ? (
                            <button
                                className={classNames({
                                    [styles.chevron]: true,
                                    [styles.open]: open,
                                })}
                            >
                                <ChevronDown size={18} />
                            </button>
                        ) : null}
                    </div>
                </div>
                {entities && open ? (
                    <div
                        className={classNames({
                            [styles.drawer]: true,
                            [styles.customer]: customer,
                        })}
                    >
                        {entities.length === 0 ? (
                            <div className={styles.noControllingParties}>
                                <p>No Controlling Parties Identified</p>
                            </div>
                        ) : (
                            entities?.map(entity => <Entity entity={entity} />)
                        )}
                    </div>
                ) : null}
                {remediations && open ? (
                    <div
                        className={classNames({
                            [styles.remediations]: true,
                        })}
                    >
                        {remediations.map(remediation => (
                            <NavLink
                                to={`/relationships/${relationshipId}/reviews/${relationshipReviewId}/remediations/${remediation.id}`}
                                className={styles.remediation}
                            >
                                <div
                                    className={classNames({
                                        [styles.checkmark]: true,
                                        [styles.checked]: remediation.completed,
                                    })}
                                >
                                    {remediation.completed ? (
                                        <Check
                                            size={14}
                                            color={'#ffffff'}
                                        />
                                    ) : null}
                                </div>
                                <p>{remediation.task}</p>
                            </NavLink>
                        ))}
                    </div>
                ) : null}
            </>
        )
    }

    if (link)
        return (
            <NavLink
                to={link}
                end={true}
                className={({ isActive }) =>
                    classNames({
                        [styles.stage]: true,
                        [styles.unavailable]: unavailable,
                        [styles.active]: isActive,
                    })
                }
            >
                {content()}
            </NavLink>
        )

    return (
        <div
            className={classNames({
                [styles.stage]: true,
                [styles.unavailable]: unavailable,
            })}
        >
            {content()}
        </div>
    )
}

export default Stage
