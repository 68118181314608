import { Edit3 } from 'react-feather'

import { useGetSelf } from '@features/account'

import Button from '@components/button/Button'
import Signature from '@components/document/Signature'
import UserLabel from '@components/user/UserLabel'

import { Action } from './Document'

import styles from './Document.module.scss'

const PolicySignatures = ({ requiredSignatures, versionName, versionId, sign }) => {
    const self = useGetSelf(() => {})

    if (!self.data) return null

    return (
        <div className={styles.policySignatures}>
            {requiredSignatures.map(signature => {
                if (signature.signature)
                    return (
                        <Signature
                            user={{
                                firstName: signature.firstName,
                                lastName: signature.lastName,
                            }}
                            date={signature.signature.createDate}
                            versionName={versionName}
                            versionId={versionId}
                        />
                    )

                return (
                    <div className={styles.unsigned}>
                        <UserLabel user={signature} />

                        {self.data?.id === signature.id ? (
                            <Button
                                icon={<Edit3 size={16} />}
                                label={'Sign Document'}
                                onClick={sign.function}
                                loading={sign.isLoading}
                            />
                        ) : (
                            <Button
                                label={'Awaiting Signature'}
                                disabled={true}
                                outline={true}
                            />
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default PolicySignatures
