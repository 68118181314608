import CircularButton from '../CircularButton/CircularButton'
import { EntityTypes } from '@prisma/client'
import { Plus, X } from 'react-feather'
import { v4 as uuid } from 'uuid'

import TextInput from '@components/inputs/textInput/TextInput'

import styles from './ControllingPartyInput.module.scss'

const ControllingPartyInput = ({ index, name, insert, remove }) => {
    return (
        <div className={styles.controllingPartyInput}>
            <div className={styles.add}>
                <CircularButton
                    icon={<Plus size={14} />}
                    onClick={() =>
                        insert(index + 1, {
                            tempId: uuid(),
                            name: '',
                            entityType: EntityTypes.NaturalPerson,
                        })
                    }
                />
            </div>
            <div className={styles.input}>
                <TextInput name={`${name}.name`} />
            </div>
            <div className={styles.remove}>
                <CircularButton
                    icon={<X size={14} />}
                    onClick={() => remove(index)}
                />
            </div>
        </div>
    )
}

export default ControllingPartyInput
