import { trpc } from '@utils/trpc'

export const useCreateTermination = onSuccess => {
    const context = trpc.useContext()

    return trpc.relationshipTerminations.createTermination.useMutation({
        onSuccess,
        onSettled: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.relationshipTerminations.invalidate()
        },
    })
}

export const useGetById = id => trpc.relationshipTerminations.getById.useQuery({ id })

export const useGetVersionById = (id, onSuccess) =>
    trpc.relationshipTerminations.getVersionById.useQuery({ id }, { onSuccess })

export const useUpdateVersion = () => {
    const context = trpc.useContext()

    return trpc.relationshipTerminations.updateVersion.useMutation({
        onSuccess: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.relationshipTerminations.invalidate()
        },
    })
}

export const useSignVersion = () => {
    const context = trpc.useContext()

    return trpc.relationshipTerminations.signVersion.useMutation({
        onSettled: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.relationshipTerminations.invalidate()
        },
    })
}

export const useDeleteVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.relationshipTerminations.remove.useMutation({
        onSuccess,
        onSettled: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.relationshipTerminations.invalidate()
        },
    })
}

export const useExportVersionToPdf = onSuccess =>
    trpc.relationshipTerminations.exportVersionToPdf.useMutation({ onSuccess })
