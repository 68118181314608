import { Grants } from '@prisma/client'
import { RelationshipApprovalRoutes } from 'features/relationships/relationship/relationshipApproval'
import { Navigate, Route, Routes } from 'react-router-dom'

import RelationshipRedirect from '@features/relationships/relationship/components/relationshipRedirect/RelationshipRedirect'
import RelationshipReviewRedirect from '@features/relationships/relationship/components/relationshipReviewRedirect/RelationshipReviewRedirect'
import Checklist from '@features/relationships/relationship/components/sidebar/checklist/Checklist'
import TerminationChecklist from '@features/relationships/relationship/components/sidebar/terminationChecklist/TerminationChecklist'
import { CustomerRiskAssessmentRoutes } from '@features/relationships/relationship/customerRiskAssessment'
import { RelationshipDetailsRoutes } from '@features/relationships/relationship/relationshipDetails'
import { RelationshipEntityRoutes } from '@features/relationships/relationship/relationshipEntity'
import { RemediationRoutes } from '@features/relationships/relationship/remediations'
import { TerminationRoutes } from '@features/relationships/relationship/termination'
import AbortReview from '@features/relationships/relationship/views/AbortReview'
import Delete from '@features/relationships/relationship/views/Delete'
import Edit from '@features/relationships/relationship/views/Edit'
import InitiateReview from '@features/relationships/relationship/views/InitiateReview'
import { useCheckGrants } from '@features/user'

import Relationship from './views/Relationship'

export const RelationshipRoutes = () => {
    const hasManageRelationshipGrant = useCheckGrants(Grants.ManageRelationship) ?? false

    return (
        <Routes>
            <Route
                path={''}
                element={<RelationshipRedirect />}
            />
            <Route
                path={'reviews'}
                element={<RelationshipRedirect />}
            />
            <Route
                path={'edit'}
                element={
                    hasManageRelationshipGrant ? (
                        <Relationship sidebarContent={<></>}>
                            <Edit />
                        </Relationship>
                    ) : (
                        <Navigate to={'..'} />
                    )
                }
            />
            <Route
                path={'delete'}
                element={
                    hasManageRelationshipGrant ? (
                        <Relationship sidebarContent={<></>}>
                            <Delete />
                        </Relationship>
                    ) : (
                        <Navigate to={'..'} />
                    )
                }
            />
            <Route
                path={'/reviews/create'}
                element={
                    hasManageRelationshipGrant ? (
                        <Relationship sidebarContent={<></>}>
                            <InitiateReview />
                        </Relationship>
                    ) : (
                        <Navigate to={'..'} />
                    )
                }
            />
            <Route
                path={'reviews/:relationshipReviewId'}
                element={<Relationship sidebarContent={<Checklist />} />}
            >
                <Route
                    path={''}
                    element={<RelationshipReviewRedirect />}
                />
                {RelationshipDetailsRoutes()}
                {CustomerRiskAssessmentRoutes()}
                {RelationshipApprovalRoutes()}
                {RelationshipEntityRoutes()}
                {RemediationRoutes()}
                <Route
                    path={'abort'}
                    element={hasManageRelationshipGrant ? <AbortReview /> : <Navigate to={'..'} />}
                />
            </Route>
            <Route
                path={'termination/:terminationId'}
                element={<Relationship sidebarContent={<TerminationChecklist />} />}
            >
                {TerminationRoutes()}
            </Route>
        </Routes>
    )
}
