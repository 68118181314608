import { Route, Routes } from 'react-router-dom'

import CreateRiskAssessmentPolicy from './views/CreateRiskAssessmentPolicy'
import RiskAssessmentPolicy from './views/RiskAssessmentPolicy'
import RiskAssessmentPolicyVersion from './views/RiskAssessmentPolicyVersion'

export const RiskAssessmentPolicyRoutes = () => {
    return (
        <Routes>
            <Route
                path={'create'}
                element={<CreateRiskAssessmentPolicy />}
            />
            <Route
                path={':riskAssessmentPolicyId'}
                element={<RiskAssessmentPolicy />}
            />
            <Route
                path={':riskAssessmentPolicyId/versions/:riskAssessmentPolicyVersionId'}
                element={<RiskAssessmentPolicyVersion />}
            />
        </Routes>
    )
}
