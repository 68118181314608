import { CreateEntityRoutes } from '../createEntity'
import { Route, Routes } from 'react-router-dom'

import { EntityRoutes } from './entity'
import Entities from './layouts/Entities'
import ListEntities from './views/ListEntities'

export const EntitiesRoutes = () => {
    return (
        <Routes>
            <Route
                path=''
                element={
                    <Entities>
                        <ListEntities />
                    </Entities>
                }
            >
                {CreateEntityRoutes()}
            </Route>
            <Route
                path='customers'
                element={
                    <Entities>
                        <ListEntities />
                    </Entities>
                }
            />
            <Route
                path='controlling-parties'
                element={
                    <Entities>
                        <ListEntities />
                    </Entities>
                }
            />
            <Route
                path='unassociated'
                element={
                    <Entities>
                        <ListEntities />
                    </Entities>
                }
            />
            <Route
                path=':entityId/*'
                element={<EntityRoutes />}
            />
        </Routes>
    )
}
