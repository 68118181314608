import { Grants } from '@prisma/client'
import { Lock } from 'react-feather'
import { useParams } from 'react-router-dom'

import { useSubmitForApproval } from '@features/relationships/relationship/hooks'
import { useCheckGrants } from '@features/user'

import Button from '@components/button/Button'

import styles from './SubmitForApproval.module.scss'

const SubmitForApproval = ({ disabled }: { disabled: boolean }) => {
    const isAllowed = useCheckGrants(Grants.SubmitRelationship) ?? false
    const { relationshipReviewId } = useParams()

    const submitForApproval = useSubmitForApproval()

    const onClick = () =>
        submitForApproval.mutate({
            relationshipReviewId: relationshipReviewId as string,
        })

    return (
        <div className={styles.submitForApproval}>
            <span />
            <Button
                icon={!isAllowed ? <Lock size={16} /> : null}
                label={'Submit for Approval'}
                onClick={onClick}
                disabled={disabled || !isAllowed}
                loading={submitForApproval.isLoading}
            />
            <span />
        </div>
    )
}

export default SubmitForApproval
