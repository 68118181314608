import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Calendar } from 'react-feather'
import { useController, useFormContext } from 'react-hook-form'

import styles from './DateInput.module.scss'

const DateInput = ({
    name,
    placeholder,
    readOnly,
    required,
}: {
    name: string
    placeholder: string
    readOnly?: boolean
    required?: boolean
}) => {
    const { control } = useFormContext()
    const { field } = useController({
        name,
        control,
        rules: { required },
    })

    return (
        <div
            className={classNames({
                [styles.datePicker]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <DatePicker
                selected={field.value}
                onChange={date => field.onChange(date)}
                dateFormat={'dd/MM/yyyy'}
                placeholderText={placeholder}
                disabled={readOnly}
                wrapperClassName={styles.datePickerWrapper}
            />
            {!readOnly ? (
                <Calendar
                    size={16}
                    color={'#aaaaaa'}
                />
            ) : null}
        </div>
    )
}

export default DateInput
