import { AtSign } from 'react-feather'
import { NavLink } from 'react-router-dom'

const CreateAccountSuccess = () => {
    return (
        <div className='notice'>
            <AtSign
                color={'#295fe7'}
                size={30}
            />
            <h2>Verify Your Email Address</h2>
            <p>
                Thanks for signing up to Proofdesk. We've emailed you a link to verify your email
                address.
            </p>
            <NavLink
                to={'/login'}
                className={'link'}
            >
                Login
            </NavLink>
        </div>
    )
}

export default CreateAccountSuccess
