import { trpc } from '@utils/trpc'

export const useCreateDocument = onSuccess => {
    const context = trpc.useContext()

    return trpc.documents.create.useMutation({
        onSuccess,
        onSettled: () => context.documents.invalidate(),
    })
}

export const useGetDocumentById = (id, onSuccess) =>
    trpc.documents.getById.useQuery({ documentId: id }, { enabled: !!id, onSuccess })

export const useGetDocumentsByEntityId = entityId =>
    trpc.documents.getByEntityId.useQuery({ entityId }, { enabled: !!entityId })

export const useExportDocumentVersionToPdf = onSuccess =>
    trpc.documents.exportVersionToPdf.useMutation({ onSuccess })

export const useGetDocumentVersionById = (id, onSuccess) =>
    trpc.documents.getVersionById.useQuery({ id }, { enabled: !!id, onSuccess })

export const useCreateDocumentVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.documents.createVersion.useMutation({
        onSuccess,
        onSettled: () => context.documents.invalidate(),
    })
}

export const useUpdateDocumentVersion = () => {
    const context = trpc.useContext()

    return trpc.documents.updateVersion.useMutation({
        onSuccess: () => context.documents.invalidate(),
    })
}

export const useSignDocumentVersion = () => {
    const context = trpc.useContext()

    return trpc.documents.signVersion.useMutation({
        onSettled: () => {
            context.entities.invalidate()
            context.documents.invalidate()
            context.relationships.invalidate()
            context.relationshipReviews.invalidate()
        },
    })
}

export const useRemoveDocumentVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.documents.removeVersion.useMutation({
        onSuccess,
        onSettled: () => context.documents.invalidate(),
    })
}

export const useRemoveDocument = onSuccess => {
    const context = trpc.useContext()

    return trpc.documents.remove.useMutation({
        onSuccess,
        onSettled: () => context.documents.invalidate(),
    })
}

export const useUploadFile = onSuccess => trpc.fileStorage.create.useMutation({ onSuccess })

export const useDownloadFile = fileName => trpc.fileStorage.get.useQuery({ fileName })
