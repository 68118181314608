import { includesAny } from '../../utils'
import { PubliclyListedStatuses } from '@prisma/client'
import classNames from 'classnames'
import { useWatch } from 'react-hook-form'

import Select from '@components/inputs/select/Select'

import pascalToReadable from '@utils/pascalToReadable'

import IdentityComponentInput from '../components/IdentityComponentInput'

import styles from '../Identity.module.scss'

const PublicListing = ({ entityType, register, control, readOnly }) => {
    const publiclyListed = useWatch({
        control,
        name: 'publiclyListed',
    })

    if (!includesAny(entityType, ['publiclyListed', 'publicListingDetails'])) return null

    return (
        <div
            className={classNames({
                [styles.identityComponents]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <div className={styles.heading}>
                <h2>Public Listing</h2>
                {/*<p>*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                {/*    incididunt.*/}
                {/*</p>*/}
            </div>

            <div className={styles.form}>
                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'publiclyListed'}
                        input={
                            <Select
                                name={'publiclyListed'}
                                options={Object.keys(PubliclyListedStatuses).map(key => ({
                                    value: key,
                                    label: pascalToReadable(key),
                                }))}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    {publiclyListed === PubliclyListedStatuses.Yes ? (
                        <div className={styles.row}>
                            <IdentityComponentInput
                                entityType={entityType}
                                name={'publicListingDetails'}
                                input={
                                    <textarea
                                        rows={8}
                                        {...register('publicListingDetails')}
                                        disabled={readOnly}
                                    />
                                }
                                readOnly={readOnly}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default PublicListing
