import { Helmet } from 'react-helmet'

import { useGetSelf } from '../hooks'

const AccountSettings = () => {
    const self = useGetSelf(() => {})

    if (!self.data) return null

    return (
        <>
            <Helmet>
                <title>Account Settings | Proofdesk</title>
            </Helmet>

            <div className='heading'>
                <div>
                    <h1>Account Settings</h1>
                </div>
            </div>

            <div className='preview'>
                <div>
                    <p>Full Name</p>
                    <p>
                        {self.data.firstName} {self.data.lastName}
                    </p>
                </div>
                <div>
                    <p>Email Address</p>
                    <p>{self.data.email}</p>
                </div>
            </div>
        </>
    )
}

export default AccountSettings
