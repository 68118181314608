import classNames from 'classnames'
import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './MenuItem.module.scss'

const MenuItem = ({
    label,
    to,
    disabled,
    alerts,
    icon,
}: {
    label?
    to?
    disabled?
    alerts?: number
    icon?
}) => {
    if (disabled) {
        return (
            <div className={classNames(styles.menuItem, styles.disabled)}>
                {icon}
                <p>{label}</p>
            </div>
        )
    } else {
        return (
            <NavLink
                to={to}
                className={({ isActive }) =>
                    isActive
                        ? `active ${styles.menuItem} ${
                              alerts && alerts > 0 ? styles.hasAlerts : null
                          }`
                        : `${styles.menuItem} ${alerts && alerts > 0 ? styles.hasAlerts : null}`
                }
            >
                {icon}
                <p>{label}</p>
                {alerts && alerts > 0 ? <div className={styles.alerts}>{alerts}</div> : null}
            </NavLink>
        )
    }
}

export default MenuItem
