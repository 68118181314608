import { includesAny } from '../../utils'
import { EntityTypes, LegalArrangementTypes, LegalPersonTypes } from '@prisma/client'
import { StatutoryProvisions } from '@proofdesk/common'
import classNames from 'classnames'
import { useWatch } from 'react-hook-form'

import { useGetCountries } from '@features/countries'

import DateInput from '@components/inputs/dateInput/DateInput'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'

import pascalToReadable from '@utils/pascalToReadable'

import IdentityComponentInput from '../components/IdentityComponentInput'

import styles from '../Identity.module.scss'

const Incorporation = ({ entityType, control, readOnly }) => {
    const jurisdiction = useWatch({
        control,
        name: 'jurisdiction',
    })

    const legalPersonType = useWatch({
        control,
        name: 'legalPersonType',
    })

    const countries = useGetCountries()
    if (!countries.data) return null

    if (
        !includesAny(entityType, [
            'jurisdiction',
            'incorporationDate',
            'registrationNumber',
            'statutoryProvision',
            'legalPersonType',
            'legalArrangementType',
        ])
    )
        return null

    const statutoryProvisions = legalPersonType
        ? StatutoryProvisions.IM[legalPersonType]?.map(statutoryProvision => ({
              value: statutoryProvision,
              label: statutoryProvision,
          }))
        : []

    return (
        <div
            className={classNames({
                [styles.identityComponents]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <div className={styles.heading}>
                <h2>Incorporation</h2>
                {/*<p>*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                {/*    incididunt.*/}
                {/*</p>*/}
            </div>

            <div className={styles.form}>
                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'jurisdiction'}
                        input={
                            <Select
                                name={'jurisdiction'}
                                isSearchable
                                options={countries.data.map(country => ({
                                    value: country.code,
                                    label: country.name,
                                }))}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'incorporationDate'}
                        input={
                            <DateInput
                                name={'incorporationDate'}
                                placeholder={''}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'registrationNumber'}
                        input={
                            <TextInput
                                name={'registrationNumber'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />
                </div>

                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'legalPersonType'}
                        input={
                            <Select
                                name={'legalPersonType'}
                                options={Object.keys(LegalPersonTypes).map(key => ({
                                    value: key,
                                    label: pascalToReadable(key),
                                }))}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'legalArrangementType'}
                        input={
                            <Select
                                name={'legalArrangementType'}
                                options={Object.keys(LegalArrangementTypes).map(key => ({
                                    value: key,
                                    label: pascalToReadable(key),
                                }))}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'statutoryProvision'}
                        input={
                            entityType === EntityTypes.LegalPerson &&
                            jurisdiction === 'IM' &&
                            statutoryProvisions.length ? (
                                <Select
                                    name={'statutoryProvision'}
                                    options={statutoryProvisions}
                                    readOnly={readOnly}
                                />
                            ) : (
                                <TextInput
                                    name={'statutoryProvision'}
                                    readOnly={readOnly}
                                />
                            )
                        }
                        readOnly={readOnly}
                    />

                    <span />
                </div>
            </div>
        </div>
    )
}

export default Incorporation
