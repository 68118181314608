import { Grants } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Grant from '@features/organisation/roles/components/Grant'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import TextInput from '@components/inputs/textInput/TextInput'

import pascalToReadable from '@utils/pascalToReadable'

import { useCreateRole } from '../hooks'

import styles from '@styles/Form.module.scss'

const CreateRole = () => {
    const navigate = useNavigate()

    const form = useForm({
        defaultValues: {
            name: '',
            grants: [],
        },
    })

    const {
        handleSubmit,
        formState: { errors },
    } = form

    const { mutate, isLoading, isError } = useCreateRole(() => navigate('/organisation/roles'))

    const submit = data => mutate(data)

    return (
        <Modal
            overlay={true}
            heading='Create Role'
            close={`/organisation/roles`}
        >
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(submit)}>
                    {isError && (
                        <p className={styles.error}>
                            There was an error submitting the form, please try again.
                        </p>
                    )}

                    <InputWrapper
                        label='Name'
                        input={
                            <TextInput
                                name={'name'}
                                required
                            />
                        }
                        errors={errors.name}
                    />

                    <div className={styles.grants}>
                        <h3>Entities</h3>

                        <Grant
                            grant={Grants.ViewEntities}
                            description={'View entities and their associated forms.'}
                        />

                        <Grant
                            grant={Grants.ManageEntity}
                            description={
                                'Create, edit and delete Entities, their Identities, Documents and Controlling Parties.'
                            }
                        />
                    </div>

                    <div className={styles.grants}>
                        <h3>Relationships</h3>

                        <Grant
                            grant={Grants.ViewRelationships}
                            description={'View Relationships and their associated forms.'}
                        />

                        <Grant
                            grant={Grants.ManageRelationship}
                            description={
                                'Establish a New Relationship and complete it’s Details and Customer Risk Assessment.'
                            }
                        />

                        <Grant
                            grant={Grants.SubmitRelationship}
                            description={'Submit a Relationship to for approval.'}
                        />

                        <Grant
                            grant={Grants.ApproveRelationship}
                            description={'Complete and sign a Relationship Approval.'}
                        />

                        <Grant
                            grant={Grants.CountersignRelationship}
                            description={'Countersign a Relationship Approval.'}
                        />

                        <Grant
                            grant={Grants.TerminateRelationship}
                            description={'Terminate a Relationship.'}
                        />
                    </div>

                    <div className={styles.grants}>
                        <h3>Policies</h3>

                        <Grant
                            grant={Grants.ViewPolicies}
                            description={'View policies and their full history.'}
                        />

                        <Grant
                            grant={Grants.ManagePolicy}
                            description={'Create and draft amendments to Policies.'}
                        />

                        <Grant
                            grant={Grants.ApprovePolicy}
                            description={'Approve a Policy.'}
                        />
                    </div>

                    <div className={styles.grants}>
                        <h3>Reports</h3>

                        <Grant
                            grant={Grants.GenerateReports}
                            description={'Generate and download Statistical Returns.'}
                        />
                    </div>

                    <div className={styles.grants}>
                        <h3>Disclosures and Enquiries</h3>

                        <Grant
                            grant={Grants.ManageDisclosures}
                            description={'View, create, edit and delete Disclosures.'}
                        />

                        <Grant
                            grant={Grants.ManageEnquiries}
                            description={'View, create, edit and delete ML/FT Enquiries.'}
                        />
                    </div>

                    <div className={styles.grants}>
                        <h3>Organisation</h3>

                        <Grant
                            grant={Grants.ManageConfiguration}
                            description={'Edit the Organisation Settings.'}
                        />

                        <Grant
                            grant={Grants.ManageTeam}
                            description={'Invite, update and remove Team Members.'}
                        />

                        <Grant
                            grant={Grants.ManageRoles}
                            description={'Create, Update and delete Roles.'}
                        />
                    </div>

                    <Button
                        label='Create Role'
                        fullWidth={true}
                        loading={isLoading}
                    />
                </form>
            </FormProvider>
        </Modal>
    )
}

export default CreateRole
