import Entity from '../layouts/Entity'
import { Grants } from '@prisma/client'
import { Navigate, Route } from 'react-router-dom'

import { IdentityPolicyContexts } from '@features/relationships/relationship/relationshipEntity'
import { useCheckGrants } from '@features/user'

import AddDocument from './views/AddDocument'
import Document from './views/Document'
import DocumentVersion from './views/DocumentVersion'
import ListDocuments from './views/ListDocuments'

export const DocumentsRoutes = ({
    isNestedInsideRelationship,
    identityPolicyContext,
    edd,
}: {
    isNestedInsideRelationship: boolean
    identityPolicyContext?: IdentityPolicyContexts
    edd?: boolean
}) => {
    const hasManageEntityGrant = useCheckGrants(Grants.ManageEntity)

    return (
        <>
            <Route
                path='documents'
                element={
                    !isNestedInsideRelationship ? (
                        <Entity>
                            <ListDocuments />
                        </Entity>
                    ) : (
                        <ListDocuments
                            edd={edd}
                            identityPolicyContext={identityPolicyContext}
                        />
                    )
                }
            >
                <Route
                    path='create'
                    element={hasManageEntityGrant ? <AddDocument /> : <Navigate to={`..`} />}
                />
            </Route>
            <Route
                path='documents/:documentId'
                element={<Document />}
            />
            <Route
                path='documents/:documentId/versions/:documentVersionId'
                element={
                    <DocumentVersion isNestedInsideRelationship={isNestedInsideRelationship} />
                }
            />
        </>
    )
}
