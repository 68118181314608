import { useNavigate, useParams } from 'react-router-dom'

import { useGetIdentityByEntityId } from '../hooks'

const Identity = () => {
    const { entityId } = useParams()
    const navigate = useNavigate()

    useGetIdentityByEntityId(entityId, data => {
        navigate(`versions/${data.latestVersion.id}`, {
            replace: true,
        })
    })

    return null
}

export default Identity
