import { useDownloadFile } from '@features/entities/entity/documents/hooks'

const FileStoreImage = ({ fileName, ...props }: any & { fileName: string | null | undefined }) => {
    const file = useDownloadFile(fileName)

    if (!file.data) return null

    console.log(file.data)

    if (file.data.mimeType === 'application/pdf')
        return <embed src={`data:application/pdf;base64,${file.data.base64String}#navpanes=0`} />

    return (
        <img
            src={`data:${file.data.mimeType};base64,${file.data.base64String}`}
            {...props}
        />
    )
}

export default FileStoreImage
