import { Outlet } from 'react-router-dom'

import styles from './Entry.module.scss'

const Entry = () => {
    return (
        <div className={styles.entry}>
            <div>
                <a href={'https://proofdesk.com'}>
                    <img
                        src={'/images/full-logo.svg'}
                        alt='logo'
                    />
                </a>

                <div className={styles.content}>
                    <Outlet />
                </div>

                <div className={styles.legal}>
                    <a
                        href={'https://legal-documents.proofdesk.com/'}
                        target={'_blank'}
                    >
                        Terms of Use
                    </a>
                    <p>•</p>
                    <a
                        href={'https://legal-documents.proofdesk.com/privacy-policy'}
                        target={'_blank'}
                    >
                        Privacy Policy
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Entry
