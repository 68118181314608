import { Grants } from '@prisma/client'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import { DisclosureRoutes } from './disclosures'
import { EnquiryRoutes } from './enquiries'
import { StatisticalReturnRoutes } from './statisticalReturns'

export const ReportsRoutes = () => {
    const hasStatisticalReturnsGrant = useCheckGrants(Grants.GenerateReports)
    const hasDisclosuresGrant = useCheckGrants(Grants.ManageDisclosures)
    const hasEnquiriesGrant = useCheckGrants(Grants.ManageEnquiries)

    return (
        <Routes>
            {hasStatisticalReturnsGrant ? StatisticalReturnRoutes() : null}
            {hasDisclosuresGrant ? DisclosureRoutes() : null}
            {hasEnquiriesGrant ? EnquiryRoutes() : null}
            <Route
                path='*'
                element={
                    <Navigate
                        to='statistical-returns'
                        replace
                    />
                }
            />
        </Routes>
    )
}
