import { Grants } from '@prisma/client'
import moment from 'moment'
import { useMemo } from 'react'
import { Plus } from 'react-feather'
import { Helmet } from 'react-helmet'
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom'

import { useGetEntity } from '@features/entities/entity/hooks'
import { useCheckGrants } from '@features/user'

import ControllingPartyTerm from '@components/controllingPartyTerm/ControllingPartyTerm'
import EntityLabel from '@components/entity/EntityLabel'
import Table from '@components/table/Table'

import pascalToReadable from '@utils/pascalToReadable'

import { useGetControllingPartiesPos2 } from '../hooks'

const ListControllingParties = () => {
    const hasManageEntityGrant = useCheckGrants(Grants.ManageEntity)
    const { entityId } = useParams()
    const controllingParties = useGetControllingPartiesPos2(entityId)
    const entity = useGetEntity({ entityId }, () => {})

    const navigate = useNavigate()
    const link = props => navigate(props.original.id)

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'entity1.name',
                Cell: props => (
                    <EntityLabel
                        id={props.row.original.entity1.id}
                        name={props.row.original.entity1.name}
                        entityType={props.row.original.entity1.entityType}
                        clickable
                    />
                ),
            },
            {
                Header: 'Position(s)',
                accessor: 'latestVersion.controllingPartyTypes',
                Cell: props => props.value.map(type => pascalToReadable(type)).join(', '),
            },
            {
                Header: 'Shareholding',
                accessor: 'latestVersion.shareholdingPercentage',
                Cell: props => (props.value > 0 ? `${(props.value * 100).toFixed(2)}%` : undefined),
            },
            {
                Header: 'Term',
                accessor: 'latestVersion.startDate',
                Cell: props => (
                    <ControllingPartyTerm
                        startDate={props.row.original.latestVersion?.startDate}
                        endDate={props.row.original.latestVersion?.endDate}
                    />
                ),
            },
            {
                Header: 'Updated On',
                accessor: 'updateDate',
                Cell: props => moment(props.value).format('MMMM DD, YYYY'),
            },
        ],
        []
    )

    return (
        <main>
            <Outlet />

            <Helmet>
                <title>
                    Controlling Parties {entity.data ? `| ${entity.data.name} ` : ''}| Proofdesk
                </title>
            </Helmet>

            <div className='heading'>
                <div>
                    <h1>Controlling Parties</h1>
                </div>

                {hasManageEntityGrant ? (
                    <NavLink
                        to={'create'}
                        className='link dark'
                    >
                        <Plus size={16} />
                        Controlling Party
                    </NavLink>
                ) : null}
            </div>

            {controllingParties.data?.length === 0 ? (
                <div className={'nothingToShow'}>
                    <p>You haven't connected any controlling parties yet.</p>
                    {hasManageEntityGrant ? (
                        <NavLink
                            to={'create'}
                            className='link dark'
                        >
                            <Plus size={16} />
                            Controlling Party
                        </NavLink>
                    ) : null}
                </div>
            ) : (
                <div className={'controllingParties'}>
                    <Table
                        button={null}
                        isLoading={controllingParties.isLoading}
                        columns={columns}
                        data={controllingParties.data ?? []}
                        defaultPageSize={50}
                        link={link}
                        queryError={controllingParties.isError || entity.isError}
                    />
                </div>
            )}
        </main>
    )
}

export default ListControllingParties
