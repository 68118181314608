import { Grants, PolicyStatuses } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import Document from '@components/document/Document'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import {
    useCreateRiskAssessmentPolicyVersion,
    useGetRiskAssessmentPolicyById,
    useGetRiskAssessmentPolicyVersionById,
    useRemoveRiskAssessmentPolicyVersion,
    useRevertVersionToDraft,
    useSetRiskAssessmentPolicyVersionPending,
    useSignRiskAssessmentPolicyVersion,
    useUpdateRiskAssessmentPolicyVersion,
} from '../hooks'

import RiskAssessmentPolicyForm from './RiskAssessmentPolicyForm'

const RiskAssessmentPolicyVersion = () => {
    const isAllowed = useCheckGrants(Grants.ManagePolicy) ?? false

    const navigate = useNavigate()
    const { riskAssessmentPolicyId, riskAssessmentPolicyVersionId } = useParams()
    const route = `/risk-assessment-policies/${riskAssessmentPolicyId}`

    const form = useForm({})
    const {
        reset,
        trigger,
        getValues,
        formState: { isDirty },
    } = form

    const riskAssessmentPolicy = useGetRiskAssessmentPolicyById(riskAssessmentPolicyId, () => {})
    const riskAssessmentPolicyVersion = useGetRiskAssessmentPolicyVersionById(
        riskAssessmentPolicyVersionId,
        riskAssessmentPolicyId,
        data => reset(data)
    )

    const createVersion = useCreateRiskAssessmentPolicyVersion(data =>
        navigate(`${route}/versions/${data.id}`)
    )
    const updateVersion = useUpdateRiskAssessmentPolicyVersion()
    const submitForApproval = useSetRiskAssessmentPolicyVersionPending()
    const revertToDraft = useRevertVersionToDraft()
    const signVersion = useSignRiskAssessmentPolicyVersion()
    const removeVersion = useRemoveRiskAssessmentPolicyVersion(() => navigate(route))

    const create = () =>
        createVersion.mutate({
            riskAssessmentPolicyId: riskAssessmentPolicyId as string,
        })

    const update = async data => updateVersion.mutateAsync(data)

    const setPending = async () => {
        try {
            const isValid = await trigger()
            if (!isValid) return null
            if (isDirty) await update(getValues())
            return submitForApproval.mutate({
                riskAssessmentPolicyVersionId: riskAssessmentPolicyVersionId as string,
            })
        } catch {
            return null
        }
    }

    const revert = async () =>
        revertToDraft.mutate({
            riskAssessmentPolicyVersionId: riskAssessmentPolicyVersionId as string,
        })

    const sign = async () =>
        signVersion.mutate({
            riskAssessmentPolicyVersionId: riskAssessmentPolicyVersionId as string,
        })

    const remove = () =>
        removeVersion.mutate({
            riskAssessmentPolicyVersionId: riskAssessmentPolicyVersionId as string,
        })

    if (riskAssessmentPolicy.isLoading || riskAssessmentPolicyVersion.isLoading) return <Loading />
    if (
        riskAssessmentPolicy.isError ||
        riskAssessmentPolicyVersion.isError ||
        !riskAssessmentPolicy.data ||
        !riskAssessmentPolicyVersion.data
    )
        return <QueryError />

    return (
        <FormProvider {...form}>
            <Document
                isAllowed={isAllowed}
                pageTitle={riskAssessmentPolicy.data.policyName}
                showHeader={true}
                landscape={true}
                type={riskAssessmentPolicyVersion.data.versionName}
                showBreadcrumbs={true}
                breadcrumbs={[
                    {
                        label: <NavLink to={'/policies'}>Policies</NavLink>,
                        link: '/policies',
                    },
                ]}
                title={riskAssessmentPolicy.data.policyName}
                versions={riskAssessmentPolicy.data.versions}
                isDraft={riskAssessmentPolicyVersion.data.status === PolicyStatuses.Draft}
                isPending={riskAssessmentPolicyVersion.data.status === PolicyStatuses.Pending}
                versionId={riskAssessmentPolicyVersion.data.id}
                versionName={riskAssessmentPolicyVersion.data.versionName}
                route={`${route}/versions`}
                Form={RiskAssessmentPolicyForm}
                create={{
                    function: create,
                    isLoading: createVersion.isLoading,
                }}
                update={{
                    function: update,
                    isLoading: updateVersion.isLoading,
                    isSuccess: updateVersion.isSuccess,
                }}
                setPending={{
                    function: setPending,
                    isLoading: submitForApproval.isLoading,
                }}
                sign={{
                    function: sign,
                    isLoading: signVersion.isLoading,
                }}
                remove={
                    riskAssessmentPolicy.data.versions.length > 1
                        ? {
                              function: remove,
                              isLoading: removeVersion.isLoading,
                          }
                        : undefined
                }
                history={riskAssessmentPolicy.data.versions.map(version => ({
                    label: `${version.versionName}`,
                    value: version.id,
                    isDraft: version.status === PolicyStatuses.Draft,
                    isPending: version.status === PolicyStatuses.Pending,
                }))}
                requiredSignatures={riskAssessmentPolicyVersion.data.requiredSignatures}
                revertToDraft={
                    riskAssessmentPolicyVersion.data.status === PolicyStatuses.Pending
                        ? {
                              function: revert,
                              isLoading: revertToDraft.isLoading,
                          }
                        : undefined
                }
                error={
                    createVersion.isError ||
                    updateVersion.isError ||
                    submitForApproval.isError ||
                    revertToDraft.isError ||
                    signVersion.isError ||
                    removeVersion.isError
                }
            />
        </FormProvider>
    )
}

export default RiskAssessmentPolicyVersion
