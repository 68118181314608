import { NavLink, useLocation } from 'react-router-dom'

import styles from './Tabs.module.scss'

const Tabs = ({
    tabs,
    more,
}: {
    tabs?: {
        label: string
        link: string
        icon?: JSX.Element
        count?: number
        alerts?: number
        divider?: boolean
        evaluateWithPath?: boolean
        end?: boolean
    }[]
    more?: JSX.Element
}) => {
    const location = useLocation()
    const path = location.pathname + location.search

    return (
        <div className={styles.tabs}>
            <div>
                {tabs?.map(tab => {
                    if (tab.divider) return <div className={styles.divider} />

                    return (
                        <NavLink
                            to={tab.link}
                            end={tab.end !== undefined ? tab.end : true}
                            className={({ isActive }) => {
                                return tab.evaluateWithPath
                                    ? path === tab.link
                                        ? styles.active
                                        : null
                                    : isActive
                                    ? styles.active
                                    : null
                            }}
                        >
                            {tab.icon}
                            {tab.label}
                            {tab.count && tab.count > 0 ? (
                                <div className={styles.count}>{tab.count}</div>
                            ) : null}
                            {tab.alerts && tab.alerts > 0 ? (
                                <div className={styles.alerts}>{tab.alerts}</div>
                            ) : null}
                        </NavLink>
                    )
                })}
                {more}
            </div>
        </div>
    )
}

export default Tabs
