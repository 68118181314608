import { EnquiringAgencies, EnquiryNatures } from '@prisma/client'
import { useFormContext } from 'react-hook-form'

import { useGetEntities } from '@features/entities'

import InputWrapper from '@components/inputWrapper/InputWrapper'
import EntityMultiSelect from '@components/inputs/entityMultiSelect/EntityMultiSelect'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import pascalToReadable from '@utils/pascalToReadable'

const EnquiryForm = ({ readOnly = false }) => {
    const {
        register,
        formState: { errors },
    } = useFormContext()

    const entities = useGetEntities()

    if (entities.isLoading) return <Loading />
    if (entities.isError || !entities.data) return <QueryError isInsideModal={true} />

    return (
        <>
            <TextInput
                hidden
                name='id'
            />

            <InputWrapper
                label={'Internal Reference'}
                input={
                    <TextInput
                        name={'internalReference'}
                        required
                        readOnly={readOnly}
                    />
                }
                readOnly={readOnly}
                errors={errors.internalReference}
            />

            <InputWrapper
                label={'Entities'}
                input={
                    <EntityMultiSelect
                        name={'entityIds'}
                        entities={entities.data}
                        readOnly={readOnly}
                    />
                }
                errors={errors.entityIds}
                readOnly={readOnly}
            />

            <InputWrapper
                label={'Enquiring Agency'}
                input={
                    <Select
                        name={'enquiringAgency'}
                        options={Object.keys(EnquiringAgencies).map(enquiringAgency => ({
                            label: pascalToReadable(enquiringAgency),
                            value: enquiringAgency,
                        }))}
                        readOnly={readOnly}
                        required
                    />
                }
                readOnly={readOnly}
                errors={errors.enquiringAgency}
            />

            <InputWrapper
                label={'Enquiring Person'}
                input={
                    <TextInput
                        name={'enquiringPerson'}
                        readOnly={readOnly}
                    />
                }
                readOnly={readOnly}
                errors={errors.enquiringPerson}
            />

            <InputWrapper
                label={'Nature of Enquiry'}
                input={
                    <Select
                        name={'nature'}
                        options={Object.keys(EnquiryNatures).map(nature => ({
                            label: pascalToReadable(nature),
                            value: nature,
                        }))}
                        readOnly={readOnly}
                        required
                    />
                }
                readOnly={readOnly}
                errors={errors.nature}
            />

            <InputWrapper
                label={'Powers Exercised'}
                input={
                    <textarea
                        {...register('powers')}
                        rows={5}
                        disabled={readOnly}
                    />
                }
                readOnly={readOnly}
                errors={errors.powers}
            />

            <InputWrapper
                label={'Details'}
                input={
                    <textarea
                        {...register('details')}
                        rows={5}
                        disabled={readOnly}
                    />
                }
                readOnly={readOnly}
                errors={errors.details}
            />
        </>
    )
}

export default EnquiryForm
