import { Navigate, Route, Routes } from 'react-router-dom'

import ForgotPassword from './views/ForgotPassword'
import ForgotPasswordSuccess from './views/ForgotPasswordSuccess'
import ResetPassword from './views/ResetPassword'
import ResetPasswordSuccess from './views/ResetPasswordSuccess'

export const ForgotPasswordRoutes = () => {
    return (
        <Routes>
            <Route
                path=''
                element={<ForgotPassword />}
            />
            <Route
                path='success'
                element={<ForgotPasswordSuccess />}
            />
            <Route
                path='reset/success'
                element={<ResetPasswordSuccess />}
            />
            <Route
                path='reset/:tokenString'
                element={<ResetPassword />}
            />
            <Route
                path='*'
                element={
                    <Navigate
                        to='.'
                        replace
                    />
                }
            />
        </Routes>
    )
}
