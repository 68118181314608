import { Helmet } from 'react-helmet'
import { FormProvider, useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

import Button from '@components/button/Button'
import form from '@components/form/Form'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import PasswordInput from '@components/inputs/passwordInput/PasswordInput'

import { useGetPasswordResetToken, useResetPassword } from '../hooks'

import styles from '@styles/Form.module.scss'

const ResetPassword = () => {
    const navigate = useNavigate()
    const { tokenString } = useParams()

    if (!tokenString) navigate('/login')

    const token = useGetPasswordResetToken(
        tokenString,
        data => {
            if (data.isSpent) navigate('/login')
        },
        () => navigate('/login')
    )

    const form = useForm()
    const {
        handleSubmit,
        formState: { errors },
    } = form

    const { mutate, isLoading, isError } = useResetPassword(() =>
        navigate('/forgot-password/reset/success')
    )

    const onSubmit = data =>
        mutate({ newPassword: data.newPassword, passwordResetTokenString: tokenString as string })

    if (!token.data) return null

    return (
        <>
            <Helmet>
                <title>Reset Password | Proofdesk</title>
            </Helmet>

            <FormProvider {...form}>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <h2>Reset Your Password</h2>

                    {isError && (
                        <p className={styles.error}>
                            There was an error submitting the form, please try again.
                        </p>
                    )}

                    <InputWrapper
                        label={'New Password'}
                        input={
                            <PasswordInput
                                name={'newPassword'}
                                required
                                disabled={isLoading}
                            />
                        }
                        errors={errors.newPassword}
                    />

                    <InputWrapper
                        label={'Confirm Password'}
                        input={
                            <PasswordInput
                                name={'confirmPassword'}
                                required
                                disabled={isLoading}
                            />
                        }
                        errors={errors.confirmPassword}
                    />

                    <Button
                        label={'Reset Password'}
                        loading={isLoading}
                        fullWidth={true}
                    />

                    <div className={styles.links}>
                        <NavLink to={'/login'}>Back to Login</NavLink>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}

export default ResetPassword
