import { useFormContext } from 'react-hook-form'

import readOnly from '@components/document/ReadOnly'

const TextInput = ({
    inline = false,
    readOnly = false,
    name,
    hidden = false,
    required = false,
    defaultValue = '',
    ...props
}) => {
    const { register, getValues } = useFormContext()

    let registerOptions: any = {
        required,
    }

    if (defaultValue) registerOptions.value = defaultValue

    if (readOnly)
        return (
            <div className={'input blank'}>
                <p>{getValues(name)}</p>
            </div>
        )

    return (
        <input
            {...props}
            {...register(name, registerOptions)}
            type={hidden ? 'hidden' : 'text'}
            disabled={props.readOnly}
            className={inline ? 'inline' : ''}
        />
    )
}

export default TextInput
