import { Grants } from '@prisma/client'

import { useGetOrganisation } from '@features/organisation'
import { useCheckGrants } from '@features/user'

import Tabs from '@components/tabs/Tabs'

const Organisation = ({ children }) => {
    const organisation = useGetOrganisation(() => {}, {})

    const hasTeamGrant = useCheckGrants(Grants.ManageTeam)
    const hasRolesGrant = useCheckGrants(Grants.ManageRoles)

    if (!organisation.data) return null

    let tabs = [
        {
            label: 'Organisation Settings',
            link: '/organisation/settings',
        },
    ]

    if (organisation.data.isDemo || organisation.data.billingPlan) {
        if (hasTeamGrant)
            tabs.push({
                label: 'Team Members',
                link: '/organisation/team-members',
            })

        if (hasRolesGrant)
            tabs.push({
                label: 'Roles',
                link: '/organisation/roles',
            })
    }

    return (
        <>
            <Tabs tabs={tabs} />

            <main>{children}</main>
        </>
    )
}

export default Organisation
