import { trpc } from '@utils/trpc'

export const useGetSelf = onSuccess =>
    trpc.users.getSelf.useQuery(undefined, {
        onSuccess,
    })
export const useGetSelfInvitations = () =>
    trpc.inviteTokens.getSelfUserInvitedOrganisations.useQuery()

export const useAcceptInvitation = onSuccess => {
    const context = trpc.useContext()

    return trpc.inviteTokens.accept.useMutation({
        onSuccess,
        onSettled: async () => {
            await context.inviteTokens.invalidate()
            await context.organisations.invalidate()
        },
    })
}

export const useUpdateSelf = onSuccess => {
    const context = trpc.useContext()

    return trpc.users.updateSelf.useMutation({
        onSuccess,
        onSettled: async () => {
            await context.users.invalidate()
        },
    })
}
