import classNames from 'classnames'
import { AlertCircle, Check, Edit3, Lock, UserCheck } from 'react-feather'
import { useFormContext } from 'react-hook-form'

import Button from '@components/button/Button'

import { Action } from './Document'

import styles from './Document.module.scss'

const EditableActions = ({
    isAllowed,
    update,
    sign,
    setPending,
    remove,
    error,
}: {
    isAllowed: boolean
    update: Action
    sign?: Action
    setPending?: Action
    remove?: Action
    error?: boolean
}) => {
    const {
        formState: { errors, dirtyFields },
    } = useFormContext()

    const isDirty = Object.keys(dirtyFields).length > 0

    if (!isAllowed)
        return (
            <div className={styles.editableActions}>
                <div
                    className={classNames({
                        [styles.error]: true,
                        [styles.notAllowed]: true,
                    })}
                >
                    <Lock size={15} />
                    <p>You do not have the necessary permissions to edit this draft.</p>
                </div>
            </div>
        )

    return (
        <div className={styles.editableActions}>
            <div className={styles.actions}>
                {remove ? (
                    <Button
                        label={'Discard Draft'}
                        outline={true}
                        onClick={remove.function}
                        loading={remove.isLoading}
                        type={'button'}
                    />
                ) : (
                    <span />
                )}

                <div>
                    {update.isSuccess || isDirty ? (
                        <Button
                            icon={!isDirty ? <Check size={16} /> : null}
                            label={!isDirty ? 'Saved' : 'Save Draft'}
                            type={'submit'}
                            form={'form'}
                            disabled={!isDirty}
                            outline={true}
                            loading={update.isLoading}
                        />
                    ) : null}

                    {setPending ? (
                        <Button
                            icon={<UserCheck size={16} />}
                            label={'Submit for Approval'}
                            onClick={setPending.function}
                            loading={setPending.isLoading}
                            type={'button'}
                        />
                    ) : (
                        <Button
                            icon={<Edit3 size={16} />}
                            label={'Sign Document'}
                            onClick={sign?.function}
                            loading={sign?.isLoading}
                            type={'button'}
                        />
                    )}
                </div>
            </div>
            {error || Object.keys(errors).length ? (
                <div className={styles.error}>
                    <AlertCircle size={15} />
                    {error ? (
                        <p>An error occurred, please try again.</p>
                    ) : (
                        <p>
                            Validation failed for {Object.keys(errors).length} field
                            {Object.keys(errors).length > 1 ? 's' : ''} highlighted above.
                        </p>
                    )}
                </div>
            ) : null}
        </div>
    )
}

export default EditableActions
