import { Outlet } from 'react-router-dom'

import MenuBar from '@layouts/main/components/menubar/MenuBar'

import styles from './Main.module.scss'

const Main = ({ disabledNav }) => {
    return (
        <div className={styles.main}>
            <MenuBar disabledNav={disabledNav} />
            <div>
                <Outlet />
            </div>
        </div>
    )
}

export default Main
