import { Plus } from 'react-feather'

import styles from './CircularButton.module.scss'

const CircularButton = ({ icon, onClick }) => {
    return (
        <button
            onClick={onClick}
            type={'button'}
            className={styles.circularButton}
        >
            {icon}
        </button>
    )
}

export default CircularButton
