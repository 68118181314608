import { FormStatuses } from '@prisma/client'
import { useParams } from 'react-router-dom'

import styles from '@features/relationships/relationship/components/sidebar/checklist/Checklist.module.scss'
import Group from '@features/relationships/relationship/components/sidebar/checklist/group/Group'
import Header from '@features/relationships/relationship/components/sidebar/checklist/header/Header'
import Stage from '@features/relationships/relationship/components/sidebar/checklist/stage/Stage'
import { useGetById } from '@features/relationships/relationship/termination/hooks'

import Loading from '@components/loading/Loading'

const TerminationChecklist = () => {
    const { relationshipId, terminationId } = useParams()

    const termination = useGetById(terminationId)

    if (!termination.data) return <Loading />

    return (
        <div className={styles.review}>
            <Header />
            <div className={styles.body}>
                <Group name={'Termination'} />

                <Stage
                    checked={termination.data.latestVersion?.status === FormStatuses.Signed}
                    name={'Termination'}
                    entities={null}
                    unavailable={false}
                    customer={false}
                    link={`/relationships/${relationshipId}/termination/${terminationId}/versions/${termination.data.latestVersion?.id}`}
                    riskLevelId={null}
                />
            </div>
        </div>
    )
}

export default TerminationChecklist
