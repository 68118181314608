import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Loading from '@components/loading/Loading'

import { env } from '@utils/env'

import { useVerifyEmail } from '../hooks'

const VerifyEmailAddress = () => {
    const navigate = useNavigate()
    const [searchParams, _] = useSearchParams()

    if (!searchParams.get('emailVerifyTokenString')) navigate('/')

    useEffect(() => {
        mutate({
            emailVerifyTokenString: searchParams.get('emailVerifyTokenString') as string,
        })
    }, [])

    const { mutate } = useVerifyEmail(data => {
        Cookies.set('tokenString', data, {
            domain: env.REACT_APP_DOMAIN as string,
        })
        window.location.reload()
    })

    return (
        <>
            <Helmet>
                <title>Verify your Email Address | Proofdesk</title>
            </Helmet>
            <Loading />
        </>
    )
}

export default VerifyEmailAddress
