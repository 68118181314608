import classNames from 'classnames'
import fuzzy from 'fuzzy'
import { useController, useFormContext } from 'react-hook-form'
import ReactSelect from 'react-select'

import TextInput from '@components/inputs/textInput/TextInput'

const Select = ({
    name,
    placeholder = 'Select...',
    options,
    required = false,
    fuzzySearch = false,
    isSearchable = false,
    isClearable = false,
    readOnly = false,
    noOptionsMessage = 'No options',
    blankText = '',
    inline = false,
}) => {
    const { control, getValues } = useFormContext()
    const controller = useController({
        name,
        control,
        rules: { required },
    })

    if (readOnly && !getValues(name))
        return (
            <div className={'input blank'}>
                <p>{blankText}</p>
            </div>
        )

    return (
        <ReactSelect
            name={name}
            options={options}
            isClearable={isClearable}
            className={classNames({
                select: true,
                disabled: readOnly,
                inline: inline,
            })}
            isSearchable={isSearchable}
            value={options.find(({ value }) => value === controller.field.value)}
            placeholder={placeholder}
            onChange={option => controller.field.onChange(option?.value)}
            filterOption={(candidate, input) => {
                if (input) {
                    if (fuzzySearch) return fuzzy.test(input, candidate.label)
                    return candidate.label.toLowerCase().startsWith(input.toLowerCase())
                }
                return true
            }}
            isDisabled={readOnly}
            noOptionsMessage={() => noOptionsMessage}
        />
    )
}

export default Select
