import { Route, Routes } from 'react-router-dom'

import RiskLevelPolicy from './views/RiskLevelPolicy'
import RiskLevelPolicyVersion from './views/RiskLevelPolicyVersion'

export const RiskLevelPoliciesRoutes = () => {
    return (
        <Routes>
            <Route
                path={':riskLevelPolicyId'}
                element={<RiskLevelPolicy />}
            />
            <Route
                path={':riskLevelPolicyId/versions/:riskLevelPolicyVersionId'}
                element={<RiskLevelPolicyVersion />}
            />
        </Routes>
    )
}
