import { trpc } from '@utils/trpc'

export const useGetCustomerRiskAssessmentVersion = (customerRiskAssessmentVersionId, onSuccess) =>
    trpc.customerRiskAssessments.getVersion.useQuery(
        { customerRiskAssessmentVersionId },
        { onSuccess }
    )

export const useExportVersionToPdf = onSuccess =>
    trpc.customerRiskAssessments.exportVersionToPdf.useMutation({ onSuccess })

export const useUpdateCustomerRiskAssessmentVersion = () => {
    const context = trpc.useContext()

    return trpc.customerRiskAssessments.updateVersion.useMutation({
        onSuccess: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.customerRiskAssessments.invalidate()
        },
    })
}

export const useInitialiseCustomerRiskAssessmentVersion = () => {
    const context = trpc.useContext()

    return trpc.customerRiskAssessments.initialiseTemplate.useMutation({
        onSettled: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.customerRiskAssessments.invalidate()
        },
    })
}

export const useCreateCustomerRiskAssessmentVersionSignature = () => {
    const context = trpc.useContext()

    return trpc.customerRiskAssessments.createVersionSignature.useMutation({
        onSettled: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.customerRiskAssessments.invalidate()
        },
    })
}

export const useRevertCustomerRiskAssessmentVersionToDraft = () => {
    const context = trpc.useContext()

    return trpc.customerRiskAssessments.revertVersionToDraft.useMutation({
        onSettled: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.customerRiskAssessments.invalidate()
        },
    })
}
