import MenuItem from '../menuItem/MenuItem'
import { Grants } from '@prisma/client'
import { AlertTriangle, BarChart2, FileText, Link, Users } from 'react-feather'

import { useCheckGrants } from '@features/user'

import styles from './Menu.module.scss'

const Menu = ({ disabled }) => {
    const hasStatisticalReturnsGrant = useCheckGrants(Grants.GenerateReports)
    const hasDisclosuresGrant = useCheckGrants(Grants.ManageDisclosures)
    const hasEnquiriesGrant = useCheckGrants(Grants.ManageEnquiries)

    return (
        <div className={styles.menu}>
            <MenuItem
                label={'Dashboard'}
                to={'/dashboard'}
                disabled={disabled}
                icon={<BarChart2 size={16} />}
            />

            <MenuItem
                label={'Relationships'}
                to={'/relationships'}
                disabled={disabled}
                icon={<Link size={16} />}
            />

            {hasStatisticalReturnsGrant || hasDisclosuresGrant || hasEnquiriesGrant ? (
                <MenuItem
                    label={'Reporting'}
                    to={'/reporting'}
                    disabled={disabled}
                    icon={<AlertTriangle size={16} />}
                />
            ) : null}

            <MenuItem
                label={'Policies'}
                to={'/policies'}
                disabled={disabled}
                icon={<FileText size={16} />}
            />

            <MenuItem
                label={'Entities'}
                to={'/entities'}
                disabled={disabled}
                icon={<Users size={16} />}
            />
        </div>
    )
}

export default Menu
