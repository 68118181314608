import { useNavigate, useParams } from 'react-router-dom'

import { useGetInvitation } from '../hooks'

const AcceptInvitation = () => {
    const navigate = useNavigate()
    const { tokenString } = useParams()

    if (!tokenString) navigate('/')

    const invitation = useGetInvitation(tokenString, () => {})
    if (!invitation.data) navigate('/')

    if (invitation.data?.userExists) navigate(`/login?invitation=${tokenString}`)
    if (!invitation.data?.userExists) navigate(`/create-account?invitation=${tokenString}`)
    return null
}

export default AcceptInvitation
