import { Outlet, useParams } from 'react-router-dom'

import { useGetEntity } from '@features/entities/entity/hooks'
import { IdentityPolicyContexts } from '@features/relationships/relationship/relationshipEntity'
import Header from '@features/relationships/relationship/relationshipEntity/components/header/Header'

import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import styles from './RelationshipEntity.module.scss'

const RelationshipEntity = ({
    identityPolicyContext,
    edd,
}: {
    identityPolicyContext: IdentityPolicyContexts
    edd: boolean
}) => {
    const { entityId } = useParams()

    const entity = useGetEntity({ entityId, identityPolicyContext, edd }, () => {})

    if (entity.isLoading) return <Loading />
    if (entity.isError || !entity.data)
        return (
            <div className={styles.relationshipEntity}>
                <QueryError />
            </div>
        )

    return (
        <div className={styles.relationshipEntity}>
            <Header
                edd={edd}
                identityPolicyContext={identityPolicyContext}
            />

            <Outlet />
        </div>
    )
}

export default RelationshipEntity
