import { NavLink, useParams } from 'react-router-dom'

import { useGetEntity } from '@features/entities/entity/hooks'
import { IdentityPolicyContexts } from '@features/relationships/relationship/relationshipEntity'

import EntityTypeIndicator from '@components/entity/EntityTypeIndicator'

import styles from './Header.module.scss'

const Header = ({
    identityPolicyContext,
    edd,
}: {
    identityPolicyContext: IdentityPolicyContexts
    edd: boolean
}) => {
    const { entityId } = useParams()

    const entity = useGetEntity({ entityId, identityPolicyContext, edd }, () => {})

    // This component's immediate parent also loads entity so no need for loading wheel or query error
    if (!entity.data) return null

    return (
        <div className={styles.header}>
            <NavLink
                to={`/entities/${entity.data.id}`}
                className={styles.label}
                target={'_blank'}
            >
                <EntityTypeIndicator
                    entityType={entity.data.entityType}
                    size={22}
                />
                <h3>{entity.data.name}</h3>
            </NavLink>

            <div className={styles.nav}>
                <NavLink
                    to={`identity`}
                    className={({ isActive }) => (isActive ? styles.active : null)}
                >
                    Identity
                    {entity.data.identityCompletion?.unidentifiedFields?.length &&
                    entity.data.identityCompletion?.unidentifiedFields?.length > 0 ? (
                        <div className={styles.alert}>
                            <p>{entity.data.identityCompletion.unidentifiedFields.length}</p>
                        </div>
                    ) : null}
                </NavLink>
                <NavLink
                    to={`documents`}
                    className={({ isActive }) => (isActive ? styles.active : null)}
                >
                    Documents
                    {entity.data.identityCompletion?.unverifiedFields?.length &&
                    entity.data.identityCompletion?.unverifiedFields?.length > 0 ? (
                        <div className={styles.alert}>
                            <p>{entity.data.identityCompletion.unverifiedFields.length}</p>
                        </div>
                    ) : null}
                </NavLink>
                <NavLink
                    to={`controlling-parties`}
                    className={({ isActive }) => (isActive ? styles.active : null)}
                >
                    Controlling Parties
                </NavLink>
            </div>
        </div>
    )
}

export default Header
