import { trpc } from '@utils/trpc'

export const useCreateEntity = onSuccess => {
    const context = trpc.useContext()

    return trpc.entities.create.useMutation({
        onSuccess,
        onSettled: () => context.entities.getAll.invalidate(),
    })
}

export const useCreateEntitiesAndControllingParties = onSuccess => {
    const context = trpc.useContext()

    return trpc.controllingParties.createManyByEntityNetwork.useMutation({
        onSuccess,
        onSettled: () => context.entities.getAll.invalidate(),
    })
}
