import { useNavigate, useParams } from 'react-router-dom'

import { useGetRiskAssessmentPolicyById } from '../hooks'

const RiskAssessmentPolicy = () => {
    const { riskAssessmentPolicyId } = useParams()
    const navigate = useNavigate()

    useGetRiskAssessmentPolicyById(riskAssessmentPolicyId, data => {
        navigate(
            `/risk-assessment-policies/${riskAssessmentPolicyId}/versions/${data.latestVersion.id}`,
            { replace: true }
        )
    })

    return null
}

export default RiskAssessmentPolicy
