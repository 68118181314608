import moment from 'moment/moment'
import { useMemo } from 'react'
import { Plus } from 'react-feather'
import { Helmet } from 'react-helmet'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'

import Table from '@components/table/Table'

import PendingInvitations from '../components/PendingInvitations'

import { useGetSelfUserMemberships } from '../hooks'
import UserLabel from '@components/user/UserLabel'

const ListTeamMembers = () => {
    const navigate = useNavigate()
    const link = props => navigate(`/organisation/team-members/${props.original.id}`)

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'user.firstName',
                Cell: props => <UserLabel user={props.row.original.user} />,
            },
            {
                Header: 'Email Address',
                accessor: 'user.email',
            },
            {
                Header: 'Role',
                accessor: 'userRole.name',
            },
            {
                Header: 'Last Active',
                accessor: 'lastActive',
                Cell: props => moment(props.value).fromNow(),
            },
        ],
        []
    )

    const members = useGetSelfUserMemberships()

    if (!members.data) return null

    return (
        <>
            <Outlet />

            <Helmet>
                <title>Team Members | Proofdesk</title>
            </Helmet>

            <div className='heading'>
                <div>
                    <h1>Team Members</h1>
                </div>

                <NavLink
                    to={'invite'}
                    className='link dark'
                >
                    <Plus size={16} />
                    Invite Team Member
                </NavLink>
            </div>

            <Table
                data={members.data}
                isLoading={members.isLoading}
                defaultPageSize={50}
                columns={columns}
                button={null}
                link={link}
            />

            <PendingInvitations />
        </>
    )
}

export default ListTeamMembers
