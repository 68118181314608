import { FormStatuses } from '@prisma/client'
import { useNavigate } from 'react-router-dom'
import Select, { components } from 'react-select'

import styles from './Document.module.scss'

const VersionSelector = ({ versionId, route, history }) => {
    const navigate = useNavigate()

    return (
        <Select
            className={'select'}
            value={history.find(version => version.value === versionId)}
            options={history}
            onChange={version => navigate(`${route}/${version?.value}`)}
            components={{
                Option: props => {
                    const version = history.find(version => version.value === props.data.value)

                    return (
                        <components.Option {...props}>
                            <div className={styles.versionOption}>
                                {version.isDraft ? (
                                    <span className={styles.draft}>Draft</span>
                                ) : null}
                                {version.isPending ? (
                                    <span className={styles.draft}>Pending</span>
                                ) : null}
                                {version.label}
                            </div>
                        </components.Option>
                    )
                },
                SingleValue: props => {
                    const version = history.find(
                        version => version.value === props.getValue()[0]?.value
                    )

                    return (
                        <components.SingleValue {...props}>
                            <div className={styles.versionOption}>
                                {version.isDraft ? (
                                    <span className={styles.draft}>Draft</span>
                                ) : null}
                                {version.isPending ? (
                                    <span className={styles.draft}>Pending</span>
                                ) : null}
                                {version.label}
                            </div>
                        </components.SingleValue>
                    )
                },
            }}
        />
    )
}

export default VersionSelector
