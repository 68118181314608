import { Navigate, Route, Routes } from 'react-router-dom'

import DeleteEntity from '@features/entities/entity/views/DeleteEntity'

import { EntityRelationRoutes } from './controllingParties'
import { DocumentsRoutes } from './documents'
import { IdentityRoutes } from './identity'

export const EntityRoutes = () => {
    return (
        <Routes>
            {IdentityRoutes({ isNestedInsideRelationship: false })}
            {EntityRelationRoutes({ isNestedInsideRelationship: false })}
            {DocumentsRoutes({ isNestedInsideRelationship: false })}
            <Route
                path={'delete'}
                element={<DeleteEntity />}
            />
            <Route
                path='*'
                element={
                    <Navigate
                        to='identity'
                        replace
                    />
                }
            />
        </Routes>
    )
}
