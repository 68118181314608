import { includesAny } from '../../utils'
import classNames from 'classnames'

import IdentityComponentInput from '../components/IdentityComponentInput'

import styles from '../Identity.module.scss'

const OwnershipAndControl = ({ entityType, register, readOnly }) => {
    if (!includesAny(entityType, ['descriptionOfOwnershipStructure'])) return null

    return (
        <div
            className={classNames({
                [styles.identityComponents]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <div className={styles.heading}>
                <h2>Ownership & Control</h2>
                {/*<p>*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                {/*    incididunt.*/}
                {/*</p>*/}
            </div>

            <div className={styles.form}>
                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'descriptionOfOwnershipStructure'}
                        input={
                            <textarea
                                rows={5}
                                {...register('descriptionOfOwnershipStructure')}
                                disabled={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />
                </div>
            </div>
        </div>
    )
}

export default OwnershipAndControl
