import { AtSign } from 'react-feather'
import { NavLink } from 'react-router-dom'

const ForgotPasswordSuccess = () => {
    return (
        <div className='notice'>
            <AtSign
                color={'#295fe7'}
                size={40}
            />
            <h2>Check Your Emails</h2>
            <p>
                We've sent a password reset link to the email address you provided if it is
                associated with a Proofdesk account.
            </p>
            <NavLink
                to={'/login'}
                className={'link'}
            >
                Login
            </NavLink>
        </div>
    )
}

export default ForgotPasswordSuccess
