import { Helmet } from 'react-helmet'
import { FormProvider, useForm } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'

import { useRequestPasswordReset } from '../hooks'

import styles from '@styles/Form.module.scss'
import EmailInput from '@components/inputs/emailInput/EmailInput'
import form from '@components/form/Form'

const ForgotPassword = () => {
    const navigate = useNavigate()

    const form = useForm()
    const {
        handleSubmit,
        formState: { errors },
    } = form

    const { mutate, isLoading, isError } = useRequestPasswordReset(() =>
        navigate('/forgot-password/success')
    )

    const onSubmit = data => mutate(data)

    return (
        <>
            <Helmet>
                <title>Forgot Password | Proofdesk</title>
            </Helmet>

            <FormProvider {...form}>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <h2>Forgot Your Password?</h2>

                    {isError && (
                        <p className={styles.error}>
                            There was an error submitting the form, please try again.
                        </p>
                    )}

                    <InputWrapper
                        label={'Email Address'}
                        input={
                            <EmailInput
                                name={'email'}
                                required
                                disabled={isLoading}
                            />
                        }
                        errors={errors.email}
                    />

                    <Button
                        label={'Send Email'}
                        loading={isLoading}
                        fullWidth={true}
                    />

                    <div className={styles.links}>
                        <NavLink to={'/login'}>Back to Login</NavLink>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}

export default ForgotPassword
