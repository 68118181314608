import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import EntityTypeIndicator from '@components/entity/EntityTypeIndicator'

import styles from './Entity.module.scss'

const EntityLabel = ({ id, entityType, name, clickable = false }) => {
    const label = (
        <div
            className={classNames({
                [styles.entityLabel]: true,
                [styles.clickable]: clickable,
            })}
        >
            <EntityTypeIndicator
                entityType={entityType}
                size={20}
            />
            <span>{name}</span>
        </div>
    )

    if (clickable)
        return (
            <div style={{ width: 'fit-content' }}>
                <NavLink
                    to={`/entities/${id}`}
                    onClick={e => e.stopPropagation()}
                    style={{ marginBottom: 0 }}
                >
                    {label}
                </NavLink>
            </div>
        )

    return label
}

export default EntityLabel
