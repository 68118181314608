import { trpc } from '@utils/trpc'

export const useGetIdentityByEntityId = (entityId, onSuccess) =>
    trpc.identities.getByEntityId.useQuery({ entityId }, { enabled: !!entityId, onSuccess })

export const useGetIdentityVersionById = (id, onSuccess) =>
    trpc.identities.getVersionById.useQuery({ id }, { enabled: !!id, onSuccess })

export const useCreateIdentityVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.identities.createVersion.useMutation({
        onSuccess,
        onSettled: () => context.identities.invalidate(),
    })
}

export const useUpdateIdentityVersion = () => {
    const context = trpc.useContext()

    return trpc.identities.updateVersion.useMutation({
        onSuccess: () => context.identities.invalidate(),
    })
}

export const useSignIdentityVersion = () => {
    const context = trpc.useContext()

    return trpc.identities.signVersion.useMutation({
        onSettled: () => {
            context.entities.invalidate()
            context.identities.invalidate()
            context.relationships.invalidate()
            context.relationshipReviews.invalidate()
        },
    })
}

export const useRemoveIdentityVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.identities.removeVersion.useMutation({
        onSuccess,
        onSettled: () => context.identities.invalidate(),
    })
}

export const useExportIdentityVersionToPdf = onSuccess =>
    trpc.identities.exportVersionToPdf.useMutation({ onSuccess })
