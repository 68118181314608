import { includesAny } from '../../utils'
import classNames from 'classnames'

import AddressInput from '@components/inputs/addressInput/AddressInput'

import IdentityComponentInput from '../components/IdentityComponentInput'

import styles from '../Identity.module.scss'

const Addresses = ({ entityType, readOnly }) => {
    if (
        !includesAny(entityType, [
            'registeredOfficeAddress',
            'residentialAddress',
            'principalPlaceOfBusiness',
        ])
    )
        return null

    return (
        <div
            className={classNames({
                [styles.identityComponents]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <div className={styles.heading}>
                <h2>Addresses</h2>
                {/*<p>*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                {/*    incididunt.*/}
                {/*</p>*/}
            </div>

            <div className={styles.form}>
                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'registeredOfficeAddress'}
                        input={
                            <AddressInput
                                name={'registeredOfficeAddress'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'residentialAddress'}
                        input={
                            <AddressInput
                                name={'residentialAddress'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'principalPlaceOfBusiness'}
                        input={
                            <AddressInput
                                name={'principalPlaceOfBusiness'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />
                </div>
            </div>
        </div>
    )
}

export default Addresses
