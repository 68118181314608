import styles from './DashboardSubheading.module.scss'

const DashboardSubheading = ({ label, quantity }: { label: string; quantity: number }) => {
    return (
        <div className={styles.dashboardSubheading}>
            <h2>{label}</h2>
            <p>{quantity}</p>
        </div>
    )
}

export default DashboardSubheading
