import _ from 'lodash'
import { NavLink } from 'react-router-dom'

import styles from './EntityRelationshipsPreview.module.scss'

const EntityRelationshipsPreview = ({
    relationships,
}: {
    relationships: { id: string; internalReference: string }[]
}) => {
    const latestRelationship = _.maxBy(relationships, 'createDate')
    const additionalRelationships = relationships.length - 1

    if (!latestRelationship) return <p>-</p>

    return (
        <div className={styles.entityRelationshipsPreview}>
            <NavLink
                to={`/relationships/${latestRelationship.id}`}
                onClick={e => e.stopPropagation()}
            >
                {latestRelationship.internalReference}
            </NavLink>
            {additionalRelationships ? (
                <p>
                    and {additionalRelationships} other{additionalRelationships > 1 ? 's' : ''}
                </p>
            ) : null}
        </div>
    )
}

export default EntityRelationshipsPreview
