import { Outlet, useParams } from 'react-router-dom'

import Sidebar from '@features/relationships/relationship/components/sidebar/Sidebar'
import { useGetRelationship } from '@features/relationships/relationship/hooks'

import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import styles from './Relationship.module.scss'

const Relationship = ({
    sidebarContent,
    children,
}: {
    sidebarContent: JSX.Element
    children?: React.ReactNode
}) => {
    const { relationshipId } = useParams()

    const relationship = useGetRelationship(relationshipId, () => {})

    if (relationship.isLoading) return <Loading />
    if (relationship.isError) return <QueryError />

    return (
        <div className={styles.relationship}>
            <Sidebar content={sidebarContent} />
            <Outlet />
            {children}
        </div>
    )
}

export default Relationship
