import { NavLink } from 'react-router-dom'

import Modal from '@layouts/modal/Modal'

import styles from '../Policies.module.scss'

const CreatePolicies = () => {
    return (
        <Modal
            overlay={true}
            heading='Create Policy'
            close={'/policies'}
        >
            <div className={styles.createPolicySelector}>
                <NavLink to={'/custom-policies/create'}>
                    <h3>Custom Policy</h3>
                    <p>Start a blank policy document.</p>
                </NavLink>
                <NavLink to={'/risk-assessment-policies/create'}>
                    <h3>Risk Assessment Policy</h3>
                    <p>Create a new risk assessment template.</p>
                </NavLink>
            </div>
        </Modal>
    )
}

export default CreatePolicies
