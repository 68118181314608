import { useController, useFormContext } from 'react-hook-form'
import Select, { components } from 'react-select'

import EntityLabel from '@components/entity/EntityLabel'

const EntityMultiSelect = ({
    name,
    entities,
    isDisabled = false,
    required = false,
    readOnly = false,
}) => {
    const { control, getValues } = useFormContext()
    const controller = useController({
        name,
        control,
        rules: { required },
    })

    let options: { label: string; value: string }[] = []
    if (entities)
        options = entities.map(entity => {
            return {
                label: entity.name,
                value: entity.id,
            }
        })

    if (readOnly && getValues(name)?.length === 0) return <div className={'input blank'} />

    if (readOnly)
        return (
            <div className={'input'}>
                {
                    <p>
                        {getValues(name)?.map((value, index) => {
                            value = options.find(option => option.value === value)?.label
                            if (index > 0) return `, ${value}`
                            return value
                        })}
                    </p>
                }
            </div>
        )

    return (
        <Select
            className={'select'}
            components={{
                Option: props => {
                    const entity = entities.find(entity => entity.id === props.data.value)

                    return (
                        <components.Option {...props}>
                            <EntityLabel id={entity.id} name={entity.name} entityType={entity.entityType} />
                        </components.Option>
                    )
                },
                MultiValueLabel: props => {
                    const entity = entities.find(entity => entity.id === props.data.value)

                    return (
                        <components.MultiValueLabel {...props}>
                            <EntityLabel id={entity.id} name={entity.name} entityType={entity.entityType} />
                        </components.MultiValueLabel>
                    )
                },
            }}
            onChange={options => controller.field.onChange(options.map(option => option.value))}
            isMulti={true}
            value={options?.filter(option => controller.field.value?.includes(option.value))}
            options={options}
            isDisabled={isDisabled}
        />
    )
}

export default EntityMultiSelect
