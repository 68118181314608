import classNames from 'classnames'
import { Plus, X } from 'react-feather'
import { useFieldArray, useFormContext } from 'react-hook-form'

import TextInput from '@components/inputs/textInput/TextInput'

import styles from './MultiInput.module.scss'

const MultiInput = ({ name, readOnly }: { name: string; readOnly?: Boolean }) => {
    const { control, getValues } = useFormContext()
    const { fields, append, remove } = useFieldArray({
        control,
        name,
    })

    if (readOnly && fields.length === 0) return <div className={'input blank'} />

    if (readOnly)
        return (
            <div className={'input'}>
                {
                    <p>
                        {fields.map((field, index) => {
                            const value = getValues(`${name}[${index}]`)
                            if (index > 0) return `, ${value}`
                            return value
                        })}
                    </p>
                }
            </div>
        )

    return (
        <div className={styles.multiInput}>
            {fields.map((field, index) => {
                return (
                    <div className={styles.input}>
                        <TextInput
                            key={field.id}
                            name={`${name}[${index}]`}
                            type={'text'}
                            required
                        />

                        <button onClick={remove.bind(index)}>
                            <X size={16} />
                        </button>
                    </div>
                )
            })}

            <button
                className={classNames(styles.add, { [styles.tall]: !fields.length })}
                onClick={e => {
                    e.preventDefault()
                    append('')
                }}
            >
                <Plus size={16} />
            </button>
        </div>
    )
}

export default MultiInput
