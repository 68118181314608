import { useNavigate, useParams } from 'react-router-dom'

import { useGetRelationship } from '@features/relationships/relationship/hooks'

import Loading from '@components/loading/Loading'

const RelationshipRedirect = () => {
    const { relationshipId } = useParams()
    const navigate = useNavigate()

    useGetRelationship(relationshipId, data => {
        if (data.termination) {
            navigate(
                `/relationships/${relationshipId}/termination/${data.termination.id}/versions/${data.termination.versions[0]?.id}`
            )
        } else {
            navigate(
                `/relationships/${relationshipId}/reviews/${data.latestReview.id}/relationship-details-version/${data.latestReview.relationshipDetailsVersion.id}`,
                {
                    replace: true,
                }
            )
        }
    })

    return <Loading />
}

export default RelationshipRedirect
