import classNames from 'classnames'
import React from 'react'
import { Check } from 'react-feather'
import { useController, useFormContext } from 'react-hook-form'

import { useGetRiskLevels } from '@features/riskLevels'

import RiskLevelTag from '@components/tag/RiskLevelTag'

import styles from './Options.module.scss'

const Options = ({ index, options, disabled }) => {
    const { control } = useFormContext()
    const controller = useController({
        name: `responses.${index}.optionId`,
        control,
    })

    const { data: riskLevels } = useGetRiskLevels()
    if (!riskLevels) return null

    return (
        <div className={classNames(styles.options, { [styles.disabled]: disabled })}>
            {options.map(option => (
                <div
                    className={classNames(styles.option, {
                        [styles.selected]:
                            controller.field.value === option.riskAssessmentTemplateOptionId,
                        [styles.notSelected]:
                            controller.field.value &&
                            controller.field.value !== option.riskAssessmentTemplateOptionId,
                    })}
                    onClick={() =>
                        !disabled &&
                        controller.field.onChange(option.riskAssessmentTemplateOptionId)
                    }
                >
                    <div className={styles.left}>
                        <div className={styles.checkbox}>
                            <Check size={14} />
                        </div>
                        <p>{option.title}</p>
                    </div>
                    <RiskLevelTag riskLevelId={option.riskLevelId} />
                </div>
            ))}
        </div>
    )
}

export default Options
