import classNames from 'classnames'
import { ArrowDown, ArrowUp } from 'react-feather'

import styles from './AbsoluteChangeIndicator.module.scss'

const AbsoluteChangeIndicator = ({
    change,
    large,
    color,
}: {
    change: number
    large: boolean
    color: string | null
}) => {
    const borderColor = color ? color : '#E1E4EB'
    const textColor = color ? color : '#838588'

    return (
        <div
            className={classNames({
                [styles.absoluteChangeIndicator]: true,
                [styles.large]: large,
            })}
            style={{ border: `1px solid ${borderColor}` }}
        >
            {change === 0 ? (
                <p style={{ color: textColor }}>=</p>
            ) : change > 0 ? (
                <>
                    <ArrowUp
                        color={textColor}
                        size={large ? 14 : 10}
                    />
                    <p style={{ color: textColor }}>{change}</p>
                </>
            ) : (
                <>
                    <ArrowDown
                        color={textColor}
                        size={large ? 14 : 10}
                    />
                    <p style={{ color: textColor }}>{Math.abs(change)}</p>
                </>
            )}
        </div>
    )
}

export default AbsoluteChangeIndicator
