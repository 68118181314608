import { FormStatuses, Grants } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { useGetRelationshipReview } from '@features/relationships/relationship/hooks'
import { useCheckGrants } from '@features/user'

import Document from '@components/document/Document'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import {
    useCreateRelationshipDetailsVersionSignature,
    useExportVersionToPdf,
    useGetRelationshipDetailsVersion,
    useRevertRelationshipDetailsVersionToDraft,
    useUpdateRelationshipDetailsVersion,
} from '../hooks'

import RelationshipDetailsForm from './RelationshipDetailsForm'

const RelationshipDetailsVersion = () => {
    const isAllowed = useCheckGrants(Grants.ManageRelationship) ?? false
    const { relationshipId, relationshipReviewId, relationshipDetailsVersionId } = useParams()

    const form = useForm()
    const {
        reset,
        trigger,
        getValues,
        formState: { isDirty },
    } = form

    const relationshipReview = useGetRelationshipReview(
        relationshipId,
        relationshipReviewId,
        () => {}
    )

    const relationshipDetailsVersion = useGetRelationshipDetailsVersion(
        relationshipDetailsVersionId,
        data => reset(data)
    )

    const exportVersion = useExportVersionToPdf(data => window.open(data, '_blank'))
    const updateVersion = useUpdateRelationshipDetailsVersion()
    const signVersion = useCreateRelationshipDetailsVersionSignature()
    const revertToDraft = useRevertRelationshipDetailsVersionToDraft()

    const exportPdf = () =>
        exportVersion.mutate({
            relationshipDetailsVersionId: relationshipDetailsVersionId as string,
        })

    const update = async data => updateVersion.mutateAsync(data)

    const sign = async () => {
        try {
            const isValid = await trigger()
            if (!isValid) return null
            if (isDirty) await update(getValues())
            return signVersion.mutate({
                relationshipDetailsVersionId: relationshipDetailsVersionId as string,
            })
        } catch {
            return null
        }
    }

    const revert = async () =>
        revertToDraft.mutate({
            relationshipDetailsVersionId: relationshipDetailsVersionId as string,
        })

    if (relationshipReview.isLoading || relationshipDetailsVersion.isLoading) return <Loading />
    if (
        relationshipReview.isError ||
        relationshipDetailsVersion.isError ||
        !relationshipReview.data ||
        !relationshipDetailsVersion.data
    )
        return <QueryError />

    return (
        <FormProvider {...form}>
            <Document
                isAllowed={isAllowed}
                pageTitle={`Relationship Details | ${relationshipReview.data.relationship.internalReference}`}
                showHeader={false}
                landscape={false}
                type={'Relationship Details'}
                showBreadcrumbs={true}
                breadcrumbs={[
                    {
                        label: relationshipReview.data.relationship.internalReference,
                        link: `/relationships/${relationshipReview.data.relationship.id}`,
                    },
                    {
                        label: relationshipReview.data.versionName,
                        link: `/relationships/${relationshipReview.data.relationship.id}/reviews/${relationshipReview.data.id}`,
                    },
                ]}
                title={'Relationship Details'}
                isDraft={relationshipDetailsVersion.data.status === FormStatuses.Draft}
                isPending={false}
                versionId={relationshipDetailsVersion.data.id}
                versionName={`Version ${relationshipDetailsVersion.data.version}`}
                Form={RelationshipDetailsForm}
                exportPdf={{
                    function: exportPdf,
                    isLoading: exportVersion.isLoading,
                }}
                update={{
                    function: update,
                    isLoading: updateVersion.isLoading,
                    isSuccess: updateVersion.isSuccess,
                }}
                sign={{
                    function: sign,
                    isLoading: signVersion.isLoading,
                }}
                revertToDraft={
                    relationshipReview.data.status === 'Profiling' ||
                    relationshipReview.data.status === 'Remediation'
                        ? {
                              function: revert,
                              isLoading: revertToDraft.isLoading,
                          }
                        : undefined
                }
                signature={{
                    user: relationshipDetailsVersion.data.signature?.user as {
                        firstName: string
                        lastName: string
                    },
                    date: relationshipDetailsVersion.data.signature?.createDate as Date,
                }}
                error={updateVersion.isError || signVersion.isError || revertToDraft.isError}
            />
        </FormProvider>
    )
}

export default RelationshipDetailsVersion
