import { trpc } from '@utils/trpc'

export const useGetIdentityPolicyById = (identityPolicyId, onSuccess) =>
    trpc.identityPolicies.getById.useQuery(
        { identityPolicyId },
        { enabled: !!identityPolicyId, onSuccess }
    )

export const useGetIdentityPolicyVersionById = (identityPolicyVersionId, onSuccess) =>
    trpc.identityPolicies.getVersionById.useQuery(
        { identityPolicyVersionId },
        { enabled: !!identityPolicyVersionId, onSuccess }
    )

export const useCreateIdentityPolicyVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.identityPolicies.createVersion.useMutation({
        onSuccess,
        onSettled: () => context.identityPolicies.invalidate(),
    })
}

export const useUpdateIdentityPolicyVersion = () => {
    const context = trpc.useContext()

    return trpc.identityPolicies.updateVersion.useMutation({
        onSuccess: () => context.identityPolicies.invalidate(),
    })
}

export const useSetIdentityPolicyVersionPending = () => {
    const context = trpc.useContext()

    return trpc.identityPolicies.setVersionPending.useMutation({
        onSettled: () => context.identityPolicies.invalidate(),
    })
}

export const useRevertVersionToDraft = () => {
    const context = trpc.useContext()

    return trpc.identityPolicies.revertVersionToDraft.useMutation({
        onSettled: () => context.identityPolicies.invalidate(),
    })
}

export const useSignIdentityPolicyVersion = () => {
    const context = trpc.useContext()

    return trpc.identityPolicies.createVersionSignature.useMutation({
        onSettled: () => context.identityPolicies.invalidate(),
    })
}

export const useRemoveIdentityPolicyVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.identityPolicies.deleteVersion.useMutation({
        onSuccess,
        onSettled: () => context.identityPolicies.invalidate(),
    })
}
