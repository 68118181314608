import moment from 'moment'
import { useMemo } from 'react'
import { Plus, X } from 'react-feather'

import Table from '@components/table/Table'

import { useGetInvitedTeamMembers, useRevokeInvite } from '../hooks'
import { NavLink } from 'react-router-dom'

const PendingInvitations = () => {
    const columns = useMemo(
        () => [
            {
                Header: 'Email Address',
                accessor: 'email',
            },
            {
                Header: 'Created',
                accessor: 'createDate',
                Cell: props => moment(props.value).fromNow(),
            },
            {
                Header: 'Expires',
                accessor: 'expires',
                Cell: props => moment(props.value).fromNow(),
            },
            {
                Header: 'Role',
                accessor: 'userRole.name',
            },
            {
                Header: 'Cancel Invitation',
                accessor: 'id',
                Cell: props => (
                    <button
                        onClick={() => mutate({ email: props.row.original.email })}
                        className='link red'
                    >
                        <X size={15} />
                        Cancel
                    </button>
                ),
            },
        ],
        []
    )

    const invitedMembers = useGetInvitedTeamMembers()

    const { mutate } = useRevokeInvite()

    return (
        <>
            <div className='heading'>
                <div>
                    <h1>Pending Invitations</h1>
                </div>
            </div>
            {!invitedMembers.data?.length ? (
                <div className={'nothingToShow'}>
                    <p>No pending invites.</p>
                    <NavLink
                        to={'invite'}
                        className='link dark'
                    >
                        <Plus size={16} />
                        Invite Team Member
                    </NavLink>
                </div>
            ) : (
                <Table
                    data={invitedMembers.data}
                    isLoading={invitedMembers.isLoading}
                    defaultPageSize={50}
                    columns={columns}
                    button={null}
                />
            )}
        </>
    )
}

export default PendingInvitations
