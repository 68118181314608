import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useGetSelfUserMemberships } from '@features/organisation/teamMembers'
import {
    useGetRelationship,
    useUpdateRelationship,
} from '@features/relationships/relationship/hooks'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import TextInput from '@components/inputs/textInput/TextInput'
import UserSelect from '@components/inputs/userSelect/UserSelect'
import Loading from '@components/loading/Loading'
import UserMultiSelect from '@components/userMultiSelect/UserMultiSelect'

import styles from '@styles/Form.module.scss'

const Edit = () => {
    const { relationshipId } = useParams()
    const navigate = useNavigate()
    const form = useForm()

    const {
        handleSubmit,
        formState: { errors },
        reset,
    } = form

    const relationship = useGetRelationship(relationshipId, data =>
        reset({
            ...data,
            subscribedUserIds: data.subscribedUsers.map(user => user.id),
        })
    )
    const users = useGetSelfUserMemberships()

    const { mutate, isLoading } = useUpdateRelationship(data =>
        navigate(`/relationships/${data.id}`, { replace: true })
    )

    const submit = data => mutate(data)

    if (relationship.isLoading || users.isLoading)
        return (
            <Modal overlay={true}>
                <Loading />
            </Modal>
        )

    return (
        <Modal
            overlay={true}
            heading={'Edit Relationship'}
            close={`/relationships/${relationshipId}`}
            error={relationship.isError || users.isError}
        >
            <FormProvider {...form}>
                <form
                    onSubmit={handleSubmit(submit)}
                    className={styles.form}
                >
                    <TextInput
                        hidden={true}
                        name={'relationshipId'}
                        defaultValue={relationshipId}
                    />

                    <InputWrapper
                        label={'Reference'}
                        input={
                            <TextInput
                                name={'internalReference'}
                                required={true}
                            />
                        }
                        errors={errors.internalReference}
                    />

                    <InputWrapper
                        label={'Owner'}
                        input={
                            <UserSelect
                                users={users.data?.map(user => user.user)}
                                name={'ownerUserId'}
                                required={true}
                            />
                        }
                        errors={errors.ownerUserId}
                    />

                    <InputWrapper
                        label={'Subscribers'}
                        input={
                            <UserMultiSelect
                                users={users.data?.map(user => user.user)}
                                name={'subscribedUserIds'}
                                required={true}
                                minLength={1}
                            />
                        }
                        errors={errors.subscribedUserIds}
                    />

                    <div className={styles.actions}>
                        <Button
                            label='Cancel'
                            onClick={() => navigate(`/relationships/${relationshipId}`)}
                            outline={true}
                            disabled={isLoading}
                            type={'button'}
                        />

                        <Button
                            label='Save Changes'
                            disabled={isLoading}
                            loading={isLoading}
                        />
                    </div>
                </form>
            </FormProvider>
        </Modal>
    )
}

export default Edit
