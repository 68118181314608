import classNames from 'classnames'

import styles from './InputWrapper.module.scss'

const InputWrapper = ({
    label,
    description,
    icon,
    input,
    errors,
    readOnly,
}: {
    label?
    description?
    icon?
    input?
    errors?
    readOnly?
}) => {
    return (
        <div
            className={classNames({
                [styles.input]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            {label && (
                <div className={styles.label}>
                    <p>{label}</p>
                    {description && <p className={styles.description}>{description}</p>}
                </div>
            )}
            {icon ? (
                <div className={styles.withIcon}>
                    {icon}
                    {input}
                </div>
            ) : (
                input
            )}
            {errors ? (
                errors.type === 'required' ? (
                    <p className={styles.errorMessage}>This is a required field.</p>
                ) : (
                    <p className={styles.errorMessage}>{errors?.message}</p>
                )
            ) : null}
        </div>
    )
}

export default InputWrapper
