import { useFieldArray, useFormContext } from 'react-hook-form'

import RequirementsTable from '@features/identityPolicies/components/RequirementsTable'

const Requirements = ({ name, readOnly }) => {
    const { control } = useFormContext()

    const requirements = useFieldArray({
        name,
        control,
    })

    return (
        <div>
            {requirements.fields.map((requirement, index) => (
                <RequirementsTable
                    key={requirement.id}
                    name={`${name}.${index}`}
                    readOnly={readOnly}
                />
            ))}
        </div>
    )
}

export default Requirements
