import moment from 'moment'
import { useFormContext } from 'react-hook-form'

import { useGetCountries } from '@features/countries'

import DateInput from '@components/inputs/dateInput/DateInput'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'

import styles from './AddressInput.module.scss'

const AddressInput = ({ name, key = '', readOnly = false }) => {
    const { getValues } = useFormContext()

    const countries = useGetCountries()

    if (!countries.data) return null

    if (readOnly) {
        const address = getValues(name)

        if (!address) return <div className={'input blank'} />

        let addressString = ''
        if (address.line1) addressString += `${address.line1}, `
        if (address.line2) addressString += `${address.line2}, `
        if (address.city) addressString += `${address.city}, `
        if (address.country)
            addressString += `${
                countries.data.find(country => country.code === address.country)?.name
            }, `
        if (address.postcode) addressString += `${address.postcode}, `
        addressString = addressString.slice(0, -2)

        return (
            <div className={'input blank'}>
                <p>{addressString}</p>
            </div>
        )
    }

    return (
        <div
            className={styles.addressInput}
            key={key}
        >
            <TextInput
                placeholder='Line One'
                name={`${name}.line1`}
            />

            <TextInput
                placeholder='Line Two'
                name={`${name}.line2`}
            />

            <TextInput
                placeholder='City'
                name={`${name}.city`}
            />

            <Select
                name={`${name}.country`}
                isSearchable
                placeholder='Country'
                options={countries.data.map(country => ({
                    label: country.name,
                    value: country.code,
                }))}
            />

            <TextInput
                placeholder='Post Code'
                name={`${name}.postcode`}
            />
        </div>
    )
}

export default AddressInput
