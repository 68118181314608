import { includesAny } from '../../utils'
import { Genders } from '@prisma/client'
import classNames from 'classnames'

import { useGetCountries } from '@features/countries'

import DateInput from '@components/inputs/dateInput/DateInput'
import MultiSelect from '@components/inputs/multiSelect/MultiSelect'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'

import pascalToReadable from '@utils/pascalToReadable'

import IdentityComponentInput from '../components/IdentityComponentInput'

import styles from '../Identity.module.scss'

const PersonalDetails = ({ entityType, readOnly }) => {
    const countries = useGetCountries()
    if (!countries.data) return null

    if (
        !includesAny(entityType, [
            'dateOfBirth',
            'gender',
            'personalIdentificationNumber',
            'nationalities',
            'placeOfBirth',
            'occupation',
            'employer',
        ])
    )
        return null

    return (
        <div
            className={classNames({
                [styles.identityComponents]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <div className={styles.heading}>
                <h2>Personal Details</h2>
                {/*<p>*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                {/*    incididunt.*/}
                {/*</p>*/}
            </div>

            <div className={styles.form}>
                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'dateOfBirth'}
                        input={
                            <DateInput
                                name={'dateOfBirth'}
                                placeholder={''}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'gender'}
                        input={
                            <Select
                                name={'gender'}
                                options={Object.keys(Genders).map(key => ({
                                    value: key,
                                    label: pascalToReadable(key),
                                }))}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'personalIdentificationNumber'}
                        input={
                            <TextInput
                                name={'personalIdentificationNumber'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />
                </div>

                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'nationalities'}
                        input={
                            <MultiSelect
                                name={'nationalities'}
                                isSearchable
                                options={countries.data.map(country => ({
                                    value: country.code,
                                    label: country.name,
                                }))}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'placeOfBirth'}
                        input={
                            <Select
                                name={'placeOfBirth'}
                                isSearchable
                                options={countries.data.map(country => ({
                                    value: country.code,
                                    label: country.name,
                                }))}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'occupation'}
                        input={
                            <TextInput
                                name={'occupation'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />
                </div>
                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'employer'}
                        input={
                            <TextInput
                                name={'employer'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />
                    <span />
                    <span />
                </div>
            </div>
        </div>
    )
}

export default PersonalDetails
