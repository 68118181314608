import classNames from 'classnames'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { usePagination, useTable } from 'react-table'

import QueryError from '@components/queryError/QueryError'

import styles from './Table.module.scss'

const Table = ({
    isLoading,
    button,
    columns,
    data,
    defaultPageSize,
    heading = '',
    link = () => {},
    queryError,
}: {
    isLoading
    button
    columns
    data
    defaultPageSize
    heading?
    link?
    queryError?: boolean
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize },
    } = useTable(
        { columns, data, initialState: { pageIndex: 0, pageSize: defaultPageSize } },
        // useSortBy,
        usePagination
    )

    if (isLoading)
        return (
            <div className={styles.loading}>
                <img
                    src='/images/loading-black.svg'
                    alt={'Loading...'}
                />
            </div>
        )

    if (queryError)
        return (
            <div className={styles.container}>
                <QueryError isInsideModal={true} />
            </div>
        )

    return (
        <div className={styles.container}>
            {heading ? (
                <div className={styles.heading}>
                    <h2>{heading}</h2>
                    {button}
                </div>
            ) : null}
            <table
                {...getTableProps()}
                className={classNames(styles.table, {
                    [styles.withHeading]: heading,
                    [styles.withPagination]: canNextPage || canPreviousPage,
                })}
            >
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    <div>{column.render('Header')}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.length === 0 ? (
                        <tr>
                            <td>-</td>
                        </tr>
                    ) : (
                        page.map(row => {
                            prepareRow(row)
                            if (row.original.groupHeading)
                                return (
                                    <tr>
                                        <td className={styles.groupHeading}>{row.original.name}</td>
                                    </tr>
                                )

                            return (
                                <tr
                                    {...row.getRowProps({
                                        onClick: () => link(row),
                                        className: link != null ? styles.clickable : null,
                                    })}
                                >
                                    {row.cells.map(cell => {
                                        let content = cell.render('Cell')
                                        if (cell.value === undefined) content = ''
                                        if (cell.value === null || cell.value?.length < 1)
                                            content = '-'
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                className={classNames({
                                                    [styles.greyedOut]: cell.value === undefined,
                                                })}
                                            >
                                                {content}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })
                    )}
                </tbody>
            </table>

            {canNextPage || canPreviousPage ? (
                <div className={styles.pagination}>
                    <p>
                        Showing results{' '}
                        {pageIndex * pageSize + 1 >= data.length
                            ? data.length
                            : pageIndex * pageSize + 1}
                        -
                        {pageIndex * pageSize + pageSize >= data.length
                            ? data.length
                            : pageIndex * pageSize + pageSize}{' '}
                        of {data.length}
                    </p>

                    <div>
                        <button
                            className='button-covert'
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            <ArrowLeft size={18} />
                        </button>
                        <p>
                            Page {pageIndex + 1} of {pageOptions.length}
                        </p>
                        <button
                            className='button-covert'
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        >
                            <ArrowRight size={18} />
                        </button>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default Table
