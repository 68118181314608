import classNames from 'classnames'

import styles from './Tag.module.scss'

export enum TagThemes {
    'Blue',
    'Grey',
    'Red',
    'Orange',
    'Black',
}

const Tag = ({
    label,
    theme,
    outline,
    themeOverrideColour,
}: {
    label: string | JSX.Element
    theme: TagThemes
    outline: boolean
    themeOverrideColour?: string
}) => {
    return (
        <p
            className={classNames({
                [styles.tag]: true,
                [styles.outline]: outline,
                [styles.blue]: theme === TagThemes.Blue,
                [styles.grey]: theme === TagThemes.Grey,
                [styles.red]: theme === TagThemes.Red,
                [styles.orange]: theme === TagThemes.Orange,
                [styles.black]: theme === TagThemes.Black,
            })}
            style={{
                backgroundColor: themeOverrideColour,
                borderColor: themeOverrideColour,
                color: themeOverrideColour ? '#ffffff' : undefined,
            }}
        >
            {label}
        </p>
    )
}

export default Tag
