import { includesAny } from '../../utils'
import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'

import MultiInput from '@components/inputs/multiInput/MultiInput'
import TextInput from '@components/inputs/textInput/TextInput'

import IdentityComponentInput from '../components/IdentityComponentInput'

import styles from '../Identity.module.scss'

const Names = ({ entityType, readOnly }) => {
    const {
        formState: { errors },
    } = useFormContext()

    if (
        !includesAny(entityType, [
            'title',
            'name',
            'previousNames',
            'otherNamesUsed',
            'tradingNames',
        ])
    )
        return null

    return (
        <div
            className={classNames({
                [styles.identityComponents]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <div className={styles.heading}>
                <h2>Names</h2>
                {/*<p>Lorem ipsum dolor sit amet adipiscing elit, sed do eiusmod tempor incididunt.</p>*/}
            </div>

            <div className={styles.form}>
                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'title'}
                        input={
                            <TextInput
                                name={'title'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'name'}
                        input={
                            <TextInput
                                name={'name'}
                                readOnly={readOnly}
                                required={true}
                            />
                        }
                        readOnly={readOnly}
                        errors={errors.name}
                    />
                </div>

                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'previousNames'}
                        input={
                            <MultiInput
                                name={'previousNames'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'otherNamesUsed'}
                        input={
                            <MultiInput
                                name={'otherNamesUsed'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    <IdentityComponentInput
                        entityType={entityType}
                        name={'tradingNames'}
                        input={
                            <MultiInput
                                name={'tradingNames'}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />
                </div>
            </div>
        </div>
    )
}

export default Names
