import moment from 'moment'
import { useMemo } from 'react'
import { Plus } from 'react-feather'
import { Helmet } from 'react-helmet'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'

import Table from '@components/table/Table'

import { useGetRoles } from '../hooks'

const ListRoles = () => {
    const navigate = useNavigate()
    const link = props => navigate(`/organisation/roles/${props.original.id}`)

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Grants',
                accessor: 'grants',
                Cell: props => `${props.value.length} Grants`,
            },
            {
                Header: 'Last Updated',
                accessor: 'updateDate',
                Cell: props => moment(props.value).fromNow(),
            },
        ],
        []
    )

    const roles = useGetRoles()

    if (!roles.data) return null

    return (
        <>
            <Outlet />

            <Helmet>
                <title>User Roles | Proofdesk</title>
            </Helmet>

            <div className='heading'>
                <div>
                    <h1>Roles</h1>
                </div>

                <NavLink
                    to={'create'}
                    className='link dark'
                >
                    <Plus size={16} />
                    Create Role
                </NavLink>
            </div>

            <Table
                data={roles.data}
                isLoading={roles.isLoading}
                defaultPageSize={50}
                columns={columns}
                button={null}
                link={link}
            />
        </>
    )
}

export default ListRoles
