import { includesAny } from '../../utils'
import {
    AdverseMediaStatuses,
    MeetingTypes,
    PepStatuses,
    PepTypes,
    SanctionsStatuses,
} from '@prisma/client'
import classNames from 'classnames'
import { useWatch } from 'react-hook-form'

import Select from '@components/inputs/select/Select'

import pascalToReadable from '@utils/pascalToReadable'

import IdentityComponentInput from '../components/IdentityComponentInput'

import styles from '../Identity.module.scss'

const Screening = ({ entityType, register, control, readOnly }) => {
    const pepStatus = useWatch({
        control,
        name: 'pepStatus',
    })

    const sanctionsStatus = useWatch({
        control,
        name: 'sanctionsStatus',
    })

    const adverseMediaStatus = useWatch({
        control,
        name: 'adverseMediaStatus',
    })

    if (
        !includesAny(entityType, [
            'faceToFace',
            'pepStatus',
            'pepType',
            'pepDetails',
            'sanctionsStatus',
            'sanctionsDetails',
            'adverseMediaStatus',
            'adverseMediaDetails',
        ])
    )
        return null

    return (
        <div
            className={classNames({
                [styles.identityComponents]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <div className={styles.heading}>
                <h2>Screening</h2>
                {/*<p>*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                {/*    incididunt.*/}
                {/*</p>*/}
            </div>

            <div className={styles.form}>
                {includesAny(entityType, ['faceToFace', 'pepStatus', 'pepType', 'pepDetails']) ? (
                    <>
                        <div className={styles.row}>
                            <IdentityComponentInput
                                entityType={entityType}
                                name={'faceToFace'}
                                input={
                                    <Select
                                        name={'faceToFace'}
                                        isClearable
                                        options={Object.keys(MeetingTypes).map(key => ({
                                            value: key,
                                            label: pascalToReadable(key),
                                        }))}
                                        readOnly={readOnly}
                                    />
                                }
                                readOnly={readOnly}
                            />
                        </div>

                        <div className={styles.row}>
                            <IdentityComponentInput
                                entityType={entityType}
                                name={'pepStatus'}
                                input={
                                    <Select
                                        name={'pepStatus'}
                                        isClearable
                                        options={Object.keys(PepStatuses).map(key => ({
                                            value: key,
                                            label: pascalToReadable(key),
                                        }))}
                                        readOnly={readOnly}
                                    />
                                }
                                readOnly={readOnly}
                            />
                        </div>

                        {pepStatus && pepStatus !== PepStatuses.NotPoliticallyExposed ? (
                            <div className={styles.row}>
                                <IdentityComponentInput
                                    entityType={entityType}
                                    name={'pepType'}
                                    input={
                                        <Select
                                            name={'pepType'}
                                            isClearable
                                            options={Object.keys(PepTypes).map(key => ({
                                                value: key,
                                                label: pascalToReadable(key),
                                            }))}
                                            readOnly={readOnly}
                                        />
                                    }
                                    readOnly={readOnly}
                                />

                                <IdentityComponentInput
                                    entityType={entityType}
                                    name={'pepDetails'}
                                    input={
                                        <textarea
                                            rows={8}
                                            {...register('pepDetails')}
                                            disabled={readOnly}
                                        />
                                    }
                                    readOnly={readOnly}
                                />
                            </div>
                        ) : null}
                    </>
                ) : null}

                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'sanctionsStatus'}
                        input={
                            <Select
                                name={'sanctionsStatus'}
                                isClearable
                                options={Object.keys(SanctionsStatuses).map(key => ({
                                    value: key,
                                    label: pascalToReadable(key),
                                }))}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    {sanctionsStatus === SanctionsStatuses.Sanctioned ? (
                        <IdentityComponentInput
                            entityType={entityType}
                            name={'sanctionsDetails'}
                            input={
                                <textarea
                                    rows={8}
                                    {...register('sanctionsDetails')}
                                    disabled={readOnly}
                                />
                            }
                            readOnly={readOnly}
                        />
                    ) : null}
                </div>

                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'adverseMediaStatus'}
                        input={
                            <Select
                                name={'adverseMediaStatus'}
                                isClearable
                                options={Object.keys(AdverseMediaStatuses).map(key => ({
                                    value: key,
                                    label: pascalToReadable(key),
                                }))}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />

                    {adverseMediaStatus === AdverseMediaStatuses.AdverseMediaFound ? (
                        <IdentityComponentInput
                            entityType={entityType}
                            name={'adverseMediaDetails'}
                            input={
                                <textarea
                                    rows={8}
                                    {...register('adverseMediaDetails')}
                                    disabled={readOnly}
                                />
                            }
                            readOnly={readOnly}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default Screening
