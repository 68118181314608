import moment from 'moment'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { useGetRelationship } from '@features/relationships/relationship/hooks'

import styles from './ReviewNameSuggestions.module.scss'

const ReviewNameSuggestions = () => {
    const { relationshipId } = useParams()
    const { setValue } = useFormContext()

    const relationship = useGetRelationship(relationshipId, () => {})

    const ordinals = [
        'Zeroth',
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
        'Sixth',
        'Seventh',
        'Eighth',
        'Ninth',
        'Tenth',
    ]

    if (!relationship.data) return null

    const suggestions = [
        `${moment().format('YYYY')} Review`,
        `${moment().format(`MMMM 'YY`)} Review`,
        `Q${moment().format(`Q YYYY`)} Review`,
        `${ordinals[relationship.data.reviews.length]} Periodic Review`,
        `Trigger Event Review`,
    ]

    return (
        <div className={styles.reviewNameSuggestions}>
            <p>Suggestions:</p>
            {suggestions.map(suggestion => (
                <button
                    onClick={() => setValue('versionName', suggestion)}
                    type={'button'}
                >
                    {suggestion}
                </button>
            ))}
        </div>
    )
}

export default ReviewNameSuggestions
