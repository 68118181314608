import { trpc } from '@utils/trpc'

export const useGetRelationship = (relationshipId, onSuccess) =>
    trpc.relationships.getById.useQuery({ relationshipId }, { onSuccess })

export const useGetRelationshipReview = (relationshipId, relationshipReviewId, onSuccess) =>
    trpc.relationshipReviews.getReview.useQuery(
        { relationshipId, relationshipReviewId },
        { enabled: !!relationshipReviewId, onSuccess }
    )

export const useUpdateRelationship = onSuccess => {
    const context = trpc.useContext()

    return trpc.relationships.update.useMutation({
        onSuccess,
        onSettled: async () => {
            await context.relationships.invalidate()
        },
    })
}

export const useSubmitForApproval = () => {
    const context = trpc.useContext()

    return trpc.relationshipReviews.submitReviewForApproval.useMutation({
        onSettled: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
        },
    })
}

export const useDeleteRelationship = onSuccess => {
    const context = trpc.useContext()

    return trpc.relationships.deleteById.useMutation({
        onSuccess,
        onSettled: async () => {
            await context.relationships.invalidate()
        },
    })
}

export const useCreateReview = onSuccess => {
    const context = trpc.useContext()

    return trpc.relationshipReviews.createReview.useMutation({
        onSuccess,
        onSettled: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
        },
    })
}

export const useDeleteRelationshipReview = onSuccess => {
    const context = trpc.useContext()

    return trpc.relationshipReviews.deleteReview.useMutation({
        onSuccess,
        onSettled: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
        },
    })
}

export const useExportReviewToPdf = onSuccess =>
    trpc.relationshipReviews.exportReviewToPdf.useMutation({ onSuccess })

export const useFlagForReview = () => {
    const context = trpc.useContext()

    return trpc.relationships.setFlaggedForReview.useMutation({
        onSettled: async () => {
            await context.relationships.invalidate()
        },
    })
}

export const useUnflagForReview = () => {
    const context = trpc.useContext()

    return trpc.relationships.setUnflaggedForReview.useMutation({
        onSettled: async () => {
            await context.relationships.invalidate()
        },
    })
}
