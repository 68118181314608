import { trpc } from '@utils/trpc'

export const useCreateRiskAssessmentPolicy = onSuccess => {
    const context = trpc.useContext()

    return trpc.riskAssessmentPolicies.create.useMutation({
        onSuccess,
        onSettled: () => context.riskAssessmentPolicies.invalidate(),
    })
}

export const useGetSignedRiskAssessmentPolicies = () =>
    trpc.riskAssessmentPolicies.getAllSigned.useQuery()

export const useGetRiskAssessmentPolicyById = (riskAssessmentPolicyId, onSuccess) =>
    trpc.riskAssessmentPolicies.getById.useQuery(
        { riskAssessmentPolicyId },
        { enabled: !!riskAssessmentPolicyId, onSuccess }
    )

export const useGetRiskAssessmentPolicyVersionById = (
    riskAssessmentPolicyVersionId,
    riskAssessmentPolicyId,
    onSuccess
) =>
    trpc.riskAssessmentPolicies.getVersionById.useQuery(
        { riskAssessmentPolicyVersionId },
        { enabled: !!riskAssessmentPolicyVersionId && !!riskAssessmentPolicyId, onSuccess }
    )

export const useCreateRiskAssessmentPolicyVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.riskAssessmentPolicies.createVersion.useMutation({
        onSuccess,
        onSettled: () => context.riskAssessmentPolicies.invalidate(),
    })
}

export const useUpdateRiskAssessmentPolicyVersion = () => {
    const context = trpc.useContext()

    return trpc.riskAssessmentPolicies.updateVersion.useMutation({
        onSuccess: () => context.riskAssessmentPolicies.invalidate(),
    })
}

export const useSetRiskAssessmentPolicyVersionPending = () => {
    const context = trpc.useContext()

    return trpc.riskAssessmentPolicies.setVersionPending.useMutation({
        onSettled: () => context.riskAssessmentPolicies.invalidate(),
    })
}

export const useRevertVersionToDraft = () => {
    const context = trpc.useContext()

    return trpc.riskAssessmentPolicies.revertVersionToDraft.useMutation({
        onSettled: () => context.riskAssessmentPolicies.invalidate(),
    })
}

export const useSignRiskAssessmentPolicyVersion = () => {
    const context = trpc.useContext()

    return trpc.riskAssessmentPolicies.createVersionSignature.useMutation({
        onSettled: () => context.riskAssessmentPolicies.invalidate(),
    })
}

export const useRemoveRiskAssessmentPolicyVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.riskAssessmentPolicies.deleteVersion.useMutation({
        onSuccess,
        onSettled: () => context.riskAssessmentPolicies.invalidate(),
    })
}
