import { useGetRiskLevel } from '@features/riskLevels'

import Tag, { TagThemes } from '@components/tag/Tag'

const RiskLevelTag = ({ riskLevelId }: { riskLevelId?: string | null }) => {
    const riskLevel = useGetRiskLevel(riskLevelId, () => {})
    if (!riskLevelId || !riskLevel.data) return <p>-</p>

    return (
        <Tag
            label={riskLevel.data.name}
            theme={TagThemes.Black}
            outline={false}
            themeOverrideColour={riskLevel.data.colour}
        />
    )
}

export default RiskLevelTag
