import { EntityTypes } from '@prisma/client'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import EntityTypeIndicator from '@components/entity/EntityTypeIndicator'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import TextInput from '@components/inputs/textInput/TextInput'

import { useCreateEntity } from '../hooks'

import styles from './CreateEntity.module.scss'

const CreateEntity = () => {
    const navigate = useNavigate()

    const form = useForm()

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = form

    const { mutate, isLoading } = useCreateEntity(data => navigate(`/entities/${data.id}`))
    const submit = data => mutate(data)

    return (
        <Modal
            overlay={true}
            heading='Create Entity'
            close={'/entities'}
        >
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(submit)}>
                    <InputWrapper
                        label={'Entity Type *'}
                        input={
                            <Controller
                                name='entityType'
                                rules={{ required: true }}
                                control={control}
                                render={({ field }) => (
                                    <div className={styles.entityTypeSelector}>
                                        <input
                                            type={'radio'}
                                            id={'naturalPerson'}
                                            name={'entityType'}
                                            value={EntityTypes.NaturalPerson}
                                            onChange={field.onChange}
                                        />
                                        <label htmlFor={'naturalPerson'}>
                                            <EntityTypeIndicator
                                                entityType={EntityTypes.NaturalPerson}
                                            />
                                            <h3>Natural Person</h3>
                                            <p>Individuals</p>
                                        </label>
                                        <input
                                            type={'radio'}
                                            id={'legalPerson'}
                                            name={'entityType'}
                                            value={EntityTypes.LegalPerson}
                                            onChange={field.onChange}
                                        />
                                        <label htmlFor={'legalPerson'}>
                                            <EntityTypeIndicator
                                                entityType={EntityTypes.LegalPerson}
                                            />
                                            <h3>Legal Person</h3>
                                            <p>Companies</p>
                                        </label>
                                        <input
                                            type={'radio'}
                                            id={'legalArrangement'}
                                            name={'entityType'}
                                            value={EntityTypes.LegalArrangement}
                                            onChange={field.onChange}
                                        />
                                        <label htmlFor={'legalArrangement'}>
                                            <EntityTypeIndicator
                                                entityType={EntityTypes.LegalArrangement}
                                            />
                                            <h3>Legal Arrangement</h3>
                                            <p>Partnerships and Trusts</p>
                                        </label>
                                        <input
                                            type={'radio'}
                                            id={'foundation'}
                                            name={'entityType'}
                                            value={EntityTypes.Foundation}
                                            onChange={field.onChange}
                                        />
                                        <label htmlFor={'foundation'}>
                                            <EntityTypeIndicator
                                                entityType={EntityTypes.Foundation}
                                            />
                                            <h3>Foundation</h3>
                                            <p>2006 Foundation Act</p>
                                        </label>
                                    </div>
                                )}
                            />
                        }
                        errors={errors.entityType}
                    />

                    <InputWrapper
                        label='Legal Name *'
                        input={
                            <TextInput
                                name='name'
                                required
                            />
                        }
                        errors={errors.name}
                    />

                    <Button
                        label='Create Entity'
                        fullWidth={true}
                        disabled={isLoading}
                    />
                </form>
            </FormProvider>
        </Modal>
    )
}

export default CreateEntity
