import Tag, { TagThemes } from '@components/tag/Tag'

import styles from './WarningFlagTags.module.scss'

const WarningFlagTags = ({ flags }: { flags: string[] }) => {
    if (!flags || flags.length === 0) return <p>-</p>

    return (
        <div className={styles.warningFlagTags}>
            {flags.map((flag, index) => (
                <Tag
                    key={index}
                    label={flag}
                    theme={TagThemes.Red}
                    outline={false}
                />
            ))}
        </div>
    )
}

export default WarningFlagTags
