import { trpc } from '@utils/trpc'

export const useCreateControllingParty = onSuccess => {
    const context = trpc.useContext()

    return trpc.controllingParties.create.useMutation({
        onSuccess,
        onSettled: () => {
            context.controllingParties.invalidate()
            context.relationships.invalidate()
            context.relationshipReviews.invalidate()
        },
    })
}

export const useGetControllingPartyById = (id, onSuccess) =>
    trpc.controllingParties.getById.useQuery(
        { controllingPartyId: id },
        { enabled: !!id, onSuccess }
    )

export const useGetControllingPartiesPos2 = entityId =>
    trpc.controllingParties.getPos2ByEntityId.useQuery({ entityId }, { enabled: !!entityId })

export const useGetControllingPartiesPos1 = entityId =>
    trpc.controllingParties.getPos1ByEntityId.useQuery({ entityId }, { enabled: !!entityId })

export const useGetControllingPartyVersionById = (id, onSuccess) =>
    trpc.controllingParties.getVersionById.useQuery({ id }, { enabled: !!id, onSuccess })

export const useExportControllingPartyVersionToPdf = onSuccess =>
    trpc.controllingParties.exportVersionToPdf.useMutation({ onSuccess })

export const useCreateControllingPartyVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.controllingParties.createVersion.useMutation({
        onSuccess,
        onSettled: () => context.controllingParties.invalidate(),
    })
}

export const useUpdateControllingPartyVersion = () => {
    const context = trpc.useContext()

    return trpc.controllingParties.updateVersion.useMutation({
        onSuccess: () => context.controllingParties.invalidate(),
    })
}

export const useSignControllingPartyVersion = () => {
    const context = trpc.useContext()

    return trpc.controllingParties.signVersion.useMutation({
        onSettled: () => {
            context.controllingParties.invalidate()
            context.relationships.invalidate()
            context.relationshipReviews.invalidate()
        },
    })
}

export const useRemoveControllingPartyVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.controllingParties.removeVersion.useMutation({
        onSuccess,
        onSettled: () => context.controllingParties.invalidate(),
    })
}
