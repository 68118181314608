import Breadcrumbs from '@components/document/Breadcrumbs'

const Title = ({
    showBreadcrumbs,
    breadcrumbs,
    title,
}: {
    showBreadcrumbs: boolean
    breadcrumbs: { label: any; link: string }[]
    title: string
}) => {
    return (
        <div>
            {showBreadcrumbs ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}
            <h1>{title}</h1>
        </div>
    )
}

export default Title
