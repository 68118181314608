import moment from 'moment'
import { Lock } from 'react-feather'

import UserLabel from '@components/user/UserLabel'

import styles from './Document.module.scss'

const Signature = ({
    user,
    date,
    versionName,
    versionId,
}: {
    user: {
        firstName: string
        lastName: string
    }
    date: Date
    versionName: string
    versionId: string
}) => {
    return (
        <div className={styles.signature}>
            <div>
                <Lock
                    color={'#295fe7'}
                    size={17}
                />
                <p>Signed by</p>
                <UserLabel user={user} />
                <p>
                    on {moment(date).format('ddd DD MMMM YYYY')}, {moment(date).format('HH:mm')}
                </p>
            </div>

            <div>
                <p>{versionName}</p>
                <p>{versionId}</p>
            </div>
        </div>
    )
}

export default Signature
