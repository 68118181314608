import { Route } from 'react-router-dom'

import Reports from '@features/reports/layouts/Reports'

import CreateEnquiry from './views/CreateEnquiry'
import Enquiry from './views/Enquiry'
import EnquiryVersion from './views/EnquiryVersion'
import ListEnquiries from './views/ListEnquiries'

export const EnquiryRoutes = () => {
    return (
        <>
            <Route
                path='enquiries'
                element={
                    <Reports>
                        <ListEnquiries />
                    </Reports>
                }
            >
                <Route
                    path='create-enquiry'
                    element={<CreateEnquiry />}
                />
            </Route>
            <Route
                path='enquiries/:enquiryId'
                element={<Enquiry />}
            />
            <Route
                path='enquiries/:enquiryId/versions/:enquiryVersionId'
                element={<EnquiryVersion />}
            />
        </>
    )
}
