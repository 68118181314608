import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useGetRoles } from '@features/organisation/roles/hooks'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import form from '@components/form/Form'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import EmailInput from '@components/inputs/emailInput/EmailInput'
import Select from '@components/inputs/select/Select'

import { useInviteUser } from '../hooks'

import styles from '@styles/Form.module.scss'

const InviteTeamMember = () => {
    const navigate = useNavigate()

    const form = useForm()
    const {
        handleSubmit,
        formState: { errors },
    } = form

    const roles = useGetRoles()

    const { mutate, isLoading, isError } = useInviteUser(() =>
        navigate('/organisation/team-members')
    )

    const submit = data => mutate(data)

    if (!roles.data) return null

    return (
        <Modal
            overlay={true}
            heading={`Invite Team Member`}
            close={`/organisation/team-members`}
        >
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(submit)}>
                    {isError && (
                        <p className={styles.error}>
                            There was an error submitting the form, please try again.
                        </p>
                    )}

                    <InputWrapper
                        label='Email Address'
                        input={
                            <EmailInput
                                name={'email'}
                                required
                            />
                        }
                        errors={errors.email}
                    />

                    <InputWrapper
                        label={`Role`}
                        input={
                            <Select
                                name={'userRoleId'}
                                required
                                options={roles.data.map(role => ({
                                    label: role.name,
                                    value: role.id,
                                }))}
                            />
                        }
                        errors={errors.userRoleId}
                    />

                    <Button
                        label='Invite Team Member'
                        fullWidth={true}
                        loading={isLoading}
                    />
                </form>
            </FormProvider>
        </Modal>
    )
}

export default InviteTeamMember
