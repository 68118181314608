import classNames from 'classnames'
import { useController, useFormContext } from 'react-hook-form'

import styles from './RequirementSelector.module.scss'

const RequirementSelector = ({
    name,
    enhanced,
    readOnly,
}: {
    name: string
    enhanced: boolean
    readOnly: boolean
}) => {
    const { setValue } = useFormContext()

    const identify = useController({
        name: `${name}${!enhanced ? 'Standard' : 'Enhanced'}Identify`,
    })

    const verify = useController({
        name: `${name}${!enhanced ? 'Standard' : 'Enhanced'}Verify`,
    })

    return (
        <div
            className={classNames({
                [styles.requirementSelector]: true,
                [styles.enhanced]: enhanced,
            })}
        >
            <p
                className={classNames({
                    [styles.identify]: true,
                    [styles.active]: identify.field.value,
                    [styles.readOnly]: readOnly,
                })}
                onClick={() => {
                    if (readOnly) return

                    const value = !identify.field.value

                    if (value) {
                        identify.field.onChange(true)
                        if (!enhanced) setValue(`${name}EnhancedIdentify`, true)
                    }
                    if (!value) {
                        identify.field.onChange(false)
                        verify.field.onChange(false)
                    }
                }}
            >
                I
            </p>
            <p
                className={classNames({
                    [styles.verify]: true,
                    [styles.active]: verify.field.value,
                    [styles.readOnly]: readOnly,
                })}
                onClick={() => {
                    if (readOnly) return

                    const value = !verify.field.value

                    if (value) {
                        identify.field.onChange(true)
                        verify.field.onChange(true)

                        if (!enhanced) {
                            setValue(`${name}EnhancedIdentify`, true)
                            setValue(`${name}EnhancedVerify`, true)
                        }
                    }
                    if (!value) verify.field.onChange(false)
                }}
            >
                V
            </p>
        </div>
    )
}

export default RequirementSelector
