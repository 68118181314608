import Header from '@features/relationships/relationship/components/sidebar/header/Header'
import Metadata from '@features/relationships/relationship/components/sidebar/metadata/Metadata'

import styles from './Sidebar.module.scss'

const Sidebar = ({ content }: { content: JSX.Element }) => {
    return (
        <div className={styles.sidebar}>
            <Header />
            <Metadata />
            {content}
        </div>
    )
}

export default Sidebar
