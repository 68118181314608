import { Grants } from '@prisma/client'
import classNames from 'classnames'
import { JSX } from 'react'
import { Edit3 } from 'react-feather'

import { useGetOrganisation } from '@features/organisation'
import { useCheckGrants } from '@features/user'

import Button from '@components/button/Button'
import Breadcrumbs from '@components/document/Breadcrumbs'
import PolicySignatures from '@components/document/PolicySignatures'
import Signature from '@components/document/Signature'
import FileStoreImage from '@components/fileStore/FileStoreImage'

import { Action } from './Document'

import styles from './Document.module.scss'
import formStyles from '@styles/Form.module.scss'

const ReadOnly = ({
    isAllowed,
    title,
    Form,
    Attachment,
    signature,
    counterSignatures,
    versionName,
    versionId,
    isPending,
    requiredSignatures,
    sign,
    revertToDraft,
    counterSign,
    showBreadcrumbs,
    breadcrumbs,
    counterSignaturesRequired,
}: {
    isAllowed: boolean
    title: string
    Form: any
    Attachment?: JSX.Element
    signature?: {
        user: {
            firstName: string
            lastName: string
        }
        date: Date
    }
    counterSignatures?: {
        user: {
            firstName: string
            lastName: string
        }
        date: Date
    }[]
    versionName: string
    versionId: string
    isPending: boolean
    requiredSignatures?: {
        id: string
        firstName: string
        lastName: string
        email: string
        signature?: any
    }[]
    sign: Action
    revertToDraft?: Action
    counterSign?: Action
    showBreadcrumbs: boolean
    breadcrumbs: { label: any; link: string }[]
    counterSignaturesRequired?: Number
}) => {
    const organisation = useGetOrganisation(null, {})
    const hasCountersignRelationshipGrant = useCheckGrants(Grants.CountersignRelationship) ?? false

    return (
        <>
            <div className={`${styles.page} container`}>
                <div className={styles.titleContainer}>
                    <div>
                        <h1>{title}</h1>
                        {breadcrumbs && showBreadcrumbs ? (
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                        ) : null}
                    </div>
                    <div>
                        {organisation.data?.logoFileName ? (
                            <FileStoreImage
                                fileName={organisation.data.logoFileName}
                                className={styles.organisationLogo}
                            />
                        ) : null}
                    </div>
                </div>
                <form
                    id={'form'} // Required to link to the Save button
                    className={classNames({
                        [formStyles.form]: true,
                        [styles.form]: true,
                    })}
                >
                    <Form readOnly={true} />
                </form>
                {Attachment ? Attachment : null}
                {signature ? (
                    <Signature
                        user={signature.user}
                        date={signature.date}
                        versionName={versionName}
                        versionId={versionId}
                    />
                ) : null}
                {counterSignatures?.map(counterSignature => (
                    <Signature
                        user={counterSignature.user}
                        date={counterSignature.date}
                        versionName={versionName}
                        versionId={versionId}
                    />
                ))}
                {requiredSignatures ? (
                    <PolicySignatures
                        requiredSignatures={requiredSignatures}
                        versionName={versionName}
                        versionId={versionId}
                        sign={sign}
                    />
                ) : null}
            </div>

            {revertToDraft && isAllowed ? (
                <div className={styles.revertToDraft}>
                    <Button
                        label={'Revert To Draft'}
                        outline={true}
                        onClick={revertToDraft.function}
                        loading={revertToDraft.isLoading}
                    />
                </div>
            ) : null}

            {isPending && counterSignaturesRequired ? (
                <div className={styles.revertToDraft}>
                    <p>
                        {counterSignatures?.length} / {counterSignaturesRequired?.toString()}{' '}
                        Countersignatures
                    </p>
                    {counterSign && hasCountersignRelationshipGrant ? (
                        <Button
                            icon={<Edit3 size={16} />}
                            label={'Counter Sign Document'}
                            onClick={counterSign.function}
                            loading={counterSign.isLoading}
                        />
                    ) : null}
                </div>
            ) : null}
        </>
    )
}

export default ReadOnly
