import classNames from 'classnames'
import { forEach } from 'lodash'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import Select, { components } from 'react-select'

import { useGetRelationship } from '@features/relationships/relationship/hooks'

import CircularProgress from '@components/circularProgress/CircularProgress'
import RelationshipReviewStatusTag from '@components/tag/RelationshipReviewStatusTag'

import styles from './ReviewSelect.module.scss'

const ReviewSelect = () => {
    const { relationshipId, relationshipReviewId, terminationId } = useParams()
    const navigate = useNavigate()

    const relationship = useGetRelationship(relationshipId, () => {})

    if (!relationship.data) return null

    const options: {
        label: string
        value: string
    }[] = []

    if (
        !relationship.data.reviews.find(review => review?.status !== 'Completed') &&
        relationship.data.status === 'Active' &&
        !relationship.data.termination
    )
        options.push({
            label: 'Initiate New Review',
            value: 'create',
        })

    if (relationship.data.termination)
        options.push({
            label: 'Termination',
            value: relationship.data.termination.id,
        })

    forEach(
        relationship.data.reviews.sort(
            (a, b) =>
                new Date(b?.createDate as Date).getTime() -
                new Date(a?.createDate as Date).getTime()
        ),
        review => {
            options.push({
                label: review?.versionName as string,
                value: review?.id as string,
            })
        }
    )

    const selectValue = relationshipReviewId || terminationId

    return (
        <Select
            className={classNames({
                select: true,
            })}
            styles={{
                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                    ...styles,
                    'cursor': 'pointer',
                    'backgroundColor': isDisabled
                        ? undefined
                        : isSelected
                        ? '#f0f0f0'
                        : isFocused
                        ? '#f9f9f9'
                        : undefined,
                    ':active': {
                        ...styles[':active'],
                        backgroundColor: !isDisabled
                            ? isSelected
                                ? '#f0f0f0'
                                : '#f0f0f0'
                            : undefined,
                    },
                }),
                indicatorSeparator: () => ({
                    display: 'none',
                }),
                control: provided => ({
                    ...provided,
                    'boxShadow': 'none',
                    'borderColor': '#CFD3DA',
                    '&:hover': { borderColor: 'gray' },
                    '&:focus': { borderColor: 'gray' },
                    'cursor': 'pointer',
                }),
                valueContainer: provided => ({
                    ...provided,
                    padding: '2px 0 2px 11px!important',
                }),
            }}
            components={{
                Option: props => {
                    const review = relationship.data.reviews.find(
                        review => (review?.id as string) === (props.data.value as string)
                    )

                    return (
                        <components.Option {...props}>
                            {review ? (
                                <div className={styles.option}>
                                    <div>
                                        <RelationshipReviewStatusTag status={review.status} />
                                        <span>{review.versionName}</span>
                                    </div>
                                    {review.status !== 'Profiling' ? (
                                        <span className={styles.date}>
                                            {moment(review.createDate).format('DD/MM/YYYY')}
                                        </span>
                                    ) : null}
                                </div>
                            ) : props.data.label === 'Termination' ? (
                                <div className={styles.option}>
                                    <div>
                                        <span>Termination</span>
                                    </div>
                                    <span className={styles.date}>
                                        {moment(relationship.data.termination?.createDate).format(
                                            'DD/MM/YYYY'
                                        )}
                                    </span>
                                </div>
                            ) : (
                                props.data.label
                            )}
                        </components.Option>
                    )
                },
                SingleValue: props => {
                    const review = relationship.data.reviews.find(
                        review => (review?.id as string) === (props.data.value as string)
                    )

                    return (
                        <components.SingleValue {...props}>
                            {review ? (
                                <div className={styles.singleValue}>
                                    <div>
                                        <div className={classNames(styles.group, styles.shrink)}>
                                            <div className={styles.grow}>
                                                <RelationshipReviewStatusTag
                                                    status={review.status}
                                                />
                                            </div>
                                            <span className={styles.shrink}>
                                                {review.versionName}
                                            </span>
                                        </div>
                                        <div className={styles.justifyRight}>
                                            {review.status === 'Profiling' &&
                                            review.checklist.percentProgress ? (
                                                <CircularProgress
                                                    value={review.checklist.percentProgress * 100}
                                                />
                                            ) : (
                                                <span className={styles.date}>
                                                    {moment(review.createDate).format('DD/MM/YYYY')}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : props.data.label === 'Termination' ? (
                                <div className={styles.option}>
                                    <div className={styles.shrink}>
                                        <span>Termination</span>
                                    </div>
                                    <span className={styles.date}>
                                        {moment(relationship.data.termination?.createDate).format(
                                            'DD/MM/YYYY'
                                        )}
                                    </span>
                                </div>
                            ) : (
                                props.data.label
                            )}
                        </components.SingleValue>
                    )
                },
            }}
            value={options.find(({ value }) => value === selectValue)}
            onChange={option => {
                if (option?.label === 'Termination') {
                    navigate(
                        `/relationships/${relationshipId}/termination/${relationship.data.termination?.id}/versions/${relationship.data.termination?.versions[0]?.id}`
                    )
                } else {
                    navigate(`/relationships/${relationshipId}/reviews/${option?.value}`)
                }
            }}
            options={options}
        />
    )
}

export default ReviewSelect
