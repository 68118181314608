import UserAvatar from '@components/user/UserAvatar'

import styles from './User.module.scss'

const UserLabel = ({ user }) => {
    if (!user) return null
    if (!user.firstName || !user.lastName) return null

    return (
        <div className={styles.userLabel}>
            <UserAvatar user={user} />
            <p>
                {user.firstName} {user.lastName}
            </p>
        </div>
    )
}

export default UserLabel
