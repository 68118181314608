import Cookies from 'js-cookie'
import moment from 'moment'
import { useMemo } from 'react'
import { Check } from 'react-feather'
import { Helmet } from 'react-helmet'

import Table from '@components/table/Table'

import { env } from '@utils/env'

import { useAcceptInvitation, useGetSelfInvitations } from '../hooks'

const ListInvitations = () => {
    const columns = useMemo(
        () => [
            {
                Header: 'Organisation',
                accessor: 'organisation.name',
            },
            {
                Header: 'Created',
                accessor: 'createDate',
                Cell: props => moment(props.value).fromNow(),
            },
            {
                Header: 'Expires',
                accessor: 'expires',
                Cell: props => moment(props.value).fromNow(),
            },
            {
                Header: 'Accept Invitation',
                accessor: 'tokenString',
                Cell: props => (
                    <button
                        onClick={() => mutate({ tokenString: props.value })}
                        className='link dark'
                    >
                        <Check />
                        Accept
                    </button>
                ),
            },
        ],
        []
    )

    const invitations = useGetSelfInvitations()

    const { mutate } = useAcceptInvitation(data => {
        Cookies.set('tokenString', data, {
            domain: env.REACT_APP_DOMAIN,
        })
        window.location.href = '/'
    })

    if (invitations.isLoading) return null

    return (
        <>
            <Helmet>
                <title>Invitations | Proofdesk</title>
            </Helmet>

            <div className='heading'>
                <div>
                    <h1>Invitations</h1>
                </div>
            </div>
            {invitations.isLoading || invitations.isError || invitations.data?.length ? (
                <Table
                    data={invitations.data}
                    isLoading={invitations.isLoading}
                    defaultPageSize={50}
                    columns={columns}
                    button={null}
                />
            ) : (
                <div className={'nothingToShow'}>
                    <p>You don't have any invitations.</p>
                </div>
            )}
        </>
    )
}

export default ListInvitations
