import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useGetEntities } from '@features/entities'
import { useGetSelfUserMemberships } from '@features/organisation/teamMembers'
import { useCreateRelationship } from '@features/relationships/hooks'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import EntitySelect from '@components/inputs/entitySelect/EntitySelect'
import TextInput from '@components/inputs/textInput/TextInput'
import UserSelect from '@components/inputs/userSelect/UserSelect'
import UserMultiSelect from '@components/userMultiSelect/UserMultiSelect'

const CreateRelationship = () => {
    const navigate = useNavigate()
    const form = useForm()

    const entities = useGetEntities()
    const users = useGetSelfUserMemberships()

    const {
        handleSubmit,
        formState: { errors },
    } = form

    const { mutate, isLoading } = useCreateRelationship(data =>
        navigate(`/relationships/${data.id}`, { replace: true })
    )

    const submit = data => mutate(data)

    if (entities.isLoading || users.isLoading)
        return (
            <Modal
                overlay={true}
                loading={true}
                heading={'Establish Relationship'}
            />
        )
    if (!entities.data || !users.data) return null

    return (
        <Modal
            overlay={true}
            heading={'Establish Relationship'}
            close={'/relationships'}
        >
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(submit)}>
                    <InputWrapper
                        label={'Customer Entity'}
                        input={
                            <EntitySelect
                                entities={entities.data}
                                name={'entityId'}
                                required={true}
                            />
                        }
                        errors={errors.entityId}
                    />

                    <InputWrapper
                        label={'Reference'}
                        input={
                            <TextInput
                                name={'internalReference'}
                                required={true}
                            />
                        }
                        errors={errors.internalReference}
                    />

                    <InputWrapper
                        label={'Owner'}
                        input={
                            <UserSelect
                                users={users.data.map(user => user.user)}
                                name={'ownerUserId'}
                                required={true}
                            />
                        }
                        errors={errors.ownerUserId}
                    />

                    <InputWrapper
                        label={'Subscribers'}
                        input={
                            <UserMultiSelect
                                users={users.data.map(user => user.user)}
                                name={'subscribedUserIds'}
                                required={true}
                                minLength={1}
                            />
                        }
                        errors={errors.subscribedUserIds}
                    />

                    <Button
                        label='Continue'
                        fullWidth={true}
                        disabled={isLoading}
                    />
                </form>
            </FormProvider>
        </Modal>
    )
}

export default CreateRelationship
