import { Route } from 'react-router-dom'

import CustomerRiskAssessmentVersion from '@features/relationships/relationship/customerRiskAssessment/views/CustomerRiskAssessmentVersion'

export const CustomerRiskAssessmentRoutes = () => {
    return (
        <>
            <Route
                path='customer-risk-assessment-version/:customerRiskAssessmentVersionId'
                element={<CustomerRiskAssessmentVersion />}
            />
        </>
    )
}
