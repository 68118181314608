import Cookies from 'js-cookie'
import { Route, Routes } from 'react-router-dom'

import { env } from '@utils/env'

export const Logout = () => {
    Cookies.remove('tokenString', {
        domain: env.REACT_APP_DOMAIN as string,
    })
    window.location.href = '/'
    return null
}

export const LogoutRoutes = () => {
    return (
        <Routes>
            <Route
                path=''
                element={<Logout />}
            />
        </Routes>
    )
}
