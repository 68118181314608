import Cookies from 'js-cookie'
import { useEffect, useRef, useState } from 'react'
import { ChevronDown, Plus, Settings, Shuffle } from 'react-feather'
import { NavLink } from 'react-router-dom'

import { useGetSelf } from '@features/account'
import {
    useGetMemberships,
    useGetOrganisation,
    useSwapTokenOrganisation,
} from '@features/organisation'

import { env } from '@utils/env'

import styles from './OrganisationSwitcher.module.scss'

const OrganisationSwitcher = () => {
    const ref = useRef<HTMLInputElement>(null)
    const [open, setOpen] = useState(false)

    const user = useGetSelf(() => {})

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) setOpen(false)
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])

    const memberships = useGetMemberships(true)
    const organisation = useGetOrganisation(null, {})

    const { mutate, isLoading } = useSwapTokenOrganisation(data => {
        Cookies.set('tokenString', data, {
            domain: env.REACT_APP_DOMAIN,
        })
        window.location.href = '/'
    })

    if (memberships.isLoading || organisation.isLoading) return null

    if (memberships.data == null || memberships.data.length === 0)
        return (
            <NavLink
                to={'/create-organisation'}
                className='link'
            >
                Create Organisation
            </NavLink>
        )

    return (
        <div
            className={styles.organisationSwitcher}
            ref={ref}
        >
            <div
                className={styles.label}
                onClick={() => setOpen(!open)}
            >
                {!isLoading ? (
                    <>
                        <div>
                            {organisation.data?.isDemo ? (
                                <span>
                                    <p>Demo</p>
                                </span>
                            ) : null}
                            <p>
                                {organisation.data?.name
                                    ? organisation.data.name
                                    : 'Select Organisation'}
                            </p>
                        </div>

                        <ChevronDown
                            size={20}
                            className={open ? styles.up : null}
                        />
                    </>
                ) : (
                    <img
                        src='/images/loading.svg'
                        alt={'Loading...'}
                    />
                )}
            </div>

            {open ? (
                <div className={styles.dropdown}>
                    {organisation.data?.name ? (
                        <NavLink
                            to={'/organisation/settings'}
                            onClick={() => setOpen(false)}
                            className={styles.title}
                        >
                            {organisation.data.name}
                            <Settings
                                size={18}
                                onClick={() => setOpen(false)}
                            />
                        </NavLink>
                    ) : null}

                    {memberships.data
                        .filter(membership => membership.organisation.id !== organisation.data?.id)
                        .map(membership => (
                            <div
                                onClick={() => {
                                    setOpen(false)
                                    mutate({ organisationId: membership.organisation.id })
                                }}
                            >
                                <Shuffle size={16} />
                                {membership.organisation.isDemo ? (
                                    <span>
                                        <p>Demo</p>
                                    </span>
                                ) : null}
                                <p>
                                    {user.data?.isSuperAdmin
                                        ? `${membership.organisation.name
                                              .split(' ')
                                              .map(word => word[0]?.toUpperCase())
                                              .join('')} (${membership.organisation.id.slice(-4)})`
                                        : membership.organisation.name}
                                </p>
                            </div>
                        ))}
                    <NavLink
                        to={'/create-organisation'}
                        onClick={() => setOpen(false)}
                    >
                        <Plus size={16} />
                        Create Organisation
                    </NavLink>
                </div>
            ) : null}
        </div>
    )
}

export default OrganisationSwitcher
