import classNames from 'classnames'
import fuzzy from 'fuzzy'
import { useController, useFormContext } from 'react-hook-form'
import Select from 'react-select'

const MultiSelect = ({
    name,
    options,
    required = false,
    fuzzySearch = false,
    isSearchable = false,
    isClearable = false,
    minLength = 0,
    readOnly = false,
}) => {
    const { control, getValues } = useFormContext()
    const controller = useController({
        name,
        control,
        rules: { required, minLength },
    })

    if (readOnly && getValues(name)?.length === 0) return <div className={'input blank'} />

    if (readOnly)
        return (
            <div className={'input'}>
                {
                    <p>
                        {getValues(name)?.map((value, index) => {
                            value = options.find(option => option.value === value)?.label
                            if (index > 0) return `, ${value}`
                            return value
                        })}
                    </p>
                }
            </div>
        )

    return (
        <Select
            isMulti={true}
            className={classNames({
                select: true,
                disabled: readOnly,
            })}
            options={options}
            isClearable={isClearable}
            isSearchable={isSearchable}
            value={options.filter(option => controller.field.value?.includes(option.value))}
            onChange={options => controller.field.onChange(options.map(option => option.value))}
            filterOption={(candidate, input) => {
                if (input) {
                    if (fuzzySearch) return fuzzy.test(input, candidate.label)
                    return candidate.label.toLowerCase().startsWith(input.toLowerCase())
                }
                return true
            }}
            isDisabled={readOnly}
        />
    )
}

export default MultiSelect
