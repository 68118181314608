import { IdentityPolicyContexts } from '@features/relationships/relationship/relationshipEntity'

import { trpc } from '@utils/trpc'

export const useGetEntity = (
    {
        entityId,
        identityPolicyContext,
        edd,
    }: {
        entityId?: string
        identityPolicyContext?: IdentityPolicyContexts
        edd?: boolean
    },
    onSuccess
) =>
    trpc.entities.getById.useQuery(
        { entityId: entityId as string, identityPolicyContext, edd },
        { refetchOnWindowFocus: false, enabled: !!entityId, onSuccess }
    )

export const useDeleteEntity = onSuccess => {
    const context = trpc.useContext()

    return trpc.entities.deleteById.useMutation({
        onSuccess,
        onSettled: async () => {
            await context.entities.invalidate()
            await context.relationships.invalidate()
            await context.customerRiskAssessments.invalidate()
        },
    })
}

export const useExportEntityToPdf = onSuccess =>
    trpc.entities.exportEntityToPdf.useMutation({ onSuccess })
