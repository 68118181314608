import { EntityTypes } from '@prisma/client'
import { Plus } from 'react-feather'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import ControllingPartiesFieldArray from '@features/createEntity/components/ControllingPartiesFieldArray/ControllingPartiesFieldArray'
import ControllingPartyInput from '@features/createEntity/components/ControllingPartyInput/ControllingPartyInput'
import { useCreateEntitiesAndControllingParties } from '@features/createEntity/hooks'
import { useGetEntity } from '@features/entities/entity/hooks'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import EntityLabel from '@components/entity/EntityLabel'

import styles from './AddControllingParties.module.scss'

const AddControllingParties = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const entity = useGetEntity({ entityId: id }, () => {})

    const form = useForm({
        defaultValues: {
            controllingParties: [
                {
                    tempId: uuid(),
                    name: '',
                    entityType: EntityTypes.NaturalPerson,
                },
            ],
        },
    })

    const {
        handleSubmit,
        formState: { errors },
        watch,
        control,
    } = form

    const abc = {
        controllingParties: [
            {
                tempId: 1,
                name: 'name',
                entityType: EntityTypes.NaturalPerson,
                controllingParties: [
                    {
                        tempId: 2,
                        name: 'name',
                        entityType: EntityTypes.NaturalPerson,
                        controllingParties: [
                            {
                                tempId: 3,
                                name: 'name',
                                entityType: EntityTypes.NaturalPerson,
                                controllingParties: [
                                    {
                                        tempId: 4,
                                        name: 'name',
                                        entityType: EntityTypes.NaturalPerson,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                tempId: 5,
                name: 'name',
                entityType: EntityTypes.NaturalPerson,
            },
            {
                tempId: 6,
                name: 'name',
                entityType: EntityTypes.NaturalPerson,
            },
        ],
    }

    // Helper function to process each entity and its children
    const processEntities = (
        entities,
        parentId = id,
        result = { entities: [], controllingParties: [] }
    ) => {
        return entities.reduce((acc, entity) => {
            // Add the current entity to the flattened list
            acc.entities.push({
                tempId: entity.tempId,
                name: entity.name,
                entityType: entity.entityType,
            })
            // Record the relationship
            acc.controllingParties.push({ entity2TempId: parentId, entity1TempId: entity.tempId })
            // If there are controlling parties, process them recursively
            if (entity.controllingParties) {
                processEntities(entity.controllingParties, entity.tempId, acc)
            }
            return acc
        }, result)
    }

    const { mutate } = useCreateEntitiesAndControllingParties(() => navigate('/entities'))

    const submit = data => {
        const { entities, controllingParties } = processEntities(data.controllingParties)

        mutate({
            entities: [
                {
                    id: id,
                    tempId: id,
                },
                ...entities,
            ],
            controllingParties,
        })
    }

    if (!entity.data) return null

    console.log(entity.data)

    return (
        <Modal
            overlay={true}
            heading='Add Controlling Parties'
            close={'/entities'}
        >
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(submit)}>
                    <div>
                        <div className={styles.entityLabel}>
                            <EntityLabel
                                id={entity.data.id}
                                name={entity.data.name}
                                entityType={entity.data.entityType}
                            />
                        </div>

                        <div className={styles.controllingParties}>
                            <ControllingPartiesFieldArray
                                key={'key'}
                                name={'controllingParties'}
                            />
                        </div>
                    </div>

                    <Button
                        fullWidth={true}
                        label={'Continue'}
                        disabled={false}
                    />
                </form>
            </FormProvider>
        </Modal>
    )
}

export default AddControllingParties
