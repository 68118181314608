import { Route, Routes } from 'react-router-dom'

import IdentityPolicy from './views/IdentityPolicy'
import IdentityPolicyVersion from './views/IdentityPolicyVersion'

export const IdentityPoliciesRoutes = () => {
    return (
        <Routes>
            <Route
                path={':identityPolicyId'}
                element={<IdentityPolicy />}
            />
            <Route
                path={':identityPolicyId/versions/:identityPolicyVersionId'}
                element={<IdentityPolicyVersion />}
            />
        </Routes>
    )
}
