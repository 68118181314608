import Tag, { TagThemes } from '@components/tag/Tag'

const RelationshipStatusTag = ({ status }: { status: string }) => {
    let theme = TagThemes.Grey
    if (status === 'Onboarding') theme = TagThemes.Orange

    return (
        <Tag
            label={status}
            theme={theme}
            outline={false}
        />
    )
}

export default RelationshipStatusTag
