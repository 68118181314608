import { Grants } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import {
    useDeleteRemediation,
    useGetRemediationById,
    useMarkRemediationAsComplete,
} from '@features/relationships/relationship/remediations/hooks'
import { useCheckGrants } from '@features/user'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import AutoGrowTextArea from '@components/inputs/autoGrowTextArea/AutoGrowTextArea'

import styles from '@styles/Form.module.scss'

const ViewRemediation = () => {
    const navigate = useNavigate()
    const { relationshipId, remediationId } = useParams()

    const hasManageRelationshipGrant = useCheckGrants(Grants.ManageRelationship) ?? false
    const hasApproveRelationshipGrant = useCheckGrants(Grants.ApproveRelationship) ?? false

    const form = useForm()
    const { reset } = form

    const remediation = useGetRemediationById(remediationId, data => reset(data))
    const markAsComplete = useMarkRemediationAsComplete(() =>
        navigate(`/relationships/${relationshipId}`)
    )
    const deleteRemediation = useDeleteRemediation(() =>
        navigate(`/relationships/${relationshipId}`)
    )

    if (remediation.isLoading)
        return (
            <Modal
                overlay={true}
                heading={'Remediation'}
                close={`..`}
                large={true}
                loading={remediation.isLoading}
            />
        )

    console.log(remediation.data)

    return (
        <Modal
            overlay={true}
            heading={'Remediation'}
            close={`..`}
            large={true}
        >
            <FormProvider {...form}>
                <form className={styles.form}>
                    <InputWrapper
                        label={'Task'}
                        input={
                            <AutoGrowTextArea
                                name={'task'}
                                medium={true}
                                required
                                readOnly={true}
                            />
                        }
                    />

                    {remediation.data?.completed ? null : (
                        <div className={styles.actions}>
                            {hasApproveRelationshipGrant ? (
                                <Button
                                    label='Delete'
                                    disabled={
                                        markAsComplete.isLoading || deleteRemediation.isLoading
                                    }
                                    loading={deleteRemediation.isLoading}
                                    onClick={() =>
                                        deleteRemediation.mutate({
                                            remediationId: remediationId as string,
                                        })
                                    }
                                    type={'button'}
                                    grey={true}
                                    outline={true}
                                />
                            ) : (
                                <span />
                            )}
                            {hasManageRelationshipGrant ? (
                                <Button
                                    label='Mark as Complete'
                                    disabled={
                                        markAsComplete.isLoading || deleteRemediation.isLoading
                                    }
                                    loading={markAsComplete.isLoading}
                                    onClick={() =>
                                        markAsComplete.mutate({
                                            remediationId: remediationId as string,
                                        })
                                    }
                                    type={'button'}
                                />
                            ) : null}
                        </div>
                    )}
                </form>
            </FormProvider>
        </Modal>
    )
}

export default ViewRemediation
