import { Route } from 'react-router-dom'

import Reports from '@features/reports/layouts/Reports'

import CreateStatisticalReturn from './views/CreateStatisticalReturn'
import ListStatisticalReturns from './views/ListStatisticalReturns'

export const StatisticalReturnRoutes = () => {
    return (
        <Route
            path='statistical-returns'
            element={
                <Reports>
                    <ListStatisticalReturns />
                </Reports>
            }
        >
            <Route
                path='create'
                element={<CreateStatisticalReturn />}
            />
        </Route>
    )
}
