import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import { FormProvider, useForm } from 'react-hook-form'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'

import { env } from '@utils/env'

import { useSwapTokenTwoFactorAuth } from '../hooks'

import styles from '@styles/Form.module.scss'
import PinInput from '@components/inputs/pinInput/PinInput'
import { useEffect } from 'react'

const Add = () => {
    const form = useForm()

    const {
        handleSubmit,
        formState: { errors, isSubmitSuccessful, isValid },
    } = form

    const { mutate, isLoading, isError } = useSwapTokenTwoFactorAuth(data => {
        Cookies.set('tokenString', data, {
            domain: env.REACT_APP_DOMAIN as string,
        })
        window.location.reload()
    })

    const onSubmit = data => mutate(data)

    useEffect(() => {
        if (isSubmitSuccessful) {
            form.reset()
        }
    }, [isSubmitSuccessful])

    useEffect(() => {
        if (isError) {
            form.resetField('twoFactorAuthToken')
        }
    }, [isError, form])

    return (
        <>
            <Helmet>
                <title>Proofdesk Login</title>
            </Helmet>
            <FormProvider {...form}>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <h2>Two Factor Auth Code</h2>
                    {isError && (
                        <p className={styles.error}>
                            There was an error submitting the form, please try again.
                        </p>
                    )}

                    <InputWrapper
                        input={
                            <PinInput
                                name={'twoFactorAuthToken'}
                                autoFocus={true}
                                disabled={isLoading}
                                required
                            />
                        }
                        errors={errors.twoFactorAuthToken}
                    />
                    <Button
                        label={'Confirm Two Factor Auth'}
                        loading={isLoading}
                        disabled={!isValid}
                        fullWidth={true}
                    />
                </form>
            </FormProvider>
        </>
    )
}

export default Add
