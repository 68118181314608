import moment from 'moment'
import { useMemo, useState } from 'react'
import { Download, Plus } from 'react-feather'
import { Helmet } from 'react-helmet'
import { NavLink, Outlet } from 'react-router-dom'

import FileNameWithIcon from '@components/fileNameWithIcon/FileNameWithIcon'
import Table from '@components/table/Table'

import downloadFile from '@utils/downloadFile'

import { useDownloadStatisticalReturn, useGetStatisticalReturns } from '../hooks'

const ListStatisticalReturns = () => {
    const [fileName, setFileName] = useState('')

    const columns = useMemo(
        () => [
            {
                Header: 'File Name',
                accessor: 'fileName',
                Cell: props => <FileNameWithIcon fileName={props.value} />,
            },
            {
                Header: 'Date Created',
                accessor: 'createDate',
                Cell: props => (
                    <div>
                        {moment(props.value).format('Do MMMM YYYY - HH:mm ')}
                        <small>({moment(props.value).fromNow()})</small>
                    </div>
                ),
            },
            {
                Header: 'Actions',
                accessor: 'id',
                Cell: props => (
                    <button
                        onClick={() => setFileName(props.row.original.fileName)}
                        className='link dark'
                    >
                        <Download size={16} />
                        Download
                    </button>
                ),
            },
        ],
        []
    )

    useDownloadStatisticalReturn(fileName, {
        enabled: !!fileName,
        onSuccess: file => {
            downloadFile(file, fileName)
            setFileName('')
        },
    })

    const statisticalReturns = useGetStatisticalReturns()

    return (
        <>
            <Outlet />

            <Helmet>
                <title>Statistical Returns | Proofdesk</title>
            </Helmet>

            <div className='heading'>
                <div>
                    <h1>Statistical Returns</h1>
                </div>

                <NavLink
                    to={'/reporting/statistical-returns/create'}
                    className='link dark'
                >
                    <Plus size={16} />
                    Generate Return
                </NavLink>
            </div>

            {statisticalReturns.isLoading ||
            statisticalReturns.isError ||
            statisticalReturns.data?.length ? (
                <Table
                    isLoading={statisticalReturns.isLoading}
                    button={null}
                    columns={columns}
                    data={statisticalReturns.data ?? []}
                    defaultPageSize={50}
                    queryError={statisticalReturns.isError}
                />
            ) : (
                <div className={'nothingToShow'}>
                    <p>You haven't created any statistical returns yet.</p>
                </div>
            )}
        </>
    )
}

export default ListStatisticalReturns
