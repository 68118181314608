import { trpc } from '@utils/trpc'

export const useGetEnquiries = () =>
    trpc.enquiries.getAll.useQuery(undefined, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    })

export const useCreateEnquiry = onSuccess => {
    const context = trpc.useContext()

    return trpc.enquiries.create.useMutation({
        onSuccess,
        onSettled: () => context.enquiries.invalidate(),
    })
}

export const useGetEnquiryById = (id, onSuccess) =>
    trpc.enquiries.getById.useQuery({ id }, { enabled: !!id, onSuccess })

export const useGetEnquiryVersionById = (id, onSuccess) =>
    trpc.enquiries.getVersionById.useQuery({ id }, { enabled: !!id, onSuccess })

export const useCreateEnquiryVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.enquiries.createVersion.useMutation({
        onSuccess,
        onSettled: () => context.enquiries.invalidate(),
    })
}

export const useUpdateEnquiryVersion = () => {
    const context = trpc.useContext()

    return trpc.enquiries.updateVersion.useMutation({
        onSuccess: () => context.enquiries.invalidate(),
    })
}

export const useSignEnquiryVersion = () => {
    const context = trpc.useContext()

    return trpc.enquiries.signVersion.useMutation({
        onSettled: () => context.enquiries.invalidate(),
    })
}

export const useRemoveEnquiryVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.enquiries.removeVersion.useMutation({
        onSuccess,
        onSettled: () => context.enquiries.invalidate(),
    })
}
