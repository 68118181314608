import Menu from '../menu/Menu'
import OrganisationSwitcher from '../organisationSwitcher/OrganisationSwitcher'
import ProfileMenu from '../profileMenu/ProfileMenu'

import styles from './MenuBar.module.scss'

const MenuBar = ({ disabledNav }) => {
    return (
        <div className={styles.sidebar}>
            <div>
                <img
                    src={'/images/logo.svg'}
                    alt='Proofdesk'
                />
                {!disabledNav ? <Menu disabled={disabledNav} /> : null}
            </div>
            <div>
                <OrganisationSwitcher />
                <ProfileMenu />
            </div>
        </div>
    )
}

export default MenuBar
