import { TerminationReasons } from '@prisma/client'
import { useFormContext } from 'react-hook-form'

import InputWrapper from '@components/inputWrapper/InputWrapper'
import AutoGrowTextArea from '@components/inputs/autoGrowTextArea/AutoGrowTextArea'
import DateInput from '@components/inputs/dateInput/DateInput'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'

import pascalToReadable from '@utils/pascalToReadable'

const TerminationForm = ({ readOnly }: { readOnly: boolean }) => {
    const {
        formState: { errors },
    } = useFormContext()

    return (
        <>
            <TextInput
                hidden
                name={'id'}
            />

            <InputWrapper
                label={'Date of Termination'}
                description={'The date the relationship will be terminated.'}
                input={
                    <DateInput
                        name={'dateOfTermination'}
                        placeholder={''}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                readOnly={readOnly}
                errors={errors.dateOfTermination}
            />

            <InputWrapper
                label={'Reason for Termination'}
                description={'The reason the relationship is being terminated.'}
                input={
                    <Select
                        name={'reason'}
                        options={Object.keys(TerminationReasons).map(key => ({
                            value: key,
                            label: pascalToReadable(key),
                        }))}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                readOnly={readOnly}
                errors={errors.reason}
            />

            <InputWrapper
                label={'Additional Information'}
                description={'Any additional information about the termination.'}
                input={
                    <AutoGrowTextArea
                        name={'notes'}
                        readOnly={readOnly}
                        medium={true}
                    />
                }
                readOnly={readOnly}
                errors={errors.notes}
            />
        </>
    )
}

export default TerminationForm
