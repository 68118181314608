import { Grants } from '@prisma/client'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import AddControllingParties from './views/AddControllingParties'
import CreateEntity from './views/CreateEntity'

export const CreateEntityRoutes = () => {
    const hasManageEntityGrant = useCheckGrants(Grants.ManageEntity)

    if (!hasManageEntityGrant)
        return (
            <Route
                path='create'
                element={<Navigate to='/entities' />}
            />
        )

    return (
        <>
            <Route
                path='create'
                element={<CreateEntity />}
            />
            <Route
                path='create/:id/controlling-parties'
                element={<AddControllingParties />}
            />
        </>
    )
}
