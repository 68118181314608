import classNames from 'classnames'

import styles from './GroupPlaceholder.module.scss'

const GroupPlaceholder = ({ text, large }: { text: string; large: boolean }) => {
    return (
        <div
            className={classNames({
                [styles.groupPlaceholder]: true,
                [styles.large]: large,
            })}
        >
            <p>{text}</p>
        </div>
    )
}

export default GroupPlaceholder
