import { Grants } from '@prisma/client'
import { AlertTriangle, Check, Clock, Plus } from 'react-feather'
import { NavLink } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import Tabs from '@components/tabs/Tabs'

import { useGetRelationships } from '../hooks'

const Relationships = ({ children }) => {
    const relationships = useGetRelationships()
    const hasManageRelationshipGrant = useCheckGrants(Grants.ManageRelationship)

    const onboarding = relationships.data?.filter(
        relationship => relationship.status === 'Onboarding'
    )
    const active = relationships.data?.filter(relationship => relationship.status === 'Active')
    const terminated = relationships.data?.filter(
        relationship =>
            relationship.status === 'Terminated' ||
            relationship.status === 'Declined' ||
            relationship.status === 'Closed'
    )

    return (
        <>
            <div className='heading withTabs'>
                <div>
                    <h1>Relationships</h1>
                </div>

                {hasManageRelationshipGrant ? (
                    <NavLink
                        to={'/relationships/create'}
                        className='link dark'
                    >
                        <Plus size={16} />
                        Establish Relationship
                    </NavLink>
                ) : null}
            </div>
            <Tabs
                tabs={[
                    {
                        label: 'All Relationships',
                        link: '/relationships',
                        count: relationships.data?.length,
                    },
                    {
                        icon: (
                            <Clock
                                size={18}
                                color={'#E78829'}
                            />
                        ),
                        label: 'Onboarding',
                        link: '/relationships/?status=onboarding',
                        count: onboarding?.length,
                        evaluateWithPath: true,
                    },
                    {
                        icon: (
                            <Check
                                size={18}
                                color={'#295FE7'}
                            />
                        ),
                        label: 'Active',
                        link: '/relationships/?status=active',
                        count: active?.length,
                        evaluateWithPath: true,
                    },
                    {
                        icon: (
                            <AlertTriangle
                                size={18}
                                color={'#E53E3E'}
                            />
                        ),
                        label: 'Terminated',
                        link: '/relationships/?status=terminated',
                        count: terminated?.length,
                        evaluateWithPath: true,
                    },
                ]}
            />

            <main className={'top'}>{children}</main>
        </>
    )
}

export default Relationships
