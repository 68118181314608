import { useController, useFormContext } from 'react-hook-form'

import Button from '@components/button/Button'
import FileStoreImage from '@components/fileStore/FileStoreImage'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import DateInput from '@components/inputs/dateInput/DateInput'
import FileUpload from '@components/inputs/fileUpload/FileUpload'
import RiskLevelSelect from '@components/inputs/riskLevelSelect/RiskLevelSelect'
import TextInput from '@components/inputs/textInput/TextInput'

import styles from '@styles/Form.module.scss'

const ExternalCustomerRiskAssessmentForm = ({ readOnly }: { readOnly?: boolean }) => {
    const {
        control,
        formState: { errors },
        getValues,
    } = useFormContext()

    const fileName = useController({
        control,
        name: 'fileName',
    })

    return (
        <>
            <TextInput
                hidden
                name={'customerRiskAssessmentVersionId'}
                defaultValue={getValues('id')}
            />

            <InputWrapper
                label={'Risk Classification'}
                description={
                    'Select risk classification proposed within the attached customer risk assessment.'
                }
                input={
                    <RiskLevelSelect
                        name={'riskLevelId'}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                readOnly={readOnly}
                errors={errors.riskLevelId}
            />

            <InputWrapper
                label={'Date'}
                description={'Enter the date the attached customer risk assessment was signed.'}
                input={
                    <DateInput
                        name={'date'}
                        placeholder={''}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                readOnly={readOnly}
                errors={errors.date}
            />

            <InputWrapper
                label={'Attachment'}
                input={
                    readOnly ? (
                        <div className={styles.attachment}>
                            <div>
                                <FileStoreImage fileName={fileName.field.value} />
                            </div>
                        </div>
                    ) : getValues('fileName') ? (
                        <div className={styles.attachment}>
                            <div>
                                <FileStoreImage fileName={fileName.field.value} />
                            </div>
                            <Button
                                outline={true}
                                label={'Remove'}
                                onClick={() => fileName.field.onChange(null)}
                                type={'button'}
                            />
                        </div>
                    ) : (
                        <FileUpload
                            onUpload={fileName.field.onChange}
                            acceptDocuments={true}
                        />
                    )
                }
                readOnly={readOnly}
            />
        </>
    )
}

export default ExternalCustomerRiskAssessmentForm
