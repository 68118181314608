import { trpc } from '@utils/trpc'

export const useGetRelationships = () => trpc.relationships.getAll.useQuery()

export const useCreateRelationship = onSuccess => {
    const context = trpc.useContext()

    return trpc.relationships.create.useMutation({
        onSuccess,
        onSettled: async () => {
            await context.relationships.invalidate()
        },
    })
}
