import classNames from 'classnames'

import styles from './ProgressBar.module.scss'

const ProgressBar = ({ progress, blue }: { progress: number; blue: boolean }) => {
    return (
        <div
            className={classNames({
                [styles.progressBar]: true,
                [styles.blue]: blue,
            })}
        >
            <p>{progress.toFixed(0)}%</p>
            <div className={styles.bar}>
                <span style={{ width: `${progress}%` }} />
            </div>
        </div>
    )
}

export default ProgressBar
