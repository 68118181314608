import { Search as Icon, X } from 'react-feather'

import styles from './Search.module.scss'
import classNames from 'classnames'

const Search = ({ search, setSearch }) => {
    return (
        <div className={styles.search}>
            <Icon
                size={20}
                color={'#838588'}
            />
            <input
                type={'text'}
                value={search}
                onChange={e => setSearch(e.target.value)}
            />
            <X
                className={classNames({
                    [styles.clear]: true,
                    [styles.isShowing]: search != '',
                })}
                size={20}
                color={'#838588'}
                onClick={() => setSearch('')}
            />
        </div>
    )
}

export default Search
