import classNames from 'classnames'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Check, UploadCloud } from 'react-feather'

import { useUploadFile } from '@features/entities/entity/documents/hooks'

import styles from './FileUpload.module.scss'

function arrayBufferToBase64(arrayBuffer) {
    const uint8Array = new Uint8Array(arrayBuffer)
    let binaryString = uint8Array.reduce((prev, curr) => prev + String.fromCharCode(curr), '')
    return btoa(binaryString)
}

const FileUpload = ({
    onUpload,
    acceptImages = false,
    acceptDocuments = false,
}: {
    onUpload: Function
    acceptImages?: boolean
    acceptDocuments?: boolean
}) => {
    const [uploaded, setUploaded] = useState(null)
    const [loading, setLoading] = useState(false)

    const { mutate } = useUploadFile(data => {
        setLoading(false)
        setUploaded(data.fileName)
        onUpload(data.fileName)
    })

    const onDrop = useCallback(async acceptedFiles => {
        setLoading(true)

        const reader = new FileReader()

        reader.onload = async () => {
            const buffer = reader.result as ArrayBuffer
            const base64String = arrayBufferToBase64(buffer)

            if (buffer) {
                await mutate({
                    base64String,
                    mimeType: acceptedFiles[0].type,
                })
            }
        }

        reader.readAsArrayBuffer(acceptedFiles[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            ...(acceptImages && { 'image/*': ['.png', '.jpg', '.jpeg', '.gif'] }),
            ...(acceptDocuments && { 'application/pdf': ['.pdf'] }),
        },
    })

    if (loading)
        return (
            <div className={styles.fileUpload}>
                <img
                    src='/images/loading-black.svg'
                    alt={'Loading...'}
                />
                <p>Uploading File...</p>
            </div>
        )

    if (uploaded)
        return (
            <div className={styles.fileUpload}>
                <Check size={30} />
                <p>File Uploaded</p>
            </div>
        )

    let acceptMessage = acceptImages ? 'Images (.png, .jpg, .jpeg, .gif)' : ''
    if (acceptDocuments) {
        if (acceptMessage) {
            acceptMessage += ' and '
        }
        acceptMessage += 'Documents (.pdf)'
    }

    return (
        <div
            className={classNames(styles.fileUpload, { [styles.active]: isDragActive })}
            {...getRootProps()}
        >
            <input {...(getInputProps() as any)} />
            <UploadCloud size={30} />
            <p>Upload File</p>
            <sub>{acceptMessage}</sub>
        </div>
    )
}

export default FileUpload
