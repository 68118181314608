import { includesAny } from '../../utils'
import { RegisteredForVatStatuses } from '@prisma/client'
import classNames from 'classnames'
import { useWatch } from 'react-hook-form'

import { useGetCountries } from '@features/countries'

import MultiSelect from '@components/inputs/multiSelect/MultiSelect'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'

import pascalToReadable from '@utils/pascalToReadable'

import IdentityComponentInput from '../components/IdentityComponentInput'

import styles from '../Identity.module.scss'

const Taxation = ({ entityType, control, readOnly }) => {
    const registeredForVat = useWatch({
        control,
        name: 'registeredForVat',
    })

    const countries = useGetCountries()
    if (!countries.data) return null

    if (!includesAny(entityType, ['registeredForVat', 'vatNumber', 'taxResidencies'])) return null

    return (
        <div
            className={classNames({
                [styles.identityComponents]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <div className={styles.heading}>
                <h2>Taxation</h2>
                {/*<p>*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                {/*    incididunt.*/}
                {/*</p>*/}
            </div>

            <div className={styles.form}>
                {includesAny(entityType, ['registeredForVat', 'vatNumber']) ? (
                    <div className={styles.row}>
                        <IdentityComponentInput
                            entityType={entityType}
                            name={'registeredForVat'}
                            input={
                                <Select
                                    name={'registeredForVat'}
                                    options={Object.keys(RegisteredForVatStatuses).map(key => ({
                                        value: key,
                                        label: pascalToReadable(key),
                                    }))}
                                    readOnly={readOnly}
                                />
                            }
                            readOnly={readOnly}
                        />

                        {registeredForVat === RegisteredForVatStatuses.Yes ? (
                            <div className={styles.row}>
                                <IdentityComponentInput
                                    entityType={entityType}
                                    name={'vatNumber'}
                                    input={
                                        <TextInput
                                            name={'vatNumber'}
                                            readOnly={readOnly}
                                        />
                                    }
                                    readOnly={readOnly}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}

                <div className={styles.row}>
                    <IdentityComponentInput
                        entityType={entityType}
                        name={'taxResidencies'}
                        input={
                            <MultiSelect
                                name={'taxResidencies'}
                                isSearchable
                                options={countries.data.map(country => ({
                                    value: country.code,
                                    label: country.name,
                                }))}
                                readOnly={readOnly}
                            />
                        }
                        readOnly={readOnly}
                    />
                </div>
            </div>
        </div>
    )
}

export default Taxation
