import { Legislation } from '@proofdesk/common'
import { Helmet } from 'react-helmet'

import { useGetOrganisation } from '@features/organisation'
import OrganisationLogoUpload from '@features/organisation/settings/OrganisationLogoUpload'

import pascalToReadable from '@utils/pascalToReadable'

const OrganisationSettings = () => {
    const organisation = useGetOrganisation(null, {})

    if (!organisation.data) return null

    return (
        <>
            <Helmet>
                <title>Organisation Settings | Proofdesk</title>
            </Helmet>

            <div className='heading'>
                <div>
                    <h1>Organisation Settings</h1>
                </div>
            </div>

            <div className='preview'>
                <div>
                    <p>Organisation Name</p>
                    <p>{organisation.data.name}</p>
                </div>
                <div>
                    <p>Jurisdiction</p>
                    <p>{pascalToReadable(organisation.data.jurisdiction)}</p>
                </div>
                <div>
                    <p>Regulated Activities</p>
                    {organisation.data.regulatedActivities.map((activity, index) => (
                        <>
                            <p>
                                {Legislation[activity]?.legislation} -{' '}
                                {Legislation[activity]?.class} ({Legislation[activity]?.description}
                                )
                            </p>
                            {index + 1 !== organisation.data?.regulatedActivities.length ? (
                                <span />
                            ) : null}
                        </>
                    ))}
                </div>
                <div>
                    <p>Logo</p>
                    <OrganisationLogoUpload />
                </div>
            </div>
        </>
    )
}

export default OrganisationSettings
