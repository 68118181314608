import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useGetSelf } from '@features/account'
import NotificationCheckbox from '@features/account/components/notificationCheckbox/NotificationCheckbox'
import { useUpdateSelf } from '@features/account/hooks'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import Checkbox from '@components/inputs/checkbox/Checkbox'

import styles from './NotificationSettings.module.scss'

const NotificationSettings = () => {
    const navigate = useNavigate()
    const form = useForm()
    const { handleSubmit, reset, control, getValues } = form
    const user = useGetSelf(data => reset(data))

    const notificationsDisabled = useWatch({
        control,
        name: 'notificationsDisabled',
    })

    const { mutate, isLoading } = useUpdateSelf(() => navigate(`/account/general-settings`))
    const onSubmit = data => mutate(data)

    if (user.isLoading)
        return (
            <Modal
                overlay={true}
                heading={'Notification Settings'}
                close={`/account/general-settings`}
                large={true}
                loading={true}
            />
        )

    return (
        <Modal
            overlay={true}
            heading={'Notification Settings'}
            close={`/account/general-settings`}
            large={true}
            loading={!user.data}
            error={user.isError}
        >
            <FormProvider {...form}>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Checkbox
                        name={'notificationsDisabled'}
                        label={'Disable all email notifications from Proofdesk.'}
                        defaultValue={getValues('notificationsDisabled')}
                    />

                    {!notificationsDisabled ? (
                        <div className={styles.group}>
                            <div className={styles.header}>
                                <h3>Relationship Notifications</h3>
                                <p>
                                    Select which of the following events you wish to be notified of.
                                </p>
                            </div>
                            <div className={styles.checkboxes}>
                                <NotificationCheckbox
                                    name={'RelationshipSubmitted'}
                                    description={'A relationship is submitted for approval.'}
                                />
                                <NotificationCheckbox
                                    name={'RelationshipTerminated'}
                                    description={
                                        'A relationship is declined, terminated or closed.'
                                    }
                                />

                                <NotificationCheckbox
                                    name={'RelationshipRequiresRemediation'}
                                    description={'A relationship requires remediation.'}
                                />

                                <NotificationCheckbox
                                    name={'RelationshipRemediationCompleted'}
                                    description={'A relationship has been remediated.'}
                                />

                                <NotificationCheckbox
                                    name={'RelationshipApproved'}
                                    description={'A relationship is approved.'}
                                />

                                <NotificationCheckbox
                                    name={'RelationshipRequiresCounterSignature'}
                                    description={'A relationship requires a counter signature.'}
                                />
                            </div>
                        </div>
                    ) : null}

                    <Button
                        label={'Save Changes'}
                        fullWidth={true}
                        loading={isLoading}
                    />
                </form>
            </FormProvider>
        </Modal>
    )
}

export default NotificationSettings
