const downloadFile = (file: { base64String: string; mimeType?: string }, fileName: string) => {
    const { base64String, mimeType } = file
    const byteCharacters = atob(base64String)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)

    const blob = new Blob([byteArray], { type: mimeType })

    // Create a URL for the blob object
    const blobUrl = URL.createObjectURL(blob)

    // Create a temporary <a> element and trigger the download
    const link = document.createElement('a')
    link.href = blobUrl
    link.download = fileName
    document.body.appendChild(link) // Append to the document
    link.click() // Simulate click to start download

    // Clean up
    document.body.removeChild(link) // Remove the link
    URL.revokeObjectURL(blobUrl) // Release object URL
}

export default downloadFile
