import classNames from 'classnames'
import { Plus, X } from 'react-feather'

import OptionRiskLevel from '@features/riskAssessmentPolicies/components/OptionRiskLevel'

import AutoGrowTextArea from '@components/inputs/autoGrowTextArea/AutoGrowTextArea'

import styles from './Option.module.scss'

const Option = ({ name, readOnly, add, remove }) => {
    return (
        <div
            className={classNames({
                [styles.option]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <AutoGrowTextArea
                name={`${name}.title`}
                readOnly={readOnly}
            />
            <OptionRiskLevel
                name={`${name}.riskLevelId`}
                readOnly={readOnly}
            />

            <button
                className={styles.add}
                onClick={add}
                type={'button'}
            >
                <Plus
                    color={'#ffffff'}
                    size={14}
                />
                Option
            </button>
            {remove ? (
                <button
                    className={styles.remove}
                    onClick={remove}
                    type={'button'}
                >
                    <X
                        color={'#ffffff'}
                        size={16}
                    />
                </button>
            ) : null}
        </div>
    )
}

export default Option
