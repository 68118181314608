import classNames from 'classnames'

import Actions from '@components/document/Actions'
import Title from '@components/document/Title'

import { Action } from './Document'

import styles from './Document.module.scss'

const Header = ({
    isAllowed,
    isNestedInsideRelationship,
    showBreadcrumbs,
    breadcrumbs,
    title,
    versionId,
    versions,
    route,
    isDraft,
    isPending,
    exportPdf,
    create,
    history,
    deleteAction,
}: {
    isAllowed: boolean
    isNestedInsideRelationship?: boolean
    showBreadcrumbs: boolean
    breadcrumbs: { label: any; link: string }[]
    title: string
    versionId: string
    versions?: any[]
    route?: string
    isDraft: boolean
    isPending: boolean
    exportPdf?: Action
    create?: Action
    history?: {
        label: string
        value: string
        isDraft: boolean
        isPending: boolean
    }[]
    deleteAction?: Action
}) => {
    return (
        <div
            className={classNames({
                [styles.header]: true,
                [styles.isNestedInsideRelationship]: isNestedInsideRelationship,
            })}
        >
            <div className={'container'}>
                {!isNestedInsideRelationship ? (
                    <Title
                        showBreadcrumbs={showBreadcrumbs}
                        breadcrumbs={breadcrumbs}
                        title={title}
                    />
                ) : (
                    <span />
                )}
                <Actions
                    isAllowed={isAllowed}
                    versionId={versionId}
                    versions={versions}
                    route={route}
                    isDraft={isDraft}
                    isPending={isPending}
                    exportPdf={exportPdf}
                    create={create}
                    history={history}
                    deleteAction={deleteAction}
                />
            </div>
        </div>
    )
}

export default Header
