import { FormStatuses, Grants } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import Document from '@components/document/Document'
import EntityLabel from '@components/entity/EntityLabel'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import {
    useCreateControllingPartyVersion,
    useExportControllingPartyVersionToPdf,
    useGetControllingPartyById,
    useGetControllingPartyVersionById,
    useRemoveControllingPartyVersion,
    useSignControllingPartyVersion,
    useUpdateControllingPartyVersion,
} from '../hooks'

import ControllingPartyForm from './ControllingPartyForm'

const ControllingPartyVersion = ({
    isNestedInsideRelationship,
}: {
    isNestedInsideRelationship: boolean
}) => {
    const isAllowed = useCheckGrants(Grants.ManageEntity) ?? false
    const navigate = useNavigate()
    const { entityId, controllingPartyId, controllingPartyVersionId } = useParams()
    const route = `../controlling-parties/${controllingPartyId}`

    const form = useForm()
    const {
        reset,
        trigger,
        getValues,
        formState: { isDirty },
    } = form

    const controllingParty = useGetControllingPartyById(controllingPartyId, () => {})
    const controllingPartyVersion = useGetControllingPartyVersionById(
        controllingPartyVersionId,
        data => reset(data)
    )

    const exportVersion = useExportControllingPartyVersionToPdf(data => window.open(data, '_blank'))
    const createVersion = useCreateControllingPartyVersion(data =>
        navigate(route, { replace: true })
    )
    const updateVersion = useUpdateControllingPartyVersion()
    const signVersion = useSignControllingPartyVersion()
    const removeVersion = useRemoveControllingPartyVersion(() => navigate(route))

    const exportPdf = () => exportVersion.mutate({ id: controllingPartyVersionId as string })

    const create = () => createVersion.mutate({ id: controllingPartyId as string })

    const update = async data => updateVersion.mutateAsync(data)
    const sign = async () => {
        try {
            const isValid = await trigger()
            if (!isValid) return null
            if (isDirty) await update(getValues())
            return signVersion.mutate({ id: controllingPartyVersionId as string })
        } catch {
            return null
        }
    }

    const remove = () => removeVersion.mutate({ id: controllingPartyVersionId as string })

    if (controllingParty.isLoading || controllingPartyVersion.isLoading) return <Loading />
    if (
        controllingParty.isError ||
        controllingPartyVersion.isError ||
        !controllingParty.data ||
        !controllingPartyVersion.data
    )
        return <QueryError />

    return (
        <FormProvider {...form}>
            <Document
                isAllowed={isAllowed}
                isNestedInsideRelationship={isNestedInsideRelationship}
                pageTitle={`${controllingPartyVersion.data.entity1?.name} | ${controllingPartyVersion.data.entity2?.name}`}
                showHeader={true}
                landscape={false}
                type={'Controlling Party'}
                showBreadcrumbs={true}
                breadcrumbs={[
                    {
                        label: (
                            <EntityLabel
                                id={controllingPartyVersion.data.entity2?.id}
                                name={controllingPartyVersion.data.entity2?.name}
                                entityType={controllingPartyVersion.data.entity2?.entityType}
                                clickable={true}
                            />
                        ),
                        link: `/entities/${entityId}`,
                    },
                    {
                        label: 'Controlling Parties',
                        link: `/entities/${entityId}/controlling-parties`,
                    },
                ]}
                title={controllingPartyVersion.data.entity1?.name || 'Controlling Party'}
                versions={controllingParty.data.versions}
                isDraft={controllingPartyVersion.data.status === FormStatuses.Draft}
                isPending={false}
                versionId={controllingPartyVersion.data.id}
                versionName={`Version ${controllingPartyVersion.data.version}`}
                route={`${route}/versions`}
                Form={ControllingPartyForm}
                exportPdf={{
                    function: exportPdf,
                    isLoading: exportVersion.isLoading,
                }}
                create={{
                    function: create,
                    isLoading: createVersion.isLoading,
                }}
                update={{
                    function: update,
                    isLoading: updateVersion.isLoading,
                    isSuccess: updateVersion.isSuccess,
                }}
                sign={{
                    function: sign,
                    isLoading: signVersion.isLoading,
                }}
                remove={
                    controllingParty.data.versions.length > 1
                        ? {
                              function: remove,
                              isLoading: removeVersion.isLoading,
                          }
                        : undefined
                }
                signature={{
                    user: controllingPartyVersion.data.signature?.user as {
                        firstName: string
                        lastName: string
                    },
                    date: controllingPartyVersion.data.signature?.createDate as Date,
                }}
                history={controllingParty.data.versions.map(version => ({
                    label: `Version ${version.version}`,
                    value: version.id,
                    isDraft: version.status === FormStatuses.Draft,
                    isPending: false,
                }))}
                error={
                    exportVersion.isError ||
                    createVersion.isError ||
                    updateVersion.isError ||
                    signVersion.isError ||
                    removeVersion.isError
                }
            />
        </FormProvider>
    )
}

export default ControllingPartyVersion
