import classNames from 'classnames'
import fuzzy from 'fuzzy'
import { useController, useFormContext } from 'react-hook-form'
import Select, { components } from 'react-select'

import { useGetRiskLevels } from '@features/riskLevels'

import styles from './OptionRiskLevel.module.scss'

const OptionRiskLevel = ({ name, readOnly }) => {
    const { control } = useFormContext()
    const { field } = useController({
        control,
        name,
    })

    const riskLevels = useGetRiskLevels()

    const options =
        riskLevels.data?.map(riskLevel => ({
            value: riskLevel.id,
            label: riskLevel.name,
            colour: riskLevel.latestSignedVersion
                ? riskLevel.latestSignedVersion.colour
                : riskLevel.versions[0]?.colour,
        })) ?? []

    if (!riskLevels.data) return null

    return (
        <Select
            className={classNames(
                {
                    select: true,
                    disabled: readOnly,
                },
                styles.optionRiskLevel
            )}
            isDisabled={readOnly}
            isSearchable={false}
            placeholder={''}
            components={{
                Option: props => {
                    return (
                        <components.Option {...props}>
                            <div
                                className={styles.option}
                                style={{ background: props.data.colour }}
                            >
                                {props.label}
                            </div>
                        </components.Option>
                    )
                },
                SingleValue: props => (
                    <components.SingleValue {...props}>
                        <div className={styles.singleValue}>
                            <span style={{ background: props.getValue()[0]?.colour }} />
                            {props.getValue()[0]?.label}
                        </div>
                    </components.SingleValue>
                ),
            }}
            value={options.find(({ value }) => value === field.value)}
            onChange={option => field.onChange(option?.value)}
            filterOption={(candidate, input) => {
                if (input) return fuzzy.test(input, candidate.label)
                return true
            }}
            options={options}
        />
    )
}

export default OptionRiskLevel
