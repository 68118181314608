import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Controller, useFormContext } from 'react-hook-form'

import { useGetSelfUserMemberships } from '@features/organisation/teamMembers'

import InputWrapper from '@components/inputWrapper/InputWrapper'
import DateInput from '@components/inputs/dateInput/DateInput'
import TextInput from '@components/inputs/textInput/TextInput'
import UserSelect from '@components/inputs/userSelect/UserSelect'
import WYSIWYG from '@components/inputs/wysiwyg/WYSIWYG'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import styles from '@styles/Form.module.scss'

const CustomPolicyForm = ({ readOnly = false }) => {
    const {
        formState: { errors },
        control,
    } = useFormContext()

    const users = useGetSelfUserMemberships()

    if (users.isLoading) return <Loading />
    if (users.isError || !users.data) return <QueryError isInsideModal={true} />

    return (
        <>
            <TextInput
                hidden
                name='id'
            />

            <TextInput
                hidden
                name='customPolicyId'
            />

            {!readOnly ? (
                <div className={styles.row}>
                    <InputWrapper
                        label='Policy Name'
                        input={
                            <TextInput
                                name={'policyName'}
                                required
                                readOnly={readOnly}
                            />
                        }
                        errors={errors.policyName}
                        readOnly={readOnly}
                    />

                    <InputWrapper
                        label='Policy Version'
                        input={
                            <TextInput
                                name={'versionName'}
                                required
                                readOnly={readOnly}
                            />
                        }
                        errors={errors.versionName}
                        readOnly={readOnly}
                    />
                </div>
            ) : null}

            <InputWrapper
                label=' '
                input={
                    <Controller
                        name='content'
                        control={control}
                        defaultValue={''}
                        render={({ field }) => (
                            <WYSIWYG
                                value={field.value}
                                onChange={field.onChange}
                                readOnly={readOnly}
                            />
                        )}
                    />
                }
                errors={errors.content}
                readOnly={false}
            />

            <InputWrapper
                label='Owner'
                input={
                    <UserSelect
                        users={users.data.map(user => user.user)}
                        name={'ownerUserId'}
                        required={true}
                        readOnly={readOnly}
                    />
                }
                errors={errors.ownerUserId}
                readOnly={readOnly}
            />

            <InputWrapper
                label='Next Review Date'
                input={
                    <DateInput
                        name={'nextReviewDate'}
                        placeholder={''}
                        readOnly={readOnly}
                        required
                    />
                }
                errors={errors.nextReviewDate}
                readOnly={readOnly}
            />
        </>
    )
}

export default CustomPolicyForm
