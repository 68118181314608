// Insert a space before capital letters. Ignore the first letter. Also ignore capital letters preceded by another capital letter.
const pascalToReadable = (string: string) => {
    if (!string) return string

    string = string.replace(/(?<!^[A-Z])(?<![A-Z])([A-Z])/g, ' $1').trim()
    string = string.charAt(0).toUpperCase() + string.slice(1)
    return string
}

export default pascalToReadable
