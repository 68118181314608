import { Route, Routes } from 'react-router-dom'

import AcceptInvitation from './view/AcceptInvitation'

export const AcceptInvitationRoutes = () => {
    return (
        <Routes>
            <Route
                path=''
                element={<AcceptInvitation />}
            />
        </Routes>
    )
}
