import { useMemo } from 'react'
import { Plus } from 'react-feather'
import { Helmet } from 'react-helmet'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'

import Table from '@components/table/Table'
import VersionStatusTag from '@components/versionStatusTag/VersionStatusTag'

import { useGetEnquiries } from '../hooks'

const ListEnquiries = () => {
    const navigate = useNavigate()
    const link = props => navigate(`/reporting/enquiries/${props.original.id}`)

    const columns = useMemo(
        () => [
            {
                Header: 'Internal Reference',
                accessor: 'internalReference',
            },
            {
                Header: 'Latest Version',
                accessor: 'latestVersion.status',
                Cell: props => (
                    <VersionStatusTag status={props.row.original.latestVersion.status} />
                ),
            },
        ],
        []
    )

    const enquiries = useGetEnquiries()

    return (
        <>
            <Outlet />

            <Helmet>
                <title>ML / TF Enquiries | Proofdesk</title>
            </Helmet>

            <div className='heading'>
                <div>
                    <h1>ML / TF Enquiries</h1>
                </div>

                <NavLink
                    to={'/reporting/enquiries/create-enquiry'}
                    className='link dark'
                >
                    <Plus size={16} />
                    New Enquiry
                </NavLink>
            </div>

            {enquiries.isLoading || enquiries.isError || enquiries.data?.length ? (
                <Table
                    isLoading={enquiries.isLoading}
                    button={null}
                    columns={columns}
                    data={enquiries.data ?? []}
                    defaultPageSize={50}
                    link={link}
                    queryError={enquiries.isError}
                />
            ) : (
                <div className={'nothingToShow'}>
                    <p>You haven't created any enquiries yet.</p>
                </div>
            )}
        </>
    )
}

export default ListEnquiries
