import { includesAny } from '../../utils'
import { PrincipalActivities } from '@prisma/client'
import classNames from 'classnames'

import Select from '@components/inputs/select/Select'

import pascalToReadable from '@utils/pascalToReadable'

import IdentityComponentInput from '../components/IdentityComponentInput'

import styles from '../Identity.module.scss'

const Activities = ({ entityType, register, readOnly }) => {
    if (!includesAny(entityType, ['principalActivity', 'descriptionOfBusinessUndertaken']))
        return null

    return (
        <div
            className={classNames({
                [styles.identityComponents]: true,
                [styles.readOnly]: readOnly,
            })}
        >
            <div className={styles.heading}>
                <h2>Activities</h2>
                {/*<p>*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                {/*    incididunt.*/}
                {/*</p>*/}
            </div>
            <div className={styles.form}>
                <IdentityComponentInput
                    entityType={entityType}
                    name={'principalActivity'}
                    input={
                        <Select
                            name={'principalActivity'}
                            options={Object.keys(PrincipalActivities).map(key => ({
                                value: key,
                                label: pascalToReadable(key),
                            }))}
                            readOnly={readOnly}
                        />
                    }
                    readOnly={readOnly}
                />

                <IdentityComponentInput
                    entityType={entityType}
                    name={'descriptionOfBusinessUndertaken'}
                    input={
                        <textarea
                            rows={5}
                            {...register('descriptionOfBusinessUndertaken')}
                            disabled={readOnly}
                        />
                    }
                    readOnly={readOnly}
                />
            </div>
        </div>
    )
}

export default Activities
