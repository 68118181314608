import { Grants } from '@prisma/client'
import { useEffect, useState } from 'react'

import { useGetOrganisation, useUpdateOrganisation } from '@features/organisation/hooks'
import { useCheckGrants } from '@features/user'

import Button from '@components/button/Button'
import FileStoreImage from '@components/fileStore/FileStoreImage'
import FileUpload from '@components/inputs/fileUpload/FileUpload'

import styles from './OrganisationLogoUpload.module.scss'

const OrganisationLogoUpload = ({}) => {
    const hasConfigurationGrant = useCheckGrants(Grants.ManageConfiguration)
    const [changeMode, setChangeMode] = useState(false)
    const organisation = useGetOrganisation(null, {})

    const { mutate, isLoading, error, isSuccess } = useUpdateOrganisation()

    useEffect(() => {
        if (isSuccess) {
            setChangeMode(false)
        }
    }, [isSuccess])

    const onUpload = (fileName: string) => {
        mutate({
            logoFileName: fileName,
        })
    }

    if (!organisation.data) return null

    if (!hasConfigurationGrant)
        return (
            <div className={styles.preview}>
                <FileStoreImage fileName={organisation.data?.logoFileName} />
            </div>
        )

    return (
        <div className={styles.organisationLogoUpload}>
            {error ? <p>{error.message}</p> : null}
            {organisation.data.logoFileName == null || changeMode ? (
                <div className={styles.upload}>
                    <FileUpload
                        onUpload={onUpload}
                        acceptImages
                    />
                    {changeMode ? (
                        <div className={styles.control}>
                            <Button
                                label='Cancel'
                                onClick={() => setChangeMode(false)}
                            />
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className={styles.preview}>
                    <FileStoreImage fileName={organisation.data?.logoFileName} />
                    <div className={styles.control}>
                        <Button
                            label='Change Logo'
                            onClick={() => setChangeMode(true)}
                            grey={true}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default OrganisationLogoUpload
