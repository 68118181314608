import CircularButton from '../CircularButton/CircularButton'
import { CornerDownRight } from 'react-feather'

import styles from './AddFirstChildButton.module.scss'

const AddFirstChildButton = ({ onClick }) => {
    return (
        <div className={styles.addFirstChildButton}>
            <CircularButton
                icon={<CornerDownRight size={12} />}
                onClick={onClick}
            />
        </div>
    )
}

export default AddFirstChildButton
