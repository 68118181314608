import UserAvatar from '@components/user/UserAvatar'

import styles from './ManyUserAvatars.module.scss'

type User = {
    firstName: string
    lastName: string
}

const ManyUserAvatars = ({ users }: { users: User[] }) => {
    return (
        <div className={styles.manyUserAvatars}>
            {users.map((user, index) => (
                <UserAvatar user={user} />
            ))}
        </div>
    )
}

export default ManyUserAvatars
