import { Helmet } from 'react-helmet'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useGetInvitation } from '@features/acceptInvitation'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import EmailInput from '@components/inputs/emailInput/EmailInput'
import PasswordInput from '@components/inputs/passwordInput/PasswordInput'
import TextInput from '@components/inputs/textInput/TextInput'

import { useCreateUser } from '../hooks'

import styles from '@styles/Form.module.scss'

const CreateAccount = () => {
    const [searchParams] = useSearchParams()

    const navigate = useNavigate()
    const form = useForm({
        shouldUnregister: true,
    })

    const {
        handleSubmit,
        formState: { errors },
        reset,
    } = form

    const invitation = useGetInvitation(searchParams.get('invitation'), data =>
        reset({ email: data.email })
    )

    const { mutate, isLoading, isError } = useCreateUser(data => {
        localStorage.setItem('id', data.id)
        localStorage.setItem('email', data.email)
        navigate('/create-account/success')
    })

    if (invitation.isFetching) return null

    const onSubmit = data =>
        mutate({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            timezone: data.timezone,
        })

    return (
        <>
            <Helmet>
                <title>Get Started with Proofdesk</title>
            </Helmet>
            <FormProvider {...form}>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <h2>
                        {invitation.data
                            ? `Join the ${invitation.data.organisation} team.`
                            : 'Create an Account'}
                    </h2>

                    {isError && (
                        <p className={styles.error}>
                            There was an error submitting the form, please try again.
                        </p>
                    )}

                    <TextInput
                        hidden
                        name={'timezone'}
                        defaultValue={'Europe/Isle_of_Man'}
                    />

                    <div className={styles.twoColumns}>
                        <InputWrapper
                            label={'First Name'}
                            input={
                                <TextInput
                                    name={'firstName'}
                                    required
                                    disabled={isLoading}
                                />
                            }
                            errors={errors.firstName}
                        />

                        <InputWrapper
                            label={'Last Name'}
                            input={
                                <TextInput
                                    name={'lastName'}
                                    required
                                    disabled={isLoading}
                                />
                            }
                            errors={errors.lastName}
                        />
                    </div>

                    <InputWrapper
                        label={'Email Address'}
                        input={
                            <EmailInput
                                name={'email'}
                                required
                                disabled={isLoading}
                            />
                        }
                        errors={errors.email}
                    />
                    <InputWrapper
                        label={'Password'}
                        input={
                            <PasswordInput
                                name={'password'}
                                required
                                disabled={isLoading}
                            />
                        }
                        errors={errors.password}
                    />

                    <InputWrapper
                        label={'Confirm Password'}
                        input={
                            <PasswordInput
                                name={'confirmPassword'}
                                required
                                disabled={isLoading}
                            />
                        }
                        errors={errors.confirmPassword}
                    />

                    <Button
                        label={'Create Account'}
                        loading={isLoading}
                        fullWidth={true}
                    />
                    <p>
                        By creating an account, you agree to our{' '}
                        <a
                            href={'https://help.proofdesk.co/legal/terms-of-use'}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            Terms of Use
                        </a>{' '}
                        and{' '}
                        <a
                            href={'https://help.proofdesk.co/legal/privacy-policy'}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            Privacy Policy
                        </a>
                        .
                    </p>
                </form>
            </FormProvider>
        </>
    )
}

export default CreateAccount
