import { Navigate, Route, Routes } from 'react-router-dom'

import { useGetSelf } from '@features/account'

import ChallengeTwoFactorAuth from './views/ChallengeTwoFactorAuth'
import SetTwoFactorAuth from './views/SetTwoFactorAuth'

export const TwoFactorAuthRoutes = () => {
    const user = useGetSelf(() => {})
    if (!user.data) return null

    if (!user.data.isTwoFactorAuthEnabled)
        return (
            <Routes>
                <Route
                    path=''
                    element={<SetTwoFactorAuth />}
                />
                <Route
                    path='*'
                    element={
                        <Navigate
                            to='.'
                            replace
                        />
                    }
                />
            </Routes>
        )

    return (
        <Routes>
            <Route
                path=''
                element={<ChallengeTwoFactorAuth />}
            />
            <Route
                path='*'
                element={
                    <Navigate
                        to='.'
                        replace
                    />
                }
            />
        </Routes>
    )
}
