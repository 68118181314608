import { RiskAssessmentTemplateTypes } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useGetSignedRiskAssessmentPolicies } from '@features/riskAssessmentPolicies/hooks'

import Button from '@components/button/Button'
import form from '@components/form/Form'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import { useInitialiseCustomerRiskAssessmentVersion } from '../hooks'

import styles from './Initialise.module.scss'

const InitialiseTemplate = ({ back }: { back: Function }) => {
    const { customerRiskAssessmentVersionId } = useParams()

    const form = useForm()
    const {
        handleSubmit,
        formState: { errors },
    } = form

    const { mutate, isLoading } = useInitialiseCustomerRiskAssessmentVersion()

    const policies = useGetSignedRiskAssessmentPolicies()

    if (policies.isLoading) return <Loading />
    if (policies.isError) return <QueryError isInsideModal={true} />

    const submit = data => mutate(data)

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(submit)}>
                <TextInput
                    hidden
                    name='customerRiskAssessmentVersionId'
                    defaultValue={customerRiskAssessmentVersionId}
                />

                <InputWrapper
                    label='Template'
                    input={
                        <Select
                            name={'riskAssessmentPolicyId'}
                            required
                            options={policies.data
                                ?.filter(
                                    policy =>
                                        policy.riskAssessmentTemplate.riskAssessmentTemplateType ===
                                        RiskAssessmentTemplateTypes.Customer
                                )
                                .map(policy => ({
                                    label: policy.riskAssessmentTemplate.name,
                                    value: policy.id,
                                }))}
                            noOptionsMessage={
                                'You do not have any signed Customer Risk Assessment Policies'
                            }
                        />
                    }
                    errors={errors.riskAssessmentPolicyId}
                />

                <div className={styles.actions}>
                    <Button
                        label='Back'
                        outline={true}
                        onClick={back}
                        type={'button'}
                    />

                    <Button
                        label='Continue'
                        loading={isLoading}
                    />
                </div>
            </form>
        </FormProvider>
    )
}

export default InitialiseTemplate
