import classNames from 'classnames'
import { Check } from 'react-feather'

import styles from './Button.module.scss'

const Button = ({
    icon,
    label,
    loading,
    disabled,
    fullWidth,
    grey,
    type = 'submit',
    onClick = null,
    red,
    form,
    outline,
}: {
    icon?
    label?
    loading?
    disabled?
    fullWidth?
    grey?
    type?
    onClick?
    red?
    form?
    outline?
}) => {
    return (
        <button
            className={classNames(styles.button, {
                [styles.loading]: loading,
                [styles.fullWidth]: fullWidth,
                [styles.grey]: grey,
                [styles.red]: red,
                [styles.outline]: outline,
            })}
            disabled={loading || disabled}
            onClick={onClick}
            type={type}
            form={form}
        >
            {loading ? (
                outline ? (
                    <img
                        src={'/images/loading-black.svg'}
                        alt='Loading...'
                    />
                ) : (
                    <img
                        src={'/images/loading.svg'}
                        alt='Loading...'
                    />
                )
            ) : null}
            <div className={styles.content}>
                {icon} {label}
            </div>
        </button>
    )
}

export default Button
