import classNames from 'classnames'
import { useController, useFormContext } from 'react-hook-form'
import Select from 'react-select'

import styles from './ColourPicker.module.scss'

const ColourPicker = ({ name, readOnly }: { name: string; readOnly?: boolean }) => {
    const { control, getValues } = useFormContext()
    const controller = useController({
        name,
        control,
    })

    const colours = ['#7FD8A5', '#2ECC71', '#E8B62D', '#EF8D3C', '#E53E3E', '#B92C2C', '#000000']

    let options: { label: string; value: string }[] = colours.map(colour => {
        return {
            label: colour,
            value: colour,
        }
    })

    return (
        <Select
            className={classNames({
                select: true,
                disabled: readOnly,
                [styles.colourPicker]: true,
            })}
            options={options}
            isSearchable={false}
            isClearable={false}
            onChange={selectedOption => controller.field.onChange(selectedOption?.value)}
            value={options.find(option => option.value === controller.field.value)}
            isDisabled={readOnly}
            formatOptionLabel={({ value }) => (
                <div
                    className={styles.colourOption}
                    style={{ backgroundColor: value }}
                />
            )}
        />
    )
}

export default ColourPicker
