import { Check } from 'react-feather'
import { NavLink } from 'react-router-dom'

const ResetPasswordSuccess = () => {
    return (
        <div className='notice'>
            <Check
                color={'#295fe7'}
                size={40}
            />
            <h2>Password Reset</h2>
            <p>Your password was successfully reset, you can now use it to login.</p>
            <NavLink
                to={'/login'}
                className={'link'}
            >
                Login
            </NavLink>
        </div>
    )
}

export default ResetPasswordSuccess
