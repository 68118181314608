const EntityIdentityComponents = {
    NaturalPerson: [
        // Names
        'title',
        'name',
        'previousNames',
        'otherNamesUsed',

        // Personal Details
        'dateOfBirth',
        'gender',
        'personalIdentificationNumber',
        'nationalities',
        'placeOfBirth',
        'occupation',
        'employer',

        // Incorporation

        // Addresses
        'residentialAddress',

        // Correspondence
        'contactPerson',
        'emailAddress',
        'phoneNumber',
        'correspondenceAddress',

        // Ownership & Control

        // Activities

        // Screening
        'faceToFace',
        'pepStatus',
        'pepType',
        'pepDetails',
        'sanctionsStatus',
        'sanctionsDetails',
        'adverseMediaStatus',
        'adverseMediaDetails',

        // Funding
        'sourceOfFunds',
        'sourceOfWealth',

        // Taxation
        'taxResidencies',

        // Regulation

        // Public Listing
    ],
    LegalArrangement: [
        // Names
        'name',
        'previousNames',
        'tradingNames',

        // Personal Details

        // Incorporation
        'jurisdiction',
        'incorporationDate',
        'registrationNumber',
        'legalArrangementType',

        // Addresses
        'principalPlaceOfBusiness',

        // Correspondence
        'contactPerson',
        'emailAddress',
        'phoneNumber',
        'correspondenceAddress',

        // Ownership & Control
        'descriptionOfOwnershipStructure',

        // Activities
        'principalActivity',
        'descriptionOfBusinessUndertaken',

        // Screening
        'sanctionsStatus',
        'sanctionsDetails',
        'adverseMediaStatus',
        'adverseMediaDetails',

        // Funding
        'sourceOfFunds',

        // Taxation

        // Regulation

        // Public Listing
    ],
    Foundation: [
        // Names
        'name',
        'previousNames',
        'tradingNames',

        // Personal Details

        // Incorporation
        'jurisdiction',
        'incorporationDate',
        'registrationNumber',
        'statutoryProvision',

        // Addresses
        'registeredOfficeAddress',
        'principalPlaceOfBusiness',

        // Correspondence
        'contactPerson',
        'emailAddress',
        'phoneNumber',
        'correspondenceAddress',

        // Ownership & Control
        'descriptionOfOwnershipStructure',

        // Activities
        'principalActivity',
        'descriptionOfBusinessUndertaken',

        // Screening
        'sanctionsStatus',
        'sanctionsDetails',
        'adverseMediaStatus',
        'adverseMediaDetails',

        // Funding
        'sourceOfFunds',

        // Taxation
        'registeredForVat',
        'vatNumber',

        // Regulation

        // Public Listing
    ],
    LegalPerson: [
        // Names
        'name',
        'previousNames',
        'tradingNames',

        // Personal Details

        // Incorporation
        'jurisdiction',
        'incorporationDate',
        'registrationNumber',
        'statutoryProvision',
        'legalPersonType',

        // Addresses
        'registeredOfficeAddress',
        'principalPlaceOfBusiness',

        // Correspondence
        'contactPerson',
        'emailAddress',
        'phoneNumber',
        'correspondenceAddress',

        // Ownership & Control
        'descriptionOfOwnershipStructure',

        // Activities
        'principalActivity',
        'descriptionOfBusinessUndertaken',

        // Screening
        'sanctionsStatus',
        'sanctionsDetails',
        'adverseMediaStatus',
        'adverseMediaDetails',

        // Funding
        'sourceOfFunds',

        // Taxation
        'registeredForVat',
        'vatNumber',

        // Regulation
        'regulated',
        'regulatedActivity',
        'regulator',

        // Public Listing
        'publiclyListed',
        'publicListingDetails',
    ],
}

export { EntityIdentityComponents }
