import _ from 'lodash'
import { Download, Trash } from 'react-feather'

import Button from '@components/button/Button'
import VersionSelector from '@components/document/VersionSelector'

import { Action } from './Document'

import styles from './Document.module.scss'

const Actions = ({
    isAllowed,
    versionId,
    versions,
    route,
    isDraft,
    isPending,
    exportPdf,
    create,
    history,
    deleteAction,
}: {
    isAllowed: boolean
    versionId: string
    versions?: any[]
    route?: string
    isDraft: boolean
    isPending: boolean
    exportPdf?: Action
    create?: Action
    history?: {
        label: string
        value: string
        isDraft: boolean
        isPending: boolean
    }[]
    deleteAction?: Action
}) => {
    let isLatestVersion = false
    if (versions) isLatestVersion = _.maxBy(versions, v => v?.createDate).id === versionId

    return (
        <div className={styles.actions}>
            {history && route ? (
                <VersionSelector
                    versionId={versionId}
                    history={history}
                    route={route}
                />
            ) : null}

            {create && !isDraft && !isPending && isLatestVersion && isAllowed ? (
                <Button
                    label={'Edit Document'}
                    onClick={create.function}
                    loading={create.isLoading}
                />
            ) : null}

            {!isDraft && !isPending && exportPdf ? (
                <Button
                    icon={<Download size={16} />}
                    onClick={exportPdf.function}
                    loading={exportPdf.isLoading}
                    outline={true}
                    grey={true}
                />
            ) : null}

            {deleteAction && isAllowed ? (
                <Button
                    icon={<Trash size={16} />}
                    onClick={deleteAction.function}
                    loading={deleteAction.isLoading}
                    outline={true}
                    grey={true}
                />
            ) : null}
        </div>
    )
}

export default Actions
