import { Grants, PolicyStatuses } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import Document from '@components/document/Document'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import {
    useCreateIdentityPolicyVersion,
    useGetIdentityPolicyById,
    useGetIdentityPolicyVersionById,
    useRemoveIdentityPolicyVersion,
    useRevertVersionToDraft,
    useSetIdentityPolicyVersionPending,
    useSignIdentityPolicyVersion,
    useUpdateIdentityPolicyVersion,
} from '../hooks'

import IdentityPolicyForm from './IdentityPolicyForm'

const IdentityPolicyVersion = () => {
    const isAllowed = useCheckGrants(Grants.ManagePolicy) ?? false

    const navigate = useNavigate()
    const { identityPolicyId, identityPolicyVersionId } = useParams()
    const route = `/identity-policies/${identityPolicyId}`

    const form = useForm({})
    const {
        reset,
        trigger,
        getValues,
        formState: { isDirty },
    } = form

    const identityPolicy = useGetIdentityPolicyById(identityPolicyId, () => {})
    const identityPolicyVersion = useGetIdentityPolicyVersionById(identityPolicyVersionId, data =>
        reset(data)
    )

    const createVersion = useCreateIdentityPolicyVersion(data =>
        navigate(`${route}/versions/${data.id}`)
    )
    const updateVersion = useUpdateIdentityPolicyVersion()
    const submitForApproval = useSetIdentityPolicyVersionPending()
    const revertToDraft = useRevertVersionToDraft()
    const signVersion = useSignIdentityPolicyVersion()
    const removeVersion = useRemoveIdentityPolicyVersion(() => navigate(route))

    const create = () =>
        createVersion.mutate({
            identityPolicyId: identityPolicyId as string,
        })

    const update = async data => updateVersion.mutateAsync(data)

    const setPending = async () => {
        try {
            const isValid = await trigger()
            if (!isValid) return null
            if (isDirty) await update(getValues())
            return submitForApproval.mutate({
                identityPolicyVersionId: identityPolicyVersionId as string,
            })
        } catch {
            return null
        }
    }

    const revert = async () =>
        revertToDraft.mutate({
            identityPolicyVersionId: identityPolicyVersionId as string,
            identityPolicyId: identityPolicyId as string,
        })

    const sign = async () =>
        signVersion.mutate({
            identityPolicyVersionId: identityPolicyVersionId as string,
        })

    const remove = () =>
        removeVersion.mutate({
            identityPolicyVersionId: identityPolicyVersionId as string,
            identityPolicyId: identityPolicyId as string,
        })

    if (identityPolicy.isLoading || identityPolicyVersion.isLoading) return <Loading />
    if (
        identityPolicy.isError ||
        identityPolicyVersion.isError ||
        !identityPolicy.data ||
        !identityPolicyVersion.data
    )
        return <QueryError />

    return (
        <FormProvider {...form}>
            <Document
                isAllowed={isAllowed}
                pageTitle={identityPolicy.data.policyName}
                showHeader={true}
                landscape={true}
                type={identityPolicyVersion.data.versionName}
                showBreadcrumbs={true}
                breadcrumbs={[
                    {
                        label: <NavLink to={'/policies'}>Policies</NavLink>,
                        link: '/policies',
                    },
                ]}
                title={identityPolicy.data.policyName}
                versions={identityPolicy.data.versions}
                isDraft={identityPolicyVersion.data.status === PolicyStatuses.Draft}
                isPending={identityPolicyVersion.data.status === PolicyStatuses.Pending}
                versionId={identityPolicyVersion.data.id}
                versionName={identityPolicyVersion.data.versionName}
                route={`${route}/versions`}
                Form={IdentityPolicyForm}
                create={{
                    function: create,
                    isLoading: createVersion.isLoading,
                }}
                update={{
                    function: update,
                    isLoading: updateVersion.isLoading,
                    isSuccess: updateVersion.isSuccess,
                }}
                setPending={{
                    function: setPending,
                    isLoading: submitForApproval.isLoading,
                }}
                sign={{
                    function: sign,
                    isLoading: signVersion.isLoading,
                }}
                remove={
                    identityPolicy.data.versions.length > 1
                        ? {
                              function: remove,
                              isLoading: removeVersion.isLoading,
                          }
                        : undefined
                }
                history={identityPolicy.data.versions.map(version => ({
                    label: `${version.versionName}`,
                    value: version.id,
                    isDraft: version.status === PolicyStatuses.Draft,
                    isPending: version.status === PolicyStatuses.Pending,
                }))}
                requiredSignatures={identityPolicyVersion.data.requiredSignatures}
                revertToDraft={
                    identityPolicyVersion.data.status === PolicyStatuses.Pending
                        ? {
                              function: revert,
                              isLoading: revertToDraft.isLoading,
                          }
                        : undefined
                }
                error={
                    createVersion.isError ||
                    updateVersion.isError ||
                    submitForApproval.isError ||
                    revertToDraft.isError ||
                    signVersion.isError ||
                    removeVersion.isError
                }
            />
        </FormProvider>
    )
}

export default IdentityPolicyVersion
