import { useFormContext } from 'react-hook-form'

import Activities from '@features/entities/entity/identity/forms/Activities'
import Addresses from '@features/entities/entity/identity/forms/Addresses'
import Correspondence from '@features/entities/entity/identity/forms/Correspondence'
import Funding from '@features/entities/entity/identity/forms/Funding'
import Incorporation from '@features/entities/entity/identity/forms/Incorporation'
import Names from '@features/entities/entity/identity/forms/Names'
import OwnershipAndControl from '@features/entities/entity/identity/forms/OwnershipAndControl'
import PersonalDetails from '@features/entities/entity/identity/forms/PersonalDetails'
import PublicListing from '@features/entities/entity/identity/forms/PublicListing'
import Regulation from '@features/entities/entity/identity/forms/Regulation'
import Screening from '@features/entities/entity/identity/forms/Screening'
import Taxation from '@features/entities/entity/identity/forms/Taxation'

import TextInput from '@components/inputs/textInput/TextInput'

const IdentityForm = ({ readOnly }: { readOnly?: Boolean }) => {
    const { register, control, watch } = useFormContext()

    const entityType = watch('identity.entity.entityType')

    if (!entityType) return null

    return (
        <>
            <TextInput
                hidden
                name={'id'}
            />

            <TextInput
                hidden
                name={'entityType'}
                defaultValue={entityType}
            />

            <Names
                entityType={entityType}
                readOnly={readOnly}
            />

            <PersonalDetails
                entityType={entityType}
                readOnly={readOnly}
            />

            <Incorporation
                entityType={entityType}
                control={control}
                readOnly={readOnly}
            />

            <Addresses
                entityType={entityType}
                readOnly={readOnly}
            />

            <Correspondence
                entityType={entityType}
                readOnly={readOnly}
            />

            <OwnershipAndControl
                entityType={entityType}
                register={register}
                readOnly={readOnly}
            />

            <Activities
                entityType={entityType}
                register={register}
                readOnly={readOnly}
            />

            <Screening
                entityType={entityType}
                register={register}
                control={control}
                readOnly={readOnly}
            />

            <Funding
                entityType={entityType}
                register={register}
                readOnly={readOnly}
            />

            <Taxation
                entityType={entityType}
                control={control}
                readOnly={readOnly}
            />

            <Regulation
                entityType={entityType}
                control={control}
                readOnly={readOnly}
            />

            <PublicListing
                entityType={entityType}
                register={register}
                control={control}
                readOnly={readOnly}
            />
        </>
    )
}

export default IdentityForm
