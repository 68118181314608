import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useGetRoles } from '@features/organisation/roles/hooks'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import form from '@components/form/Form'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'

import RemoveTeamMember from '../components/RemoveTeamMember'

import { useGetMembership, useUpdateMembership } from '../hooks'

import styles from '@styles/Form.module.scss'

const EditTeamMember = () => {
    const navigate = useNavigate()
    const { membershipId } = useParams()

    const form = useForm()
    const {
        handleSubmit,
        formState: { errors },
        reset,
    } = form

    const membership = useGetMembership(membershipId, data => reset(data))
    const roles = useGetRoles()

    const { mutate, isLoading, isError } = useUpdateMembership(() =>
        navigate('/organisation/team-members')
    )

    const submit = data => mutate(data)

    if (!membership.data || !roles.data) return null

    return (
        <Modal
            overlay={true}
            heading={`Update ${membership.data.user.firstName} ${membership.data.user.lastName}'s Role`}
            close={`/organisation/team-members`}
        >
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(submit)}>
                    {isError && (
                        <p className={styles.error}>
                            There was an error submitting the form, please try again.
                        </p>
                    )}

                    <TextInput
                        hidden
                        name={'membershipId'}
                        defaultValue={membershipId}
                    />

                    <InputWrapper
                        label='Role'
                        input={
                            <Select
                                name={'userRoleId'}
                                required
                                options={roles.data.map(role => ({
                                    label: role.name,
                                    value: role.id,
                                }))}
                            />
                        }
                        errors={errors.userRoleId}
                    />

                    <Button
                        label='Update Team Member'
                        fullWidth={true}
                        loading={isLoading}
                    />
                    <RemoveTeamMember />
                </form>
            </FormProvider>
        </Modal>
    )
}

export default EditTeamMember
