import { Route } from 'react-router-dom'

import Reports from '@features/reports/layouts/Reports'

import CreateDisclosure from './views/CreateDisclosure'
import Disclosure from './views/Disclosure'
import DisclosureVersion from './views/DisclosureVersion'
import ListDisclosures from './views/ListDisclosures'

export const DisclosureRoutes = () => {
    return (
        <>
            <Route
                path='disclosures'
                element={
                    <Reports>
                        <ListDisclosures />
                    </Reports>
                }
            >
                <Route
                    path='create-disclosure'
                    element={<CreateDisclosure />}
                />
            </Route>
            <Route
                path='disclosures/:disclosureId'
                element={<Disclosure />}
            />
            <Route
                path='disclosures/:disclosureId/versions/:disclosureVersionId'
                element={<DisclosureVersion />}
            />
        </>
    )
}
