import { Grants } from '@prisma/client'
import moment from 'moment'
import { useMemo } from 'react'
import { Plus } from 'react-feather'
import { Helmet } from 'react-helmet'
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom'

import { useGetEntity } from '@features/entities/entity/hooks'
import { IdentityPolicyContexts } from '@features/relationships/relationship/relationshipEntity'
import { useCheckGrants } from '@features/user'

import FileNameWithIcon from '@components/fileNameWithIcon/FileNameWithIcon'
import MissingFields from '@components/missingFields/MissingFields'
import Table from '@components/table/Table'
import VersionStatusTag from '@components/versionStatusTag/VersionStatusTag'

import pascalToReadable from '@utils/pascalToReadable'

import { useGetDocumentsByEntityId } from '../hooks'

const ListDocuments = ({
    identityPolicyContext,
    edd,
}: {
    identityPolicyContext?: IdentityPolicyContexts
    edd?: boolean
}) => {
    const hasManageEntityGrant = useCheckGrants(Grants.ManageEntity)
    const { entityId } = useParams()

    const navigate = useNavigate()
    const link = props => navigate(props.original.id)

    const columns = useMemo(
        () => [
            {
                Header: 'Title',
                accessor: 'title',
            },
            {
                Header: 'Attachment',
                accessor: 'latestVersion.fileName',
                Cell: props => <FileNameWithIcon fileName={props.value} />,
            },
            {
                Header: 'Source',
                accessor: 'latestVersion.documentSource',
                Cell: props => (props.value ? pascalToReadable(props.value) : undefined),
            },
            {
                Header: 'Document Date',
                accessor: 'latestVersion.date',
                Cell: props => (props.value ? moment(props.value).format('DD/MM/YYYY') : undefined),
            },
            {
                Header: 'Latest Version',
                accessor: 'latestVersion.status',
                Cell: props => (
                    <VersionStatusTag status={props.row.original.latestVersion.status} />
                ),
            },
            {
                Header: 'Uploaded On',
                accessor: 'createDate',
                Cell: props => moment(props.value).format('MMMM DD, YYYY'),
            },
            {
                Header: 'Updated On',
                accessor: 'updateDate',
                Cell: props => moment(props.value).format('MMMM DD, YYYY'),
            },
        ],
        []
    )

    const documents = useGetDocumentsByEntityId(entityId)
    const entity = useGetEntity({ entityId }, () => {})

    return (
        <main>
            <Outlet />

            <Helmet>
                <title>Documents {entity.data ? `| ${entity.data.name} ` : ''}| Proofdesk</title>
            </Helmet>

            <div className='heading'>
                <div>
                    <h1>Verification Documents</h1>
                </div>

                {hasManageEntityGrant ? (
                    <NavLink
                        to={'create'}
                        className='link dark'
                    >
                        <Plus size={16} />
                        New Document
                    </NavLink>
                ) : null}
            </div>

            {identityPolicyContext && edd !== undefined ? (
                <MissingFields
                    entityId={entityId as string}
                    edd={edd}
                    identityPolicyContext={identityPolicyContext}
                    type={'unverified'}
                />
            ) : null}

            {documents.data?.length === 0 ? (
                <div className={'nothingToShow'}>
                    <p>You haven't uploaded any verification documents yet.</p>
                    {hasManageEntityGrant ? (
                        <NavLink
                            to={'create'}
                            className='link dark'
                        >
                            <Plus size={16} />
                            New Document
                        </NavLink>
                    ) : null}
                </div>
            ) : (
                <Table
                    isLoading={documents.isLoading}
                    button={null}
                    columns={columns}
                    data={documents.data ?? []}
                    defaultPageSize={50}
                    link={link}
                    queryError={documents.isError || entity.isError}
                />
            )}
        </main>
    )
}

export default ListDocuments
