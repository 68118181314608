import ControllingPartyInput from '../ControllingPartyInput/ControllingPartyInput'
import { EntityTypes } from '@prisma/client'
import React from 'react'
import { Plus } from 'react-feather'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { v4 as uuid } from 'uuid'

import AddFirstChildButton from '@features/createEntity/components/AddFirstChildButton/AddFirstChildButton'

import Button from '@components/button/Button'

import styles from './ControllingPartiesFieldArray.module.scss'

const ControllingPartiesFieldArray = ({ name }) => {
    const { control } = useFormContext()

    const { fields, insert, remove } = useFieldArray({
        control,
        name,
    })

    return (
        <div className={styles.controllingParties}>
            {fields.length === 0 ? (
                <div className={styles.addFirstChild}>
                    <AddFirstChildButton
                        onClick={() =>
                            insert(0, {
                                tempId: uuid(),
                                name: '',
                                entityType: EntityTypes.NaturalPerson,
                            })
                        }
                    />
                </div>
            ) : (
                fields.map((field, index) => (
                    <React.Fragment key={field.id}>
                        <ControllingPartyInput
                            index={index}
                            name={`${name}.${index}`}
                            insert={insert}
                            remove={remove}
                        />
                        <ControllingPartiesFieldArray
                            name={`${name}.${index}.controllingParties`}
                        />
                    </React.Fragment>
                ))
            )}
        </div>
    )
}

export default ControllingPartiesFieldArray
