import { Route } from 'react-router-dom'

import RelationshipApprovalVersion from '@features/relationships/relationship/relationshipApproval/views/RelationshipApprovalVersion'

export const RelationshipApprovalRoutes = () => {
    return (
        <>
            <Route
                path='relationship-approval-version/:relationshipApprovalVersionId'
                element={<RelationshipApprovalVersion />}
            />
        </>
    )
}
