import styles from './Checkbox.module.scss'
import { useFormContext } from 'react-hook-form'

const Checkbox = ({
    name,
    label = '',
    description = '',
    disabled = false,
    defaultValue = false,
    readOnly = false,
}: {
    name: string
    label?: string
    description?: string
    disabled?: boolean
    defaultValue?: boolean
    readOnly?: boolean
}) => {
    const { register, getValues } = useFormContext()

    if(readOnly) return (
        <div className={styles.readOnly}>
            <div>
                <p>{label}</p>
            </div>
            <div>
                <p>{getValues(name) ? 'Yes' : 'No'}</p>
            </div>
        </div>
    )

    return (
        <label className={styles.checkbox}>
            <div className={styles.input}>
                <input
                    type={'checkbox'}
                    {...register(name, { disabled, value: defaultValue })}
                    disabled={disabled}
                />
                <span />
            </div>
            <div>
                <p>{label}</p>
                {description && <p>{description}</p>}
            </div>
        </label>
    )
}

export default Checkbox
