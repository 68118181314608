import { RelationshipSources } from '@prisma/client'
import { Legislation } from '@proofdesk/common'
import { useFormContext, useWatch } from 'react-hook-form'

import { useGetEntities } from '@features/entities'
import { useGetOrganisation } from '@features/organisation'

import InputWrapper from '@components/inputWrapper/InputWrapper'
import AutoGrowTextArea from '@components/inputs/autoGrowTextArea/AutoGrowTextArea'
import MultiSelect from '@components/inputs/multiSelect/MultiSelect'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import pascalToReadable from '@utils/pascalToReadable'

const RelationshipDetailsForm = ({ readOnly }: { readOnly?: boolean }) => {
    const {
        control,
        formState: { errors },
        getValues,
    } = useFormContext()

    const sourceOfBusiness = useWatch({
        control,
        name: 'source',
    })

    const entityId = useWatch({
        control,
        name: 'entityId',
    })

    const organisation = useGetOrganisation(() => {}, {})
    const entities = useGetEntities()

    if (organisation.isLoading || entities.isLoading) return <Loading />
    if (organisation.isError || entities.isError || !organisation.data || !entities.data)
        return <QueryError isInsideModal={true} />

    return (
        <>
            <TextInput
                hidden
                name={'relationshipDetailsVersionId'}
                defaultValue={getValues('id')}
            />

            <InputWrapper
                label='Nature and Purpose'
                description='Provide a detailed description of the economic or commercial rationale for the relationship as well as the agreed upon scope of services the firm will provide. Include additional relevant information such as fees, durations and/or geographic regions.'
                input={
                    <AutoGrowTextArea
                        name={'natureOfRelationship'}
                        readOnly={readOnly}
                        large={true}
                    />
                }
                readOnly={readOnly}
            />

            <InputWrapper
                label='Regulated Activities'
                description={
                    'Select the regulated activities that the firm will be providing to the customer.'
                }
                input={
                    <MultiSelect
                        name={'activities'}
                        options={organisation.data.regulatedActivities.map(activity => ({
                            value: activity,
                            label: `${Legislation[activity].class} - ${Legislation[activity].description}`,
                        }))}
                        readOnly={readOnly}
                        required={true}
                        minLength={1}
                    />
                }
                readOnly={readOnly}
                errors={errors.activities}
            />

            <TextInput
                hidden
                name={'subActivity'}
            />

            {/*{Legislation[activity]?.subActivities ? (*/}
            {/*    <InputWrapper*/}
            {/*        label={`Sub-Activity`}*/}
            {/*        input={*/}
            {/*            <Select*/}
            {/*                control={control}*/}
            {/*                name={'subActivity'}*/}
            {/*                options={Legislation[activity].subActivities.map(*/}
            {/*                    subActivity => ({*/}
            {/*                        value: subActivity,*/}
            {/*                        label: subActivity,*/}
            {/*                    })*/}
            {/*                )}*/}
            {/*            />*/}
            {/*        }*/}
            {/*    />*/}
            {/*) : null}*/}

            <InputWrapper
                label='Source of Business'
                description={
                    'Select whether the relationship was referred by an existing customer, introduced, or sourced independently (direct).'
                }
                input={
                    <Select
                        name={'source'}
                        options={Object.keys(RelationshipSources).map(source => ({
                            label: pascalToReadable(source),
                            value: source,
                        }))}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                readOnly={readOnly}
                errors={errors.source}
            />

            {sourceOfBusiness === RelationshipSources.Introduced ? (
                <InputWrapper
                    label='Introducer(s)'
                    input={
                        <MultiSelect
                            name={'introducerEntityIds'}
                            options={entities.data
                                .filter(entity => entity.id !== entityId)
                                .map(entity => ({
                                    value: entity.id,
                                    label: entity.name,
                                }))}
                            readOnly={readOnly}
                            required={true}
                            minLength={1}
                        />
                    }
                    readOnly={readOnly}
                    errors={errors.introducerEntityIds}
                />
            ) : null}

            <InputWrapper
                label='Source of Funds'
                description={
                    'Provide the details of the account from which our fees will be paid as well as how the account is funded.'
                }
                input={
                    <AutoGrowTextArea
                        name={'sourceOfFunds'}
                        readOnly={readOnly}
                        medium={true}
                    />
                }
                readOnly={readOnly}
                errors={errors.sourceOfFunds}
            />

            <InputWrapper
                label='Additional Information'
                description={
                    'Provide any additional information about the relationship that you feel may be relevant.'
                }
                input={
                    <AutoGrowTextArea
                        name={'notes'}
                        readOnly={readOnly}
                        medium={true}
                    />
                }
                readOnly={readOnly}
                errors={errors.notes}
            />
        </>
    )
}

export default RelationshipDetailsForm
