import { RiskAssessmentTemplateTypes } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import TextInput from '@components/inputs/textInput/TextInput'

import { useCreateRiskAssessmentPolicy } from '../hooks'

const CreateRiskAssessmentPolicy = () => {
    const navigate = useNavigate()

    const form = useForm({
        defaultValues: {
            initialVersion: {
                policyName: null,
                nextReviewDate: new Date(),
                versionName: 'New Version',
                riskAssessmentTemplate: {
                    name: '',
                    riskAssessmentTemplateType: RiskAssessmentTemplateTypes.Customer,
                    questions: [
                        {
                            title: '',
                            options: [
                                {
                                    title: '',
                                    riskLevelId: null,
                                },
                            ],
                            justification: '',
                        },
                    ],
                },
            },
        },
    })

    const {
        handleSubmit,
        formState: { errors },
    } = form

    const { mutate, isLoading } = useCreateRiskAssessmentPolicy(data =>
        navigate(`/risk-assessment-policies/${data.id}`)
    )

    const onSubmit = data => mutate(data)

    return (
        <Modal
            overlay={true}
            heading={'New Risk Assessment Policy'}
            close={'/policies'}
        >
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputWrapper
                        label={'Policy Name'}
                        input={
                            <TextInput
                                name={'initialVersion.policyName'}
                                required
                            />
                        }
                        errors={errors.initialVersion?.policyName}
                    />

                    <Button
                        label={'Continue'}
                        fullWidth={true}
                        disabled={isLoading}
                    />
                </form>
            </FormProvider>
        </Modal>
    )
}

export default CreateRiskAssessmentPolicy
