import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import {
    useDeleteRelationshipReview,
    useGetRelationshipReview,
} from '@features/relationships/relationship/hooks'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import TextInput from '@components/inputs/textInput/TextInput'

import styles from '@styles/Form.module.scss'

const AbortReview = () => {
    const { relationshipId, relationshipReviewId } = useParams()
    const navigate = useNavigate()
    const form = useForm()

    const relationshipReview = useGetRelationshipReview(
        relationshipId,
        relationshipReviewId,
        () => {}
    )

    const {
        handleSubmit,
        formState: { errors },
    } = form

    const { mutate, isLoading } = useDeleteRelationshipReview(data =>
        navigate(`/relationships/${relationshipId}`, { replace: true })
    )

    const submit = data => mutate(data)

    return (
        <Modal
            overlay={true}
            heading={`Abort ${relationshipReview.data?.versionName ?? ''}`}
            close={`/relationships/${relationshipId}/reviews/${relationshipReviewId}`}
            error={relationshipReview.isError}
            loading={relationshipReview.isLoading}
        >
            <FormProvider {...form}>
                <form
                    onSubmit={handleSubmit(submit)}
                    className={styles.form}
                >
                    <TextInput
                        hidden={true}
                        name={'relationshipReviewId'}
                        defaultValue={relationshipReviewId}
                    />

                    <p>
                        This action will abort the review process and you will lose any changes you
                        have made to the Relationship Details, Customer Risk Assessment or Approval
                        forms.
                    </p>

                    <div className={styles.actions}>
                        <Button
                            label='Cancel'
                            onClick={() =>
                                navigate(
                                    `/relationships/${relationshipId}/reviews/${relationshipReviewId}`
                                )
                            }
                            outline={true}
                            disabled={isLoading}
                            type={'button'}
                        />

                        <Button
                            label='Abort Review'
                            red={true}
                            disabled={isLoading}
                            loading={isLoading}
                        />
                    </div>
                </form>
            </FormProvider>
        </Modal>
    )
}

export default AbortReview
