import React from 'react'
import { Trash2 } from 'react-feather'
import { useNavigate, useParams } from 'react-router-dom'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'

import { useDeleteEntity, useGetEntity } from '../hooks'

import styles from '@styles/Form.module.scss'

const DeleteEntity = () => {
    const { entityId } = useParams()
    const navigate = useNavigate()

    const entity = useGetEntity({ entityId }, null)
    const deleteEntity = () => mutate({ entityId: entityId as string })

    const { mutate, isLoading, isError } = useDeleteEntity(() => navigate(`/entities`))

    return (
        <Modal
            overlay={true}
            heading={`Delete ${entity.data?.name ?? ''}`}
            close={`/entities/${entityId}`}
            error={isError}
            loading={isLoading}
        >
            <div className={styles.form}>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris.
                </p>

                <div className={styles.actions}>
                    <Button
                        label='Cancel'
                        onClick={() => navigate(`/entities/${entityId}`)}
                        outline={true}
                        disabled={isLoading}
                        type={'button'}
                    />

                    <Button
                        loading={isLoading}
                        icon={<Trash2 size={18} />}
                        label={'Delete Entity'}
                        onClick={deleteEntity}
                        type={'button'}
                        red={true}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default DeleteEntity
