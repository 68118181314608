import { EntityTypes } from '@prisma/client'

const EntityTypeIndicator = ({ entityType, size = 20 }) => {
    if (entityType === EntityTypes.NaturalPerson)
        return (
            <img
                height={size}
                src='/images/entityTypes/natural-person.svg'
                alt={'Natural Person'}
                title={'Natural Person'}
                style={{ display: 'inline-block' }}
            />
        )

    if (entityType === EntityTypes.LegalPerson)
        return (
            <img
                height={size}
                src='/images/entityTypes/legal-person.svg'
                alt={'Legal Person'}
                title={'Legal Person'}
            />
        )

    if (entityType === EntityTypes.LegalArrangement)
        return (
            <img
                height={size}
                src='/images/entityTypes/legal-arrangement.svg'
                alt={'Legal Arrangement'}
                title={'Legal Arrangement'}
            />
        )

    if (entityType === EntityTypes.Foundation)
        return (
            <img
                height={size}
                src='/images/entityTypes/foundation.svg'
                alt={'Foundation'}
                title={'Foundation'}
            />
        )

    return null
}

export default EntityTypeIndicator
