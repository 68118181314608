import FileStoreImage from '@components/fileStore/FileStoreImage'

import styles from './Attachment.module.scss'

const Attachment = ({ fileName }: { fileName: string }) => {
    return (
        <div className={styles.attachment}>
            <p>Attachment</p>

            <div>
                <FileStoreImage fileName={fileName} />
            </div>
        </div>
    )
}

export default Attachment
