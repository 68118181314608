import { useFieldArray, useFormContext } from 'react-hook-form'

import Question from './Question'

import styles from './TemplateTable.module.scss'

const TemplateTable = ({ name, readOnly }) => {
    const { control } = useFormContext()
    const questions = useFieldArray({
        control,
        name,
    })

    return (
        <div className={styles.templateTable}>
            <div className={styles.heading}>
                <p>Question</p>
                <p>Option</p>
                <p>Risk Level</p>
                <p>Justification</p>
            </div>
            {questions.fields.map((question, index) => (
                <Question
                    key={question.id}
                    name={`${name}.${index}`}
                    readOnly={readOnly}
                    add={() =>
                        questions.insert(index + 1, {
                            title: '',
                            options: [{ title: '', riskLevelId: null }],
                        })
                    }
                    remove={questions.fields.length === 1 ? null : () => questions.remove(index)}
                />
            ))}
        </div>
    )
}

export default TemplateTable
