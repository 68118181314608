import { Grants } from '@prisma/client'
import { JSX } from 'react'
import { Download } from 'react-feather'
import { Helmet } from 'react-helmet'

import { useCheckGrants } from '@features/user'

import Button from '@components/button/Button'
import Editable from '@components/document/Editable'
import Header from '@components/document/Header'
import ReadOnly from '@components/document/ReadOnly'
import QueryError from '@components/queryError/QueryError'

import styles from './Document.module.scss'

export type Action = {
    function: Function
    isLoading: boolean
    isSuccess?: boolean
}

const Document = ({
    more,
    isAllowed,
    alert,
    isNestedInsideRelationship,
    pageTitle,
    showHeader,
    landscape,
    showBreadcrumbs,
    breadcrumbs,
    title,
    versions,
    isDraft,
    isPending,
    versionId,
    versionName,
    route,
    Form,
    Attachment,
    exportPdf,
    create,
    update,
    sign,
    setPending,
    remove,
    deleteAction,
    revertToDraft,
    signature,
    counterSignatures,
    history,
    requiredSignatures,
    counterSign,
    error,
    counterSignaturesRequired,
}: {
    more?: JSX.Element
    isAllowed: boolean
    alert?: JSX.Element
    isNestedInsideRelationship?: boolean
    pageTitle: string
    showHeader: boolean
    landscape: boolean
    type: string
    showBreadcrumbs: boolean
    breadcrumbs: { label: any; link: string }[]
    title: string
    versions?: any[]
    isDraft: boolean
    isPending: boolean
    versionId: string
    versionName: string
    route?: string
    Form: any
    Attachment?: JSX.Element
    exportPdf?: Action
    create?: Action
    update: Action
    sign: Action
    setPending?: Action
    remove?: Action
    deleteAction?: Action
    revertToDraft?: Action
    counterSign?: Action
    signature?: {
        user: {
            firstName: string
            lastName: string
        }
        date: Date
    }
    counterSignatures?: {
        user: {
            firstName: string
            lastName: string
        }
        date: Date
    }[]
    history?: {
        label: string
        value: string
        isDraft: boolean
        isPending: boolean
    }[]
    requiredSignatures?: {
        id: string
        firstName: string
        lastName: string
        email: string
        signature?: any
    }[]
    error?: boolean
    counterSignaturesRequired?: Number
}) => {
    return (
        <>
            <Helmet>
                <title>{pageTitle} | Proofdesk</title>
            </Helmet>
            <div className={styles.document}>
                {showHeader ? (
                    <Header
                        isAllowed={isAllowed}
                        isNestedInsideRelationship={isNestedInsideRelationship}
                        exportPdf={exportPdf}
                        showBreadcrumbs={showBreadcrumbs}
                        breadcrumbs={breadcrumbs}
                        title={title}
                        versionId={versionId}
                        versions={versions}
                        route={route}
                        isDraft={isDraft}
                        isPending={isPending}
                        create={create}
                        history={history}
                        deleteAction={deleteAction}
                    />
                ) : null}

                <div className={`${styles.content} ${landscape ? styles.landscape : null}`}>
                    {alert ? alert : null}

                    <div>
                        {isDraft ? (
                            <Editable
                                more={more}
                                isAllowed={isAllowed}
                                title={title}
                                Form={Form}
                                update={update}
                                sign={sign}
                                setPending={setPending}
                                remove={
                                    (history?.length && history.length > 1) ||
                                    title === 'Termination'
                                        ? remove
                                        : undefined
                                }
                                showBreadcrumbs={showBreadcrumbs}
                                breadcrumbs={breadcrumbs}
                                error={error}
                            />
                        ) : (
                            <ReadOnly
                                isAllowed={isAllowed}
                                title={title}
                                showBreadcrumbs={showBreadcrumbs}
                                breadcrumbs={breadcrumbs}
                                Form={Form}
                                Attachment={Attachment}
                                signature={signature}
                                counterSignatures={counterSignatures}
                                versionName={versionName}
                                versionId={versionId}
                                isPending={isPending}
                                requiredSignatures={requiredSignatures}
                                sign={sign}
                                revertToDraft={revertToDraft}
                                counterSign={counterSign}
                                counterSignaturesRequired={counterSignaturesRequired}
                            />
                        )}
                    </div>
                    {!showHeader && !isDraft && !isPending && exportPdf ? (
                        <div className={styles.exportPdfOnly}>
                            <Button
                                icon={<Download size={16} />}
                                label={'Export PDF'}
                                grey={true}
                                fullWidth={true}
                                loading={exportPdf.isLoading}
                                onClick={exportPdf.function}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}

export default Document
