import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import TextInput from '@components/inputs/textInput/TextInput'

import { useCreateEnquiry } from '../hooks'

const CreateEnquiry = () => {
    const navigate = useNavigate()

    const form = useForm({
        shouldUnregister: true,
    })

    const { handleSubmit } = form

    const { mutate, isLoading } = useCreateEnquiry(data =>
        navigate(`/reporting/enquiries/${data.id}`)
    )

    const onSubmit = data => mutate(data)

    return (
        <Modal
            overlay={true}
            heading={'New Enquiry'}
            close={'/reporting/enquiries'}
        >
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputWrapper
                        label={'Internal Reference'}
                        input={
                            <TextInput
                                name={'internalReference'}
                                required
                            />
                        }
                    />

                    <Button
                        label={'Continue'}
                        fullWidth={true}
                        disabled={isLoading}
                    />
                </form>
            </FormProvider>
        </Modal>
    )
}

export default CreateEnquiry
