import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { MoreVertical } from 'react-feather'
import { useLocation } from 'react-router-dom'

import styles from './More.module.scss'

const useClickOutside = (ref, setOpen) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])
}

const More = ({ children }) => {
    const ref = useRef(null)
    const [open, setOpen] = useState(false)
    const location = useLocation()
    useClickOutside(ref, setOpen)

    useEffect(() => {
        setOpen(false)
    }, [location.pathname])

    if (
        !children || children.every
            ? children.every(child => child === null) || children.length < 1
            : true
    )
        return null

    return (
        <div
            className={classNames({
                [styles.more]: true,
                [styles.open]: open,
            })}
            ref={ref}
        >
            <button
                onClick={() => setOpen(!open)}
                type={'button'}
            >
                <MoreVertical size={20} />
            </button>

            <div
                className={classNames({
                    [styles.menu]: true,
                    [styles.open]: open,
                })}
            >
                {children}
            </div>
        </div>
    )
}

export default More
