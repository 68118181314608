import { Grants } from '@prisma/client'

import { useCheckGrants } from '@features/user'

import Tabs from '@components/tabs/Tabs'

const Reports = ({ children }) => {
    const hasStatisticalReturnsGrant = useCheckGrants(Grants.GenerateReports)
    const hasDisclosuresGrant = useCheckGrants(Grants.ManageDisclosures)
    const hasEnquiriesGrant = useCheckGrants(Grants.ManageEnquiries)

    return (
        <>
            <Tabs
                tabs={[
                    ...(hasStatisticalReturnsGrant
                        ? [
                              {
                                  label: 'Statistical Returns',
                                  link: '/reporting/statistical-returns',
                              },
                          ]
                        : []),
                    ...(hasDisclosuresGrant
                        ? [
                              {
                                  label: 'Disclosures',
                                  link: '/reporting/disclosures',
                              },
                          ]
                        : []),
                    ...(hasEnquiriesGrant
                        ? [
                              {
                                  label: 'ML / TF Enquiries',
                                  link: '/reporting/enquiries',
                              },
                          ]
                        : []),
                ]}
            />

            <main>{children}</main>
        </>
    )
}

export default Reports
