import { AlertTriangle } from 'react-feather'
import { useParams } from 'react-router-dom'

import { useGetEntity } from '@features/entities/entity/hooks'
import { IdentityPolicyContexts } from '@features/relationships/relationship/relationshipEntity'

import Tag, { TagThemes } from '@components/tag/Tag'

import pascalToReadable from '@utils/pascalToReadable'

import styles from './MissingFields.module.scss'

const MissingFields = ({
    entityId,
    identityPolicyContext,
    edd,
    type,
}: {
    entityId: string
    identityPolicyContext: IdentityPolicyContexts
    edd: boolean
    type: 'unidentified' | 'unverified'
}) => {
    const entity = useGetEntity({ entityId, identityPolicyContext, edd }, () => {})
    if (!entity.data) return null

    let fields: string[] | undefined = []
    if (type === 'unidentified') {
        fields = entity.data.identityCompletion?.unidentifiedFields
    } else {
        fields = entity.data.identityCompletion?.unverifiedFields
    }

    if (!fields || fields.length === 0) return null

    return (
        <div className={styles.missingFields}>
            <div className={styles.header}>
                <AlertTriangle
                    color={'#e53e3e'}
                    size={16}
                />
                <p>Missing {type === 'unidentified' ? 'Fields' : 'Verification'}</p>
            </div>
            <div className={styles.fields}>
                {fields?.map(field => (
                    <Tag
                        label={pascalToReadable(field)}
                        theme={TagThemes.Grey}
                        outline={false}
                    />
                ))}
            </div>
        </div>
    )
}

export default MissingFields
