import { trpc } from '@utils/trpc'

export const useVerifyEmail = onSuccess => {
    const context = trpc.useContext()

    return trpc.users.updateSelfIsEmailVerified.useMutation({
        onSuccess,
        onSettled: () => context.users.getSelf.invalidate(),
    })
}
