import { Grants, PolicyStatuses } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import Document from '@components/document/Document'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import {
    useCreateRiskLevelPolicyVersion,
    useGetRiskLevelPolicyById,
    useGetRiskLevelPolicyVersionById,
    useRemoveRiskLevelPolicyVersion,
    useRevertVersionToDraft,
    useSetRiskLevelPolicyVersionPending,
    useSignRiskLevelPolicyVersion,
    useUpdateRiskLevelPolicyVersion,
} from '../hooks'

import RiskLevelPolicyForm from './RiskLevelPolicyForm'

const RiskLevelPolicyVersion = () => {
    const isAllowed = useCheckGrants(Grants.ManagePolicy) ?? false

    const navigate = useNavigate()
    const { riskLevelPolicyId, riskLevelPolicyVersionId } = useParams()
    const route = `/risk-level-policies/${riskLevelPolicyId}`

    const form = useForm({})
    const {
        reset,
        trigger,
        getValues,
        formState: { isDirty },
    } = form

    const riskLevelPolicy = useGetRiskLevelPolicyById(riskLevelPolicyId, () => {})
    const riskLevelPolicyVersion = useGetRiskLevelPolicyVersionById(
        riskLevelPolicyVersionId,
        riskLevelPolicyId,
        data => reset(data)
    )

    const createVersion = useCreateRiskLevelPolicyVersion(data =>
        navigate(`${route}/versions/${data.id}`)
    )
    const updateVersion = useUpdateRiskLevelPolicyVersion()
    const submitForApproval = useSetRiskLevelPolicyVersionPending()
    const revertToDraft = useRevertVersionToDraft()
    const signVersion = useSignRiskLevelPolicyVersion()
    const removeVersion = useRemoveRiskLevelPolicyVersion(() => navigate(route))

    const create = () => createVersion.mutate()

    const update = async data => updateVersion.mutateAsync(data)

    const setPending = async () => {
        try {
            const isValid = await trigger()
            if (!isValid) return null
            if (isDirty) await update(getValues())
            return submitForApproval.mutate({
                riskLevelPolicyVersionId: riskLevelPolicyVersionId as string,
            })
        } catch {
            return null
        }
    }

    const revert = async () =>
        revertToDraft.mutate({
            riskLevelPolicyVersionId: riskLevelPolicyVersionId as string,
            riskLevelPolicyId: riskLevelPolicyId as string,
        })

    const sign = async () =>
        signVersion.mutate({
            riskLevelPolicyVersionId: riskLevelPolicyVersionId as string,
        })

    const remove = () =>
        removeVersion.mutate({
            riskLevelPolicyVersionId: riskLevelPolicyVersionId as string,
        })

    if (riskLevelPolicy.isLoading || riskLevelPolicyVersion.isLoading) return <Loading />
    if (
        riskLevelPolicy.isError ||
        riskLevelPolicyVersion.isError ||
        !riskLevelPolicy.data ||
        !riskLevelPolicyVersion.data
    )
        return <QueryError />

    console.log(riskLevelPolicyVersion.data)

    return (
        <FormProvider {...form}>
            <Document
                isAllowed={isAllowed}
                pageTitle={riskLevelPolicy.data.policyName}
                showHeader={true}
                landscape={true}
                type={riskLevelPolicyVersion.data.versionName}
                showBreadcrumbs={true}
                breadcrumbs={[
                    {
                        label: <NavLink to={'/policies'}>Policies</NavLink>,
                        link: '/policies',
                    },
                ]}
                title={riskLevelPolicy.data.policyName}
                versions={riskLevelPolicy.data.versions}
                isDraft={riskLevelPolicyVersion.data.status === PolicyStatuses.Draft}
                isPending={riskLevelPolicyVersion.data.status === PolicyStatuses.Pending}
                versionId={riskLevelPolicyVersion.data.id}
                versionName={riskLevelPolicyVersion.data.versionName}
                route={`${route}/versions`}
                Form={RiskLevelPolicyForm}
                create={{
                    function: create,
                    isLoading: createVersion.isLoading,
                }}
                update={{
                    function: update,
                    isLoading: updateVersion.isLoading,
                    isSuccess: updateVersion.isSuccess,
                }}
                setPending={{
                    function: setPending,
                    isLoading: submitForApproval.isLoading,
                }}
                sign={{
                    function: sign,
                    isLoading: signVersion.isLoading,
                }}
                remove={
                    riskLevelPolicy.data.versions.length > 1
                        ? {
                              function: remove,
                              isLoading: removeVersion.isLoading,
                          }
                        : undefined
                }
                history={riskLevelPolicy.data.versions.map(version => ({
                    label: `${version.versionName}`,
                    value: version.id,
                    isDraft: version.status === PolicyStatuses.Draft,
                    isPending: version.status === PolicyStatuses.Pending,
                }))}
                requiredSignatures={riskLevelPolicyVersion.data.requiredSignatures}
                revertToDraft={
                    riskLevelPolicyVersion.data.status === PolicyStatuses.Pending
                        ? {
                              function: revert,
                              isLoading: revertToDraft.isLoading,
                          }
                        : undefined
                }
                error={
                    createVersion.isError ||
                    updateVersion.isError ||
                    submitForApproval.isError ||
                    revertToDraft.isError ||
                    signVersion.isError ||
                    removeVersion.isError
                }
            />
        </FormProvider>
    )
}

export default RiskLevelPolicyVersion
