import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { LogOut } from 'react-feather'
import { NavLink } from 'react-router-dom'

import { useGetSelf, useGetSelfInvitations } from '@features/account'

import UserAvatar from '@components/user/UserAvatar'

import styles from './ProfileMenu.module.scss'

const ProfileMenu = () => {
    const ref = useRef<HTMLInputElement>(null)
    const self = useGetSelf(() => {})
    const invitations = useGetSelfInvitations()

    const [open, setOpen] = useState(false)

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) setOpen(false)
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])

    if (!self.data || invitations.isLoading) return null

    return (
        <div
            className={styles.profileMenu}
            ref={ref}
        >
            <div
                className={classNames(styles.avatar, {
                    [styles.notification]: invitations.data?.length,
                })}
                onClick={() => setOpen(!open)}
            >
                <UserAvatar
                    user={self.data}
                    light
                    large
                />
            </div>

            {open ? (
                <div className={styles.dropdown}>
                    <NavLink
                        to={'/account/general-settings'}
                        onClick={() => setOpen(false)}
                        className={invitations.data?.length ? styles.notification : null}
                    >
                        Account Settings
                    </NavLink>
                    <NavLink
                        to={'/account/notification-settings'}
                        onClick={() => setOpen(false)}
                    >
                        Notification Settings
                    </NavLink>
                    <NavLink to={'/logout'}>
                        <LogOut size={18} />
                        Logout
                    </NavLink>
                </div>
            ) : null}
        </div>
    )
}

export default ProfileMenu
