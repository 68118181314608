import { Route } from 'react-router-dom'

import Entity from '@features/entities/entity/layouts/Entity'
import { IdentityPolicyContexts } from '@features/relationships/relationship/relationshipEntity'

import Identity from './views/Identity'
import IdentityVersion from './views/IdentityVersion'

export const IdentityRoutes = ({
    isNestedInsideRelationship,
    identityPolicyContext,
    edd,
}: {
    isNestedInsideRelationship: boolean
    identityPolicyContext?: IdentityPolicyContexts
    edd?: boolean
}) => {
    return (
        <>
            <Route
                path='identity'
                element={<Identity />}
            />
            <Route
                path='identity/versions/:identityVersionId'
                element={
                    !isNestedInsideRelationship ? (
                        <Entity>
                            <IdentityVersion
                                isNestedInsideRelationship={isNestedInsideRelationship}
                            />
                        </Entity>
                    ) : (
                        <IdentityVersion
                            isNestedInsideRelationship={isNestedInsideRelationship}
                            edd={edd}
                            identityPolicyContext={identityPolicyContext}
                        />
                    )
                }
            />
        </>
    )
}
