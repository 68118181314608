import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import ReviewNameSuggestions from '@features/relationships/relationship/components/reviewNameSuggestions/ReviewNameSuggestions'
import { useCreateReview } from '@features/relationships/relationship/hooks'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import TextInput from '@components/inputs/textInput/TextInput'

import styles from '@styles/Form.module.scss'

const InitiateReview = () => {
    const { relationshipId } = useParams()
    const navigate = useNavigate()
    const form = useForm()

    const {
        handleSubmit,
        formState: { errors },
    } = form

    const { mutate, isLoading } = useCreateReview(data =>
        navigate(`/relationships/${relationshipId}/reviews/${data.id}`, { replace: true })
    )

    const submit = data =>
        mutate({
            relationshipId: relationshipId as string,
            versionName: data.versionName,
        })

    return (
        <Modal
            overlay={true}
            heading={'Initiate Review'}
            close={`/relationships/${relationshipId}`}
        >
            <FormProvider {...form}>
                <form
                    onSubmit={handleSubmit(submit)}
                    className={styles.form}
                >
                    <TextInput
                        hidden={true}
                        name={'relationshipId'}
                        defaultValue={relationshipId}
                    />

                    <InputWrapper
                        label={'Review Name'}
                        input={
                            <TextInput
                                name={'versionName'}
                                required={true}
                            />
                        }
                        errors={errors.versionName}
                    />

                    <ReviewNameSuggestions />

                    <div className={styles.actions}>
                        <Button
                            label='Cancel'
                            onClick={() => navigate(`/relationships/${relationshipId}`)}
                            outline={true}
                            disabled={isLoading}
                            type={'button'}
                        />

                        <Button
                            label='Continue'
                            disabled={isLoading}
                            loading={isLoading}
                        />
                    </div>
                </form>
            </FormProvider>
        </Modal>
    )
}

export default InitiateReview
