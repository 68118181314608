import Tag, { TagThemes } from '@components/tag/Tag'

const RelationshipReviewStatusTag = ({ status }: { status: string }) => {
    let outline = true
    if (status === 'Completed') outline = false

    return (
        <Tag
            label={status}
            theme={TagThemes.Grey}
            outline={outline}
        />
    )
}

export default RelationshipReviewStatusTag
