import styles from './Group.module.scss'

const Group = ({ name }) => {
    return (
        <div className={styles.group}>
            <p>{name}</p>
        </div>
    )
}

export default Group
