import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useDeleteRelationship } from '@features/relationships/relationship/hooks'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import TextInput from '@components/inputs/textInput/TextInput'

import styles from '@styles/Form.module.scss'

const Delete = () => {
    const { relationshipId } = useParams()
    const navigate = useNavigate()
    const form = useForm()

    const {
        handleSubmit,
        formState: { errors },
    } = form

    const { mutate, isLoading } = useDeleteRelationship(data =>
        navigate(`/relationships`, { replace: true })
    )

    const submit = data => mutate(data)

    return (
        <Modal
            overlay={true}
            heading={'Delete Relationship'}
            close={`/relationships/${relationshipId}`}
        >
            <FormProvider {...form}>
                <form
                    onSubmit={handleSubmit(submit)}
                    className={styles.form}
                >
                    <TextInput
                        hidden={true}
                        name={'relationshipId'}
                        defaultValue={relationshipId}
                    />

                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris.
                    </p>

                    <div className={styles.actions}>
                        <Button
                            label='Cancel'
                            onClick={() => navigate(`/relationships/${relationshipId}`)}
                            outline={true}
                            disabled={isLoading}
                            type={'button'}
                        />

                        <Button
                            label='Delete Permanently'
                            red={true}
                            disabled={isLoading}
                        />
                    </div>
                </form>
            </FormProvider>
        </Modal>
    )
}

export default Delete
