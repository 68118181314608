import { useFormContext } from 'react-hook-form'

const PasswordInput = ({
    name,
    hidden = false,
    required = false,
    defaultValue = undefined,
    ...props
}) => {
    const { register } = useFormContext()

    const registerOptions = {
        required,
        value: defaultValue,
    }

    return (
        <input
            {...props}
            {...register(name, registerOptions)}
            type={hidden ? 'hidden' : 'password'}
        />
    )
}

export default PasswordInput
