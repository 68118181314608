import { Navigate, Route, Routes } from 'react-router-dom'

import VerifyEmailAddress from './views/VerifyEmailAddress'

export const VerifyEmailRoutes = () => {
    return (
        <Routes>
            <Route
                path=''
                element={<VerifyEmailAddress />}
            />
            <Route
                path='*'
                element={
                    <Navigate
                        to='.'
                        replace
                    />
                }
            />
        </Routes>
    )
}
