import { Route } from 'react-router-dom'

import EditTeamMember from './views/EditTeamMember'
import InviteTeamMember from './views/InviteTeamMember'
import ListTeamMembers from './views/ListTeamMembers'

export const TeamMembersRoutes = () => {
    return (
        <>
            <Route
                path='team-members'
                element={<ListTeamMembers />}
            >
                <Route
                    path=':membershipId'
                    element={<EditTeamMember />}
                />
                <Route
                    path='invite'
                    element={<InviteTeamMember />}
                />
            </Route>
        </>
    )
}
