import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useGetEntities } from '@features/entities'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import EntitySelect from '@components/inputs/entitySelect/EntitySelect'
import TextInput from '@components/inputs/textInput/TextInput'

import { useCreateControllingParty } from '../hooks'

import styles from '@styles/Form.module.scss'

const AddControllingParty = () => {
    let { entityId } = useParams()

    const navigate = useNavigate()

    const form = useForm()

    const {
        handleSubmit,
        formState: { errors },
    } = form

    let entities = useGetEntities()
    const { mutate, isLoading, error } = useCreateControllingParty(data =>
        navigate(`..`, { replace: true })
    )

    if (entities.isLoading)
        return (
            <Modal
                overlay={true}
                loading={true}
                heading={'Add Controlling Party'}
            />
        )

    const onSubmit = data => {
        data.shareholdingPercentage = parseInt(data.shareholdingPercentage) / 100
        mutate(data)
    }

    return (
        <Modal
            overlay={true}
            heading={'Add Controlling Party'}
            close={`..`}
            large={true}
            loading={!entities}
            error={entities.isError}
        >
            <FormProvider {...form}>
                <form
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <>
                        {error && (
                            <p className={styles.error}>
                                There was an error submitting the form, please try again.
                            </p>
                        )}

                        <TextInput
                            hidden
                            name={'entity2Id'}
                            defaultValue={entityId}
                        />

                        <InputWrapper
                            label={'Entity'}
                            input={
                                <EntitySelect
                                    name={'entity1Id'}
                                    required
                                    entities={entities.data?.filter(
                                        entity => entity.id !== entityId
                                    )}
                                />
                            }
                            errors={errors.entity1Id}
                        />

                        <Button
                            label={'Continue'}
                            fullWidth={true}
                            loading={isLoading}
                        />
                    </>
                </form>
            </FormProvider>
        </Modal>
    )
}

export default AddControllingParty
