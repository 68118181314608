import { Route } from 'react-router-dom'

import TerminationVersion from '@features/relationships/relationship/termination/views/TerminationVersion'

export const TerminationRoutes = () => {
    return (
        <>
            <Route
                path='versions/:terminationVersionId'
                element={<TerminationVersion />}
            />
        </>
    )
}
