import { Grants, PolicyStatuses } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import Document from '@components/document/Document'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import {
    useCreateCustomPolicyVersion,
    useExportCustomPolicyVersionToPdf,
    useGetCustomPolicyById,
    useGetCustomPolicyVersionById,
    useRemoveCustomPolicyVersion,
    useRevertVersionToDraft,
    useSetCustomPolicyVersionPending,
    useSignCustomPolicyVersion,
    useUpdateCustomPolicyVersion,
} from '../hooks'

import CustomPolicyForm from './CustomPolicyForm'

const CustomPolicyVersion = () => {
    const isAllowed = useCheckGrants(Grants.ManagePolicy) ?? false

    const navigate = useNavigate()
    const { customPolicyId, customPolicyVersionId } = useParams()
    const route = `/custom-policies/${customPolicyId}`

    const form = useForm({
        shouldUnregister: true,
    })
    const {
        reset,
        trigger,
        getValues,
        formState: { isDirty },
    } = form

    const customPolicy = useGetCustomPolicyById(customPolicyId, () => {})
    const customPolicyVersion = useGetCustomPolicyVersionById(
        customPolicyVersionId,
        customPolicyId,
        data => reset(data)
    )

    const exportVersion = useExportCustomPolicyVersionToPdf(data => window.open(data, '_blank'))
    const createVersion = useCreateCustomPolicyVersion(data =>
        navigate(`${route}/versions/${data.id}`)
    )
    const updateVersion = useUpdateCustomPolicyVersion()
    const submitForApproval = useSetCustomPolicyVersionPending()
    const revertToDraft = useRevertVersionToDraft()
    const signVersion = useSignCustomPolicyVersion()
    const removeVersion = useRemoveCustomPolicyVersion(() => navigate(route))

    const exportPdf = () =>
        exportVersion.mutate({
            customPolicyId: customPolicyId as string,
            customPolicyVersionId: customPolicyVersionId as string,
        })

    const create = () => createVersion.mutate({ customPolicyId: customPolicyId as string })

    const update = async data => updateVersion.mutateAsync(data)

    const setPending = async () => {
        try {
            const isValid = await trigger()
            if (!isValid) return null
            if (isDirty) await update(getValues())
            return submitForApproval.mutate({
                customPolicyVersionId: customPolicyVersionId as string,
                customPolicyId: customPolicyId as string,
            })
        } catch {
            return null
        }
    }

    const revert = async () =>
        revertToDraft.mutate({
            customPolicyVersionId: customPolicyVersionId as string,
            customPolicyId: customPolicyId as string,
        })

    const sign = async () =>
        signVersion.mutate({
            customPolicyVersionId: customPolicyVersionId as string,
            customPolicyId: customPolicyId as string,
        })

    const remove = () =>
        removeVersion.mutate({
            customPolicyVersionId: customPolicyVersionId as string,
            customPolicyId: customPolicyId as string,
        })

    if (customPolicy.isLoading || customPolicyVersion.isLoading) return <Loading />
    if (
        customPolicy.isError ||
        customPolicyVersion.isError ||
        !customPolicy.data ||
        !customPolicyVersion.data
    )
        return <QueryError />

    return (
        <FormProvider {...form}>
            <Document
                isAllowed={isAllowed}
                pageTitle={customPolicy.data.policyName}
                showHeader={true}
                landscape={false}
                type={customPolicyVersion.data.versionName}
                showBreadcrumbs={true}
                breadcrumbs={[
                    {
                        label: <NavLink to={'/policies'}>Policies</NavLink>,
                        link: '/policies',
                    },
                ]}
                title={customPolicy.data.policyName}
                versions={customPolicy.data.versions}
                isDraft={customPolicyVersion.data.status === PolicyStatuses.Draft}
                isPending={customPolicyVersion.data.status === PolicyStatuses.Pending}
                versionId={customPolicyVersion.data.id}
                versionName={customPolicyVersion.data.versionName}
                route={`${route}/versions`}
                Form={CustomPolicyForm}
                exportPdf={{
                    function: exportPdf,
                    isLoading: exportVersion.isLoading,
                }}
                create={{
                    function: create,
                    isLoading: createVersion.isLoading,
                }}
                update={{
                    function: update,
                    isLoading: updateVersion.isLoading,
                    isSuccess: updateVersion.isSuccess,
                }}
                setPending={{
                    function: setPending,
                    isLoading: submitForApproval.isLoading,
                }}
                sign={{
                    function: sign,
                    isLoading: signVersion.isLoading,
                }}
                remove={
                    customPolicy.data.versions.length > 1
                        ? {
                              function: remove,
                              isLoading: removeVersion.isLoading,
                          }
                        : undefined
                }
                history={customPolicy.data.versions.map(version => ({
                    label: `${version.versionName}`,
                    value: version.id,
                    isDraft: version.status === PolicyStatuses.Draft,
                    isPending: version.status === PolicyStatuses.Pending,
                }))}
                requiredSignatures={customPolicyVersion.data.requiredSignatures}
                revertToDraft={
                    customPolicyVersion.data.status === PolicyStatuses.Pending
                        ? {
                              function: revert,
                              isLoading: revertToDraft.isLoading,
                          }
                        : undefined
                }
                error={
                    exportVersion.isError ||
                    createVersion.isError ||
                    updateVersion.isError ||
                    submitForApproval.isError ||
                    revertToDraft.isError ||
                    signVersion.isError ||
                    removeVersion.isError
                }
            />
        </FormProvider>
    )
}

export default CustomPolicyVersion
