import classNames from 'classnames'
import { useController, useFormContext } from 'react-hook-form'
import Select from 'react-select'

import { useGetRiskLevels } from '@features/riskLevels'

import RiskLevelTag from '@components/tag/RiskLevelTag'

const RiskLevelSelect = ({ name, required, readOnly }) => {
    const { control } = useFormContext()
    const controller = useController({
        name,
        control,
        rules: { required },
    })

    const { data: riskLevels } = useGetRiskLevels()

    if (!riskLevels) return null

    const options = riskLevels.map(riskLevel => ({ value: riskLevel.id, label: riskLevel.name }))

    return (
        <Select
            className={classNames({
                select: true,
                disabled: readOnly,
            })}
            isDisabled={readOnly}
            placeholder={''}
            value={options.find(option => option.value === controller.field.value)}
            options={options}
            onChange={option => controller.field.onChange(option?.value)}
            formatOptionLabel={({ value }) => <RiskLevelTag riskLevelId={value} />}
            styles={{
                control: styles => {
                    return {
                        ...styles,
                        background: 'none',
                    }
                },
            }}
        />
    )
}

export default RiskLevelSelect
