import { useController, useFormContext, useWatch } from 'react-hook-form'

import Button from '@components/button/Button'
import FileStoreImage from '@components/fileStore/FileStoreImage'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import AutoGrowTextArea from '@components/inputs/autoGrowTextArea/AutoGrowTextArea'
import DateInput from '@components/inputs/dateInput/DateInput'
import FileUpload from '@components/inputs/fileUpload/FileUpload'
import RiskLevelSelect from '@components/inputs/riskLevelSelect/RiskLevelSelect'
import TextInput from '@components/inputs/textInput/TextInput'

import styles from '@styles/Form.module.scss'

const RelationshipApprovalForm = ({ readOnly }: { readOnly?: boolean }) => {
    const {
        control,
        formState: { errors },
        getValues,
    } = useFormContext()

    const fileName = useController({
        control,
        name: 'fileName',
    })

    const isExternal = useWatch({ control, name: 'isExternal' })

    return (
        <>
            <TextInput
                hidden
                name={'relationshipApprovalVersionId'}
                defaultValue={getValues('id')}
            />

            {isExternal ? null : (
                <InputWrapper
                    label='Compliance Assessment'
                    description={
                        'Provide a detailed summary of the ML/FT risks posed by the relationship as well as any mitigating circumstances, the use of enhanced due diligence and/or concessions. Include all factors that have formed part of your decision making.'
                    }
                    input={
                        <AutoGrowTextArea
                            name={'comments'}
                            readOnly={readOnly}
                            large={true}
                        />
                    }
                    readOnly={readOnly}
                    errors={errors.comments}
                />
            )}

            <InputWrapper
                label={'Final Risk Classification'}
                description={'Select the final risk classification for the relationship.'}
                input={
                    <RiskLevelSelect
                        name={'finalRiskLevelId'}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                readOnly={readOnly}
                errors={errors.finalRiskLevelId}
            />

            {isExternal ? (
                <>
                    <InputWrapper
                        label={'Date'}
                        description={
                            'Enter the date that the attached approval document was signed.'
                        }
                        input={
                            <DateInput
                                name={'date'}
                                placeholder={''}
                                readOnly={readOnly}
                                required={true}
                            />
                        }
                        readOnly={readOnly}
                        errors={errors.date}
                    />

                    <InputWrapper
                        label={'Attachment'}
                        input={
                            readOnly ? (
                                <div className={styles.attachment}>
                                    <div>
                                        <FileStoreImage fileName={fileName.field.value} />
                                    </div>
                                </div>
                            ) : getValues('fileName') ? (
                                <div className={styles.attachment}>
                                    <div>
                                        <FileStoreImage fileName={fileName.field.value} />
                                    </div>
                                    <Button
                                        outline={true}
                                        label={'Remove'}
                                        onClick={() => fileName.field.onChange(null)}
                                        type={'button'}
                                    />
                                </div>
                            ) : (
                                <FileUpload
                                    onUpload={fileName.field.onChange}
                                    acceptDocuments={true}
                                />
                            )
                        }
                        readOnly={readOnly}
                    />
                </>
            ) : null}
        </>
    )
}

export default RelationshipApprovalForm
