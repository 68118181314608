import { Grants } from '@prisma/client'
import moment from 'moment'
import { useMemo } from 'react'
import { Plus } from 'react-feather'
import Helmet from 'react-helmet'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import Table from '@components/table/Table'
import Tag, { TagThemes } from '@components/tag/Tag'
import UserLabel from '@components/user/UserLabel'
import VersionStatusTag from '@components/versionStatusTag/VersionStatusTag'

import { useGetPolicies } from '../hooks'

const ListPolicies = () => {
    const navigate = useNavigate()
    const hasManagePoliciesGrant = useCheckGrants(Grants.ManagePolicy)
    const link = props => navigate(`/${props.original.path}/${props.original.id}`)

    const columns = useMemo(
        () => [
            {
                Header: 'Status',
                accessor: () => true,
                Cell: props => {
                    let status = 'First Draft'
                    let theme = TagThemes.Orange
                    if (props.row.original.latestSignedVersion) {
                        status = 'Active'
                        theme = TagThemes.Grey
                    }

                    return (
                        <Tag
                            outline={false}
                            theme={theme}
                            label={status}
                        />
                    )
                },
            },
            {
                Header: 'Name',
                accessor: 'policyName',
            },
            {
                Header: 'Latest Version',
                accessor: 'latestVersion.status',
                Cell: props => (
                    <VersionStatusTag
                        status={props.row.original.latestVersion.status}
                        versionName={props.row.original.latestVersion.versionName}
                    />
                ),
            },
            {
                Header: 'Policy Type',
                accessor: 'type',
            },
            {
                Header: 'Owner',
                accessor: 'ownerUser',
                Cell: props =>
                    props.row.original.ownerUser ? (
                        <UserLabel user={props.row.original.ownerUser} />
                    ) : undefined,
            },
            {
                Header: 'Updated On',
                accessor: 'updateDate',
                Cell: props => moment(props.value).format('MMMM DD, YYYY'),
            },
            {
                Header: 'Next Review',
                accessor: 'nextReviewDate',
                Cell: props =>
                    props.value ? moment(props.value).format('MMMM DD, YYYY') : undefined,
            },
        ],
        []
    )

    const policies = useGetPolicies()

    const list = policies.data
        ? [
              ...policies.data.customPolicies.map(x => ({
                  ...x,
                  type: 'Custom Policy',
                  path: 'custom-policies',
              })),
              {
                  ...policies.data.identityPolicy,
                  type: 'Configuration',
                  path: 'identity-policies',
              },
              {
                  ...policies.data.riskLevelPolicy,
                  type: 'Configuration',
                  path: 'risk-level-policies',
              },
              ...policies.data.riskAssessmentPolicies.map(x => ({
                  ...x,
                  type: 'Configuration',
                  path: 'risk-assessment-policies',
              })),
          ]
        : []

    console.log(list)

    return (
        <>
            <Outlet />

            <Helmet>
                <title>Policies | Proofdesk</title>
            </Helmet>

            <main>
                <div className='heading'>
                    <div>
                        <h1>Policies</h1>
                    </div>
                    {hasManagePoliciesGrant ? (
                        <NavLink
                            to={'/policies/create'}
                            className='link dark'
                        >
                            <Plus size={16} />
                            Create Policy
                        </NavLink>
                    ) : null}
                </div>

                <Table
                    data={list}
                    isLoading={policies.isLoading}
                    defaultPageSize={50}
                    columns={columns}
                    button={null}
                    link={link}
                    queryError={policies.isError}
                />
            </main>
        </>
    )
}

export default ListPolicies
