import { FormStatuses, Grants } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

import { useGetRelationship } from '@features/relationships/relationship/hooks'
import { useCheckGrants } from '@features/user'

import Document from '@components/document/Document'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import {
    useDeleteVersion,
    useExportVersionToPdf,
    useGetById,
    useGetVersionById,
    useSignVersion,
    useUpdateVersion,
} from '../hooks'

import TerminationForm from './TerminationForm'

const TerminationVersion = () => {
    const isAllowed = useCheckGrants(Grants.TerminateRelationship) ?? false

    const navigate = useNavigate()
    const { relationshipId, terminationId, terminationVersionId } = useParams()

    const form = useForm()
    const {
        reset,
        trigger,
        getValues,
        formState: { isDirty },
    } = form

    const exportVersion = useExportVersionToPdf(data => window.open(data, '_blank'))
    const relationship = useGetRelationship(relationshipId, () => {})
    const termination = useGetById(terminationId)
    const terminationVersion = useGetVersionById(terminationVersionId, data => reset(data))

    const updateVersion = useUpdateVersion()
    const signVersion = useSignVersion()
    const removeDraft = useDeleteVersion(() => navigate(`/relationships/${relationshipId}`))

    const exportPdf = () => exportVersion.mutate({ id: terminationVersionId as string })

    const update = async data => updateVersion.mutateAsync(data)

    const sign = async () => {
        try {
            const isValid = await trigger()
            if (!isValid) return null
            if (isDirty) await update(getValues())
            return signVersion.mutate({
                id: terminationVersionId as string,
            })
        } catch {
            return null
        }
    }

    const remove = async () => removeDraft.mutate({ id: terminationId as string })

    if (relationship.isLoading || termination.isLoading || terminationVersion.isLoading)
        return <Loading />
    if (
        relationship.isError ||
        termination.isError ||
        terminationVersion.isError ||
        !relationship.data ||
        !termination.data ||
        !terminationVersion.data
    )
        return <QueryError />

    return (
        <FormProvider {...form}>
            <Document
                isAllowed={isAllowed}
                pageTitle={`Termination | ${relationship.data.internalReference}`}
                showHeader={false}
                landscape={false}
                type={'Termination'}
                showBreadcrumbs={true}
                breadcrumbs={[
                    {
                        label: relationship.data.internalReference,
                        link: `/relationships/${relationshipId}`,
                    },
                ]}
                title={'Termination'}
                isDraft={terminationVersion.data.status === FormStatuses.Draft}
                isPending={false}
                versionId={terminationVersion.data.id}
                versionName={`Version ${terminationVersion.data.version}`}
                Form={TerminationForm}
                exportPdf={{
                    function: exportPdf,
                    isLoading: exportVersion.isLoading,
                }}
                update={{
                    function: update,
                    isLoading: updateVersion.isLoading,
                    isSuccess: updateVersion.isSuccess,
                }}
                remove={{
                    function: remove,
                    isLoading: removeDraft.isLoading,
                }}
                sign={{
                    function: sign,
                    isLoading: signVersion.isLoading,
                }}
                signature={{
                    user: terminationVersion.data.signature?.user as {
                        firstName: string
                        lastName: string
                    },
                    date: terminationVersion.data.signature?.createDate as Date,
                }}
                error={updateVersion.isError || signVersion.isError}
            />
        </FormProvider>
    )
}

export default TerminationVersion
