import Cookies from 'js-cookie'
import { useRoutes } from 'react-router-dom'

import { LogoutRoutes } from '@features/logout'

import { PublicRoutes } from './public'
import { RestrictedRoutes } from './restricted'

export default () => {
    const token = Cookies.get('tokenString')

    const commonRoutes = [
        {
            path: 'logout',
            element: <LogoutRoutes />,
        },
    ]

    const routes = token ? RestrictedRoutes() : PublicRoutes

    const element = useRoutes([...routes, ...commonRoutes])

    return <>{element}</>
}
