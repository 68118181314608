import { useFormContext } from 'react-hook-form'
import { Controller } from 'react-hook-form'

import { useGetEntities } from '@features/entities/hooks'

import InputWrapper from '@components/inputWrapper/InputWrapper'
import AutoGrowTextArea from '@components/inputs/autoGrowTextArea/AutoGrowTextArea'
import DateInput from '@components/inputs/dateInput/DateInput'
import EntitySelect from '@components/inputs/entitySelect/EntitySelect'
import MultiSelect from '@components/inputs/multiSelect/MultiSelect'
import TextInput from '@components/inputs/textInput/TextInput'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import pascalToReadable from '@utils/pascalToReadable'

const ControllingPartyForm = ({ readOnly = false }) => {
    const {
        formState: { errors },
        watch,
        control,
    } = useFormContext()

    const controllingPartyType = watch('controllingPartyTypes')

    const entityId = watch('controllingParty.entity2.id')
    const entityType = watch('controllingParty.entity2.entityType')

    const entities = useGetEntities()

    let controllingPartyTypes = ['']
    if (entityType === 'NaturalPerson')
        controllingPartyTypes = [
            'UnderlyingClient',
            'ThirdPartyRecipientOfFunds',
            'OtherControllingParty',
        ]
    if (entityType === 'LegalArrangement')
        controllingPartyTypes = [
            'Trustee',
            'Beneficiary',
            'Protector',
            'Enforcer',
            'Settlor',
            'UnderlyingClient',
            'ThirdPartyRecipientOfFunds',
            'OtherControllingParty',
        ]
    if (entityType === 'Foundation')
        controllingPartyTypes = [
            'CouncilMember',
            'Beneficiary',
            'Founder',
            'UnderlyingClient',
            'ThirdPartyRecipientOfFunds',
            'OtherControllingParty',
        ]
    if (entityType === 'LegalPerson')
        controllingPartyTypes = [
            'Shareholder',
            'Director',
            'UnderlyingClient',
            'ThirdPartyRecipientOfFunds',
            'OtherControllingParty',
        ]

    const controllingPartyOptions = controllingPartyTypes.map(controllingPartyType => ({
        label: pascalToReadable(controllingPartyType) as string,
        value: controllingPartyType,
    }))

    if (entities.isLoading || !entities.data) return <Loading />
    if (entities.isError || !entities.data) return <QueryError isInsideModal={true} />

    return (
        <>
            <TextInput
                hidden
                name={'controllingPartyId'}
            />

            <TextInput
                hidden
                name={'entity2Id'}
            />

            <InputWrapper
                label={'Entity'}
                input={
                    <EntitySelect
                        name={'controllingParty.entity1.id'}
                        required
                        disabled
                        entities={entities.data.filter(entity => entity.id !== entityId)}
                        readOnly={readOnly}
                    />
                }
                errors={errors.entity1Id}
                readOnly={readOnly}
            />

            <InputWrapper
                label={'Type(s)'}
                description={'Select the controlling party type(s) that apply to this entity.'}
                input={
                    <MultiSelect
                        required
                        minLength={1}
                        name={'controllingPartyTypes'}
                        options={controllingPartyOptions}
                        readOnly={readOnly}
                    />
                }
                errors={errors.controllingPartyTypes}
                readOnly={readOnly}
            />

            {controllingPartyType?.includes('Shareholder') ? (
                <InputWrapper
                    label={'Shareholding Percentage'}
                    input={
                        <Controller
                            control={control}
                            name={`shareholdingPercentage`}
                            rules={{ required: true }}
                            defaultValue={0}
                            render={({ field: { onChange, value } }) => (
                                <input
                                    type='number'
                                    min={0}
                                    max={100}
                                    value={value * 100}
                                    onChange={e => onChange(parseFloat(e.target.value) / 100)}
                                    step={'any'}
                                />
                            )}
                        />
                    }
                    readOnly={readOnly}
                    errors={errors.shareholdingPercentage}
                />
            ) : null}

            <InputWrapper
                label={'Start Date'}
                description={'Provide the date that the entity became a controlling party.'}
                input={
                    <DateInput
                        name={'startDate'}
                        placeholder={''}
                        readOnly={readOnly}
                        required
                    />
                }
                readOnly={readOnly}
                errors={errors.startDate}
            />

            <InputWrapper
                label={'End Date'}
                description={'Provide the date that the entity ceased to be a controlling party.'}
                input={
                    <DateInput
                        name={'endDate'}
                        placeholder={''}
                        readOnly={readOnly}
                    />
                }
                readOnly={readOnly}
                errors={errors.endDate}
            />

            <InputWrapper
                label={'Additional Information'}
                description={'Provide any additional information about the controlling party.'}
                input={
                    <AutoGrowTextArea
                        name={'notes'}
                        readOnly={readOnly}
                    />
                }
                errors={errors.notes}
                readOnly={readOnly}
            />
        </>
    )
}

export default ControllingPartyForm
