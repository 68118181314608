import { useController, useFormContext } from 'react-hook-form'

import styles from './NotificationCheckbox.module.scss'

const NotificationCheckbox = ({ name, description }: { name: string; description: string }) => {
    const { control } = useFormContext()
    const { field } = useController({
        control,
        name: 'notifications',
    })

    return (
        <label className={styles.notificationCheckbox}>
            <div className={styles.input}>
                <input
                    type={'checkbox'}
                    onChange={e => {
                        const checked = e.target.checked
                        const value = field.value
                        if (checked) {
                            field.onChange([...value, name])
                        } else {
                            field.onChange(value.filter(v => v !== name))
                        }
                    }}
                    checked={field.value?.includes(name)}
                />
                <span />
            </div>
            <p>{description}</p>
        </label>
    )
}

export default NotificationCheckbox
