import { Grants } from '@prisma/client'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import CreatePolicies from './views/CreatePolicies'
import ListPolicies from './views/ListPolicies'

export const PoliciesRoutes = () => {
    const hasManagePoliciesGrant = useCheckGrants(Grants.ManagePolicy)

    return (
        <Routes>
            <Route
                path='create'
                element={
                    hasManagePoliciesGrant ? <CreatePolicies /> : <Navigate to={'/policies'} />
                }
            />
            <Route
                path=''
                element={<ListPolicies />}
            />
        </Routes>
    )
}
