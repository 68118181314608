import classNames from 'classnames'
import fuzzy from 'fuzzy'
import { useController, useFormContext } from 'react-hook-form'
import Select, { components } from 'react-select'

import TextInput from '@components/inputs/textInput/TextInput'
import UserLabel from '@components/user/UserLabel'

const UserMultiSelect = ({
    users,
    name,
    required = false,
    disabled = false,
    readOnly = false,
    minLength = 0,
}) => {
    const { control, getValues } = useFormContext()
    const controller = useController({
        name,
        control,
        rules: { required, minLength },
    })

    let options: { label: string; value: string }[] = []
    if (users)
        options = users.map(user => {
            return {
                label: `${user.firstName} ${user.lastName}`,
                value: user.id,
            }
        })

    return (
        <Select
            className={classNames({
                select: true,
                disabled: readOnly,
            })}
            isDisabled={disabled || readOnly}
            components={{
                Option: props => {
                    return (
                        <components.Option {...props}>
                            {props.data.value === 'new' ? (
                                props.label
                            ) : (
                                <UserLabel
                                    user={users?.find(user => user.id === props.data.value)}
                                />
                            )}
                        </components.Option>
                    )
                },
                MultiValueLabel: props => {
                    const user = users.find(user => user.id === props.data.value)

                    return (
                        <components.MultiValueLabel {...props}>
                            <UserLabel user={user} />
                        </components.MultiValueLabel>
                    )
                },
            }}
            value={options?.filter(option => controller.field.value?.includes(option.value))}
            onChange={options => controller.field.onChange(options.map(option => option.value))}
            filterOption={(candidate, input) => {
                if (input) return fuzzy.test(input, candidate.label)
                return true
            }}
            options={options}
            isMulti={true}
        />
    )
}

export default UserMultiSelect
