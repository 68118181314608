import { useFormContext } from 'react-hook-form'

const NumberInput = ({
    inline,
    readOnly = false,
    name,
    hidden = false,
    required = false,
    defaultValue = undefined,
    ...props
}: {
    inline?: boolean
    name: string
    hidden?: boolean
    required?: boolean
    defaultValue?: number
    [key: string]: any
}) => {
    const { register, getValues } = useFormContext()

    const registerOptions = {
        required,
        value: defaultValue,
        valueAsNumber: true,
    }

    if (readOnly)
        return (
            <div className={'input blank'}>
                <p>{getValues(name)}</p>
            </div>
        )

    return (
        <input
            {...props}
            {...register(name, registerOptions)}
            type={hidden ? 'hidden' : 'number'}
            step={'any'}
            className={inline ? 'inline' : ''}
        />
    )
}

export default NumberInput
