import { trpc } from '@utils/trpc'

export const useGetCustomPolicyById = (id, onSuccess) =>
    trpc.customPolicies.getById.useQuery({ id }, { enabled: !!id, onSuccess })

export const useCreateCustomPolicy = onSuccess => {
    const context = trpc.useContext()

    return trpc.customPolicies.create.useMutation({
        onSuccess,
        onSettled: () => context.customPolicies.invalidate(),
    })
}

export const useGetCustomPolicyVersionById = (customPolicyVersionId, customPolicyId, onSuccess) =>
    trpc.customPolicies.getVersionById.useQuery(
        { customPolicyVersionId, customPolicyId },
        { enabled: !!(customPolicyVersionId && customPolicyId), onSuccess }
    )

export const useExportCustomPolicyVersionToPdf = onSuccess =>
    trpc.customPolicies.exportVersionToPdf.useMutation({ onSuccess })

export const useCreateCustomPolicyVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.customPolicies.createVersion.useMutation({
        onSuccess,
        onSettled: () => context.customPolicies.invalidate(),
    })
}

export const useUpdateCustomPolicyVersion = () => {
    const context = trpc.useContext()

    return trpc.customPolicies.updateVersion.useMutation({
        onSuccess: () => context.customPolicies.invalidate(),
    })
}

export const useSetCustomPolicyVersionPending = () => {
    const context = trpc.useContext()

    return trpc.customPolicies.setVersionPending.useMutation({
        onSettled: () => context.customPolicies.invalidate(),
    })
}

export const useRevertVersionToDraft = () => {
    const context = trpc.useContext()

    return trpc.customPolicies.revertVersionToDraft.useMutation({
        onSettled: () => context.customPolicies.invalidate(),
    })
}

export const useSignCustomPolicyVersion = () => {
    const context = trpc.useContext()

    return trpc.customPolicies.createVersionSignature.useMutation({
        onSettled: () => context.customPolicies.invalidate(),
    })
}

export const useRemoveCustomPolicyVersion = onSuccess => {
    const context = trpc.useContext()

    return trpc.customPolicies.deleteVersion.useMutation({
        onSuccess,
        onSettled: () => context.customPolicies.invalidate(),
    })
}
