import { EntityIdentityComponents } from '@proofdesk/common'

import InputWrapper from '@components/inputWrapper/InputWrapper'

import pascalToReadable from '@utils/pascalToReadable'

const IdentityComponentInput = ({
    entityType,
    name,
    input,
    readOnly,
    errors,
}: {
    entityType: string
    name: string
    input: JSX.Element
    readOnly: boolean
    errors?: any
}) => {
    if (!EntityIdentityComponents[entityType].includes(name)) return null

    return (
        <InputWrapper
            label={pascalToReadable(name)}
            input={input}
            readOnly={readOnly}
            errors={errors}
        />
    )
}

export default IdentityComponentInput
