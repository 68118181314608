import { trpc } from '@utils/trpc'

export const useGetRelationshipDetailsVersion = (relationshipDetailsVersionId, onSuccess) =>
    trpc.relationshipDetails.getVersionById.useQuery(
        { relationshipDetailsVersionId },
        { enabled: !!relationshipDetailsVersionId, onSuccess }
    )

export const useExportVersionToPdf = onSuccess =>
    trpc.relationshipDetails.exportVersionToPdf.useMutation({ onSuccess })

export const useUpdateRelationshipDetailsVersion = () => {
    const context = trpc.useContext()

    return trpc.relationshipDetails.updateVersion.useMutation({
        onSuccess: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.relationshipDetails.invalidate()
        },
    })
}

export const useRevertRelationshipDetailsVersionToDraft = () => {
    const context = trpc.useContext()

    return trpc.relationshipDetails.revertVersionToDraft.useMutation({
        onSettled: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.relationshipDetails.invalidate()
        },
    })
}

export const useCreateRelationshipDetailsVersionSignature = () => {
    const context = trpc.useContext()

    return trpc.relationshipDetails.createVersionSignature.useMutation({
        onSettled: async () => {
            await context.relationships.invalidate()
            await context.relationshipReviews.invalidate()
            await context.relationshipDetails.invalidate()
        },
    })
}
