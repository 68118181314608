import { useNavigate, useParams } from 'react-router-dom'

import { useGetEnquiryById } from '../hooks'

const Enquiry = () => {
    const { enquiryId } = useParams()
    const navigate = useNavigate()

    useGetEnquiryById(enquiryId, data => {
        navigate(`/reporting/enquiries/${enquiryId}/versions/${data.latestVersion.id}`)
    })

    return null
}

export default Enquiry
