import { Grants } from '@prisma/client'
import { NavLink, useParams } from 'react-router-dom'

import ReviewSelect from '@features/relationships/relationship/components/sidebar/checklist/reviewSelect/ReviewSelect'
import {
    useExportReviewToPdf,
    useGetRelationship,
    useGetRelationshipReview,
} from '@features/relationships/relationship/hooks'
import { useCheckGrants } from '@features/user'

import Button from '@components/button/Button'
import More from '@components/more/More'

import styles from './Header.module.scss'

const Header = () => {
    const hasManageRelationshipGrant = useCheckGrants(Grants.ManageRelationship) ?? false
    const { relationshipId, relationshipReviewId } = useParams()

    const relationship = useGetRelationship(relationshipId, () => {})
    const relationshipReview = useGetRelationshipReview(
        relationshipId,
        relationshipReviewId,
        () => {}
    )
    const exportReview = useExportReviewToPdf(data => window.open(data, '_blank'))

    const isCompleted = relationshipReview.data?.status === 'Completed'
    const isTermination = !relationshipReviewId

    const isFirstReview = relationship.data?.reviews?.length === 1

    return (
        <div className={styles.header}>
            <p>Reviews</p>
            <div>
                <ReviewSelect />

                {(hasManageRelationshipGrant || isCompleted) && !isTermination ? (
                    <More>
                        {hasManageRelationshipGrant &&
                        !isCompleted &&
                        !isTermination &&
                        !isFirstReview ? (
                            <NavLink
                                to={`/relationships/${relationshipId}/reviews/${relationshipReviewId}/abort`}
                            >
                                Abort Review
                            </NavLink>
                        ) : null}
                        {isCompleted ? (
                            <Button
                                label={'Export Review'}
                                onClick={() =>
                                    exportReview.mutate({
                                        relationshipId: relationshipId as string,
                                        relationshipReviewId: relationshipReviewId as string,
                                    })
                                }
                                loading={exportReview.isLoading}
                                outline={true}
                            />
                        ) : null}
                    </More>
                ) : null}
            </div>
        </div>
    )
}

export default Header
