import styles from './User.module.scss'
import classNames from 'classnames'

const UserAvatar = ({ user, light = false, large = false }) => {
    if (!user) return null
    if (!user.firstName || !user.lastName) return null

    const initials = user.firstName.charAt(0) + user.lastName.charAt(0)

    return (
        <div
            className={classNames(styles.userAvatar, {
                [styles.light]: light,
                [styles.large]: large,
            })}
        >
            <span>{initials}</span>
        </div>
    )
}

export default UserAvatar
