import { FormStatuses } from '@prisma/client'
import { FormProvider, useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

import Document from '@components/document/Document'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import {
    useCreateEnquiryVersion,
    useGetEnquiryById,
    useGetEnquiryVersionById,
    useRemoveEnquiryVersion,
    useSignEnquiryVersion,
    useUpdateEnquiryVersion,
} from '../hooks'

import EnquiryForm from './EnquiryForm'

const EnquiryVersion = () => {
    const navigate = useNavigate()
    const { enquiryId, enquiryVersionId } = useParams()
    const route = `/reporting/enquiries/${enquiryId}`

    const form = useForm({
        shouldUnregister: true,
    })
    const {
        reset,
        trigger,
        getValues,
        formState: { isDirty },
    } = form

    const enquiry = useGetEnquiryById(enquiryId, () => {})
    const enquiryVersion = useGetEnquiryVersionById(enquiryVersionId, data => reset(data))

    const createVersion = useCreateEnquiryVersion(data => navigate(`${route}/versions/${data.id}`))
    const updateVersion = useUpdateEnquiryVersion()
    const signVersion = useSignEnquiryVersion()
    const removeVersion = useRemoveEnquiryVersion(() => navigate(route))

    const create = () => createVersion.mutate({ id: enquiryId as string })

    const update = async data => updateVersion.mutateAsync(data)

    const sign = async () => {
        try {
            const isValid = await trigger()
            if (!isValid) return null
            if (isDirty) await update(getValues())
            return signVersion.mutate({
                id: enquiryVersionId as string,
            })
        } catch {
            return null
        }
    }

    const remove = () => removeVersion.mutate({ id: enquiryVersionId as string })

    if (enquiry.isLoading || enquiryVersion.isLoading) return <Loading />
    if (enquiry.isError || enquiryVersion.isError || !enquiry.data || !enquiryVersion.data)
        return <QueryError />

    return (
        <FormProvider {...form}>
            <Document
                isAllowed={true}
                pageTitle={`${enquiry.data.internalReference} | ML / TF Enquiries`}
                showHeader={true}
                landscape={false}
                type={'Enquiry'}
                showBreadcrumbs={true}
                breadcrumbs={[
                    {
                        label: <NavLink to={'/reporting/enquiries'}>ML /TF Enquiries</NavLink>,
                        link: '/reporting/enquiries',
                    },
                ]}
                title={enquiry.data.internalReference}
                versions={enquiry.data.versions}
                isDraft={enquiryVersion.data.status === FormStatuses.Draft}
                isPending={false}
                versionId={enquiryVersion.data.id}
                versionName={`Version ${enquiryVersion.data.version}`}
                route={`${route}/versions`}
                Form={EnquiryForm}
                create={{
                    function: create,
                    isLoading: createVersion.isLoading,
                }}
                update={{
                    function: update,
                    isLoading: updateVersion.isLoading,
                    isSuccess: updateVersion.isSuccess,
                }}
                sign={{
                    function: sign,
                    isLoading: signVersion.isLoading,
                }}
                remove={
                    enquiry.data.versions.length > 1
                        ? {
                              function: remove,
                              isLoading: removeVersion.isLoading,
                          }
                        : undefined
                }
                signature={{
                    user: enquiryVersion.data.signature?.user as {
                        firstName: string
                        lastName: string
                    },
                    date: enquiryVersion.data.signature?.createDate as Date,
                }}
                history={enquiry.data.versions.map(version => ({
                    label: `Version ${version.version}`,
                    value: version.id,
                    isDraft: version.status === FormStatuses.Draft,
                    isPending: false,
                }))}
                error={
                    createVersion.isError ||
                    updateVersion.isError ||
                    signVersion.isError ||
                    removeVersion.isError
                }
            />
        </FormProvider>
    )
}

export default EnquiryVersion
