import { useFieldArray, useFormContext } from 'react-hook-form'

import Button from '@components/button/Button'

import RiskLevelInput from './RiskLevelInput'

import styles from './RiskLevelTable.module.scss'

const RiskLevelTable = ({ name, readOnly }) => {
    const { control } = useFormContext()

    const riskLevelVersions = useFieldArray({
        control,
        name,
    })

    return (
        <>
            <div className={styles.riskLevelTable}>
                <div className={styles.header}>
                    <p>Name</p>
                    <p>Statistical Return</p>
                    <p>Colour</p>
                    <p>Review Frequency</p>
                    <p>Countersignatures</p>
                    <p>Justification</p>
                </div>
                {riskLevelVersions.fields.map((riskLevelVersion, index) => (
                    <RiskLevelInput
                        key={riskLevelVersion.id}
                        name={`${name}.${index}`}
                        readOnly={readOnly}
                    />
                ))}
            </div>
            {!readOnly ? (
                <Button
                    label={'Add Risk Level'}
                    onClick={() =>
                        riskLevelVersions.append({ version: { unacceptableRisk: false } })
                    }
                    type={'button'}
                    grey={true}
                />
            ) : null}
        </>
    )
}

export default RiskLevelTable
