import classNames from 'classnames'
import fuzzy from 'fuzzy'
import { useController, useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Select, { components } from 'react-select'

import EntityLabel from '@components/entity/EntityLabel'

const EntitySelect = ({ entities, name, required = false, disabled = false, readOnly = false }) => {
    const navigate = useNavigate()

    const { control, getValues } = useFormContext()
    const controller = useController({
        name,
        control,
        rules: { required },
    })

    let options: { label: string; value: string }[] = []
    if (entities)
        options = entities.map(entity => {
            return {
                label: entity.name,
                value: entity.id,
            }
        })

    if (readOnly && !getValues(name)) return <div className={'input blank'} />

    return (
        <Select
            className={classNames({
                select: true,
                disabled: readOnly,
            })}
            isDisabled={disabled || readOnly}
            // styles={{
            //     menuList: provided => {
            //         return {
            //             ...provided,
            //             '& >:first-of-type': {
            //                 borderBottom: '1px solid #c9c9c9',
            //             },
            //         }
            //     },
            // }}
            components={{
                Option: props => {
                    const entity = entities.find(entity => entity.id === props.data.value)

                    return (
                        <components.Option {...props}>
                            {props.data.value === 'new' ? (
                                props.label
                            ) : (
                                <EntityLabel
                                    id={entity.id}
                                    name={entity.name}
                                    entityType={entity.entityType}
                                />
                            )}
                        </components.Option>
                    )
                },
                SingleValue: props => {
                    const entity = entities.find(entity => entity.id === props.getValue()[0]?.value)

                    return (
                        <components.SingleValue {...props}>
                            {!props.hasValue ? (
                                props.children
                            ) : (
                                <EntityLabel
                                    id={entity.id}
                                    name={entity.name}
                                    entityType={entity.entityType}
                                />
                            )}
                        </components.SingleValue>
                    )
                },
            }}
            value={options.find(({ value }) => value === controller.field.value)}
            onChange={option => {
                if (option?.value === 'new') navigate(`/entities/create`)

                controller.field.onChange(option?.value)
            }}
            filterOption={(candidate, input) => {
                if (input) return candidate.value === 'new' || fuzzy.test(input, candidate.label)
                return true
            }}
            options={[
                // {
                //     value: 'new',
                //     label: '+ New Entity',
                // },
                ...options,
            ]}
        />
    )
}

export default EntitySelect
