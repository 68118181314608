import RelationshipReviewStatusTag from '@components/tag/RelationshipReviewStatusTag'
import Tag, { TagThemes } from '@components/tag/Tag'

import styles from './ReviewTag.module.scss'

const ReviewTag = ({ status, name }: { status: string; name: string }) => {
    return (
        <div className={styles.reviewTag}>
            <RelationshipReviewStatusTag status={status} />
            <p>{name}</p>
        </div>
    )
}

export default ReviewTag
