import { useEffect } from 'react'
import { Check, Download, FileText } from 'react-feather'
import { useNavigate } from 'react-router-dom'

import Modal from '@layouts/modal/Modal'

import Button from '@components/button/Button'
import RandomProgressBar from '@components/progressBar/RandomProgressBar'

import downloadFile from '@utils/downloadFile'

import { useCreateStatisticalReturn, useDownloadStatisticalReturn } from '../hooks'

import styles from './CreateStatisticalReturn.module.scss'

const CreateStatisticalReturn = () => {
    const navigate = useNavigate()

    useEffect(() => {
        onSubmit({})
    }, [])

    const { mutate, isLoading, data } = useCreateStatisticalReturn()

    const onSubmit = data => mutate(data)

    const statReturn = useDownloadStatisticalReturn(data?.fileName, { onSuccess: () => {} })

    const onDownload = () => {
        if (!statReturn.data || !data?.fileName) return
        downloadFile(
            { base64String: statReturn.data.base64String, mimeType: statReturn.data.mimeType },
            data?.fileName
        )
    }

    if (data) {
        return (
            <Modal
                overlay
                close={'/reporting/statistical-returns'}
                heading={'Statistical Return Generated'}
                description={'Saved to Statistical Returns under "Reporting"'}
            >
                <div className={styles.fileContainer}>
                    <div className={styles.file}>
                        <FileText size={64} />
                        <Check
                            className={styles.check}
                            size={27}
                            color={'#FFFFFF'}
                        />
                    </div>
                    <p>
                        <small>{data.fileName}</small>
                    </p>
                </div>
                <div className={styles.buttons}>
                    <Button
                        grey
                        label={'Close'}
                        onClick={() => navigate('/reporting/statistical-returns')}
                    />
                    <Button
                        label={
                            <>
                                <Download size={16} />
                                Download
                            </>
                        }
                        onClick={onDownload}
                    />
                </div>
            </Modal>
        )
    }
    return (
        <Modal overlay>
            <h1>Generating Statistical Return...</h1>
            <RandomProgressBar />
        </Modal>
    )
}

export default CreateStatisticalReturn
