import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import Responses from '@features/relationships/relationship/customerRiskAssessment/components/responses/Responses'

import InputWrapper from '@components/inputWrapper/InputWrapper'
import AutoGrowTextArea from '@components/inputs/autoGrowTextArea/AutoGrowTextArea'
import RiskLevelSelect from '@components/inputs/riskLevelSelect/RiskLevelSelect'
import TextInput from '@components/inputs/textInput/TextInput'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import { useGetCustomerRiskAssessmentVersion } from '../hooks'

const InternalCustomerRiskAssessmentForm = ({ readOnly }: { readOnly?: boolean }) => {
    const { customerRiskAssessmentVersionId } = useParams()

    const customerRiskAssessmentVersion = useGetCustomerRiskAssessmentVersion(
        customerRiskAssessmentVersionId,
        () => {}
    )

    const {
        formState: { errors },
        getValues,
    } = useFormContext()

    if (customerRiskAssessmentVersion.isLoading) return <Loading />

    if (customerRiskAssessmentVersion.isError || !customerRiskAssessmentVersion.data)
        return <QueryError isInsideModal={true} />

    return (
        <>
            <TextInput
                hidden
                name={'customerRiskAssessmentVersionId'}
                defaultValue={getValues('id')}
            />

            <Responses readOnly={readOnly} />

            <InputWrapper
                label='Additional Risks'
                description={
                    'Please list and explain any additional ML/FT risks that have been identified.'
                }
                input={
                    <AutoGrowTextArea
                        name={'additionalRisks'}
                        readOnly={readOnly}
                    />
                }
                readOnly={readOnly}
                errors={errors.additionalRisks}
            />

            <InputWrapper
                label='Assessment of Risks'
                description={
                    'Provide a detailed assessment of the level of ML/FT risk posed by this relationship, the customer and any third parties.'
                }
                input={
                    <AutoGrowTextArea
                        name={'assessmentOfRisks'}
                        readOnly={readOnly}
                        large={true}
                    />
                }
                readOnly={readOnly}
                errors={errors.assessmentOfRisks}
            />

            <InputWrapper
                label={'Risk Classification'}
                description={
                    'Select your proposed risk classification for this relationship based on your ML/FT risk assessment.'
                }
                input={
                    <RiskLevelSelect
                        name={'riskLevelId'}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                readOnly={readOnly}
                errors={errors.riskLevelId}
            />
        </>
    )
}

export default InternalCustomerRiskAssessmentForm
