import { useNavigate, useParams } from 'react-router-dom'

import { useGetDisclosureById } from '@features/reports/disclosures/hooks'

const Disclosure = () => {
    const { disclosureId } = useParams()
    const navigate = useNavigate()

    useGetDisclosureById(disclosureId, data => {
        navigate(`/reporting/disclosures/${disclosureId}/versions/${data.latestVersion.id}`)
    })

    return null
}

export default Disclosure
