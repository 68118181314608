import { DisclosureReasons, DisclosureTo, DisclosureTypes } from '@prisma/client'
import { useFormContext } from 'react-hook-form'

import { useGetEntities } from '@features/entities'

import InputWrapper from '@components/inputWrapper/InputWrapper'
import EntityMultiSelect from '@components/inputs/entityMultiSelect/EntityMultiSelect'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'
import Loading from '@components/loading/Loading'
import QueryError from '@components/queryError/QueryError'

import pascalToReadable from '@utils/pascalToReadable'

const DisclosureForm = ({ readOnly = false }) => {
    const {
        register,
        formState: { errors },
        watch,
    } = useFormContext()

    const entities = useGetEntities()

    const InternalDisclosureReasons = {
        [DisclosureReasons.MoneyLaundering]: DisclosureReasons.MoneyLaundering,
        [DisclosureReasons.TerrorismFinancing]: DisclosureReasons.TerrorismFinancing,
        [DisclosureReasons.SuspectedBreachOfSanctions]:
            DisclosureReasons.SuspectedBreachOfSanctions,
    }

    const disclosureType = watch('disclosureType')

    if (entities.isLoading) return <Loading />
    if (entities.isError || !entities.data) return <QueryError isInsideModal={true} />

    return (
        <>
            <TextInput
                hidden
                name='id'
            />

            <InputWrapper
                label='Internal Reference'
                input={
                    <TextInput
                        name={'internalReference'}
                        required
                        readOnly={readOnly}
                    />
                }
                errors={errors.internalReference}
                readOnly={readOnly}
            />

            <InputWrapper
                label='Disclosure Type'
                input={
                    <Select
                        required
                        name={'disclosureType'}
                        options={Object.keys(DisclosureTypes).map(disclosureType => ({
                            label: pascalToReadable(disclosureType),
                            value: disclosureType,
                        }))}
                        readOnly={readOnly}
                    />
                }
                errors={errors.disclosureType}
                readOnly={readOnly}
            />

            <InputWrapper
                label='Entities'
                input={
                    <EntityMultiSelect
                        name={'entityIds'}
                        entities={entities.data}
                        isDisabled={entities.isLoading}
                        readOnly={readOnly}
                    />
                }
                errors={errors.entityIds}
                readOnly={readOnly}
            />

            <InputWrapper
                label={'Reason'}
                input={
                    <Select
                        name={'reason'}
                        options={Object.keys(
                            disclosureType === DisclosureTypes.Internal
                                ? InternalDisclosureReasons
                                : DisclosureReasons
                        ).map(disclosureReason => ({
                            label: pascalToReadable(disclosureReason),
                            value: disclosureReason,
                        }))}
                        readOnly={readOnly}
                        required
                    />
                }
                errors={errors.reason}
                readOnly={readOnly}
            />

            <InputWrapper
                label={'Details'}
                input={
                    <textarea
                        {...register('details')}
                        rows={10}
                        disabled={readOnly}
                    />
                }
                errors={errors.details}
                readOnly={readOnly}
            />

            {disclosureType === DisclosureTypes.Internal ? (
                <InputWrapper
                    label={'Reported To'}
                    input={
                        <Select
                            name={'to'}
                            options={Object.keys(DisclosureTo).map(disclosureTo => ({
                                label: pascalToReadable(disclosureTo),
                                value: disclosureTo,
                            }))}
                            readOnly={readOnly}
                            required
                        />
                    }
                    errors={errors.to}
                    readOnly={readOnly}
                />
            ) : null}

            {disclosureType === DisclosureTypes.External ? (
                <InputWrapper
                    label={'FIU Reference Number'}
                    input={<TextInput name={'fiuReference'} />}
                    errors={errors.fiuReference}
                    readOnly={readOnly}
                />
            ) : null}
        </>
    )
}

export default DisclosureForm
