import { trpc } from '@utils/trpc'

export const useSwapTokenTwoFactorAuth = onSuccess =>
    trpc.authTokens.swapTokenTwoFactorAuth.useMutation({
        onSuccess,
    })
export const useGenerateTwoFactorAuthCandidateSecret = () =>
    trpc.users.generateCandidateTwoFactorAuthSecret.useQuery(undefined, {
        refetchOnMount: false,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    })
export const useSetTwoFactorAuthSecret = onSuccess =>
    trpc.users.setSelfTwoFactorAuthSecret.useMutation({ onSuccess })
