import classNames from 'classnames'
import React from 'react'
import { useParams } from 'react-router-dom'

import Options from '@features/relationships/relationship/customerRiskAssessment/components/options/Options'

import InputWrapper from '@components/inputWrapper/InputWrapper'
import AutoGrowTextArea from '@components/inputs/autoGrowTextArea/AutoGrowTextArea'
import TextInput from '@components/inputs/textInput/TextInput'

import { useGetCustomerRiskAssessmentVersion } from '../../hooks'

import styles from './Responses.module.scss'

const Responses = ({ readOnly }) => {
    const { customerRiskAssessmentVersionId } = useParams()

    const { data: customerRiskAssessmentVersion } = useGetCustomerRiskAssessmentVersion(
        customerRiskAssessmentVersionId,
        () => {}
    )
    if (!customerRiskAssessmentVersion) return null

    return (
        <div className={styles.responses}>
            {customerRiskAssessmentVersion.riskAssessmentAppliedTemplate?.questions.map(
                (question, index) => (
                    <InputWrapper
                        label={question.title}
                        input={
                            <div
                                className={classNames(styles.response, {
                                    [styles.disabled]: readOnly,
                                })}
                            >
                                <TextInput
                                    hidden
                                    name={`responses.${index}.questionId`}
                                    defaultValue={question.riskAssessmentTemplateQuestionId}
                                />

                                <Options
                                    index={index}
                                    options={question.options}
                                    disabled={readOnly}
                                />

                                <AutoGrowTextArea
                                    name={`responses.${index}.comments`}
                                    readOnly={readOnly}
                                    placeholder={'Comments'}
                                />
                            </div>
                        }
                    />
                )
            )}
        </div>
    )
}

export default Responses
