import { Grants } from '@prisma/client'
import { Flag } from 'react-feather'
import { NavLink, useNavigate, useParams } from 'react-router-dom'

import {
    useFlagForReview,
    useGetRelationship,
    useUnflagForReview,
} from '@features/relationships/relationship/hooks'
import { useCreateTermination } from '@features/relationships/relationship/termination/hooks'
import { useCheckGrants } from '@features/user'

import CircularProgress from '@components/circularProgress/CircularProgress'
import EntityLabel from '@components/entity/EntityLabel'
import Loading from '@components/loading/Loading'
import More from '@components/more/More'
import RelationshipStatusTag from '@components/tag/RelationshipStatusTag'

import styles from './Header.module.scss'

const Header = () => {
    const hasManageRelationshipGrant = useCheckGrants(Grants.ManageRelationship) ?? false
    const hasTerminateRelationshipGrant = useCheckGrants(Grants.TerminateRelationship) ?? false

    const navigate = useNavigate()
    const { relationshipId } = useParams()

    const relationship = useGetRelationship(relationshipId, () => {})

    const createTermination = useCreateTermination(data =>
        navigate(
            `/relationships/${relationshipId}/termination/${data.id}/versions/${data.versions[0].id}`
        )
    )

    const flag = useFlagForReview()
    const unflag = useUnflagForReview()

    const handleTerminate = () =>
        createTermination.mutate({ relationshipId: relationshipId as string })

    if (!relationship.data) return null

    return (
        <div className={styles.header}>
            <div className={styles.reference}>
                <div>
                    <RelationshipStatusTag status={relationship.data.status} />
                    {relationship.data.checklistPercentProgress ? (
                        <CircularProgress
                            value={relationship.data.checklistPercentProgress * 100}
                        />
                    ) : null}
                    {relationship.data.isFlaggedForReview ? (
                        <Flag
                            size={18}
                            color={'#295fe7'}
                        />
                    ) : null}
                </div>
                <div>
                    <h1>{relationship.data.internalReference}</h1>
                </div>
                <EntityLabel
                    id={relationship.data.entity?.id}
                    name={relationship.data.entity?.name}
                    entityType={relationship.data.entity?.entityType}
                    clickable
                />
            </div>
            {hasManageRelationshipGrant ? (
                <More>
                    {relationship.data.status === 'Active' ? (
                        relationship.data.isFlaggedForReview ? (
                            <button
                                onClick={() =>
                                    unflag.mutate({
                                        relationshipId: relationshipId as string,
                                    })
                                }
                            >
                                {unflag.isLoading ? <Loading /> : 'Unflag for Review'}
                            </button>
                        ) : (
                            <button
                                onClick={() =>
                                    flag.mutate({
                                        relationshipId: relationshipId as string,
                                    })
                                }
                            >
                                {flag.isLoading ? <Loading /> : 'Flag for Review'}
                            </button>
                        )
                    ) : null}
                    <NavLink to={`/relationships/${relationshipId}/edit`}>
                        Edit Relationship
                    </NavLink>
                    {hasTerminateRelationshipGrant && !relationship.data.termination ? (
                        <button onClick={handleTerminate}>Terminate Relationship</button>
                    ) : null}
                    <NavLink to={`/relationships/${relationshipId}/delete`}>
                        Delete Relationship
                    </NavLink>
                </More>
            ) : null}
        </div>
    )
}

export default Header
