import { Grants } from '@prisma/client'
import fuzzy from 'fuzzy'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { Plus } from 'react-feather'
import { Helmet } from 'react-helmet'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'

import { useCheckGrants } from '@features/user'

import EntityLabel from '@components/entity/EntityLabel'
import EntityRelationshipsPreview from '@components/entityRelationshipsPreview/EntityRelationshipsPreview'
import Search from '@components/inputs/search/Search'
import Table from '@components/table/Table'
import WarningFlagTags from '@components/tag/WarningFlagTags'

import { useGetEntities } from '../hooks'

const ListEntities = () => {
    const hasManageEntityGrant = useCheckGrants(Grants.ManageEntity)

    const [search, setSearch] = useState('')

    const navigate = useNavigate()
    const link = props => navigate(`/entities/${props.original.id}`)

    const columns = useMemo(
        () => [
            {
                Header: 'Legal Name',
                accessor: 'name',
                Cell: props => (
                    <EntityLabel
                        id={props.row.original.id}
                        name={props.row.original.name}
                        entityType={props.row.original.entityType}
                        clickable
                    />
                ),
            },
            {
                Header: 'D.O.B',
                accessor: 'dateOfBirth',
                Cell: props => (props.value ? moment(props.value).format('DD/MM/YYYY') : undefined),
            },
            {
                Header: 'Date of Inc.',
                accessor: 'incorporationDate',
                Cell: props => (props.value ? moment(props.value).format('DD/MM/YYYY') : undefined),
            },
            {
                Header: 'Personal ID',
                accessor: 'personalIdentificationNumber',
                Cell: props => props.value,
            },
            {
                Header: 'Registration ID',
                accessor: 'registrationNumber',
                Cell: props => props.value,
            },
            {
                Header: 'Country',
                accessor: 'country',
                Cell: props => props.value,
            },
            {
                Header: 'Relationships',
                accessor: 'relationships',
                Cell: props => <EntityRelationshipsPreview relationships={props.value} />,
            },
            {
                Header: 'Warning Flags',
                accessor: 'warningFlags',
                Cell: props => <WarningFlagTags flags={props.value} />,
            },
            {
                Header: 'Updated On',
                accessor: 'updateDate',
                Cell: props => moment(props.value).format('MMMM Do, YYYY'),
            },
        ],
        []
    )

    const entities = useGetEntities()

    const fuzzyResults = fuzzy
        .filter(search, entities.data || [], {
            extract: entity => entity.name || '',
        })
        .map(result => result.original)

    const resultsWithHeadings = fuzzyResults.reduce((accumulator: any[], currentValue, index) => {
        if (currentValue.name) {
            const currentFirstLetter = currentValue.name[0] as string
            const previousFirstLetter = accumulator[accumulator.length - 1]?.name[0] as string
            if (index === 0 || currentFirstLetter !== previousFirstLetter) {
                accumulator.push({ groupHeading: true, name: currentFirstLetter })
            }
        }

        accumulator.push(currentValue)

        return accumulator
    }, [])

    return (
        <>
            <Outlet />

            <Helmet>
                <title>Entities | Proofdesk</title>
            </Helmet>

            <Search
                search={search}
                setSearch={setSearch}
            />

            {entities.isLoading || entities.isError || entities.data?.length ? (
                <Table
                    isLoading={entities.isLoading}
                    button={null}
                    columns={columns}
                    data={resultsWithHeadings ?? []}
                    defaultPageSize={50}
                    link={link}
                    queryError={entities.isError}
                />
            ) : (
                <div className={'nothingToShow'}>
                    <p>You haven't created any entities yet.</p>
                    {hasManageEntityGrant ? (
                        <NavLink
                            to={'create'}
                            className='link dark'
                        >
                            <Plus size={16} />
                            Create Entity
                        </NavLink>
                    ) : null}
                </div>
            )}
        </>
    )
}

export default ListEntities
