import { DocumentCertificationTypes, DocumentSources } from '@prisma/client'
import { useFormContext } from 'react-hook-form'

import Attachment from '@components/attachment/Attachment'
import InputWrapper from '@components/inputWrapper/InputWrapper'
import AutoGrowTextArea from '@components/inputs/autoGrowTextArea/AutoGrowTextArea'
import DateInput from '@components/inputs/dateInput/DateInput'
import MultiSelect from '@components/inputs/multiSelect/MultiSelect'
import Select from '@components/inputs/select/Select'
import TextInput from '@components/inputs/textInput/TextInput'

import pascalToReadable from '@utils/pascalToReadable'

const DocumentForm = ({ readOnly = false }) => {
    const {
        getValues,
        formState: { errors },
        watch,
    } = useFormContext()

    const documentSource = watch('documentSource')
    const entityType = watch('document.entity.entityType')

    let fields: any[] = []

    if (entityType === 'NaturalPerson')
        fields = [
            'title',
            'name',
            'previousNames',
            'otherNamesUsed',
            'dateOfBirth',
            'gender',
            'personalIdentificationNumber',
            'nationalities',
            'placeOfBirth',
            'occupation',
            'employer',
            'residentialAddress',
            'contactPerson',
            'emailAddress',
            'phoneNumber',
            'correspondenceAddress',
            'faceToFace',
            'pepStatus',
            'sanctionsStatus',
            'adverseMediaStatus',
            'sourceOfFunds',
            'sourceOfWealth',
            'taxResidencies',
        ]

    if (entityType === 'LegalArrangement')
        fields = [
            'name',
            'previousNames',
            'tradingNames',
            'jurisdiction',
            'incorporationDate',
            'registrationNumber',
            'legalArrangementType',
            'principalPlaceOfBusiness',
            'contactPerson',
            'emailAddress',
            'phoneNumber',
            'correspondenceAddress',
            'descriptionOfOwnershipStructure',
            'principalActivity',
            'descriptionOfBusinessUndertaken',
            'sanctionsStatus',
            'adverseMediaStatus',
            'sourceOfFunds',
        ]

    if (entityType === 'Foundation')
        fields = [
            'name',
            'previousNames',
            'tradingNames',
            'jurisdiction',
            'incorporationDate',
            'registrationNumber',
            'statutoryProvision',
            'registeredOfficeAddress',
            'principalPlaceOfBusiness',
            'contactPerson',
            'emailAddress',
            'phoneNumber',
            'correspondenceAddress',
            'descriptionOfOwnershipStructure',
            'principalActivity',
            'descriptionOfBusinessUndertaken',
            'sanctionsStatus',
            'adverseMediaStatus',
            'sourceOfFunds',
            'registeredForVat',
        ]

    if (entityType === 'LegalPerson')
        fields = [
            'name',
            'previousNames',
            'tradingNames',
            'jurisdiction',
            'incorporationDate',
            'registrationNumber',
            'statutoryProvision',
            'legalPersonType',
            'registeredOfficeAddress',
            'principalPlaceOfBusiness',
            'contactPerson',
            'emailAddress',
            'phoneNumber',
            'correspondenceAddress',
            'descriptionOfOwnershipStructure',
            'principalActivity',
            'descriptionOfBusinessUndertaken',
            'sanctionsStatus',
            'adverseMediaStatus',
            'sourceOfFunds',
            'registeredForVat',
            'regulated',
            'publiclyListed',
        ]

    const options = fields.map(field => ({
        value: field,
        label: pascalToReadable(field),
    }))

    return (
        <>
            <TextInput
                hidden
                name='id'
            />

            <InputWrapper
                label={'Document Title'}
                description={'Eg. Passport, Certificate of Incorporation, etc.'}
                input={
                    <TextInput
                        name={'title'}
                        required
                        readOnly={readOnly}
                    />
                }
                errors={errors.title}
                readOnly={readOnly}
            />

            <InputWrapper
                label={'Verified Identity Components'}
                description={
                    'Select the identity components that this document is being used to verify.'
                }
                input={
                    <MultiSelect
                        name={'verifiedIdentityFields'}
                        options={options}
                        readOnly={readOnly}
                        required={true}
                        minLength={1}
                    />
                }
                errors={errors.verifiedIdentityFields}
                readOnly={readOnly}
            />

            <InputWrapper
                label={'Document Date'}
                description={'Select the date the document was issued or is valid from.'}
                input={
                    <DateInput
                        name={'date'}
                        placeholder={''}
                        readOnly={readOnly}
                        required
                    />
                }
                errors={errors.date}
                readOnly={readOnly}
            />

            <InputWrapper
                label={'Source'}
                description={
                    'Select whether the document was obtained directly from the entity or via a third party.'
                }
                input={
                    <Select
                        name={'documentSource'}
                        options={Object.keys(DocumentSources).map(documentSource => ({
                            label: pascalToReadable(documentSource),
                            value: documentSource,
                        }))}
                        readOnly={readOnly}
                        required={true}
                    />
                }
                errors={errors.documentSource}
                readOnly={readOnly}
            />

            {documentSource === DocumentSources.ThirdParty ? (
                <InputWrapper
                    label={'Certification'}
                    input={
                        <Select
                            name={'documentCertificationType'}
                            options={Object.keys(DocumentCertificationTypes).map(
                                documentCertificationType => ({
                                    label: pascalToReadable(documentCertificationType),
                                    value: documentCertificationType,
                                })
                            )}
                            readOnly={readOnly}
                            required={true}
                        />
                    }
                    errors={errors.documentCertificationType}
                    readOnly={readOnly}
                />
            ) : null}

            <InputWrapper
                label={'Additional Information'}
                description={
                    'Provide any additional information about the document, such as who certified the copy and/or details of any third parties involved.'
                }
                input={
                    <AutoGrowTextArea
                        name={'notes'}
                        readOnly={readOnly}
                    />
                }
                errors={errors.notes}
                readOnly={readOnly}
            />

            {getValues('fileName') ? <Attachment fileName={getValues('fileName')} /> : null}
        </>
    )
}

export default DocumentForm
