import { Navigate, Route, useParams } from 'react-router-dom'

import { EntityRelationRoutes } from '@features/entities/entity/controllingParties'
import { DocumentsRoutes } from '@features/entities/entity/documents'
import { IdentityRoutes } from '@features/entities/entity/identity'
import { useGetRelationshipReview } from '@features/relationships/relationship/hooks'
import RelationshipEntity from '@features/relationships/relationship/relationshipEntity/views/RelationshipEntity'

import Loading from '@components/loading/Loading'

export enum IdentityPolicyContexts {
    Customer = 'Customer',
    ControllingParty = 'ControllingParty',
    BeneficialOwner = 'BeneficialOwner',
    Introducer = 'Introducer',
}

export const RelationshipEntityRoutes = () => {
    return (
        <>
            <Route
                path='customer/:entityId'
                element={
                    <RelationshipEntity
                        identityPolicyContext={IdentityPolicyContexts.Customer}
                        edd={false}
                    />
                }
            >
                {IdentityRoutes({
                    isNestedInsideRelationship: true,
                    identityPolicyContext: IdentityPolicyContexts.Customer,
                    edd: false,
                })}
                {DocumentsRoutes({
                    isNestedInsideRelationship: true,
                    identityPolicyContext: IdentityPolicyContexts.Customer,
                    edd: false,
                })}
                {EntityRelationRoutes({
                    isNestedInsideRelationship: true,
                })}
                <Route
                    path=''
                    element={<Navigate to='identity' />}
                />
            </Route>
            <Route
                path='controlling-party/:entityId'
                element={
                    <RelationshipEntity
                        identityPolicyContext={IdentityPolicyContexts.ControllingParty}
                        edd={false}
                    />
                }
            >
                {IdentityRoutes({
                    isNestedInsideRelationship: true,
                    identityPolicyContext: IdentityPolicyContexts.ControllingParty,
                    edd: false,
                })}
                {DocumentsRoutes({
                    isNestedInsideRelationship: true,
                    identityPolicyContext: IdentityPolicyContexts.ControllingParty,
                    edd: false,
                })}
                {EntityRelationRoutes({
                    isNestedInsideRelationship: true,
                })}
                <Route
                    path=''
                    element={<Navigate to='identity' />}
                />
            </Route>
            <Route
                path='edd/customer/:entityId'
                element={
                    <RelationshipEntity
                        identityPolicyContext={IdentityPolicyContexts.Customer}
                        edd={true}
                    />
                }
            >
                {IdentityRoutes({
                    isNestedInsideRelationship: true,
                    identityPolicyContext: IdentityPolicyContexts.Customer,
                    edd: true,
                })}
                {DocumentsRoutes({
                    isNestedInsideRelationship: true,
                    identityPolicyContext: IdentityPolicyContexts.Customer,
                    edd: true,
                })}
                {EntityRelationRoutes({
                    isNestedInsideRelationship: true,
                })}
                <Route
                    path=''
                    element={<Navigate to='identity' />}
                />
            </Route>
            <Route
                path='edd/controlling-party/:entityId'
                element={
                    <RelationshipEntity
                        identityPolicyContext={IdentityPolicyContexts.ControllingParty}
                        edd={true}
                    />
                }
            >
                {IdentityRoutes({
                    isNestedInsideRelationship: true,
                    identityPolicyContext: IdentityPolicyContexts.ControllingParty,
                    edd: true,
                })}
                {DocumentsRoutes({
                    isNestedInsideRelationship: true,
                    identityPolicyContext: IdentityPolicyContexts.ControllingParty,
                    edd: true,
                })}
                {EntityRelationRoutes({
                    isNestedInsideRelationship: true,
                })}
                <Route
                    path=''
                    element={<Navigate to='identity' />}
                />
            </Route>
        </>
    )
}
