import { FormStatuses } from '@prisma/client'

import styles from './VersionStatusTag.module.scss'

const VersionStatusTag = ({
    status,
    versionName,
}: {
    status: FormStatuses
    versionName?: string
}) => {
    return (
        <div className={styles.versionStatusTag}>
            <p className={styles.status}>{status}</p>
            {versionName ? <p>{versionName}</p> : null}
        </div>
    )
}

export default VersionStatusTag
